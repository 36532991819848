<template>
  <div
    id="special-offer-badge"
    class="clickable"
    @click="$root.$emit('show-special-offer')"
  >
    <AnimationIconSpecialOffer />
    <div
      class="offer-text bold text-white text-uppercase text-center w-100 position-absolute"
    >
      {{ $t("header.offer") }}
    </div>

    <div class="countdown-box position-absolute w-100">
      <CountdownDigital
        :time="$store.getters['shop/getSpecialOfferTime']"
        :with-time-diff="true"
        @over="clearSpecialOffer"
      />
    </div>
  </div>
</template>
<script>
import AnimationIconSpecialOffer from "@/components/animation/animation-spine-icon-special-offer.vue";
import CountdownDigital from "@/components/base/countdown-digital.vue";
export default {
  name: "SpecialOfferBadge",
  components: { AnimationIconSpecialOffer, CountdownDigital },
  methods: {
    clearSpecialOffer() {
      this.$store.commit("shop/setSpecialOffer", null);
    },
  },
};
</script>
<style lang="scss">
#special-offer-badge {
  z-index: 30;
  width: 120px;
  height: 126px;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;

  .offer-text {
    bottom: 5px;
    z-index: 31;
    left: 13px;
  }

  .countdown-box {
    text-align: center;
    top: 63px;
    left: 13px;
    z-index: 31;
    .countdown-digital {
      color: white;
      font-size: 20.3px;
      font-family: Ubuntu-Bold;
      text-shadow: 0 1px 5px rgba(0, 0, 0, 0.5), 0 1px 5px rgba(0, 0, 0, 0.5),
        0 1px 5px rgba(0, 0, 0, 0.5);
    }
  }
}
</style>
