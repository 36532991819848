<template>
  <div
    id="menu-item-mission"
    class="special-container position-relative clickable"
  >
    <div class="item-content expandable">
      <div
        class="icon-container col clickable"
        @click="
          () => {
            openMissionModal();
            firebaseClickItemFooter('footer_mission_button');
          }
        "
      >
        <img
          draggable="false"
          class="icon"
          src="@/assets/img/nav-menu/nav-icon-mission.png"
        />
      </div>
      <div class="expanded-items-container row">
        <div
          v-for="(chest, i) in chests"
          :key="chest.id"
          class="chest-container col clickable"
        >
          <template v-if="i === 0 && !expired[i]">
            <div
              :id="'active-chest-' + (i + 1) + '-container'"
              :key="'active-chest-' + (i + 1) + '-container'"
              class="chest-item"
              @click="
                () => {
                  openChestModal(i);
                  firebaseClickItemFooter('footer_chest' + (i + 1) + '_button');
                }
              "
            >
              <img
                :id="'active-chest-' + (i + 1) + '-icon'"
                draggable="false"
                class="chest-icon active"
                :src="
                  require('@/assets/img/chests/chest-' +
                    chest.chest.level +
                    '.png')
                "
              />
              <img
                draggable="false"
                class="chest-lock position-absolute"
                src="@/assets/img/common/lock-gold.png"
              />
              <div class="chest-countdown to-center-abs">
                <span class="countdown-numbers bold">
                  {{ timer[i] }}
                </span>
              </div>
            </div>
          </template>
          <template v-else>
            <div
              :key="'closed-chest-' + (i + 1) + '-container'"
              class="chest-item"
              @click="
                () => {
                  openChestModal(i);
                  firebaseClickItemFooter('footer_chest' + (i + 1) + '_button');
                }
              "
            >
              <template v-if="expired[i]">
                <img
                  :id="'closed-chest-' + (3 - i) + '-icon'"
                  draggable="false"
                  class="chest-icon closed"
                  :src="
                    require('@/assets/img/chests/chest-' +
                      chest.chest.level +
                      '-open.png')
                  "
                />
                <span class="open-button-text to-center-abs">
                  {{ $t("footer.chest_open") }}
                </span>
              </template>
              <template v-else>
                <img
                  :id="'closed-chest-' + (3 - i) + '-icon'"
                  draggable="false"
                  class="chest-icon closed"
                  :src="
                    require('@/assets/img/chests/chest-' +
                      chest.chest.level +
                      '.png')
                  "
                />
                <img
                  draggable="false"
                  class="chest-lock position-absolute"
                  src="@/assets/img/common/lock-gold.png"
                />
              </template>
            </div>
          </template>
        </div>

        <div
          v-for="(n, i) in freeSpaces"
          :key="i"
          class="chest-container col clickable"
        >
          <div
            v-if="loaded"
            :key="'empty-chest-' + (3 - freeSpaces + i) + '-container'"
            class="chest-item"
            @click="
              () => {
                openMissionModal(i);
                firebaseClickItemFooter(
                  'footer_chest' + (chests.length + i + 1) + '_button'
                );
              }
            "
          >
            <img
              :id="'empty-chest-' + (3 - freeSpaces + i) + '-icon'"
              draggable="false"
              :src="require('@/assets/img/chests/chest-empty.png')"
              class="chest-icon empty"
            />
            <div
              v-if="i === 0 && missionInfos"
              class="progress-dots d-inline-flex align-items-center w-100 to-center-abs"
            >
              <div
                v-for="(dot, index) in 3"
                :key="`dot-${index}`"
                class="progress-dot"
              >
                <div
                  v-if="
                    (completedTasks >= 1 && index === 0) ||
                    (completedTasks >= 2 && index === 1) ||
                    (completedTasks >= 3 && index === 2)
                  "
                  class="progress-dot fill"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <!--      <div class="arrow">-->
        <!--        <div class="inner-arrow"></div>-->
        <!--      </div>-->
      </div>
      <div v-if="showBadge" class="new-icon">!</div>
      <div class="banner-text">{{ $t("footer.mission_text") }}</div>
      <ModalVanillaMission
        :data="missionData"
        :current-chest="chests[currentChestIndex]"
        :timer="timer[currentChestIndex]"
        @test-finish-mission="testFinishMission"
        @test-transform-chest="testTransformChest"
        @handle-fetch-mission-data="fetchMissionData"
        @handle-change-mission="handleChangeMissions"
        @fetch-chest-data-footer="fetchChestData"
      >
      </ModalVanillaMission>
      <modal-chest-storage-full
        @handle-open-modal-chest="openChestModal(0)"
      ></modal-chest-storage-full>
    </div>
  </div>
</template>
<script>
import * as chestAPI from "@/API/chests";
import ModalVanillaMission from "@/components/mission/modal-vanilla-mission";
import * as missionsAPI from "@/API/missions";
import Vue from "vue";
import ModalChestStorageFull from "@/components/mission/modal-chest-storage-full";

export default {
  name: "ItemMenuVanillaMission",
  components: {
    ModalChestStorageFull,
    ModalVanillaMission,
  },
  data() {
    return {
      timer: [],
      chests: [],
      missionData: [],
      currentChestIndex: 0,
      interval: "",
      loaded: false,
      expired: [false, false, false],
      showBadge: false,
    };
  },
  created() {
    this.$root.$on("mission-accomplished", () => {
      this.openChestModal(0);
    });
  },

  computed: {
    generalConfig: function () {
      return this.$store.getters["getGeneralConfig"];
    },

    freeSpaces: function () {
      return 3 - this.chests.length;
    },
    storageFull: function () {
      return this.chests.length >= 3;
    },
    missionInfos: function () {
      return this.missionData.mission;
    },
    missions: function () {
      return this.missionData.tasks;
    },
    completedTasks() {
      if (!this.missionData.tasks) {
        return;
      }

      let counter = 0;
      this.missionData.tasks.forEach((task) => {
        if (task.completed_at) {
          counter += 1;
        }
      });
      return counter;
    },
    config() {
      return this.$store.state.generalConfig;
    },
  },
  watch: {
    updateMission: {
      handler(data) {
        if (data) {
          this.$store.commit("missions/setUpdateMission", false);
          this.fetchMissionData();
        }
      },
    },
    completeMission: {
      handler(data) {
        this.$store.commit("missions/setMissionComplete", false);
        if (data) {
          const chestIndex = 2 - this.freeSpaces + 1;
          this.fetchChestData().then(() => {
            setTimeout(() => {
              this.openChestModal(chestIndex);
            }, 2000);
          });
        }
      },
    },
  },
  mounted() {
    this.fetchMissionData();
    this.fetchChestData();
  },
  methods: {
    fetchChestData: async function () {
      await chestAPI
        .fetchChests()
        .then((result) => {
          if (result.chests.length <= 0) {
            this.chests = [];
            this.loaded = true;
            return;
          }

          this.chests = result.chests;
          this.resetCountdown();

          if (this.chests[0].unlocked_at != null) {
            this.countdown(this.chests[0].unlocked_at, 0);
          }
          this.loaded = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchMissionData: async function () {
      await missionsAPI
        .fetchMissionen()
        .then((result) => {
          this.missionData = result;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    openMissionModal: function () {
      this.playSoundButtonCommon();

      if (this.freeSpaces === 0) {
        this.$bvModal.show("modal-chest-storage-full");
        return;
      }

      this.currentChestIndex = null;
      this.$bvModal.show("modal-vanilla-mission");
    },

    openChestModal: function (index) {
      this.playSoundButtonCommon();
      this.currentChestIndex = index;
      this.$bvModal.show("modal-vanilla-mission");
    },

    countdown: function (enddate, index) {
      const now = new Date().getTime();
      const target = new Date(enddate).getTime();
      const distance = target - now;

      if (distance < 0) {
        this.endCountdown();
        this.expired[index] = true;
        return;
      }

      this.interval = setInterval(() => {
        // Get today's date and time
        const now = new Date().getTime();
        const target = new Date(enddate).getTime();

        // Find the distance between now and the count down date
        const distance = target - now;
        if (distance < 0) {
          this.endCountdown();
          this.expired[index] = true;
          this.fetchChestData();
          return;
        }
        this.expired[index] = false;

        // Time calculations
        let hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );

        let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((distance % (1000 * 60)) / 1000);
        hours = ("0" + hours).substr(-2, 2);
        minutes = ("0" + minutes).substr(-2, 2);
        seconds = ("0" + seconds).substr(-2, 2);

        let result = hours + ":" + minutes + ":" + seconds;

        // Count down finished?

        Vue.set(this.timer, index, result);
      }, 1000);
    },
    resetCountdown: function () {
      this.endDates = [];
      this.timer = [];
      clearInterval(this.interval);
    },

    endCountdown: function () {
      clearInterval(this.interval);
      this.timer = [];
    },
    handleChangeMissions: async function () {
      this.playSoundButtonCommon();
      await missionsAPI
        .exchangeMission()
        .then((result) => {
          this.firebaseVirtualCurrencyDrain(
            "change_mission_tasks",
            "CROWN",
            this.config.settings["mission.exchange.price"],
            "MISSION"
          );

          this.missionData = result;
          this.playSoundButtonUpcraft();
          this.$bvModal.hide("modal-change-vanilla-mission");
        })
        .catch((err) => {
          if (err.data.id === "general.error.not_enough_currency") {
            this.$bvModal.hide("modal-change-vanilla-mission");
            this.$bvModal.hide("modal-vanilla-mission");
            this.$store.commit("popups/setAlertBannerContent", {
              alertText: this.$t("profile.user.error.not_enough_crowns"),
              alertHeading: this.$t("alerts.title.error"),
              variant: "danger",
              type: "danger",
            });
          } else {
            console.log(err);
          }
        });
    },
    testFinishMission: async function () {
      this.$bvModal.hide("modal-vanilla-mission");

      await missionsAPI
        .testRaise()
        .then(() => {
          this.fetchMissionData().then(async () => {
            await chestAPI.debugChest().then(() => {
              this.fetchChestData();
            });
            await chestAPI.debugChest().then(() => {
              this.fetchChestData();
            });
            await chestAPI.debugChest().then(() => {
              this.fetchChestData();
            });
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    testTransformChest: function (newChestLevel) {
      this.chests[this.currentChestIndex].chest.level = newChestLevel;
    },
  },
};
</script>
<style scoped lang="scss">
.special-container {
  .item-content {
    background-color: #050d1c;
    border-radius: 0.75rem;
    border: 0.125rem solid rgba(77, 229, 255, 0.9);
    box-shadow: inset 0 0 0.25rem $light-blue;
    height: 3.75rem;
    width: 3.75rem;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 1.25rem;

    .icon-container {
      padding: 0 0 0 0.25rem;
      height: 3.438rem;
      .icon {
        height: 3.125rem;
      }
    }

    .expanded-items-container {
      width: 12.5rem;
      position: absolute;
      left: 6.875rem;
      display: none;

      .chest-container {
        height: 3.75rem;
        .chest-item {
          position: absolute;
          top: 0.5rem;
          left: 0;
          .chest-icon {
            &.active {
              height: 2.813rem;
            }

            &.closed {
              height: 2.813rem;
            }

            &.empty {
              height: 2.813rem;
            }
          }

          .open-button-text {
            bottom: 0;
            color: #43ff00;
            font-size: 0.9rem;
            text-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.5);
            text-transform: uppercase;
            left: -0.313rem;
          }

          .progress-dots {
            top: -0.188rem;

            .progress-dot {
              width: 0.75rem;
              height: 0.625rem;
              background: url(~@/assets/img/footer/progress-dot.svg)
                center/0.625rem 0.625rem no-repeat;

              &.fill {
                background: url(~@/assets/img/footer/progress-dot-green.svg)
                  center/0.625rem 0.625rem no-repeat;
              }
            }
          }

          .chest-countdown {
            top: -0.25rem;
            left: -0.563rem;

            .countdown-numbers {
              background: red;
              color: white;
              font-size: 0.875rem;
              padding: 0.063rem;
            }
          }
        }

        .chest-lock {
          width: 1.125rem;
          height: 1.5rem;
          top: 1.375rem;
          right: 0.313rem;
        }
      }

      .arrow {
        width: 0;
        height: 0;
        border-top: 1.438rem solid transparent;
        border-bottom: 1.438rem solid transparent;
        border-right: 1.438rem solid rgba(249, 254, 255, 0.5);
        filter: drop-shadow(0 0rem 0.5rem #4de5ff);
      }

      .inner-arrow {
        position: relative;
        top: -1.25rem;
        left: 0.125rem;
        width: 0;
        height: 0;
        border-top: 1.25rem solid transparent;
        border-bottom: 1.25rem solid transparent;
        border-right: 1.25rem solid #050d1c;
      }
    }

    .new-icon {
      position: absolute;
      top: 0.375rem;
      right: -0.625rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 0.125rem;
      background: linear-gradient(to bottom, #ff0000, #3a0000);
      height: 1.5rem;
      width: 1.5rem;
      color: white;
      font-size: 1.313rem;
      font-family: Ubuntu-bold;
      border-radius: 50%;
    }

    &:hover {
      .banner-text {
        display: flex;
      }
    }
    .banner-text {
      display: none;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 1.25rem;
      right: -9.063rem;
      width: 8rem;
      height: 2rem;
      background-color: rgba(5, 13, 28, 0.8);
      border: 0.063rem solid rgba(77, 229, 255, 0.5);
      border-radius: 0.25rem;
      box-shadow: inset 0 0 0.125rem $light-blue;
      color: white;
      font-size: 0.875rem;
      font-family: Ubuntu-Medium;
      text-transform: uppercase;
      z-index: 999;
    }
  }
}
</style>
