<template>
  <div
    v-show="!loading"
    id="canvas-container-animation-event-levelup"
    ref="canvascontainer"
    class="canvas-container-animation-event-levelup"
  ></div>
</template>

<script>
import AnimationSpineBaseFunctions from "@/components/animation/animation-spine-base-functions";
import { Spine } from "pixi-spine";

export default {
  name: "AnimationEventLevelup",
  mixins: [AnimationSpineBaseFunctions],
  props: {
    loop: {
      type: Boolean,
      default: true,
    },
    run: {
      type: Boolean,
      default: true,
    },
    animationStep: {
      type: String,
      default: "main",
    },
  },
  data() {
    return {
      intervalId: null,
      unsubscribeEvents: [],
      destroyApp: false,
      loading: true,
      version: 1,
    };
  },
  computed: {
    assets() {
      switch (this.animationStep) {
        case "main":
          return [
            [
              "levelup",
              "/assets/spines/events/levelup/leveluprally-main.json?v=" +
                this.version,
            ],
          ];
        case "done":
          return [
            [
              "levelup",
              "/assets/spines/events/levelup/leveluprally-finish.json?v=" +
                this.version,
            ],
            [
              "coinsflight",
              "/assets/spines/events/levelup/leveluprally-coinsflight.json?v=" +
                this.version,
            ],
          ];
        case "winner":
          return [
            [
              "levelup",
              "/assets/spines/events/levelup/leveluprally-winner.json?v=" +
                this.version,
            ],
            [
              "coinsflight",
              "/assets/spines/events/levelup/leveluprally-coinsflight.json?v=" +
                this.version,
            ],
          ];
        default:
          return [
            [
              "levelup",
              "/assets/spines/events/levelup/leveluprally-main.json?v=" +
                this.version,
            ],
          ];
      }
    },
    widthPlacement() {
      switch (this.animationStep) {
        case "winner":
          return 2;
        case "main":
          return 3;
        case "done":
          return 2;
        default:
          return 3;
      }
    },
    heightPlacement() {
      switch (this.animationStep) {
        case "winner":
          return 220;
        case "main":
          return 250;
        case "done":
          return 300;
        default:
          return 300;
      }
    },
  },

  watch: {
    run: function (newVal) {
      if (newVal) {
        this.destroyApp = true;
        this.play();
      }
    },
    animationStep: function (newVal) {
      if (newVal) {
        this.destroyApp = true;
        this.play();
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.intervalId);

    //destroy event listeners
    for (let i = 0; i < this.unsubscribeEvents.length; i++) {
      this.unsubscribeEvents[i]();
    }

    this.destroyApp = true;
  },
  mounted: function () {
    this.play();
  },

  methods: {
    play: function () {
      const assets = this.assets;
      const options = {
        width: 1200,
        height: 639,
        antialias: true,
        backgroundAlpha: 0,
        autoResize: true,
      };

      const app = this.createCanvas(
        options,
        assets,
        this.$refs.canvascontainer.id
      );
      app.view.id = "canvas-animation-event-levelup";

      app.ticker.add(() => {
        if (this.destroyApp) {
          this.destroyApp = false;
          this.destroyAll(app);
        }
      });

      const setup = (loader, res) => {
        let spineData = this.getSpineData(res);

        spineData.forEach((data) => {
          const spine = new Spine(data);

          //skeleton and animation configs
          let animationIndex = 0;

          // set the position
          spine.x = app.screen.width / this.widthPlacement;
          spine.y = app.screen.height - this.heightPlacement;

          //set scale
          spine.scale.set(0.94);

          //add spine to stage
          app.stage.addChild(spine);

          let animation = data.animations[animationIndex];
          //run animation
          spine.state.setAnimation(0, animation.name);

          //show first frame when not running
          if (!this.run) {
            spine.state.getCurrent(0).animationEnd = 0;
            return;
          }

          //set animation end
          spine.state.getCurrent(0).animationEnd = animation.duration;

          // Detach event listeners
          this.unsubscribeEvents.push(() => {
            spine.state.getCurrent(0).onEvent = null;
          });

          //add loop
          if (this.loop) {
            spine.state.getCurrent(0).loop = true;
          }
        });
      };

      app.loader.load(setup);
      app.loader.onComplete.add(() => {
        this.$emit("done");
      });
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
.canvas-container-animation-event-levelup {
  pointer-events: none;
}
#canvas-animation-event-levelup {
  position: relative;
}
</style>
