import { optionsFunction } from "./_config";
const axios = require("axios");

export const config = () =>
  axios
    .get(`api/bonusslot/config`, optionsFunction())
    .then((res) => res.data.data)
    .catch((e) => e.data);

export const collect = () =>
  axios
    .post(`api/bonusslot/collect`, {}, optionsFunction())
    .then((res) => res.data.data)
    .catch((e) => e.data);

export const upgrade = () =>
  axios
    .post(`api/bonusslot/upgrade`, {}, optionsFunction())
    .then((res) => res.data.data)
    .catch((e) => e.data);

export const buyElixir = () =>
  axios.post(`api/bonusslot/buyelixir`, {}, optionsFunction());

export const getBonusSlotSpeedup = () =>
  axios
    .get(`api/bonusslot/speedup`, optionsFunction())
    .then((res) => res.data.data)
    .catch((e) => e.data);

export const buySpeedup = () =>
  axios.post(`api/bonusslot/speedup/${level}`, {}, optionsFunction());
