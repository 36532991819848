<template>
  <b-modal
    id="modal-olympia-medal-bonus"
    ref="modal-olympia-medal-bonus"
    :hide-footer="true"
    modal-class="custom-modal1"
  >
    <template slot="modal-header" slot-scope="{ close }">
      <div
        id="headline"
        class="light-blue-glow bold text-uppercase d-inline-flex align-items-center px-5"
      >
        {{ $t("events.olympics.medal_bonus.headline") }}
      </div>
      <a href="#" class="close-button" @click="close()"> </a>
    </template>

    <template slot="default">
      <div class="container-fluid">
        <b-row id="subline" no-gutters align-v="center" align-h="center"
          ><span
            class="text-uppercase light-blue-glow"
            v-html="subline"
          ></span> </b-row
        ><b-row no-gutters
          ><b-col class="d-flex flex-column justify-content-center"
            ><div class="container-fluid">
              <b-row no-gutters align-h="between"
                ><b-col cols="2"
                  ><span class="text-uppercase text-grey">{{
                    $t("events.olympics.medals>other")
                  }}</span></b-col
                ><b-col
                  ><span class="text-uppercase text-grey">{{
                    $t("events.olympics.medal_bonus.addition_bonus")
                  }}</span></b-col
                ></b-row
              ><b-row no-gutters class="medal-info-row top"
                ><b-col cols="3" class="d-inline-flex align-items-center"
                  ><div class="medal gold"></div>
                  <span class="bold single-letters">=</span></b-col
                ><b-col
                  ><div class="w-100 d-inline-flex align-items-center">
                    <div class="icon gold mr-3"></div>
                    <span class="text-golden bold single-letters mr-3">{{
                      "&"
                    }}</span>
                    <div class="icon crown"></div>
                    <span class="blue bold single-letters">*</span>
                  </div>
                  <div class="w-100 text-left">
                    <span class="text-uppercase medium"
                      >x
                      {{
                        $t("events.olympics.medal_bonus.number_of_medals")
                      }}</span
                    >
                  </div></b-col
                ></b-row
              ><b-row no-gutters class="medal-info-row"
                ><b-col cols="3" class="d-inline-flex align-items-center"
                  ><div class="medal silver"></div>
                  <span class="bold single-letters">=</span></b-col
                ><b-col
                  ><div class="w-100 d-inline-flex align-items-center">
                    <span class="text-golden single-letters mr-3">+20</span>
                    <div class="icon crown"></div>
                    <span class="blue bold single-letters">*</span>
                  </div>
                  <div class="w-100 text-left">
                    <span class="text-uppercase medium">
                      {{ $t("events.olympics.medal_bonus.per_medal") }}</span
                    >
                  </div></b-col
                ></b-row
              ><b-row no-gutters class="medal-info-row bottom"
                ><b-col cols="3" class="d-inline-flex align-items-center"
                  ><div class="medal bronze"></div>
                  <span class="bold single-letters">=</span></b-col
                ><b-col
                  ><div class="w-100 d-inline-flex align-items-center">
                    <span class="text-golden single-letters mr-3"
                      >+1.000.000</span
                    >
                    <div class="icon gold"></div>
                    <span class="blue single-letters bold">**</span>
                  </div>
                  <div class="w-100 text-left">
                    <span class="text-uppercase medium">
                      {{ $t("events.olympics.medal_bonus.per_medal") }}</span
                    >
                  </div></b-col
                ></b-row
              ><b-row no-gutters align-v="center" align-h="between"
                ><span class="blue text-uppercase footnotes medium"
                  >*{{
                    $t("events.olympics.medal_bonus.purchase_required")
                  }}</span
                ><span class="blue text-uppercase medium footnotes"
                  >**{{
                    $t("events.olympics.medal_bonus.purchase_not_required")
                  }}</span
                ></b-row
              >
            </div></b-col
          ><b-col class="d-flex flex-column justify-content-center"
            ><div class="container-fluid text-left">
              <b-row no-gutters class="medal-info-row plain"
                ><span class="text-grey text-uppercase">
                  {{ $t("events.olympics.medal_bonus.example") }}</span
                >
              </b-row>
              <b-row no-gutters class="medal-info-row plain p-0"
                ><span class="text-uppercase bold text-white">
                  {{ $t("events.olympics.medal_bonus.package_purchase") }}</span
                >
              </b-row>
              <b-row
                no-gutters
                align-h="start"
                align-v="center"
                :style="{ 'font-size': '36px' }"
                class="pb-0"
              >
                <span class="text-golden bold mr-3">300.000.000</span>
                <div class="icon gold mr-5"></div>
                <span class="text-golden bold mr-3">150</span>
                <div class="icon crown"></div>
              </b-row>
              <b-row no-gutters class="medal-info-row plain pb-0"
                ><span class="text-uppercase text-white bold">
                  {{ $t("events.olympics.medal_bonus.won_medals") }}</span
                >
              </b-row>
              <b-row
                no-gutters
                align-v="center"
                align-h="between"
                class="medal-info-row plain"
                ><b-col
                  cols="3"
                  class="d-inline-flex align-items-center justify-content-between"
                  ><span class="text-white bold bigger">2 x</span>
                  <div class="medal gold small"></div>
                  <span class="text-white bold bigger">=</span></b-col
                ><b-col class="d-flex flex-column ml-1"
                  ><div class="d-inline-flex align-items-center">
                    <span class="text-golden bold">+600.000.000 </span>
                    <div class="icon gold small ml-1"></div>
                  </div>
                  <span class="text-grey light">(300.000.000 x 2)</span></b-col
                ><b-col cols="3" class="d-flex flex-column"
                  ><div class="d-inline-flex align-items-center">
                    <span class="text-golden bold">+300</span>
                    <div class="icon crown small"></div>
                  </div>
                  <span class="text-grey light">(150 x 2)</span></b-col
                ></b-row
              ><b-row
                no-gutters
                align-v="center"
                align-h="between"
                class="medal-info-row plain"
                ><b-col
                  cols="3"
                  class="d-inline-flex align-items-center justify-content-between"
                  ><span class="text-white bold bigger">2 x</span>
                  <div class="medal silver small"></div>
                  <span class="text-white bold bigger">=</span></b-col
                ><b-col class="d-flex flex-column ml-1"
                  ><div class="d-inline-flex align-items-center">
                    <span class="text-golden bold">+40</span>
                    <div class="icon crown small"></div>
                  </div>
                  <span class="text-grey light">(20 x 2)</span></b-col
                ></b-row
              ><b-row no-gutters align-v="center" class="medal-info-row plain"
                ><b-col
                  cols="3"
                  class="d-inline-flex align-items-center justify-content-between"
                  ><span class="text-white bold bigger">2 x</span>
                  <div class="medal bronze small"></div>
                  <span class="text-white bold bigger">=</span> </b-col
                ><b-col class="d-flex flex-column ml-1"
                  ><div class="d-inline-flex align-items-center">
                    <span class="text-golden bold">+2.000.000 </span>
                    <div class="icon gold small ml-1"></div>
                  </div>
                  <span class="text-grey light">(1.000.000 x 2)</span></b-col
                ></b-row
              ><b-row no-gutters class="medal-info-row plain pb-0"
                ><span class="text-white text-uppercase light-blue-glow">{{
                  $t("events.olympics.medal_bonus.total_bonus")
                }}</span></b-row
              >
              <b-row
                no-gutters
                align-h="start"
                align-v="center"
                :style="{ 'font-size': '36px' }"
                ><b-col class="d-flex flex-column">
                  <div class="d-inline-flex align-items-center">
                    <span class="text-golden bold mr-3">+602.000.000</span>
                    <div class="icon gold mr-3"></div>
                  </div>
                  <span class="text-grey light">(600.000.000 + 2.000.000)</span>
                </b-col>
                <b-col cols="4" class="d-flex flex-column"
                  ><div class="d-inline-flex align-items-center">
                    <span class="text-golden bold mr-3">+340</span>
                    <div class="icon crown"></div>
                  </div>
                  <span class="text-grey light">(300 + 40)</span>
                </b-col>
              </b-row>
            </div></b-col
          ></b-row
        >
      </div></template
    >
  </b-modal>
</template>

<script>
export default {
  name: "ModalOlympiaMedalBonus",
  components: {},

  data() {
    return {};
  },

  computed: {
    subline() {
      return this.$t("events.olympics.medal_bonus.subline");
    },
  },
};
</script>
<style lang="scss">
#modal-olympia-medal-bonus {
  .modal-dialog {
    max-width: 1160px;
    width: 100%;
    height: 720px;
    padding-top: 50px;

    @media (max-height: 750px), (max-width: 1200px) {
      transform: scale(0.9);
      top: -40px;
      margin-bottom: -10%;
    }
    @media (max-height: 700px), (max-width: 1100px) {
      transform: scale(0.8);
      top: -100px;
      margin-bottom: -20%;
    }
  }
  .light-blue-glow {
    text-shadow: 0 0 16px rgba(77, 229, 255, 0.13), 0 0 8px $light-blue;
  }
  .text-grey {
    color: #524749;
    font-family: Ubuntu-medium;
    font-size: 21px;
    line-height: 1;
    @media (max-width: 1200px) {
      font-size: 18px;
    }
    &.light {
      color: #8e8e8e;
    }
  }
  .text-golden {
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    background-image: linear-gradient(to bottom, #f7ffff, #ffee51 58%, #8b5a00);
    filter: drop-shadow(0 0 12px rgba(0, 0, 0, 0.5))
      drop-shadow(0 0 6px rgba(0, 0, 0, 0.8));
  }
  .icon {
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 32px;

    &.gold {
      background-image: url(~@/assets/img/common/coin1.png);
      width: 32px;
      &.small {
        width: 24px;
        height: 24px;
      }
    }
    &.crown {
      background-image: url(~@/assets/img/common/crown6.png);
      width: 43px;
      &.small {
        width: 32px;
        height: 24px;
      }
    }
  }

  .modal-header {
    margin-top: 0;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-bottom: 0;
    #headline {
      font-size: 40px;
      height: 96px;
      background: url(~@/assets/img/events/gfg-wintable-headline-bg.svg)
        center/100% 100% no-repeat;
      margin-top: -45px;
    }
    .close-button {
      right: -50px;
      top: 0;
    }
  }

  .modal-body {
    margin-top: 0 !important;
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding-top: 0;
    #subline {
      font-size: 24px;
    }
    .medal-info-row {
      padding-bottom: 8px;
      padding-top: 8px;
      border-bottom: 2px solid black;
      border-top: 2px solid #4f4f4f;
      &.top {
        border-top: none;
      }
      &.bottom {
        border-bottom: none;
      }
      &.plain {
        border: none;
      }
      .text-white {
        font-size: 21px;
        &.bigger {
          font-size: 26px;
        }
      }
      .text-golden {
        font-size: 28px;
      }

      .medal {
        width: 71px;
        height: 100px;
        background-position: center;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin-right: 10px;
        &.gold {
          background-image: url(~@/assets/img/events/gfg-medal-gold.png);
        }
        &.silver {
          background-image: url(~@/assets/img/events/gfg-medal-silver.png);
        }
        &.bronze {
          background-image: url(~@/assets/img/events/gfg-medal-bronze.png);
        }
        &.small {
          width: 39px;
          height: 54px;
        }
      }
      .single-letters {
        font-size: 36px;
        font-family: Ubuntu-bold;
      }
      .text-left {
        font-size: 28px;
      }
    }
    .footnotes {
      font-size: 18px;
    }
  }

  .modal-content {
    width: 100%;
    height: 100%;
    background: url(~@/assets/img/events/gfg-wintable-main-bg.svg) center/100%
      100% no-repeat;
  }
}
</style>
