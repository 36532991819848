<template>
  <div
    class="deco-button"
    role="button"
    :class="[colorVariant, size, width, { disabled: isDisabled }]"
    @click="$emit('click')"
  >
    <slot></slot>
    <div class="deco-gem left" :class="size"></div>
    <div class="deco-gem right" :class="size"></div>
    <div
      v-if="iconType.length > 0"
      class="deco-button-icon to-center-abs"
      :class="iconType"
    ></div>
  </div>
</template>

<script>
export default {
  name: "DecoButton",
  props: {
    // available colors so far: 'green' and 'blue'
    colorVariant: {
      type: String,
      default: "blue",
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    // available size so far: 'lg', 'md'
    size: {
      type: String,
      default: "",
    },
    // set width to 'wide' for longer buttons
    width: {
      type: String,
      default: "",
    },
    // available types so far: 'slot-battle', 'slot-battle flames' and 'chat-message'
    iconType: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped lang="scss">
.deco-button {
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent;
  border: 1px solid $hightlight-yellow;
  height: 26px;
  min-width: 71px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 13px;
  font-family: Ubuntu-Medium;
  color: #fff8b7;
  text-transform: uppercase;
  cursor: pointer;
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.5));
  padding: 0 8px 0 8px;
  @media (max-width: 1050px) {
    font-size: 11px;
  }
  &.wide {
    padding: 0 16px 0 16px;
  }
  &.lg {
    height: 38px;
    font-size: 21px;
  }
  &.md {
    height: 32px;
    font-size: 18px;
  }
  &.disabled {
    filter: grayscale(100%);
    cursor: default;
    pointer-events: none;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      /* IE10+ */
      &:before {
        content: "";
        background-color: rgba(136, 136, 136, 0.5);
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
  &.blue {
    background-image: linear-gradient(180deg, #4de5ff 0%, #050d1c 61%);
    box-shadow: inset 0 0 2px 0 #4de5ff, inset 0 0 4px 0 #4de5ff,
      inset 0 0 7px 0 #4de5ff;
    &:hover {
      background-image: linear-gradient(180deg, #4de5ff 0%, #050d1c 100%);
      box-shadow: inset 0 0 2px #4de5ff, 0 0 4px #4de5ff, 0 0 7px #4de5ff;
    }
    &.force-hover {
      background-image: linear-gradient(180deg, #4de5ff 0%, #050d1c 100%);
      box-shadow: inset 0 0 2px #4de5ff, 0 0 4px #4de5ff, 0 0 7px #4de5ff;
    }
  }
  &.green {
    background-image: linear-gradient(179deg, #2dac00 7%, #0a2700 71%);
    border: 1px solid #ffe600;
    box-shadow: inset 0 0 2px 0 #43ff00, inset 0 0 4px 0 #43ff00,
      inset 0 0 6px 0 rgba(67, 255, 0, 0.43);
    &:hover {
      background-image: linear-gradient(179deg, #2dac00 7%, #228000 100%);
      box-shadow: inset 0 0 2px 0 #43ff00, inset 0 0 4px 0 #43ff00,
        inset 0 0 6px 0 rgba(67, 255, 0, 0.43),
        inset 0 0 8px 0 rgba(67, 255, 0, 0.87),
        inset 0 0 8px 0 rgba(67, 255, 0, 0.87);
    }
    &.force-hover {
      background-image: linear-gradient(179deg, #2dac00 7%, #228000 100%);
      box-shadow: inset 0 0 2px 0 #43ff00, inset 0 0 4px 0 #43ff00,
        inset 0 0 6px 0 rgba(67, 255, 0, 0.43),
        inset 0 0 8px 0 rgba(67, 255, 0, 0.87),
        inset 0 0 8px 0 rgba(67, 255, 0, 0.87);
    }
  }
}

.deco-gem {
  position: absolute;
  height: 145%;
  width: 13px;
  top: -6px;
  &.lg {
    width: 21px;
    top: -9px;
  }

  &.left {
    background: url(~@/assets/img/buttons/deco-button-frame-left.png)
      center/100% 100% no-repeat;
    left: -6px;
    &.lg {
      left: -10px;
    }
  }
  &.right {
    background: url(~@/assets/img/buttons/deco-button-frame-right.png)
      center/100% 100% no-repeat;
    right: -6px;
    &.lg {
      right: -10px;
    }
  }
}
.deco-button-icon {
  pointer-events: none;
  position: absolute;

  &.slot-battle {
    width: 124px;
    height: 59px;
    top: -59px;
    background: url(~@/assets/img/friends/icon-slot-machine.png) center/100%
      100% no-repeat;
    &.flames {
      width: 205px;
      height: 150px;
      top: -150px;
      background: url(~@/assets/img/friends/icon-slot-machine-flames.png)
        center/100% 100% no-repeat;
    }
  }
  &.chat-message {
    width: 48px;
    height: 45px;
    right: -18px;
    top: -38px;
    background: url(~@/assets/img/chat/speech-bubble-small.svg) center/100% 100%
      no-repeat;
    -webkit-transform: translate3d(0, 0, 0);
  }
  &.cards {
    width: 75px;
    height: 75px;
    right: -55px;
    bottom: -20px;
    background: url(~@/assets/img/nav-menu/nav-icon-cards.png) center/100% 100%
      no-repeat;
  }
}
</style>
