<template>
  <b-modal
    id="modal-winter-help"
    ref="modal-winter-help"
    :hide-footer="true"
    modal-class="custom-modal1"
    @shown="onOpen"
  >
    <template slot="default">
      <div class="preload"></div>
      <div class="w-100 h-100 bg"></div>
      <template>
        <!--page 1-->
        <div v-if="currentPage === 1" id="page1">
          <div class="logo">
            <div class="main"></div>
            <div class="bonus" :class="$store.state.locale"></div>
          </div>

          <div class="headline-white text1 to-center-abs">
            {{ $t("events.winter.manual.page1.headline") }}
          </div>

          <div class="visual to-center-abs"></div>
        </div>

        <!--page 2-->
        <div v-else-if="currentPage === 2" id="page2">
          <div class="logo">
            <div class="main"></div>
            <div class="bonus" :class="$store.state.locale"></div>
          </div>

          <div class="headline-white head-badge">
            {{ $t("events.winter.manual.page2.headline") }}
          </div>

          <div class="sub-head headline-sub-white">
            {{ $t("events.winter.manual.page2.sub-head") }}
          </div>

          <div class="price1">
            <div class="text1">1 x</div>
            <div class="text2">
              {{ $t("events.winter.manual.page2.tv.text1") }}
            </div>
            <div class="text3">
              {{ $t("events.winter.manual.page2.tv.text2") }}
            </div>
          </div>

          <div class="price2">
            <div class="text1">1 x</div>
            <div class="text2">
              {{ $t("events.winter.manual.page2.shell.text1") }}
            </div>
            <!--            <div class="text3">-->
            <!--              {{ $t("events.winter.manual.page2.shell.text2") }}-->
            <!--            </div>-->
          </div>

          <div class="price3">
            <div class="text1">2 x</div>
            <div class="text2">
              {{ $t("events.winter.manual.page2.amazon.text1") }}
            </div>
          </div>

          <div class="img-price to-center-abs"></div>
        </div>

        <!--page 3-->
        <div v-else-if="currentPage === 3" id="page3">
          <div class="logo">
            <div class="main"></div>
            <div class="bonus" :class="$store.state.locale"></div>
          </div>

          <div class="text-side">
            <div class="headline-gold">
              {{ $t("events.winter.manual.page3.headline") }}
            </div>

            <div class="normal-white mt-4">
              {{ $t("events.winter.manual.page3.text1") }}
            </div>

            <div class="normal-white mt-4">
              <i18next path="events.winter.manual.page3.text2" tag="span">
                <span class="highlight" place="0">
                  {{ $t("events.winter.manual.page3.text2_highlighted") }}
                </span>
              </i18next>
              <span class="icn-badge">
                <span class="icn-badge-1"></span>
              </span>
            </div>
            <div class="normal-white mt-4">
              <i18next path="events.winter.manual.page3.text3" tag="span">
                <span class="highlight" place="0">
                  {{ $t("events.winter.manual.page3.text3_highlighted") }}
                </span>
              </i18next>
              <span class="icn-badge">
                <span class="icn-badge-2"></span>
              </span>
            </div>
          </div>

          <div class="example-img"></div>
        </div>

        <!--page 4-->
        <div v-else-if="currentPage === 4" id="page4">
          <div class="logo">
            <div class="main"></div>
            <div class="bonus" :class="$store.state.locale"></div>
          </div>

          <div class="text-side">
            <div class="headline-gold">
              {{ $t("events.winter.manual.page4.headline") }}
            </div>

            <div class="page4-headline mt-4">
              <div class="headline-sub-white2">
                {{ $t("events.winter.manual.page4.sub.text1") }}
              </div>

              <div class="headline-white">
                {{ $t("events.winter.manual.page4.sub.text2") }}
              </div>
            </div>

            <div class="normal-white mt-3 space-right">
              <i18next path="events.winter.manual.page4.text1" tag="span">
                <span class="highlight" place="0">
                  {{ $t("events.winter.manual.page4.text1_highlighted") }}
                </span>
              </i18next>
            </div>

            <div class="normal-white mt-3 space-right">
              {{ $t("events.winter.manual.page4.text2") }}
            </div>
          </div>

          <div class="example-img"></div>
        </div>

        <!--page 5-->
        <div v-else-if="currentPage === 5" id="page5">
          <div class="logo">
            <div class="main"></div>
            <div class="bonus" :class="$store.state.locale"></div>
          </div>

          <div class="text-side-left">
            <div class="page5-headline headline-gold">
              {{ $t("events.winter.manual.page5.headline") }}
            </div>

            <div class="normal-white-small mt-3">
              {{ $t("events.winter.manual.page5.text1") }}
            </div>

            <div class="normal-white-small mt-3">
              {{ $t("events.winter.manual.page5.text2") }}
            </div>
          </div>

          <div class="text-side-right">
            <div class="normal-white-small mt-3">
              {{ $t("events.winter.manual.page5.text3") }}
            </div>

            <div class="normal-white-small mt-3">
              {{ $t("events.winter.manual.page5.text4") }}
            </div>
          </div>

          <div class="text-bottom">
            <div class="text-icon"></div>

            <div class="text1 headline-white">
              {{ $t("events.winter.manual.page5.text5") }}
            </div>

            <div class="text2 normal-white">
              {{ $t("events.winter.manual.page5.text6") }}
            </div>
          </div>
        </div>
      </template>

      <div class="bottom-menu to-center-abs">
        <div v-if="currentPage >= 2" class="arrow left" @click="prevPage"></div>
        <div class="arrow right" @click="nextPage"></div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import eventPopups from "@/mixins/eventPopups.js";
import getRewardsFromPackage from "@/mixins/paymentFunctions.js";
import AnimationFullRewardEarned from "@/components/animation/animation-full-reward-earned";
import dateFunctions from "@/mixins/dateFunctions.js";

export default {
  name: "ModalWinterHelp",
  components: {},
  mixins: [
    eventPopups,
    getRewardsFromPackage,
    AnimationFullRewardEarned,
    dateFunctions,
  ],

  data() {
    return {
      modalId: "modal-winter-help",
      currentPage: 4,
      maxPages: 5,
    };
  },

  mounted() {
    // this.$bvModal.show("modal-winter-help");
  },
  methods: {
    onOpen() {
      this.currentPage = 1;
    },
    prevPage() {
      if (this.currentPage <= 0) {
        this.currentPage = 1;
        return;
      }
      this.currentPage--;
    },
    nextPage() {
      if (this.currentPage >= this.maxPages) {
        this.$bvModal.hide(this.modalId);
        return;
      }
      this.currentPage++;
    },
  },
};
</script>
<style lang="scss">
$modal-width: 822px;
$modal-height: 535px;

#modal-winter-help {
  .preload {
    height: 0;
    width: 0;
    background-image: url(~@/assets/img/events/bundesliga/btn-collect-all-bg-hover.png);
  }

  .modal-dialog {
    min-width: $modal-width;
    max-width: $modal-width;
    width: 100%;
    height: $modal-height;
    margin-top: 80px;

    @media (max-height: 850px), (max-width: 1250px) {
      transform: scale(0.8);
      top: -40px;
    }
    @media (max-height: 770px), (max-width: 1100px) {
      transform: scale(0.7);
      top: -100px;
    }
    @media (max-width: 1100px) {
      left: -5%;
    }
  }

  .modal-body {
    width: 100%;
    height: 100%;
    overflow: visible;
    padding: 0;
    .bg {
      position: absolute;
      left: 0;
      top: 0;
      background: url(~@/assets/img/events/winter-bonus-jagd/manual-bg-main.png)
        center/100% 100% no-repeat;
    }
  }

  .modal-header {
    visibility: hidden;
  }

  .modal-content {
    width: 100%;
    height: 100%;
    background: none;
    line-height: 1.2;

    .icn-badge {
      display: inline-block;
      position: relative;
      left: 7px;
      top: -22px;

      .icn-badge-1 {
        width: 28px;
        height: 29px;
        background: url(~@/assets/img/events/winter-bonus-jagd/icn-badge-1.png)
          right/100% 100% no-repeat;
        position: absolute;
      }

      .icn-badge-2 {
        width: 28px;
        height: 29px;
        background: url(~@/assets/img/events/winter-bonus-jagd/icn-badge-2.png)
          right/100% 100% no-repeat;
        position: absolute;
      }
    }

    .bottom-menu {
      top: 50%;
      width: 77%;

      a {
        font-family: Ubuntu-Bold;
        font-size: 22px;
        color: #8e8e8e;
        cursor: pointer;
      }

      .arrow {
        position: absolute;
        top: -35px;
        width: 68px;
        height: 68px;
        cursor: pointer;

        &.left {
          background: url(~@/assets/img/events/winter-bonus-jagd/btn-slide-arrow.png)
            bottom center/100% 100% no-repeat;
          left: -120px;
          transform: scaleX(-1);
        }

        &.right {
          background: url(~@/assets/img/events/winter-bonus-jagd/btn-slide-arrow.png)
            bottom center/100% 100% no-repeat;
          right: -120px;
        }
      }
    }

    .logo {
      z-index: 30;
      .main {
        position: absolute;
        left: 13px;
        top: 0;
        z-index: 20;
        background: url(~@/assets/img/events/winter-bonus-jagd/wbj-logo-main.png)
          right/100% 100% no-repeat;
      }

      .bonus {
        position: absolute;
        left: 0;
        top: 84px;
        z-index: 21;

        &.en {
          background: url(~@/assets/img/events/winter-bonus-jagd/logotype-en.png)
            right/100% 100% no-repeat;
        }
        &.de {
          background: url(~@/assets/img/events/winter-bonus-jagd/logotype-de.png)
            right/100% 100% no-repeat;
        }
      }
    }

    .headline-white {
      font-family: Ubuntu-Bold;
      font-size: 36px;
      color: white;
      text-shadow: 0 0 18px rgba(77, 229, 255, 0.5),
        0 0 18px rgba(77, 229, 255, 0.5);
      text-transform: uppercase;
      line-height: 1.3;
    }

    .headline-sub-white {
      font-family: Ubuntu-Bold;
      font-size: 26px;
      color: white;
      text-shadow: 0 0 18px rgba(77, 229, 255, 0.5),
        0 0 18px rgba(77, 229, 255, 0.5);
      text-transform: uppercase;
      line-height: 1.2;
    }

    .headline-sub-white2 {
      font-family: Ubuntu-Medium;
      font-size: 24px;
      color: white;
      text-shadow: 0 0 18px rgba(77, 229, 255, 0.5),
        0 0 18px rgba(77, 229, 255, 0.5);
      text-transform: uppercase;
      line-height: 1.2;
    }

    .headline-gold {
      font-family: Ubuntu-Bold;
      font-size: 33px;
      color: white;
      text-transform: uppercase;
      line-height: 1.3;
      background-image: linear-gradient(
        to bottom,
        #fef7bb,
        #f9bc19 55%,
        #da8408 70%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      //IE10
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        /* IE10+ */
        & {
          background: transparent;
          color: rgb(253, 242, 141);
        }
      }

      // Safari (7.1+)
      _::-webkit-full-page-media,
      _:future,
      :root & {
        -webkit-text-fill-color: #ffe824 !important;
      }
    }

    .normal-white {
      font-family: Ubuntu-Medium;
      font-size: 24px;
      color: white;
      line-height: 1.2;
    }

    .normal-white-small {
      font-family: Ubuntu-Medium;
      font-size: 20px;
      color: white;
      line-height: 1.2;
    }

    .highlight {
      font-family: Ubuntu-Medium;
      font-size: 24px;
      color: #fabe1e;
    }

    #page1 {
      width: 100%;
      height: 100%;

      .logo {
        position: absolute;
        left: 210px;
        top: -60px;

        .main {
          width: 304px;
          height: 132px;
        }

        .bonus {
          width: 340px;
          height: 120px;
        }
      }

      .text1 {
        top: 145px;
        padding: 0 101px;
      }

      .visual {
        bottom: 25px;
        width: 712px;
        height: 218px;
        background: url(~@/assets/img/events/winter-bonus-jagd/wbj-manual-1-visual.png)
          right/100% 100% no-repeat;
      }
    }

    #page2 {
      .logo {
        position: absolute;
        left: -60px;
        top: -90px;

        .main {
          width: 304px;
          height: 132px;
        }

        .bonus {
          width: 340px;
          height: 120px;
        }
      }

      .head-badge {
        position: absolute;
        right: 60px;
        top: -25px;
        width: 454px;
        height: 70px;
        background: linear-gradient(to bottom, #4de5ff, #050d1c 57%),
          linear-gradient(to bottom, #8b5a00, #f7ffff 41%, #ffee51 75%, #8b5a00);
        border: double 2px transparent;
        background-origin: border-box;
        background-clip: padding-box, border-box;
        border-radius: 25.2px;
        line-height: 70px;
      }

      .sub-head {
        position: absolute;
        right: 75px;
        top: 75px;
        width: 450px;
        text-align: left;
      }

      .price1 {
        width: 165px;
        height: 165px;
        background: url(~@/assets/img/events/winter-bonus-jagd/raffle-badge-orange.png)
          right/100% 100% no-repeat;
        position: absolute;
        left: 70px;
        top: 117px;
        text-align: center;
        color: white;
        padding: 21px 7px 0 7px;
        z-index: 10;

        .text1 {
          font-family: Ubuntu-bold;
          font-size: 32px;
        }
        .text2 {
          font-family: Ubuntu-bold;
          font-size: 21px;
          text-transform: uppercase;
        }
        .text3 {
          font-family: Ubuntu-Medium;
          font-size: 13px;
          text-transform: uppercase;
          margin-top: 5px;
        }
      }

      .price2 {
        width: 128px;
        height: 128px;
        background: url(~@/assets/img/events/winter-bonus-jagd/wbj-manual-badge-amazon-voucher.png)
          right/100% 100% no-repeat;
        position: absolute;
        text-align: center;
        color: white;
        padding: 18px 0 0 0;
        z-index: 10;
        left: 369px;
        top: 157px;

        .text1 {
          font-family: Ubuntu-bold;
          font-size: 27px;
        }
        .text2 {
          font-family: Ubuntu-bold;
          font-size: 13px;
          text-transform: uppercase;
          padding: 0 30px;
          line-height: 1;
        }
        .text3 {
          font-family: Ubuntu-Medium;
          font-size: 11px;
          text-transform: uppercase;
          margin-top: 5px;
        }
      }

      .price3 {
        width: 128px;
        height: 128px;
        background: url(~@/assets/img/events/winter-bonus-jagd/wbj-manual-badge-amazon-voucher.png)
          right/100% 100% no-repeat;
        position: absolute;
        text-align: center;
        color: white;
        padding: 18px 0 0 0;
        z-index: 10;
        left: 613px;
        top: 178px;

        .text1 {
          font-family: Ubuntu-bold;
          font-size: 27px;
        }
        .text2 {
          font-family: Ubuntu-bold;
          font-size: 13px;
          text-transform: uppercase;
          padding: 0 30px;
          line-height: 1;
        }
        .text3 {
          font-family: Ubuntu-Medium;
          font-size: 11px;
          text-transform: uppercase;
          margin-top: 5px;
        }
      }

      .img-price {
        width: 687px;
        height: 290px;
        background: url(~@/assets/img/events/winter-bonus-jagd/wbj-manual-1-visual-update-2022.png)
          right/100% 100% no-repeat;
        bottom: 25px;
      }
    }

    #page3 {
      .logo {
        position: absolute;
        left: 475px;
        top: -63px;

        .main {
          width: 272px;
          height: 118px;
        }

        .bonus {
          width: 304px;
          height: 107px;
          top: 75px;
        }
      }

      .text-side {
        width: 400px;
        position: absolute;
        left: 70px;
        top: 50px;
        text-align: left;
      }

      .example-img {
        position: absolute;
        left: 483px;
        bottom: 12px;
        width: 281px;
        height: 309px;
        background: url(~@/assets/img/events/winter-bonus-jagd/manual-demo-panel.png)
          right/100% 100% no-repeat;
      }
    }

    #page4 {
      .logo {
        position: absolute;
        left: 475px;
        top: -63px;

        .main {
          width: 272px;
          height: 118px;
        }

        .bonus {
          width: 304px;
          height: 107px;
          top: 75px;
        }
      }

      .text-side {
        width: 700px;
        position: absolute;
        left: 70px;
        top: 55px;
        text-align: left;

        .space-right {
          padding-right: 350px;
        }

        .headline-white {
          line-height: 1.1;
        }
      }

      .example-img {
        position: absolute;
        left: 415px;
        bottom: 30px;
        width: 329px;
        height: 280px;
        background: url(~@/assets/img/events/winter-bonus-jagd/wbj-manual-2-visual-update-2022.png)
          right/100% 100% no-repeat;
      }
    }

    #page5 {
      .logo {
        position: absolute;
        left: 475px;
        top: -63px;

        .main {
          width: 272px;
          height: 118px;
        }

        .bonus {
          width: 304px;
          height: 107px;
          top: 75px;
        }
      }

      .text-side-left {
        width: 300px;
        position: absolute;
        left: 70px;
        top: 125px;
        text-align: left;

        .page5-headline {
          position: absolute;
          left: 0;
          top: -50px;
        }
      }

      .text-side-right {
        width: 300px;
        position: absolute;
        left: 450px;
        top: 125px;
        text-align: left;
      }

      .text-bottom {
        position: absolute;
        left: 180px;
        bottom: 35px;
        text-align: left;
        width: 610px;

        .text-icon {
          position: absolute;
          left: -150px;
          top: -15px;
          width: 142px;
          height: 113px;
          background: url(~@/assets/img/events/winter-bonus-jagd/icn-tickets.png)
            right/100% 100% no-repeat;
        }

        .text1 {
          font-size: 21px;
          text-transform: none;
          line-height: 1.2;
        }

        .text2 {
          font-size: 16px;
          font-family: Ubuntu;
          margin-top: 7px;
        }
      }
    }
  }
}
</style>
