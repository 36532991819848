<template>
  <div
    class="matchday-entry d-inline-flex align-items-center justify-content-between clickable"
    @click="$emit('matchdaySelected', index)"
  >
    <span class="date" :class="[isSelected ? 'blue' : '']">{{
      matchdayDate
    }}</span>
    <div
      class="matchday-icons d-inline-flex align-items-center justify-content-between"
    >
      <div class="matchday-icon cart" :class="cartClass"></div>
      <div class="matchday-icon medal" :class="medalClass"></div>
      <div class="matchday-icon bonus" :class="bonusClass"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ModalOlympiaMatchdayEntry",
  props: {
    matchday: { type: Object, default: () => {} },
    index: { type: Number, default: 0 },
    selectedMatchdayIndex: { type: Number, default: 0 },
    todaysMatchdayIndex: { type: Number, default: 0 },
    ingameEvents: { type: Array, default: () => [] },
    userRaffles: { type: Array, default: () => [] },
    activeMatchdayIndex: { type: Number, default: 0 },
  },

  computed: {
    matchdayDate() {
      return new Date(this.matchday.start_at).toLocaleDateString(
        this.$store.state.locale,
        {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        }
      );
    },
    allRaffles() {
      return this.$store.state.events.paymentEventRaffles;
    },
    isToday() {
      return this.index === this.todaysMatchdayIndex;
    },

    isActive() {
      return this.index === this.activeMatchdayIndex;
    },

    isInFuture() {
      return this.index > this.todaysMatchdayIndex;
    },
    matchdayRaffle() {
      if (!this.allRaffles) return null;
      return this.allRaffles.find(
        (raffle) => raffle.raffleable_id === this.matchday.id
      );
    },
    matchdayUserRaffle() {
      if (!this.userRaffles) return null;
      return this.userRaffles.find(
        (userRaffle) => userRaffle.raffle.raffleable_id === this.matchday.id
      );
    },
    matchdayIngameEvent() {
      if (!this.ingameEvents) return null;
      return this.ingameEvents.find((event) => {
        if (!event) return null;
        return event.ingame_event_id === this.matchday.id;
      });
    },
    packagePurchased() {
      if (this.matchdayIngameEvent && this.matchdayIngameEvent.payload) {
        return this.matchdayIngameEvent.payload.packages.length > 0;
      } else {
        return false;
      }
    },

    medalsDecided() {
      if (this.matchdayRaffle) {
        return true;
      }

      return false;
    },
    bonusAvailable() {
      if (this.matchdayUserRaffle) {
        return true;
      }
      return false;
    },
    bonusCollected() {
      if (!this.matchdayUserRaffle) {
        return false;
      } else {
        if (this.matchdayUserRaffle.status === "COLLECTED") {
          return true;
        } else {
          return false;
        }
      }
    },
    isSelected() {
      return this.index === this.selectedMatchdayIndex;
    },
    cartClass() {
      if (
        (!this.isToday && !this.packagePurchased && !this.isInFuture) ||
        (this.isToday && !this.isActive && !this.packagePurchased)
      ) {
        return "nobuy";
      } else if (!this.packagePurchased || this.isInFuture) {
        return "";
      } else if (this.packagePurchased) {
        return "active";
      } else {
        return "";
      }
    },
    medalClass() {
      if (this.medalsDecided) {
        return "active";
      } else {
        return "";
      }
    },
    bonusClass() {
      if (this.bonusAvailable && !this.bonusCollected) {
        return "active";
      }
      if (this.bonusCollected) {
        return "collected";
      } else {
        return "";
      }
    },
  },
};
</script>
<style lang="scss">
.matchday-entry {
  width: 100%;
  min-height: 60px;
  background-color: rgba(13, 37, 53, 0.77);
  font-family: Ubuntu-medium;
  font-size: 26px;
  text-transform: uppercase;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 8px;
  margin-bottom: 8px;
  .date {
    font-family: Ubuntu-bold;
    font-size: 26px;
  }
  .matchday-icons {
    width: 40%;
    .matchday-icon {
      background-size: 100% 100%;
      background-position: center;
      background-repeat: no-repeat;

      &.cart {
        width: 30px;
        height: 27px;
        background-image: url(~@/assets/img/events/gfg-basket-inactive.svg);
        &.active {
          background-image: url(~@/assets/img/events/gfg-icn-basket-active.svg);
        }
        &.nobuy {
          background-image: url(~@/assets/img/events/gfg-icn-basket-nobuy.svg);
        }
      }
      &.medal {
        width: 19px;
        height: 27px;
        background-image: url(~@/assets/img/events/gfg-medal-inactive.svg);
        &.active {
          background-image: url(~@/assets/img/events/gfg-medal-active.svg);
        }
      }
      &.bonus {
        width: 27px;
        height: 27px;
        background-image: url(~@/assets/img/events/gfg-gift-inactive.svg);
        &.active {
          background-image: url(~@/assets/img/events/gfg-gift-active.svg);
        }
        &.collected {
          background-image: url(~@/assets/img/events/gfg-gift-collected.svg);
        }
      }
    }
  }
  &.scroll-arrow {
    background: url(~@/assets/img/events/gfg-list-arrow.svg) center/100% 40px
      no-repeat;
  }
}
</style>
