<template>
  <b-nav class="hero-nav d-inline-flex">
    <NavTab
      v-for="tab in heroNavTabs"
      :key="tab.title"
      :href="tab.path"
      :label="$t(tab.title)"
      :special-class="contentType"
      :locked="tab.locked"
      @click-attempt="handleClickAttempt(tab.locked)"
    />
  </b-nav>
</template>
<script>
import NavTab from "@/components/base/nav-tab.vue";
export default {
  name: "HeroNav",
  components: { NavTab },
  props: {
    contentType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      chatClicked: false,
      friendsClicked: false,
    };
  },
  computed: {
    heroNavTabs() {
      if (this.contentType === "profile") {
        return [
          {
            title: "lobby.navigation.profile",
            path: "/profile",
          },
          {
            title: "profile.tab_name.stats",
            path: "/profile/stats",
          },
          {
            title: "profile.tab_name.voucher",
            path: "/profile/voucher",
          },

          {
            title: "lobby.navigation.achievements",
            path: "/profile/achievements",
          },
          {
            title: "loyalty.headline",
            path: "/profile/loyalty",
          },
        ];
      } else if (this.contentType === "tournament") {
        return [
          {
            title: "tournaments.headline",
            path: "/tournament",
            name: "tournament-link-main",
          },
          {
            title: "tournaments.button_history",
            path: "/tournament/history",
            name: "tournament-link-history",
          },
          {
            title: "tournaments.button_achievements",
            path: "/tournament/achievement",
            name: "tournament-link-achievement",
          },
          {
            title: "content_tab.info",
            path: "/tournament/faq",
            name: "tournament-link-faq",
            germanOnly: true,
          },
        ];
      } else return [];
    },
    isChatLocked() {
      return (
        !this.$store.getters["teams/isTeamMember"] ||
        !this.$store.getters["teamsEnabled"]
      );
    },
  },
  methods: {
    handleClickAttempt(locked) {
      if (!locked) return;
      let text;
      let heading;
      if (!this.$store.state.generalConfig.settings["teams_enabled"]) {
        text = this.$t("locked.teams_disabled");
        heading = this.$t("locked.teams_disabled.title");
      } else if (!this.$store.getters["teams/isTeamMember"]) {
        text = this.$t("team.error.no_team");
        heading = this.$t("locked.not_in_team.title");
      } else {
        text = this.$t("generic_error_message");
        heading = "";
      }

      this.$store.commit("popups/setAlertBannerContent", {
        alertText: text,
        type: "fellowship",
        alertHeading: heading,
      });
    },
  },
};
</script>
<style lang="scss">
.hero-nav {
  max-width: $stage-size;
  margin-bottom: 18px;
  flex-wrap: nowrap;
}
</style>
