<template>
  <div>
    <b-modal
      id="modal-buy-elixir"
      class="custom-modal2"
      :hide-footer="true"
      modal-class="custom-modal2"
    >
      <template slot="modal-header" slot-scope="{ close }">
        <a href="#" class="close-button" @click="close()"> </a>
      </template>

      <template slot="default" slot-scope="{ hide, close }">
        <div class="text">
          {{ $t("bonus_slot.button.buy_potion_price", { 0: "" }) }}
          <div class="amount">
            {{ price }}?
            <div class="potion-crown-icon"></div>
          </div>
        </div>

        <div id="button-container-left" class="button-container">
          <button class="button-blue-md" @click="close()">
            {{ $t("no") }}
          </button>
        </div>
        <div id="button-container-right" class="button-container">
          <button class="button-green-md" @click="$emit('buy-elixir')">
            {{ $t("yes") }}
          </button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "ModalBuyElixir",
  props: { price: { type: Number, default: 0 } },
};
</script>
<style lang="scss">
$modal-width: auto;
$modal-height: 190px;

#modal-buy-elixir {
  .modal-body {
    margin-top: -15px;
  }

  .modal-dialog {
    top: 5%;
    max-width: $modal-width;
    max-height: $modal-height;
  }

  .text {
    font-size: 22px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;

    .amount {
      font-size: 26px;
      font-family: Ubuntu-Bold;
      position: relative;
      display: inline-block;
      margin-top: -2px;
      padding-right: 40px;
      padding-left: 2px;

      .potion-crown-icon {
        width: 34px;
        height: 34px;
        position: absolute;
        top: 6px;
        right: 0;
        background-image: url(~@/assets/img/common/crown5.png);
        background-repeat: no-repeat;
        background-size: 100%;
      }
    }
  }

  #button-container-left {
    position: absolute;
    left: 70px;
    bottom: 20px;
  }

  #button-container-right {
    position: absolute;
    right: 70px;
    bottom: 20px;
  }

  .button-container {
    button {
      text-transform: uppercase;
    }
  }
}
</style>
