<template>
  <b-modal
    id="modal-buy-complete"
    class="custom-modal2"
    :hide-footer="true"
    modal-class="custom-modal2"
    content-class="rectangular-dark-blue-modal"
    @shown="onOpen"
    @hidden="onClose"
  >
    <template slot="modal-header" slot-scope="{ close }">
      <a href="#" class="close-button" @click="close()"> </a>
      <div class="head-icon to-center-abs"></div>
    </template>

    <template slot="default">
      <div v-if="lastBoughtItem && bookingResult" id="buy-complete-container">
        <div class="complete-icon to-center-abs"></div>
        <div
          v-if="getMoreContentLabel(lastBoughtItem.package)"
          class="shop-more-percent"
        >
          {{ getMoreContentLabel(lastBoughtItem.package).value }}
        </div>
        <div class="headline to-center-abs">
          {{ $t("shop.complete.header") }}
        </div>

        <div
          v-if="packageName && !lastBoughtItem.customImg"
          class="package-image to-center-abs"
        >
          <img
            draggable="false"
            :src="getImageSrc(lastBoughtItem.package.slug)"
            class="h-100"
          />
        </div>

        <div
          v-if="lastBoughtItem.customImg"
          class="package-image to-center-abs"
        >
          <img
            draggable="false"
            :src="getImageSrc(lastBoughtItem.customImg)"
            class="h-100"
          />
        </div>

        <div v-if="packageName" class="package-name glow to-center-abs">
          {{ packageName }}
        </div>
        <div v-else class="package-name glow to-center-abs">
          {{ $t("header.offer") }}
        </div>

        <div v-if="bookingResult.gold > 0" class="package-amount glow">
          {{ formatNumber(bookingResult.gold) }} x

          <div class="main-currency-icon gold">
            <img src="@/assets/img/common/coin1.png" class="w-100" />
          </div>
        </div>

        <div v-if="bookingResult.crown > 0" class="package-amount glow">
          {{ formatNumber(bookingResult.crown) }} x

          <div class="main-currency-icon crown">
            <img src="@/assets/img/common/crown4.png" class="w-100" />
          </div>
        </div>

        <div class="glow-box glowing-border to-center-abs">
          <div class="package-bonus-text glow">
            {{ $t(`shop.header.bonus`) }}
          </div>
          <div v-if="bookingResult" class="package-bonus-icons">
            <div class="row m-0 justify-content-center">
              <div
                v-if="bookingResult.card"
                class="col package-bonus-icon-card"
              >
                <div class="text">{{ bookingResult.card }} x</div>
                <div class="icon">
                  <img
                    src="@/assets/img/common/cardstack-3.png"
                    class="img-card"
                  />
                </div>
              </div>
              <div
                v-if="bookingResult.loyalty"
                class="col package-bonus-icon-loyalty"
              >
                <div class="text">{{ bookingResult.loyalty }} x</div>
                <div class="icon">
                  <img
                    src="@/assets/img/nav-menu/nav-icon-loyalty.png"
                    class="img-loyalty"
                  />
                </div>
              </div>
              <div
                v-if="bookingResult.elixir"
                class="col package-bonus-icon-elixir"
              >
                <div class="text">{{ bookingResult.elixir }} x</div>
                <div class="icon">
                  <img
                    src="@/assets/img/common/elixir.png"
                    class="img-elixir"
                  />
                </div>
              </div>
              <div
                v-if="bookingResult.expbooster"
                class="col package-bonus-icon-expbooster"
              >
                <div class="text">
                  {{ Math.round(bookingResult.expbooster / 60) }}
                  Min.
                </div>
                <div class="icon">
                  <img
                    src="@/assets/img/shop/level-turbo-6-x-copy@2x.png"
                    class="img-exp"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import AnimationParsingFunctions from "@/components/animation/animation-parsing-functions";
import getMoreContentLabel from "@/mixins/paymentFunctions.js";

export default {
  name: "ModalBuyComplete",
  components: {},
  mixins: [AnimationParsingFunctions, getMoreContentLabel],
  data() {
    return {
      packageName: null,
    };
  },

  computed: {
    bookingResult: function () {
      return this.$store.getters["shop/getBookingResult"];
    },
    lastBoughtItem: function () {
      return this.$store.getters["shop/getShopChosenPackage"];
    },
  },

  mounted: function () {
    // this.$bvModal.show("modal-buy-complete");
  },

  methods: {
    onOpen() {
      this.setPackageName();

      this.$bvModal.hide("modal-payment");
      this.$bvModal.hide("modal-shop");

      this.$store.dispatch("user/updateUser");
    },
    onClose() {
      this.$store.commit("setIsPageLoading", false);
      this.mapShopItem();

      if (this.lastBoughtItem.callback) {
        this.lastBoughtItem.callback();
      }
      this.$store.commit("shop/setLastPaymentReference", null);

      this.$bvModal.hide("modal-offer-ab-testing");
    },

    setPackageName() {
      if (this.lastBoughtItem.customName) {
        this.packageName = this.lastBoughtItem.customName;
        return;
      }

      if (
        this.$i18n.i18next.exists(
          `shop.items.${this.lastBoughtItem.package.slug}`
        )
      ) {
        this.packageName = this.$t(
          `shop.items.${this.lastBoughtItem.package.slug}`
        );
        return;
      }

      this.packageName = null;
    },
    getImageSrc: function (slug) {
      try {
        return require("@/assets/img/shop/" + slug + ".png");
      } catch (e) {
        console.log(e);
        return "";
      }
    },
  },
};
</script>
<style lang="scss">
$modal-width: 530px;
$modal-height: 618px;

#modal-buy-complete {
  .modal-header {
    .head-icon {
      top: -95px;
      background: url(~@/assets/img/shop/shop-visual-default.png) center/100%
        100% no-repeat;
      width: 480px;
      height: 182px;
    }
  }
  .modal-dialog {
    top: 50px;
    max-width: $modal-width;
    min-height: $modal-height;
    height: auto;

    @media (max-height: 785px) {
      transform: scale(0.9);
      top: 40px;
      margin-bottom: -10%;
    }

    @media (max-height: 700px), (max-width: 1200px) {
      transform: scale(0.75);
      top: -40px;
      margin-bottom: -25%;
    }
  }

  .modal-content {
    width: $modal-width;
    box-shadow: 0 0 26px rgba(77, 229, 255, 0.6),
      0 0 26px rgba(77, 229, 255, 0.6), 0 0 26px rgba(77, 229, 255, 0.6),
      0 0 26px rgba(77, 229, 255, 0.6), 0 0 13px rgba(77, 229, 255, 0.6),
      0 0 13px rgba(77, 229, 255, 0.6), 0 0 6px rgba(0, 94, 110, 0.8),
      0 0 6px rgba(0, 94, 110, 0.8);
    border: 2px solid white;
    min-height: $modal-height;
    border-radius: 16px;
  }
}
</style>

<style scoped lang="scss">
#modal-buy-complete {
  .glow {
    text-shadow: 0 0 16px rgba(77, 229, 255, 0.6),
      0 0 8px rgba(77, 229, 255, 0.6), 0 0 8px rgba(77, 229, 255, 0.6),
      0 0 4px rgba(0, 94, 110, 0.8), 0 0 4px rgba(0, 94, 110, 0.8);
  }

  .glowing-border {
    box-shadow: 0 0 26px rgba(77, 229, 255, 0.6),
      0 0 26px rgba(77, 229, 255, 0.6), 0 0 26px rgba(77, 229, 255, 0.6),
      0 0 26px rgba(77, 229, 255, 0.6), 0 0 13px rgba(77, 229, 255, 0.6),
      0 0 13px rgba(77, 229, 255, 0.6), 0 0 6px rgba(0, 94, 110, 0.8),
      0 0 6px rgba(0, 94, 110, 0.8) !important;
  }

  #buy-complete-container {
    margin-top: -30px;
  }

  .complete-icon {
    top: 50px;
    background: url(~@/assets/img/shop/icn-shop-buy-success.png) center/100%
      100% no-repeat;
    width: 70px;
    height: 70px;
  }

  .shop-more-percent {
    top: 180px;
    right: 110px;
    z-index: 1;
  }

  .headline {
    top: 120px;
    font-family: Ubuntu-Bold;
    font-size: 36px;
    text-transform: uppercase;
    color: #43ff00;
  }

  .package-image {
    height: 82px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    top: 170px;
  }

  .package-name {
    font-family: Ubuntu-Bold;
    font-size: 36px;
    text-align: center;
    text-transform: uppercase;
    top: 250px;
  }

  .package-amount {
    font-family: Ubuntu-Bold;
    font-size: 48px;
    position: relative;
    display: inline-flex;
    text-align: center;
    top: 340px;
    line-height: 0.7;
  }

  .main-currency-icon {
    width: 48px;
    margin-left: 15px;
    margin-top: -5px;
  }

  .glow-box {
    border: 2px solid white;
    border-radius: 16px;
    width: 420px;
    height: 104px;
    margin-left: auto;
    margin-right: auto;
    top: 430px;

    .package-bonus-text {
      font-family: Ubuntu-Bold;
      font-size: 21px;
      text-align: center;
      margin-top: 10px;
      text-transform: uppercase;
    }

    .package-bonus-icons {
      width: 100%;

      .text {
        color: white;
        font-family: Ubuntu-Bold;
        font-size: 21px;
        white-space: nowrap;
        margin-top: auto;
        margin-bottom: auto;
        display: inline;
      }

      .icon {
        filter: drop-shadow(0 2px 12px #4de5ff);
        display: inline;

        .img-card {
          height: 46px;
        }
        .img-loyalty {
          height: 45px;
        }

        .img-elixir {
          height: 50px;
        }

        .img-exp {
          height: 50px;
        }
      }
    }
  }
}
</style>
