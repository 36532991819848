<template>
  <div
    id="canvas-container-animation-spine-icon-special-offer"
    ref="canvascontainer"
    class="canvas-container-animation-spine-icon-special-offer"
  ></div>
</template>

<script>
import AnimationSpineBaseFunctions from "@/components/animation/animation-spine-base-functions";
import {Spine} from "pixi-spine";

export default {
  name: "AnimationIconSpecialOffer",
  mixins: [AnimationSpineBaseFunctions],
  props: {
    loop: {
      type: Boolean,
      default: true,
    },
    run: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      unsubscribeEvents: [],
      destroyApp: false,
    };
  },

  watch: {
    run: function () {
      this.play();
    },
  },
  mounted() {
    this.play();
  },
  beforeDestroy() {
    //destroy event listeners
    for (let i = 0; i < this.unsubscribeEvents.length; i++) {
      this.unsubscribeEvents[i]();
    }

    this.destroyApp = true;
  },

  methods: {
    setScale() {
      let specialContainer = document.getElementsByClassName(
        "special-offer-container"
      )[0];
      let scaleFactor =
        window
          .getComputedStyle(document.body)
          .getPropertyValue("font-size")
          .replace("px", "") / 16;
      scaleFactor *= 0.7;
      specialContainer.style.transform = `scale(${scaleFactor})`;
    },
    play: function () {
      const canvasEl = document.getElementById(
        "canvas-animation-spine-icon-special-offer"
      );

      const assets = [
        ["icn-specialoffer", "/assets/spines/offer/icn-specialoffer-ani.json"],
      ];
      const options = {
        width: 150,
        height: 200,
        antialias: true,
        backgroundAlpha: 0,
        autoResize: true,
      };

      const app = this.createCanvas(
        options,
        assets,
        this.$refs.canvascontainer.id
      );
      app.view.id = "canvas-animation-spine-icon-special-offer";

      app.ticker.add(() => {
        if (this.destroyApp) {
          this.destroyApp = false;
          this.destroyAll(app);
        }
      });

      const setup = (loader, res) => {
        let spineData = this.getSpineData(res);

        spineData.forEach((data) => {
          const spine = new Spine(data);

          // set the position
          spine.x = app.screen.width / 2;
          spine.y = app.screen.height - 120;

          // set scale
          window.addEventListener("resize", this.setScale);
          this.unsubscribeEvents.push(() => {
            window.removeEventListener("resize", this.setScale);
          });
          this.setScale();

          //add spine to stage
          app.stage.addChild(spine);

          //run animation
          let animationName = "main";

          if (!this.run) {
            animationName = "idle";
          }
          let animation = this.getAnimationByName(data, animationName);
          spine.state.setAnimation(0, animation.name);

          //set animation end
          spine.state.getCurrent(0).animationEnd = animation.duration;

          // Detach event listeners
          this.unsubscribeEvents.push(() => {
            spine.state.getCurrent(0).onEvent = null;
          });

          //add loop
          if (this.loop) {
            spine.state.getCurrent(0).loop = true;
          }
        });
      };

      app.loader.load(setup);

      //remove old canvas if exist
      app.loader.onComplete.add(() => {
        if (canvasEl) {
          this.removePrevCanvas(this.$refs.canvascontainer.id);
        }
      });
    },
  },
};
</script>

<style lang="scss">
.canvas-container-animation-spine-icon-special-offer {
  cursor: pointer;

  canvas {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 30;
  }
}
</style>
