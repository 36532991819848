<template v-if="config">
  <b-modal
    id="modal-bonus-slot"
    ref="modal-bonus-slot"
    :hide-footer="true"
    modal-class="custom-modal1"
    @shown="onOpen"
    @hidden="onClose"
  >
    <template slot="modal-header" slot-scope="{ close }">
      <div class="preload"></div>
      <button
        v-if="showTestButton"
        class="testo-button"
        style="position: absolute; left: -200px; top: 0"
        @click="testSuperWin = true"
      >
        Test 3x Win
      </button>
      <button
        v-if="showTestButton"
        class="testo-button"
        style="position: absolute; left: -200px; top: 200px"
        @click="testNormalWin = true"
      >
        Test Normal Spin 1
      </button>
      <button
        v-if="showTestButton"
        class="testo-button"
        style="position: absolute; left: -200px; top: 400px"
        @click="testNormalWin2 = true"
      >
        Test Normal Spin 2
      </button>
      <button
        v-if="showTestButton"
        class="testo-button"
        style="position: absolute; left: -200px; top: 600px"
        @click="testUpgrade"
      >
        Elixir Updgrade
      </button>

      <modal-buy-elixir
        :price="config['bonusslot.elixir_price']"
        @buy-elixir="handleBuyElxir"
      ></modal-buy-elixir>
      <Spinner
        v-if="loading"
        size="medium"
        context="Loading Bonus Slot..."
        classes="dead-center"
      ></Spinner>

      <a href="#" class="close-button" @click="close()"> </a>
    </template>

    <template slot="default">
      <div v-show="showSlot" class="content bonus-slot-content to-center-abs">
        <div class="bg1"></div>
        <div class="bg2"></div>
        <div class="firework-coin to-center-abs">
          <animation-spine-coins
            v-if="runCoinFirework"
            :repeat="3"
            size="L"
          ></animation-spine-coins>
        </div>
        <div
          v-for="index in 3"
          :key="index"
          class="slot-bg"
          :class="'back' + index"
        ></div>
        <div v-if="glowtoggle" class="headline to-center-abs"></div>
        <div v-else class="headline2 to-center-abs"></div>
        <div class="winamounts">
          <div ref="win0" class="win win0 yellow-text"></div>
          <div ref="win1" class="win win1 yellow-text"></div>
          <div ref="win2" class="win win2 yellow-text"></div>
        </div>

        <div class="winpanels">
          <div
            v-for="index in 3"
            :id="'winpanel' + (index - 1)"
            :ref="'winpanel' + (index - 1)"
            :key="index"
            :class="'winpanel' + (index - 1)"
            class="winpanel"
          >
            <div class="panel-bg" :class="'panel-bg' + (index - 1)">
              <animation-spine-bonus-panel
                :run="runPanelAnimation"
                :index="index - 1"
              ></animation-spine-bonus-panel>
            </div>
            <span class="panel-amount to-center-abs"></span>
            <img src="" class="reward-icon" />
            <div class="jackpot-number-bonus-slot to-center-abs">
              <div class="jackpot-number-bonus-slot-x to-center-abs">x</div>
              <div class="jackpot-number-bonus-slot-amount to-center-abs">
                3
              </div>
              <div class="jackpot-number-bonus-slot-glow to-center-abs"></div>
            </div>
            <div class="jackpot-image-bonus-slot to-center-abs">
              <img
                src="@/assets/img/bonus-slot/reward-show-ani-bonustype@2x.png"
                class="w-100"
              />
            </div>
          </div>
        </div>

        <div class="kingspin-text to-center-abs">King Spin!</div>
        <div class="kingspin-light-container to-center-abs">
          <img
            src="@/assets/img/bonus-slot/king-spinner.png"
            class="kingspin-light w-100"
          />
        </div>

        <div class="coin-left"></div>
        <div class="coin-right"></div>
        <div class="crown-right"></div>

        <div id="pixi-bonus-slot" class="to-center-abs"></div>
        <div
          href="#"
          class="page-button text-decoration-none bottom-toggle to-center-abs d-inline-flex align-items-center justify-content-between"
          @click="toggle"
        >
          <div class="icon-elixir bg-img"></div>
          {{ $t("bonus_slot.button.profit_info_new") }}
          <div class="icon-elixir right bg-img"></div>
        </div>
      </div>

      <div v-show="!showSlot" class="content elixir-content to-center-abs">
        <div class="bg1"></div>
        <div class="bg2"></div>
        <div class="headline to-center-abs" :class="$store.state.locale"></div>

        <div
          href="#"
          class="text-decoration-none bottom-toggle"
          @click="toggle"
        >
          {{ $t("bonus_slot.button.to_slot") }}
        </div>

        <div
          class="bottom-button to-center-abs d-flex align-items-center justify-content-center"
          @click="showElixirModal()"
        >
          <div class="text col-5">
            {{ $t("bonus_slot.button.buy_potion") }}
          </div>

          <div v-if="config" class="price col-4">
            {{ config["bonusslot.elixir_price"] }}
          </div>
          <div class="potion-crown-icon col-3"></div>
        </div>

        <div
          class="sub-headline to-center-abs d-flex align-items-center justify-content-center"
        >
          <div class="scroll-text-content">
            <div
              class="scroll-text text1 to-center-abs"
              :class="$store.state.locale"
            ></div>
            <div
              class="scroll-text text2 to-center-abs"
              :class="$store.state.locale"
            ></div>
          </div>
        </div>

        <div class="activate-elixir-container to-center-abs">
          <div ref="Elixirtext" class="amount to-center-abs">
            <InfoBadge
              id="info-elixir-amount"
              :tooltip-only="true"
              position="center w-50 ml-5"
              :info-text="$t('info_badge.bonus_slot.elixir_amount')"
            />
            {{ formatNumber(elixir) }} x
          </div>
          <div
            id="bonus-potion-container"
            ref="Elixir"
            class="icon to-center-abs"
          >
            <animation-spine-elixir
              :run="runElixirAnimation"
            ></animation-spine-elixir>
          </div>
          <div class="activate-button to-center-abs" @click="upgrade()">
            {{ $t("bonus_slot.profit.button.activate") }}
          </div>
          <div class="info-text to-center-abs">
            {{
              $t("bonus_slot.profit.information2_web", {
                0: "xxx",
                1: "yyy",
              }).split("xxx")[0]
            }}
            <span class="green-text">
              {{
                $t("bonus_slot.profit.information2_web", { 0: "xxx", 1: "yyy" })
                  .split("xxx")[1]
                  .split("yyy")[0]
              }}
            </span>
            {{
              $t("bonus_slot.profit.information2_web", {
                0: "xxx",
                1: "yyy",
              }).split("yyy")[1]
            }}
          </div>
        </div>

        <template v-if="levels">
          <div
            v-for="(level, index) in levels"
            :key="level.symbol"
            class="to-center-abs"
            :class="[
              'item-' + level.symbol.toLowerCase(),
              index < 9 ? 'item-normal' : 'item-small',
            ]"
          >
            <div
              :ref="'BgGlow' + level.symbol"
              class="to-center-abs"
              :class="[
                index < 9 ? 'bg-glow-item-normal' : 'bg-glow-item-small',
              ]"
            ></div>

            <div :ref="'Col' + level.symbol" class="col-background"></div>
            <div class="icon-bg">
              <div class="amount-container to-center-abs">
                <InfoBadge
                  :id="`info-amount-${level.symbol}`"
                  position="right w-50"
                  :tooltip-only="true"
                  :info-text="$t('info_badge.bonus_slot.amount')"
                />
                <span :ref="'ColText' + level.symbol">
                  <template v-if="level.reward_type === 'LEVEL_TURBO_SECS'">
                    {{ secondsToTime(level.reward_amount) }}
                  </template>
                  <template v-else>
                    {{ formatNumber(level.reward_amount) }}
                  </template>
                </span>
              </div>
              <div v-if="index < 9" class="amount-icon to-center-abs"></div>
              <div class="level-icon">
                <InfoBadge
                  :id="`info-${level.symbol}`"
                  position="center w-100 h-100"
                  :tooltip-only="true"
                  :info-text="$t('info_badge.bonus_slot.icon')"
                />
                <img
                  :src="
                    require('@/assets/img/bonus-slot/icons/small_' +
                      level.symbol.toLowerCase() +
                      '.png')
                  "
                  class="h-100"
                />
              </div>
              <div
                :key="level.symbol + '_level'"
                class="lvl-text-container to-center-abs"
              >
                <InfoBadge
                  :id="`info-level-${level.symbol}`"
                  :tooltip-only="true"
                  position="center w-25"
                  :info-text="$t('info_badge.bonus_slot.level')"
                />
                <div class="sub1">
                  {{ $t("level") }}
                </div>
                <div class="sub2">
                  {{ level.level }}
                </div>
              </div>
            </div>
            <div
              v-if="index === 9 || index === 10"
              class="elixir-counter-container"
            >
              <div class="elixir-icon to-center-abs"></div>
              <div class="elixir-lights">
                <div
                  v-for="lightIndex in 6"
                  :key="lightIndex"
                  class="elixir-light"
                  :class="(level.level - 1) % 6 >= lightIndex ? 'on' : 'off'"
                ></div>
              </div>
            </div>
          </div>

          <div class="down-elixir-box-bg">
            <div class="box-bg1"></div>
            <div class="box-bg2"></div>
            <div class="text to-center-abs">
              {{ $t("bonus_slot.profit.info_six_sublevels") }}
            </div>
          </div>
        </template>
      </div>
    </template>
  </b-modal>
</template>

<script>
import * as PIXI from "pixi.js-legacy";
import * as bonusSlotAPI from "@/API/bonus-slot.js";
import AnimationElixir from "../animation/animation-bonus-slot";
import AnimationNumberIncrease from "../animation/animation-number-increase";
import AnimationParsingFunctions from "@/components/animation/animation-parsing-functions";
import AnimationEffects from "../animation/animation-effects";
import AnimationFirework from "../animation/animation-firework";
import Spinner from "@/components/animation/animation-spinner";
import ModalBuyElixir from "./modal-buy-elixir";
import InfoBadge from "@/components/base/info-badge.vue";
import AnimationSpineElixir from "@/components/animation/animation-spine-elixir";
import AnimationSpineCoins from "@/components/animation/animation-spine-coins";
import AnimationSpineBonusPanel from "@/components/animation/animation-spine-bonus-panel";

export default {
  name: "ModalBonusSlot",
  components: {
    AnimationSpineBonusPanel,
    AnimationSpineCoins,
    AnimationSpineElixir,
    ModalBuyElixir,
    Spinner,
    InfoBadge,
  },
  mixins: [
    AnimationElixir,
    AnimationNumberIncrease,
    AnimationParsingFunctions,
    AnimationEffects,
    AnimationFirework,
  ],
  data() {
    return {
      testing: false,
      loading: true,
      showSlot: true,
      data: null,
      errorMessage: "",
      rewards: {
        gold: 0,
        crowns: 0,
        expbooster: 0,
        cards: [],
      },
      animationRunningCount: 0,
      destroyApp: false,
      testSuperWin: false,
      testNormalWin: false,
      testNormalWin2: false,
      isJackpot: false,
      intervalId: null,
      intervalId2: null,
      glowtoggle: false,
      modalClosed: true,
      doBuy: false,
      runCoinFirework: false,
      runPanelAnimation: false,
      runElixirAnimation: false,
      levelsBefore: null,
      unsubscribeEvents: [],
    };
  },

  computed: {
    forceElxirPage() {
      return this.$store.getters["bonusslot/getForceShowElixirPage"];
    },
    showTestButton: function () {
      return process.env.VUE_APP_TESTBUTTONS === "true";
    },
    spinEndDate: function () {
      return this.$store.getters["bonusslot/getBonusSlotEndDate"];
    },

    levels: function () {
      if (!this.data || this.data.length === 0) {
        return;
      }

      //sort data
      let levels = this.data.levels;
      let sort_arr = [
        "FLAG",
        "CASTLE",
        "MEDAL",
        "DRAGON",
        "TREASURE",
        "PRINCE",
        "PRINCESS",
        "QUEEN",
        "KING",
        "CROWN",
        "MAP",
        "DIAMOND",
      ];
      let result = [];

      for (let i = 0; i < sort_arr.length; i++) {
        for (let j = 0; j < levels.length; j++) {
          if (sort_arr[i] === levels[j].symbol) {
            result[i] = levels[j];
            break;
          }
        }
      }

      return result;
    },

    elixir: function () {
      if (this.data === null) {
        return 0;
      }

      return this.data.elixir;
    },
    config: function () {
      const config = this.$store.getters["getGeneralConfig"];
      if (!config) {
        return null;
      }

      return config.settings;
    },
  },

  watch: {
    forceElxirPage(data) {
      if (data) {
        this.$store.commit("bonusslot/setForceShowElixirPage", null);
        this.showSlot = false;
      }
      if (data === false) {
        this.$store.commit("bonusslot/setForceShowElixirPage", null);
        this.showSlot = true;
      }
    },
  },

  mounted: function () {
    if (this.$route.path.startsWith("/bonus-slot-elixier")) {
      this.showSlot = false;
    }
    if (this.$route.path.startsWith("/bonus-slot")) {
      this.$bvModal.show("modal-bonus-slot");
    }

  },
  beforeDestroy: function () {
    this.killOtherThings();
  },

  methods: {
    killOtherThings() {
      this.destroyApp = true;
      clearInterval(this.intervalId);
      clearInterval(this.intervalId2);

      //destroy event listeners
      for (let i = 0; i < this.unsubscribeEvents.length; i++) {
        this.unsubscribeEvents[i]();
      }
    },

    onOpen() {
      this.$store.commit("bonusslot/setForceShowElixirPage", null);

      this.destroyApp = false;
      this.modalClosed = false;

      this.fetchData();

      //create bonus slot
      this.createBonusSlot();

      //reset
      this.$store.dispatch("user/setDelayedUpdate", true);
      this.$store.commit("user/setDelayedBoosterUpdate", true);
      this.rewards.gold = 0;
      this.rewards.crowns = 0;
      this.rewards.expbooster = 0;
      this.rewards.cards = [];

      //play bonus slot background sound
      this.playBackgroundBonusSlot();

      this.preloadingBonusSlot();

      this.animationRunningCount = 0;

      this.intervalId = setInterval(() => {
        this.glowtoggle = !this.glowtoggle;
      }, 1000);

      this.animateElixirHeadline();

      this.runCoinFirework = false;
      this.runPanelAnimation = false;

      this.$store.commit("bonusslot/setActivatePulseIcon", false);
    },
    onClose() {
      this.killOtherThings();

      try {
        this.modalClosed = true;
        this.loading = true;

        //force stop all animations
        this.$store.dispatch("user/setDelayedUpdate", false);
        this.$store.commit("user/setDelayedBoosterUpdate", false);

        this.stopAllSoundsExceptBackground();
        this.playBackgroundMusic();
        this.mapApiJsonBonusSlot(this.rewards);

        this.destroyApp = true;

        clearInterval(this.intervalId);

        this.runCoinFirework = false;
        this.runPanelAnimation = false;
      } catch (e) {
        console.log(e);
        this.destroyApp = true;
      }
    },
    animateElixirHeadline: function () {
      const scrollTexts = document.getElementsByClassName("scroll-text");

      const intervalSek = 3;

      this.gsap.set(scrollTexts[0], {
        x: 0,
      });
      this.gsap.set(scrollTexts[1], {
        x: 400,
      });
      const timeline = this.gsap.timeline();
      this.unsubscribeEvents.push(() => {
        timeline.kill();
      });
      timeline
        .fromTo(scrollTexts[0], 1, { x: 0 }, { x: -400 }, intervalSek)
        .fromTo(scrollTexts[1], 1, { x: 400 }, { x: 0 }, intervalSek)
        .to(scrollTexts[0], 0, { x: 400 }, intervalSek * 2)
        .to(scrollTexts[0], 1, { x: 0 }, intervalSek * 2)
        .to(scrollTexts[1], 1, { x: -400 }, intervalSek * 2)
        .to(
          scrollTexts,
          1,
          {
            onComplete: () => {
              timeline.restart();
            },
          },
          intervalSek * 2
        );
    },

    // BEGIN CREATE BONUS SLOT
    createBonusSlot: function () {
      const this_canvas = document
        .getElementById("pixi-bonus-slot")
        .getElementsByTagName("canvas")[0];
      if (this_canvas) {
        console.log("already created. close");
        return;
      }

      const isWebGLSupported = PIXI.utils.isWebGLSupported();

      //globals
      let runSlot = [false, false, false],
        slotIsReady = false,
        slotIsRunning = false,
        apiCollectabls,
        testJackot = false,
        icon_space_x = 296,
        icon_width = 240,
        icon_height = 300,
        stopSlot = [false, false, false],
        default_position_x = 339,
        default_position_y = 435 - icon_height * 2,
        slotIsStopped = [false, false, false],
        frameSeconds = 0,
        disableClick = false,
        reelContainer = [],
        reelScrollReset = [0, 0, 0],
        automaticStopDate = "";

      let counterEndDate;
      if (this.spinEndDate) {
        counterEndDate = new Date(this.spinEndDate);
      }

      //Sprites
      let bonus_button,
        bonus_button_text,
        bonus_button_text_countdown,
        bonus_button_small = [],
        borders = [],
        border1;

      const url_map = require("@/assets/img/bonus-slot/icons/map.jpg");
      const url_diamond = require("@/assets/img/bonus-slot/icons/diamond.jpg");
      const url_castle = require("@/assets/img/bonus-slot/icons/castle.jpg");
      const url_crown = require("@/assets/img/bonus-slot/icons/crown.jpg");
      const url_dragon = require("@/assets/img/bonus-slot/icons/dragon.jpg");
      const url_king = require("@/assets/img/bonus-slot/icons/king.jpg");
      const url_league = require("@/assets/img/bonus-slot/icons/medal.jpg");
      const url_princess = require("@/assets/img/bonus-slot/icons/princess.jpg");
      const url_prince = require("@/assets/img/bonus-slot/icons/prince.jpg");
      const url_queen = require("@/assets/img/bonus-slot/icons/queen.jpg");
      const url_flag = require("@/assets/img/bonus-slot/icons/flag.jpg");
      const url_treasure = require("@/assets/img/bonus-slot/icons/treasure.jpg");
      const url_mask = require("@/assets/img/bonus-slot/slot/mask.png");
      const url_border1 = require("@/assets/img/bonus-slot/slot/frame@3x.png");
      const url_border2 = require("@/assets/img/bonus-slot/slot/eingeloggtCopy@3x.png");
      const url_button_green1 = require("@/assets/img/bonus-slot/slot/kleinekrone-bonusslot-spinbutton-green-normal@3x.png");
      const url_button_green2 = require("@/assets/img/bonus-slot/slot/kleinekrone-bonusslot-spinbutton-green-blink@3x.png");
      const url_button_green_click = require("@/assets/img/bonus-slot/slot/kleinekrone-bonusslot-spinbutton-green-click@3x.png");
      const url_button_red1 = require("@/assets/img/bonus-slot/slot/kleinekrone-bonusslot-spinbutton-red-normal@3x.png");
      const url_button_red2 = require("@/assets/img/bonus-slot/slot/kleinekrone-bonusslot-spinbutton-red-blink@3x.png");
      const url_button_red_click = require("@/assets/img/bonus-slot/slot/kleinekrone-bonusslot-spinbutton-red-click@3x.png");
      const url_button_small_red1 = require("@/assets/img/bonus-slot/slot/kleinekrone-bonusslot-spinbutton-red-normal-small@3x.png");
      const url_button_small_red2 = require("@/assets/img/bonus-slot/slot/kleinekrone-bonusslot-spinbutton-red-blink-small@3x.png");
      const url_button_small_red_click = require("@/assets/img/bonus-slot/slot/kleinekrone-bonusslot-spinbutton-red-click-small@3x.png");
      const url_button_grey = require("@/assets/img/bonus-slot/slot/kleinekrone-bonusslot-spinbutton-grey-normal@3x.png");
      const url_sound_metal_hit = require("@/assets/sound/bonus-slot/kleinekrone-animations-mainsound-metal-hit-big.mp3");
      const url_sound_start = require("@/assets/sound/bonus-slot/kleinekrone-animations-mainsound-Slot-Button-Start.mp3");
      const url_sound_spin = require("@/assets/sound/bonus-slot/kleinekrone-animations-mainsound-Slot-ReelSpinMelody.mp3");
      const url_sound_spin2 = require("@/assets/sound/bonus-slot/kleinekrone-animations-mainsound-Slot-SpinFXLoop.mp3");

      //all URLs
      const urls = [
        url_mask,
        url_border1,
        url_border2,
        url_map,
        url_diamond,
        url_castle,
        url_crown,
        url_dragon,
        url_king,
        url_league,
        url_princess,
        url_prince,
        url_queen,
        url_flag,
        url_treasure,
        url_button_green1,
        url_button_green2,
        url_button_green_click,
        url_button_red1,
        url_button_red2,
        url_button_red_click,
        url_button_small_red1,
        url_button_small_red2,
        url_button_small_red_click,
        url_button_grey,
      ];

      //icon URLs
      const urls_icons = [
        [url_crown, "CROWN"],
        [url_map, "MAP"],
        [url_diamond, "DIAMOND"],
        [url_flag, "FLAG"],
        [url_castle, "CASTLE"],
        [url_league, "MEDAL"],
        [url_dragon, "DRAGON"],
        [url_treasure, "TREASURE"],
        [url_prince, "PRINCE"],
        [url_princess, "PRINCESS"],
        [url_queen, "QUEEN"],
        [url_king, "KING"],
      ];

      //contains all icon sprites
      let icons = [];

      //containers
      let slotContainer = new PIXI.Container();

      const container = document.getElementById("pixi-bonus-slot");
      const WIDTH = 1300;
      const HEIGHT = 700;

      //reset loader and textures
      let loader = PIXI.Loader.shared;

      //Aliases
      let Application = PIXI.Application,
        resources = loader.resources,
        Sprite = PIXI.Sprite;

      //Create a Pixi Application
      let app = new Application({
        width: WIDTH,
        height: HEIGHT,
        backgroundAlpha: 0,
        autoResize: false,
      });
      app.view.id = "pixi-bonus-slot-canvas";
      container.appendChild(app.view);

      loader.add(urls).load(() => {
        setup();
      });

      const setup = () => {
        createSprites();
        animateButton();
        animateSmallButtons();

        //Start the game loop
        app.ticker.fps = 60;
        app.ticker.add((delta) => gameLoop(delta));

        fadeInSlots();
        this.loadingFinshed();
      };

      const reset = (callback) => {
        for (let i = 0; i < icons.length; i++) {
          borders[i].alpha = 0;
        }
        stopSlot = [false, false, false];
        slotIsRunning = false;

        if (callback) {
          callback();
        }
      };

      const destroyAll = () => {
        this.destroyApp = false;

        while (app.stage.children[0]) {
          app.stage.children[0].destroy(true);
        }

        while (slotContainer.children[0]) {
          slotContainer.children[0].destroy(true);
        }

        app.loader.reset();
        app.loader.destroy(true);
        loader.reset();
        loader.destroy(true);

        //destroy canvas context
        const contextIds = ["bitmaprenderer", "2d", "webgl", "webg2"];
        for (let i = 0; i < contextIds.length; i++) {
          let gl = app.view.getContext(contextIds[i]);
          if (gl) {
            const buf = gl.createBuffer();
            gl.bindBuffer(gl.ARRAY_BUFFER, buf);
            const numAttributes = gl.getParameter(gl.MAX_VERTEX_ATTRIBS);
            for (let attrib = 0; attrib < numAttributes; ++attrib) {
              gl.vertexAttribPointer(attrib, 1, gl.FLOAT, false, 0, 0);
            }

            const numTextureUnits = gl.getParameter(gl.MAX_TEXTURE_IMAGE_UNITS);
            for (let unit = 0; unit < numTextureUnits; ++unit) {
              gl.activeTexture(gl.TEXTURE0 + unit);
              gl.bindTexture(gl.TEXTURE_2D, null);
              gl.bindTexture(gl.TEXTURE_CUBE_MAP, null);
            }

            gl.canvas.width = 1;
            gl.canvas.height = 1;

            let ext = gl.getExtension("WEBGL_lose_context");
            if (ext) {
              ext.loseContext();
            }
          }
        }

        app.destroy(true);

        PIXI.utils.clearTextureCache();
        PIXI.utils.destroyTextureCache();
      };

      const playTestJackpot = (slot) => {
        testJackot = true;
        play(slot);
      };

      //run slot after click button
      const play = (slot) => {
        if (!slotIsReady) {
          return;
        }
        if (disableClick) {
          return;
        }
        if (slotIsRunning) {
          if (slot === undefined) {
            if (
              stopSlot[0] === false ||
              stopSlot[1] === false ||
              stopSlot[1] === false
            ) {
              this.playSoundEffect(url_sound_start);
            }
            stopSlot = [true, true, true];
            bonus_button_small[0].alpha = 0;
            bonus_button_small[0].interactive = false;
            bonus_button_small[0].buttonMode = false;
            bonus_button_small[1].alpha = 0;
            bonus_button_small[1].interactive = false;
            bonus_button_small[1].buttonMode = false;
            bonus_button_small[2].alpha = 0;
            bonus_button_small[2].interactive = false;
            bonus_button_small[2].buttonMode = false;
            bonus_button.clicked = true;
          } else {
            this.playSoundEffect(url_sound_start);
            stopSlot[slot] = true;
            bonus_button_small[slot].alpha = 0;
            bonus_button_small[slot].interactive = false;
            bonus_button_small[slot].buttonMode = false;
            resetRemainingTime();
          }
          return;
        }

        this.playSoundEffect(url_sound_start);

        if (
          !testJackot &&
          counterEndDate !== undefined &&
          countdown() !== undefined &&
          counterEndDate.getTime() > 0
        ) {
          return;
        }

        reset(() => {
          this.hidePanels();
          fetchData();
          activateSlots();
          resetRemainingTime();

          setTimeout(() => {
            this.playMusic(url_sound_spin, true, 0.2);
            this.playSoundEffect(url_sound_spin2, true, 0.5);
            this.playBackgroundBonusSlotRunning();
          }, 100);
        });
      };

      //set automatic stop in 10 seconds
      const resetRemainingTime = () => {
        automaticStopDate = new Date();
        automaticStopDate.setSeconds(automaticStopDate.getSeconds() + 10);
        automaticStopDate = automaticStopDate.getTime();
      };

      //Update text of button based on current state
      const updateButton = (delta) => {
        //animate buttons
        frameSeconds += (1 / 60) * delta;
        if (frameSeconds >= 0.5) {
          frameSeconds = 0;
          animateButton();
          animateSmallButtons();
        }

        //change to red button
        if (slotIsRunning) {
          bonus_button_text.alpha = 0;
          bonus_button.interactive = true;
          bonus_button.buttonMode = true;

          if (
            bonus_button.name !== url_button_red1 &&
            bonus_button.name !== url_button_red2
          ) {
            setButtonTexture(url_button_red1);
          }
          return;
        }

        //change to grey waiting button
        if (
          counterEndDate !== undefined &&
          countdown() !== undefined &&
          counterEndDate.getTime() > 0
        ) {
          bonus_button_text_countdown.text = countdown();
          bonus_button_text.alpha = 1;
          bonus_button.interactive = false;
          bonus_button.buttonMode = false;
          setButtonTexture(url_button_grey);
          if (bonus_button_text_countdown.text.length > 5) {
            bonus_button_text_countdown.style = {
              fontSize: 7,
              fill: 0xffffff,
              fontWeight: "bold",
              lineHeight: 28,
            };
            bonus_button_text_countdown.x = 33;
            bonus_button_text_countdown.y = -1;
          } else {
            bonus_button_text_countdown.style = {
              fontSize: 10,
              fill: 0xffffff,
              fontWeight: "bold",
              lineHeight: 28,
            };
            bonus_button_text_countdown.x = 35;
            bonus_button_text_countdown.y = -3;
          }
          return;
        }

        //change to green button
        bonus_button_text.alpha = 0;
        bonus_button.interactive = true;
        bonus_button.buttonMode = true;
        if (
          bonus_button.name !== url_button_green1 &&
          bonus_button.name !== url_button_green2
        ) {
          setButtonTexture(url_button_green1);
        }
      };

      const animateButton = () => {
        switch (bonus_button.name) {
          case url_button_red1:
            setButtonTexture(url_button_red2);
            break;
          case url_button_red2:
            setButtonTexture(url_button_red1);
            break;
          case url_button_green1:
            setButtonTexture(url_button_green2);
            break;
          case url_button_green2:
            setButtonTexture(url_button_green1);
            break;
          default:
            setButtonTexture(url_button_green1);
        }
      };

      const animateSmallButtons = () => {
        const ratio = 3.8;
        for (let i = 0; i < bonus_button_small.length; i++) {
          if (
            bonus_button_small[i].name === url_button_small_red2 ||
            !slotIsRunning
          ) {
            bonus_button_small[i].texture =
              resources[url_button_small_red1].texture;
            bonus_button_small[i].name = url_button_small_red1;
            bonus_button_small[i].height =
              bonus_button_small[i].texture.height * ratio;
            bonus_button_small[i].width =
              bonus_button_small[i].texture.width * ratio;
            bonus_button_small[i].y = 435;
          } else {
            bonus_button_small[i].texture =
              resources[url_button_small_red2].texture;
            bonus_button_small[i].name = url_button_small_red2;
            bonus_button_small[i].height =
              bonus_button_small[i].texture.height * ratio;
            bonus_button_small[i].width =
              bonus_button_small[i].texture.width * ratio;

            bonus_button_small[i].y = 430;
          }
        }
      };

      //countdown for next available spin
      const countdown = () => {
        const now = new Date();
        const distance = counterEndDate.getTime() - now.getTime();
        // const distance = 3000000;
        if (distance < 0) {
          return;
        }

        let hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((distance % (1000 * 60)) / 1000);
        if (distance < 600000 || ("00" + hours).slice(-2) === "00") {
          return ("00" + minutes).slice(-2) + ":" + ("00" + seconds).slice(-2);
        } else
          return (
            ("00" + hours).slice(-2) +
            ":" +
            ("00" + minutes).slice(-2) +
            ":" +
            ("00" + seconds).slice(-2)
          );
      };

      //activate slots and delay start
      const activateSlots = () => {
        disableClick = true;
        setTimeout(() => {
          bonus_button_small[0].interactive = true;
          bonus_button_small[0].buttonMode = true;
        }, 250);

        setTimeout(() => {
          bonus_button_small[1].interactive = true;
          bonus_button_small[1].buttonMode = true;
        }, 500);

        setTimeout(() => {
          bonus_button_small[2].interactive = true;
          bonus_button_small[2].buttonMode = true;
          disableClick = false;
        }, 750);

        setTimeout(() => {
          runSlot[0] = true;
          handleSlot(icons[0], 0);
        }, 0);
        setTimeout(() => {
          runSlot[1] = true;
          handleSlot(icons[1], 1);
        }, 250);
        setTimeout(() => {
          runSlot[2] = true;
          handleSlot(icons[2], 2);
        }, 500);

        //automatic stop after x seconds
        app.ticker.add(() => {
          const now = new Date().getTime();
          if (now > automaticStopDate) {
            stopSlot = [true, true, true];
            bonus_button_small[0].alpha = 0;
            bonus_button_small[0].interactive = false;
            bonus_button_small[0].buttonMode = false;
            bonus_button_small[1].alpha = 0;
            bonus_button_small[1].interactive = false;
            bonus_button_small[1].buttonMode = false;
            bonus_button_small[2].alpha = 0;
            bonus_button_small[2].interactive = false;
            bonus_button_small[2].buttonMode = false;
          }
        });
      };

      const handleSlot = (slot, index) => {
        //set bottom symbol position to top symbol position
        const repeatSymbols = () => {
          if (reelContainer[index].y > reelScrollReset[index]) {
            reelScrollReset[index] += icon_height;
            let lastSymbol = slot[slot.length - 1];
            lastSymbol.y = slot[0].y - icon_height;
            slot.pop();
            slot.unshift(lastSymbol);
          }
        };

        //slots reel down after stop button is pressed
        const slowDown = () => {
          const target = icon_height * 3.5;

          const timelineSlotDown = this.gsap.timeline();
          this.unsubscribeEvents.push(() => {
            timelineSlotDown.kill();
          });
          timelineSlotDown.to(reelContainer[index], 1, {
            y: "+=" + target,
            ease: "power1",
            onUpdate: () => {
              repeatSymbols();
            },
            onComplete: () => {
              //slot stopped. set variables
              slotIsStopped[index] = true;
              runSlot[index] = false;
              borders[index].alpha = 1;

              //do bounce effect when stopping
              bounceSlot(index);

              // set winner animation
              let isTime = false;
              if (apiCollectabls[index].symbol === "DIAMOND") {
                isTime = true;
              }
              let allFinished = false;
              if (slotIsStopped[0] && slotIsStopped[1] && slotIsStopped[2]) {
                allFinished = true;
              }
              this.playSoundEffect(url_sound_metal_hit, false, 0.4);
              this.animateWin(
                index,
                apiCollectabls[index],
                isTime,
                allFinished
              );
            },
          });
        };

        //move reel
        const animationRunSlot = (i) => {
          const timeline = this.gsap.timeline();
          this.unsubscribeEvents.push(() => {
            timeline.kill();
          });
          timeline.to(reelContainer[i], 0.1, {
            y: "+=150",
            ease: "linear.in",
            onComplete: () => {
              //repeat symbols
              repeatSymbols();

              //find target and stop point
              if (stopSlot[index] && apiCollectabls !== undefined) {
                const target_icon = apiCollectabls[index].symbol;
                if (slot[slot.length - 1].name === target_icon) {
                  //fix spikes if occurred
                  if (reelContainer[i].y % icon_height !== 150) {
                    reelContainer[i].y -=
                      150 - (reelContainer[i].y % icon_height);
                  }

                  slowDown();
                  return;
                }
              }
              animationRunSlot(index);
            },
          });
        };
        animationRunSlot(index);
      };

      const gameLoop = (delta) => {
        //update the button to green/grey/red depending on the situation
        updateButton(delta);

        //destroy app if closed
        if (this.destroyApp === true) {
          destroyAll();
          return;
        }

        //check if all slots stopped
        slotIsRunning = runSlot[0] || runSlot[1] || runSlot[2];

        if (this.testSuperWin || this.testNormalWin || this.testNormalWin2) {
          playTestJackpot();
        }
      };

      const fetchData = () => {
        if (testJackot) {
          let fakeJson = JSON.parse(
            '{"collect":[{"symbol":"CROWN","type":"CURRENCY_CROWN","amount":120,"jackpot":true,"asset":true},{"symbol":"DIAMOND","type":"LEVEL_TURBO_SECS","amount":75000,"jackpot":false,"asset":true},{"symbol":"MAP","type":"CARDS","amount":75000,"jackpot":false,"asset":true}],"status":{"collectable":false,"last_collect":{"datetime":"2020-12-03 14:17:42"},"next_collect":{"datetime":"2020-12-03 22:17:42","remaining_secs":5}},"gold":{"id":331,"user_id":161,"type":"GOLD","amount":131870895473}}'
          );
          if (this.testNormalWin2) {
            fakeJson = JSON.parse(
              '{"collect":[{"symbol":"DRAGON","type":"CURRENCY_GOLD","amount":120,"jackpot":true,"asset":true},{"symbol":"PRINCESS","type":"CURRENCY_GOLD","amount":75000,"jackpot":false,"asset":true},{"symbol":"PRINCE","type":"CURRENCY_GOLD","amount":75000,"jackpot":false,"asset":true}],"status":{"collectable":false,"last_collect":{"datetime":"2020-12-03 14:17:42"},"next_collect":{"datetime":"2020-12-03 22:17:42","remaining_secs":5}},"gold":{"id":331,"user_id":161,"type":"GOLD","amount":131870895473}}'
            );
          }

          this.testNormalWin = false;
          this.testNormalWin2 = false;
          if (this.testSuperWin) {
            this.testSuperWin = false;
            fakeJson = JSON.parse(
              '{"collect":[{"symbol":"TREASURE","type":"CURRENCY_GOLD","amount":120,"jackpot":true,"asset":true},{"symbol":"TREASURE","type":"CURRENCY_GOLD","amount":75000,"jackpot":false,"asset":true},{"symbol":"TREASURE","type":"CURRENCY_GOLD","amount":75000,"jackpot":false,"asset":true}],"status":{"collectable":false,"last_collect":{"datetime":"2020-12-03 14:17:42"},"next_collect":{"datetime":"2020-12-03 22:17:42","remaining_secs":5}},"gold":{"id":331,"user_id":161,"type":"GOLD","amount":131870895473}}'
            );
          }
          cofigurateForRun(fakeJson);
          testJackot = false;
          return;
        }

        bonusSlotAPI.collect().then((res) => {
          if (res !== null && res.status !== "error") {
            cofigurateForRun(res);
          } else {
            if (res.message) {
              this.$bvModal.hide("modal-bonus-slot");
              this.$store.commit("popups/setAlertBannerContent", {
                alertText: res.message,
                alertHeading: this.$t("alerts.title.error"),
                variant: "danger",
                type: "danger",
              });
            } else {
              this.displayErrorMessage();
            }
          }
        });
      };

      const cofigurateForRun = (res) => {
        if (this.testing && process.env.NODE_ENV !== "production") {
          res = JSON.parse(
            '{"collect":[{"symbol":"DRAGON","type":"CURRENCY_GOLD","amount":60000,"jackpot":true,"asset":true},{"symbol":"KING","type":"CURRENCY_GOLD","amount":420000,"jackpot":false,"asset":true},{"symbol":"PRINCESS","type":"CURRENCY_GOLD","amount":150000,"jackpot":false,"asset":true}],"status":{"collectable":false,"last_collect":{"datetime":"2021-06-10 07:11:09"},"next_collect":{"datetime":"2021-06-10 08:11:09","remaining_secs":3599}},"gold":{"id":12,"user_id":7,"type":"GOLD","amount":4129220}}'
          );
        }

        let waitSeconds = res.status.next_collect.remaining_secs + 1;
        this.$store.commit("bonusslot/setBonusSlotEndDate", waitSeconds);
        apiCollectabls = res.collect;
        counterEndDate = new Date();
        counterEndDate.setSeconds(counterEndDate.getSeconds() + waitSeconds);
        this.isJackpot =
          apiCollectabls[0].symbol === apiCollectabls[1].symbol &&
          apiCollectabls[1].symbol === apiCollectabls[2].symbol;
      };

      const setButtonTexture = (texture_url) => {
        const ratio = 3;

        bonus_button.texture = resources[texture_url].texture;
        bonus_button.name = texture_url;
        bonus_button.height = bonus_button.texture.height * ratio;
        bonus_button.width = bonus_button.texture.width * ratio;
        bonus_button.x = 802;

        if (texture_url === url_button_red2) {
          bonus_button.y = 456;
        } else {
          bonus_button.y = 454;
        }
      };

      const fadeInSlots = () => {
        for (let i = 0; i < 3; i++) {
          icons[i].forEach((symbol) => {
            const timeline = this.gsap.timeline();
            this.unsubscribeEvents.push(() => {
              timeline.kill();
            });
            timeline.fromTo(
              symbol,
              1,
              {
                alpha: 0,
              },
              {
                alpha: 1,
              },
              0
            );
            timeline.fromTo(
              symbol.position,
              1,
              {
                y: "-=20",
              },
              {
                y: "+=20",
                onComplete: () => {
                  slotIsReady = true;
                },
              },
              0
            );
          });
        }
      };
      const bounceSlot = (index) => {
        for (let key in icons[index]) {
          const timeline = this.gsap.timeline();
          this.unsubscribeEvents.push(() => {
            timeline.kill();
          });
          timeline.fromTo(
            icons[index][key].position,
            1,
            {
              y: "+=10",
            },
            {
              y: "-=10",
              ease: "elastic",
            },
            0
          );
        }
      };

      const createSprites = () => {
        let sprite_obj;
        const ratio = 1.315;

        slotContainer.zIndex = 20;
        slotContainer.x = 0;
        slotContainer.y = 160;
        slotContainer.vx = 0;
        slotContainer.vy = 0;
        app.stage.addChild(slotContainer);

        //button big
        sprite_obj = new Sprite(resources[url_button_green1].texture);
        sprite_obj.name = url_button_green1;

        sprite_obj.height = sprite_obj.texture.height * ratio;
        sprite_obj.width = sprite_obj.texture.width * ratio;
        sprite_obj.zIndex = 30;
        sprite_obj.clicked = false;
        sprite_obj.interactive = true;
        sprite_obj.buttonMode = true;
        sprite_obj.anchor.set(1, 1);

        sprite_obj.on("mousedown", () => {
          play();
        });
        sprite_obj.on("touchstart", () => {
          play();
        });
        slotContainer.addChild(sprite_obj);
        bonus_button = sprite_obj;

        bonus_button_text = new PIXI.Text(
          this.$t("bonus_slot.remaining_time").toUpperCase(),
          {
            fontSize: 6,
            fill: 0xffffff,
            align: "center",
            fontWeight: "bold",
            lineHeight: 28,
          }
        );
        bonus_button_text.anchor.set(0.5, 0.5);
        bonus_button_text.x = -70;
        bonus_button_text.y = -9;
        bonus_button_text.resolution = 2;
        bonus_button_text.alpha = 0;
        bonus_button.addChild(bonus_button_text);

        bonus_button_text_countdown = new PIXI.Text("", {
          fontSize: 10,
          fill: 0xffffff,
          fontWeight: "bold",
          lineHeight: 28,
        });
        bonus_button_text_countdown.anchor.set(0, 0.5);
        bonus_button_text_countdown.x = 35;
        bonus_button_text_countdown.y = -3;
        bonus_button_text_countdown.resolution = 2;
        bonus_button_text.addChild(bonus_button_text_countdown);

        //small stop button 1
        const space_small_buttons = 294;
        sprite_obj = new Sprite(resources[url_button_small_red1].texture);
        sprite_obj.name = url_button_small_red1;
        sprite_obj.x = 222;
        sprite_obj.zIndex = 60;
        sprite_obj.on("mousedown", () => {
          play(0);
        });
        sprite_obj.on("touchstart", () => {
          play(0);
        });
        app.stage.addChild(sprite_obj);
        bonus_button_small[0] = sprite_obj;

        //small stop button 2
        sprite_obj = new Sprite(resources[url_button_small_red1].texture);
        sprite_obj.name = url_button_small_red1;
        sprite_obj.x = bonus_button_small[0].x + space_small_buttons;
        sprite_obj.y = 520;
        sprite_obj.zIndex = 60;
        sprite_obj.on("mousedown", () => {
          play(1);
        });
        sprite_obj.on("touchstart", () => {
          play(1);
        });
        app.stage.addChild(sprite_obj);
        bonus_button_small[1] = sprite_obj;

        //small stop button 3
        sprite_obj = new Sprite(resources[url_button_small_red1].texture);
        sprite_obj.name = url_button_small_red1;
        sprite_obj.x = bonus_button_small[1].x + space_small_buttons;
        sprite_obj.y = 520;
        sprite_obj.zIndex = 60;
        sprite_obj.on("mousedown", () => {
          play(2);
        });
        sprite_obj.on("touchstart", () => {
          play(2);
        });
        app.stage.addChild(sprite_obj);
        bonus_button_small[2] = sprite_obj;

        for (let i = 0; i < 3; i++) {
          const space_between = 296;

          //Border blue
          sprite_obj = new Sprite(resources[url_border1].texture);
          sprite_obj.name = "border1";
          sprite_obj.height = 324;
          sprite_obj.width = 274;
          sprite_obj.x = 338 + space_between * i;
          sprite_obj.y = 452;
          sprite_obj.zIndex = 38;
          sprite_obj.anchor.set(0.5, 1);
          app.stage.addChild(sprite_obj);
          border1 = sprite_obj;

          //Border gold
          sprite_obj = new Sprite(resources[url_border2].texture);
          sprite_obj.name = "border2";
          sprite_obj.height = border1.height;
          sprite_obj.width = border1.width;
          sprite_obj.x = border1.x;
          sprite_obj.y = border1.y;
          sprite_obj.zIndex = border1.zIndex;
          sprite_obj.anchor.set(0.5, 1);
          sprite_obj.alpha = 0;
          app.stage.addChild(sprite_obj);
          borders[i] = sprite_obj;

          //MASK Sprite
          sprite_obj = new Sprite(resources[url_mask].texture);
          sprite_obj.name = "mask";
          sprite_obj.height = border1.height * 0.99;
          sprite_obj.width = border1.width * 0.99;
          sprite_obj.x = border1.x;
          sprite_obj.y = border1.y;
          sprite_obj.vx = 0;
          sprite_obj.vy = 0;
          sprite_obj.zIndex = 34;
          sprite_obj.anchor.set(0.5, 1);
          app.stage.addChild(sprite_obj);
          let myMask = sprite_obj;

          if (!isWebGLSupported) {
            //Background MASK (important mask for machines that not support above sprite mask e.g. IE)
            let bg_mask = new PIXI.Graphics();
            bg_mask.beginFill(0xffff00);
            bg_mask.drawRoundedRect(10, 142, 1200, 290, 20);
            bg_mask.zIndex = 50;
            bg_mask.alpha = 0.5;
            bg_mask.mask = myMask;
            app.stage.addChild(bg_mask);
            const myMaskContainer = new PIXI.Container();
            myMaskContainer.addChild(myMask);
            myMaskContainer.addChild(bg_mask);
            myMask = myMaskContainer;
          }

          //reel container
          let reel = new PIXI.Container();
          reel.name = "reel Container " + i;
          reelContainer[i] = reel;
          app.stage.addChild(reelContainer[i]);

          //Icons
          icons[i] = [];
          for (let j = 0; j < urls_icons.length; j++) {
            sprite_obj = new Sprite(resources[urls_icons[j][0]].texture);
            sprite_obj.name = urls_icons[j][1];
            sprite_obj.x = default_position_x + icon_space_x * i;
            sprite_obj.y = default_position_y + icon_height * j;
            sprite_obj.width = icon_width;
            sprite_obj.height = icon_height;
            sprite_obj.zIndex = 35;
            sprite_obj.anchor.set(0.5, 1);
            sprite_obj.mask = myMask;

            icons[i].push(sprite_obj);
            reelContainer[i].addChild(sprite_obj);
          }
        }

        const updateLayersOrder = () => {
          app.stage.children.sort(function (a, b) {
            a.zIndex = a.zIndex || 0;
            b.zIndex = b.zIndex || 0;
            return a.zIndex - b.zIndex;
          });
        };

        updateLayersOrder();
      };
    },

    hidePanels: function () {
      let panels = document.getElementsByClassName("winpanel");
      let jackpot_numbers = document.getElementsByClassName(
        "jackpot-number-bonus-slot"
      );
      let jackpot_image = document.getElementsByClassName(
        "jackpot-image-bonus-slot"
      );
      this.gsap.set([panels, jackpot_image], {
        scale: 0,
      });
      this.gsap.set(jackpot_numbers, {
        opacity: 0,
        y: 0,
      });
    },
    loadingFinshed: function () {
      this.loading = false;
    },

    toggle: function () {
      this.playSoundTab();
      this.showSlot = !this.showSlot;
    },

    fetchData: async function () {
      await bonusSlotAPI
        .config()
        .then((result) => {
          this.data = result;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    testUpgrade: async function () {
      for (let i = 0; i < 5; i++) {
        await bonusSlotAPI.upgrade();
      }
      this.upgrade();
    },

    //upgrade a symbol with elixir
    upgrade: async function () {
      this.playSoundButtonCommon();
      this.animationRunningCount++;

      await bonusSlotAPI
        .upgrade()
        .then((result) => {
          //show error message
          if (result.status === "error") {
            this.errorMessage = this.$t(result.message);
            this.$store.commit("popups/setAlertBannerContent", {
              alertText: this.errorMessage,
              alertHeading: this.$t("alerts.title.error"),
              variant: "danger",
              type: "danger",
            });
            this.animationRunningCount--;
            return;
          }

          this.runElixirAnimation = false;

          this.firebaseVirtualCurrencyDrain(
            "bonus_slot_upcraft",
            "ELIXIR",
            1,
            "BONUS_SLOT"
          );

          //find the symbol that has to be upgraded
          let changed_symbol;
          const level_before = this.levelsBefore
            ? this.levelsBefore
            : this.data.levels;
          const level_after = result.levels;
          for (let prop in level_before) {
            for (let prop2 in level_after) {
              if (level_before[prop].symbol === level_after[prop2].symbol) {
                if (level_before[prop].level !== level_after[prop2].level) {
                  changed_symbol = level_after[prop2];
                  this.levelsBefore = result.levels;
                  break;
                }
              }
            }
          }

          //update elixir amount
          this.data.elixir = result.elixir.amount;

          //show upgrade animation
          const callback = () => {
            let temp_data = this.data;
            temp_data.levels = result.levels;

            this.data = temp_data;
          };
          this.animateSymbol(changed_symbol, callback);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //save all earned rewards while modal is open
    setRewardAmount: function (result) {
      let multiplier = 1;
      if (this.isJackpot) {
        multiplier = 3;
      }

      switch (result.type) {
        case "CURRENCY_GOLD":
          this.rewards.gold += result.amount * multiplier;
          break;
        case "CURRENCY_CROWN":
          this.rewards.crowns += result.amount * multiplier;
          break;
        case "LEVEL_TURBO_SECS":
          this.rewards.expbooster += result.amount * multiplier;
          break;
        case "CARDS":
          this.rewards.cards.push(result.asset);
          break;
      }
    },

    //bonus slow win animation
    animateWin: function (index, result, isTime, allFinished) {
      this.setRewardAmount(result);

      let sound_fanfare = require("@/assets/sound/animation/kleinekrone-animations-soundFX-reward-fanfare-2.mp3");

      //get dom elements
      let target, panel, paneltext, panelicon, kingspinText, kingspinLight;
      for (let key in this.$refs) {
        if (key === "win" + index) {
          target = this.$refs[key];
          break;
        }
      }
      panel = document.getElementById("winpanel" + index);
      paneltext = panel.getElementsByClassName("panel-amount")[0];
      panelicon = panel.getElementsByClassName("reward-icon")[0];
      kingspinText = document.getElementsByClassName("kingspin-text")[0];
      kingspinLight = document.getElementsByClassName("kingspin-light")[0];

      if (target === null || target === undefined) {
        console.log("ERROR. TARGET NULL");
        return;
      }

      //set the win amount for a single slot
      let amount = this.formatNumber(result.amount);
      if (isTime) {
        //win time instead of gold
        amount = amount.replace(".", "");
        amount = this.secondsToTime(amount);
      }
      this.gsap.set(target, { y: 0 });
      target.innerHTML = "+" + amount;
      paneltext.innerHTML = "+ " + amount;

      //set the win sybmol for a single slot
      let currencySrc;
      switch (result.type) {
        case "CURRENCY_GOLD":
          currencySrc = require("@/assets/img/common/coin1.png");
          break;
        case "CURRENCY_CROWN":
          currencySrc = require("@/assets/img/common/crown2.png");
          break;
        case "CARDS":
          currencySrc = require("@/assets/img/common/card.png");
          break;
        case "LEVEL_TURBO_SECS":
          currencySrc = require("@/assets/img/common/booster.png");
          break;
        default:
          currencySrc = require("@/assets/img/common/coin1.png");
          break;
      }
      panelicon.src = currencySrc;

      const timeline = this.gsap.timeline();
      this.unsubscribeEvents.push(() => {
        timeline.kill();
      });

      //when modal is closed and animation is interrupted, stop animation
      if (allFinished) {
        this.intervalId2 = setInterval(() => {
          if (this.modalClosed) {
            timeline.pause(0).clear().kill();
            clearInterval(this.intervalId2);
          }
        }, 1000);
      }

      try {
        /* BEGIN ANIMATION */

        //show win amount
        timeline.to(
          target,
          1,
          {
            opacity: 1,
          },
          0
        );

        //move
        timeline.to(
          target,
          3,
          {
            y: "-130",
            onStart: () => {
              if (allFinished) {
                this.stopSound(
                  require("@/assets/sound/bonus-slot/kleinekrone-animations-mainsound-Slot-ReelSpinMelody.mp3")
                );
                this.stopSound(
                  require("@/assets/sound/bonus-slot/kleinekrone-animations-mainsound-Slot-SpinFXLoop.mp3")
                );
                this.stopSound(
                  require("@/assets/sound/bonus-slot/kleinekrone-animations-musicident-bonusslot.mp3")
                );

                if (this.rewards.expbooster > 0) {
                  this.$store.commit("user/setDelayedBoosterUpdate", false);
                  this.$store.commit("popups/setAlertBannerContent", {
                    alertHeading: this.$t("header.expbooster_title"),
                    alertText: this.$t("header.expbooster_message", { 0: 50 }),
                    type: "booster",
                    variant: "success",
                  });
                }
              }
            },
          },
          0
        );

        // //hide
        timeline.to(
          target,
          1,
          {
            opacity: 0,
          },
          1.5
        );

        let jackpot_numbers = document.getElementsByClassName(
          "jackpot-number-bonus-slot"
        );
        let jackpot_image = document.getElementsByClassName(
          "jackpot-image-bonus-slot"
        );

        if (allFinished) {
          let panels = document.getElementsByClassName("winpanel");

          //show panels
          for (let i = 0; i < panels.length; i++) {
            timeline.to(
              panels[i],
              1,
              {
                scale: 1,
                ease: "elastic",
                onStart: () => {
                  this.playSoundEffect(
                    require("@/assets/sound/animation/full-reward-earned/counter.mp3"),
                    false,
                    0.5
                  );
                },
              },
              1 + i * 0.5
            );
          }

          //sound effect and panel animation
          timeline.to(
            target,
            0.01,
            {
              onStart: () => {
                this.playSoundEffect(sound_fanfare);
                this.runPanelAnimation = true;
              },
            },
            3
          );

          const timelineWinText = this.gsap.timeline();
          this.unsubscribeEvents.push(() => {
            timelineWinText.kill();
          });

          //animate win text
          timelineWinText.fromTo(
            kingspinText,
            0.5,
            { fontSize: "60px", y: "+5" },
            {
              fontSize: "70px",
              y: "-5",
              yoyo: true,
              repeat: -1,
              ease: "power0.inOut",
            },
            3
          );

          //show win text
          timeline
            .to(
              kingspinText,
              1,
              {
                opacity: 1,
              },
              3
            )
            .fromTo(
              kingspinText,
              0.5,
              {
                scale: 0,
                transformOrigin: "center bottom",
              },
              {
                scale: 1,
              },
              3
            );

          //light
          timeline
            .to(
              kingspinLight,
              0,
              {
                opacity: 1,
              },
              3
            )
            .fromTo(
              kingspinLight,
              7,
              {
                rotation: 0,
              },
              {
                rotation: 450,
                ease: "power0.inOut",
              },
              3
            );

          //coin firework
          timeline.to(
            target,
            0.01,
            {
              onStart: () => {
                this.runCoinFirework = true;
              },
            },
            3
          );

          if (this.isJackpot) {
            let paneltextAll = document.getElementsByClassName("panel-amount");

            for (let i = 0; i < jackpot_image.length; i++) {
              //show shining '3x' word
              timeline
                .to(
                  jackpot_numbers[i],
                  0.5,
                  {
                    opacity: 1,
                    onStart: () => {
                      this.AnimationEffectsAnimateMorphBlue(
                        185,
                        220,
                        jackpot_numbers[i].parentNode
                      );
                      this.playSoundEffect(
                        require("@/assets/sound/animation/full-reward-earned/gold.wav"),
                        false,
                        0.5
                      );
                    },
                  },
                  5 + i * 0.5
                )
                .to(
                  jackpot_numbers[i],
                  2,
                  {
                    y: -300,
                    ease: "power0.inOut",
                  },
                  5 + i * 0.5
                )
                .to(
                  jackpot_numbers[i],
                  1,
                  {
                    opacity: 0,
                  },
                  6 + i * 0.5
                );

              //show bonus word
              timeline.to(
                jackpot_image[i],
                1,
                {
                  scale: 1,
                  ease: "elastic",
                  onStart: () => {
                    this.AnimationElixirAnimateMorph(
                      130,
                      -100,
                      false,
                      jackpot_image[i]
                    );
                  },
                },
                3
              );

              //count numbers up
              timeline.to(
                jackpot_image[i],
                0.01,
                {
                  onStart: () => {
                    let isTime = false;
                    let currentVal = paneltextAll[i].innerHTML;
                    let val_old;
                    let val_new;

                    if (currentVal.indexOf(":") !== -1) {
                      //is time
                      isTime = true;
                      val_old = this.timeToSeconds(
                        currentVal.replace("+", "").trim()
                      );
                    } else {
                      //is not time
                      val_old = parseInt(
                        currentVal.split(".").join("").replace("+", "").trim()
                      );
                    }
                    val_new = val_old * 3;

                    this.AnimationNumberIncreaseAnimate(
                      paneltextAll[i],
                      val_old,
                      val_new,
                      3,
                      "+ ",
                      null,
                      isTime
                    );
                  },
                },
                5
              );
            }
          }

          //hide win elements
          timeline
            .to(
              kingspinText,
              0.5,
              {
                opacity: 0,
              },
              9
            )
            .to(
              kingspinLight,
              0.5,
              {
                opacity: 0,
              },
              9.2
            );

          //close modal
          timeline.to(
            kingspinText,
            0.01,
            {
              onStart: () => {
                this.$bvModal.hide("modal-bonus-slot");
              },
            },
            10
          );

          // update wallet
          timeline.to(
            target,
            0,
            {
              onComplete: () => {
                if (allFinished) {
                  this.$store.dispatch("user/setDelayedUpdate", false);
                }
              },
            },
            4
          );
        }
      } catch (e) {
        console.log("Bonus Slot Win Animation abgebrochen.");
      }
    },

    //animation of upgrade a symbol with elixir
    animateSymbol: function (symbol, updateData) {
      //get elements
      let col1 = this.$refs["Col" + symbol.symbol];
      let elixirText = this.$refs["Elixirtext"];
      let ColText = this.$refs["ColText" + symbol.symbol];
      let glowBg = this.$refs["BgGlow" + symbol.symbol];

      let val_old = ColText[0].innerHTML;
      let val_new = symbol.reward_amount;

      let sound_potion_bubbles = require("@/assets/sound/animation/elixir/kleinekrone-animations-soundFX-potion-active-bubbles.mp3");
      const timeline = this.gsap.timeline();
      this.unsubscribeEvents.push(() => {
        timeline.kill();
      });

      try {
        /* begin animation */

        //run elixir spine animation
        timeline.to(
          elixirText,
          0.01,
          {
            onStart: () => {
              this.runElixirAnimation = true;
            },
          },
          0
        );

        //sound potion
        timeline.to(
          elixirText,
          0,
          {
            onComplete: () => {
              this.playSoundEffect(sound_potion_bubbles, false, 0.4);
            },
          },
          0
        );

        //elixir amount
        timeline
          .to(
            elixirText,
            0.1,
            {
              y: +10,
            },
            0
          )
          .to(
            elixirText,
            0.1,
            {
              y: 0,
            },
            0.1
          );

        //green border
        timeline
          .to(
            col1,
            0.5,
            {
              scale: 1,
            },
            2.4
          )
          .to(
            col1,
            5,
            {
              scale: 0,
            },
            5.9
          );

        //flare
        timeline.to(
          col1,
          0,
          {
            onComplete: () => {
              this.AnimationElixirAnimateFlare(
                33,
                33,
                false,
                col1[0].parentNode
              );
            },
          },
          2.4
        );

        //morph
        timeline.to(
          col1,
          0,
          {
            onComplete: () => {
              this.AnimationElixirAnimateMorph(
                35,
                37,
                false,
                col1[0].parentNode
              );
            },
          },
          2.4
        );

        //bg glow
        timeline
          .to(
            glowBg,
            2,
            {
              opacity: 1,
            },
            2.4
          )
          .to(
            glowBg,
            0.7,
            {
              scale: 1.1,
            },
            2.4
          )
          .to(
            glowBg,
            0.7,
            {
              scale: 1,
            },
            3.1
          )
          .to(
            glowBg,
            2,
            {
              opacity: 0,
            },
            5.7
          );

        //number increase
        timeline.to(
          ColText,
          0,
          {
            onComplete: () => {
              let isTime = false;
              if (symbol.symbol === "DIAMOND") {
                isTime = true;
                val_old = this.timeToSeconds(val_old);
              }

              this.AnimationNumberIncreaseAnimate(
                ColText[0],
                val_old,
                val_new,
                3,
                null,
                null,
                isTime
              );
            },
          },
          2.7
        );

        //update data
        timeline.to(
          elixirText,
          0,
          {
            onComplete: () => {
              this.animationRunningCount--;
              updateData();
              this.runElixirAnimation = false;
            },
          },
          5.7
        );
      } catch (e) {
        console.log("Bonus Slot Win Animation abgebrochen.");
      }
    },

    showElixirModal: function () {
      this.playSoundButtonCommon();
      this.$bvModal.show("modal-buy-elixir");
    },

    handleBuyElxir: async function () {
      if (this.doBuy) {
        return;
      }
      this.doBuy = true;

      this.$store.dispatch("user/setDelayedUpdate", false); //turn off wallet delay
      bonusSlotAPI
        .buyElixir()
        .then((result) => {
          this.$bvModal.hide("modal-buy-elixir");
          this.data.elixir = result.data.data.wallet.amount;

          this.firebaseVirtualCurrencyDrain(
            "buy_elixir",
            "CROWN",
            this.config["bonusslot.elixir_price"],
            "BONUS_SLOT"
          );

          //turn on wallet delay
          setTimeout(() => {
            this.$store.dispatch("user/setDelayedUpdate", true);
            this.doBuy = false;
          }, 500);
        })
        .catch((e) => {
          this.errorMessage = e.data.message;
          this.$store.commit("popups/setAlertBannerContent", {
            alertText: this.errorMessage,
            alertHeading: this.$t("alerts.title.error"),
            variant: "danger",
            type: "danger",
          });
          this.doBuy = false;
          this.$bvModal.hide("modal-buy-elixir");
        });
    },
  },
};
</script>
<style lang="scss">
$modal-width: 1276px;
$modal-height: 720px;

#modal-bonus-slot {
  .modal-dialog {
    max-width: $modal-width;
    width: 100%;
    height: $modal-height;
    padding-top: 50px;

    @media (max-height: 770px), (max-width: 1250px) {
      transform: scale(0.9);
      top: -40px;

      .info-badge-popover {
        top: 20px !important;
        left: 60px !important;
      }
    }
    @media (max-height: 690px), (max-width: 1100px) {
      transform: scale(0.8);
      top: -120px;

      .info-badge-popover {
        top: 30px !important;
      }
    }

    @media (max-width: 1250px) {
      left: -100px;
    }
  }

  .modal-body {
    margin-top: 0 !important;
    width: $modal-width;
    height: $modal-height;
    overflow: visible;
  }

  .modal-content {
    width: 100%;
    height: 100%;
    background: none;
    margin-top: 70px;

    .bg1 {
      margin-top: -10px;
      width: $modal-width;
      height: $modal-height;
      background: url(~@/assets/img/bonus-slot/bs-main-back-half.png) bottom
        right/50% 100% no-repeat;
      position: absolute;
      left: 1px;
      top: 0;
      transform: scaleX(-1);
    }

    .bg2 {
      margin-top: -10px;
      width: $modal-width;
      height: $modal-height;
      background: url(~@/assets/img/bonus-slot/bs-main-back-half.png) bottom
        right/50% 100% no-repeat;
      position: absolute;
      right: 1px;
      top: 0;
    }

    .slot-bg {
      margin-top: -10px;
      width: 284px;
      height: 348px;
      background: url(~@/assets/img/bonus-slot/slot/bs-main-bg-reel.png)
        center/100% 100% no-repeat;
      position: absolute;

      &.back1 {
        left: 200px;
        top: 140px;
      }

      &.back2 {
        left: 500px;
        top: 140px;
      }

      &.back3 {
        left: 800px;
        top: 140px;
      }
    }
  }

  .close-button {
    position: absolute;
    left: 1200px;
    top: -34px;
    width: 54px;
    height: 54px;
    max-width: none;
    background: url(~@/assets/img/mission/mission-close-button.png) no-repeat;
    background-size: 100% 100%;
    z-index: 999;

    &:hover {
      background-image: url(~@/assets/img/mission/mission-close-button-hover.png);
    }
  }

  .spinner-container {
    top: -345px;
    left: 98px;
    z-index: 99;
  }

  .elixir-flare {
    z-index: 21;
    opacity: 1;
  }

  .elixir-morph {
    z-index: 21;
    opacity: 0.9;
  }

  .firework-coin {
    z-index: 50;
    top: -100px;
    pointer-events: none;
  }

  .confetti {
    z-index: 900;
    position: absolute;
    top: 185px;
    left: 500px;
  }

  .potion-crown-price {
    position: relative;
    font-family: Ubuntu-Bold;
    text-align: center;
    padding: 0 15px;
    line-height: 1.3;

    .amount {
      font-size: 26px;
      font-family: Ubuntu-Bold;
      position: relative;
      display: inline-block;
      margin-top: -2px;
      padding-right: 40px;

      .potion-crown-icon {
        width: 34px;
        height: 34px;
        position: absolute;
        top: 2px;
        right: 0;
        background-image: url(~@/assets/img/common/crown5.png);
        background-repeat: no-repeat;
        background-size: 100%;
      }
    }
  }

  .jackpot-image-bonus-slot {
    .elixir-morph {
      top: 20px;
      transform: scale(3);
    }
  }
}
</style>

<style scoped lang="scss">
$modal-width: 1276px;
$modal-height: 720px;

#modal-bonus-slot {
  .preload {
    height: 0;
    width: 0;
    background-image: url(~@/assets/img/mission/mission-close-button-hover.png),
      url(~@/assets/img/mission/mission-green-button-hover-kopie.png),
      url(~@/assets/img/bonus-slot/bs-paytable-back-half.png),
      url(~@/assets/img/bonus-slot/bs-paytable-back-half.png),
      url(~@/assets/img/bonus-slot/bs-btn-activate-elixir-hover.png),
      url(~@/assets/img/bonus-slot/paytable-symbol-dis-glow-green.png);
  }

  .content {
    height: $modal-height;
    position: relative;
    margin-left: auto;
    margin-right: auto;

    &.bonus-slot-content {
      width: $modal-width;

      .bg1,
      .bg2 {
        z-index: 10;
      }

      .slot-bg {
        z-index: 5;
      }

      .headline {
        top: 10px;
        width: 553px;
        height: 82px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url(~@/assets/img/bonus-slot/bs-main-hl-normal.png);
        z-index: 15;
      }

      .headline2 {
        top: -8px;
        width: 617px;
        height: 131px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url(~@/assets/img/bonus-slot/bs-main-hl-glow.png);
        z-index: 15;
      }

      .kingspin-text {
        font-size: 62px;
        font-family: cinzel-decorative-black;
        color: white;
        text-shadow: 1px 1px 2px #1ae5f4, 0 0 25px #1ae5f4, 0 0 5px #1ae5f4;
        text-align: center;
        top: 410px;
        opacity: 0;
        z-index: 950;
        pointer-events: none;
      }

      .kingspin-light-container {
        width: 925px;
        height: 662px;
        top: 29px;
        overflow: hidden;
        pointer-events: none;
        z-index: 19;

        .kingspin-light {
          opacity: 0;
          margin-top: -80px;
        }
      }

      .winpanels {
        position: absolute;
        top: 110px;
        width: 100%;
        z-index: 21;

        .yellow-text {
          font-family: Ubuntu-Bold;
          font-size: 20.4pt;
        }

        .panel-amount {
          font-family: Ubuntu-Bold;
          color: white;
          font-size: 30px;
          z-index: 20;
          width: 258px;
          top: 21px;
        }

        .winpanel {
          position: absolute;
          z-index: 1000;
          top: 0;
          text-align: center;
          transform: scale(0);
          width: 270px;

          .panel-bg {
            position: absolute;
            z-index: 1;
            left: -35px;
          }
        }

        .reward-icon {
          height: 25px;
          margin-top: -10px;
          margin-left: 10px;
          z-index: 20;
          position: absolute;
          top: 42px;
          left: 15px;
        }

        .jackpot-number-bonus-slot {
          top: 0;
          opacity: 0;

          .jackpot-number-bonus-slot-x {
            color: white;
            font-size: 40px;
            font-family: cinzel-decorative-black;
            z-index: 20;
            margin-left: -60px;
            margin-top: 30px;
          }

          .jackpot-number-bonus-slot-amount {
            color: white;
            font-size: 65px;
            font-family: cinzel-decorative-black;
            z-index: 20;
          }

          .jackpot-number-bonus-slot-glow {
            z-index: 19;
            width: 1px;
            height: 10px;
            border-radius: 50%;
            background-color: #00f0f0;
            box-shadow: 0 0 30px 10px #00f0f0, 0 0 50px 30px #00f0f0,
              0 0 70px 45px #00f0f0;
            left: 40px;
            top: 15px;
          }
        }

        .jackpot-image-bonus-slot {
          top: 200px;
          z-index: 1009;
          width: 220px;
          transform: scale(0);
        }

        .winpanel0 {
          left: 207px;
        }

        .winpanel1 {
          left: 504px;
        }

        .winpanel2 {
          left: 799px;
        }
      }

      .winamounts {
        text-align: center;
        width: 100%;
        position: absolute;
        top: 200px;
        z-index: 21;

        .win {
          position: absolute;
          font-family: Ubuntu-Bold;
          font-size: 25pt;
          opacity: 0;
          text-align: center;
          line-height: 200px;

          &.win0 {
            left: 215px;
            width: 22%;
          }

          &.win1 {
            left: 499px;
            width: 22%;
          }

          &.win2 {
            left: 783px;
            width: 22%;
          }
        }
      }

      .coin-left {
        position: absolute;
        top: 475px;
        left: 136px;
        width: 281px;
        height: 147px;
        background-image: url(~@/assets/img/bonus-slot/bs-main-coins-left.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        z-index: 15;
      }

      .coin-right {
        position: absolute;
        top: 430px;
        right: 140px;
        width: 84px;
        height: 128px;
        background-image: url(~@/assets/img/bonus-slot/bs-main-coin-right.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        z-index: 15;
      }

      .crown-right {
        position: absolute;
        top: 520px;
        right: 245px;
        width: 197px;
        height: 102px;
        background-image: url(~@/assets/img/bonus-slot/bs-main-crowns.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        z-index: 15;
      }

      #pixi-bonus-slot {
        height: 100%;
        width: 100%;
        z-index: 20;

        canvas {
          width: 100%;
          height: 100%;
        }
      }

      .page-button {
        font-size: 16px;
      }

      .bottom-button {
        position: absolute;
        top: 304px;
        z-index: 21;
        font-size: 20px;
        height: 70px;
        width: 300px;
      }

      .bottom-toggle {
        top: 633px;
        z-index: 21;
        color: white;
        font-family: Ubuntu-Bold;
        text-transform: uppercase;
        cursor: pointer;
        filter: drop-shadow(0 -2px 6px rgba(77, 229, 255, 0.59));
        width: 496px;
        height: 81px;
        line-height: 2.5;

        .icon-elixir {
          width: 60px;
          height: 100%;
          background-image: url(~@/assets/img/common/elixir.png);
          margin-bottom: 16px;
        }

        &:hover {
          filter: drop-shadow(0 0 8px #52cbee);
        }
      }

      .icon-bg {
        background-image: url(~@/assets/img/bonus-slot/overview/rectangleCopy3@3x.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        width: 158pt;
        height: 36.5pt;
        line-height: 36.5pt;
        text-align: left;
        z-index: 20;
        position: absolute;

        .amount-container {
          font-family: Ubuntu-Bold;
          font-size: 20.4pt;
          margin-left: 55px;

          span {
            font-family: Ubuntu-Bold;
            font-size: 20.4pt;
          }
        }

        .icon-container {
          position: absolute;
          left: -34px;
          top: -4px;
          width: 85px;
          text-align: center;

          .icon {
            top: -4px;
            height: 50px;
          }
        }
      }
    }

    &.elixir-content {
      width: 1280px;
      margin-left: -2px;

      .bg1 {
        margin-top: -10px;
        width: 1280px;
        height: $modal-height;
        background: url(~@/assets/img/bonus-slot/bs-paytable-back-half.png)
          bottom right/50% 100% no-repeat;
        position: absolute;
        left: 1px;
        top: 0;
        transform: scaleX(-1);
      }

      .bg2 {
        margin-top: -10px;
        width: 1280px;
        height: $modal-height;
        background: url(~@/assets/img/bonus-slot/bs-paytable-back-half.png)
          bottom right/50% 100% no-repeat;
        position: absolute;
        right: 1px;
        top: 0;
      }

      .headline {
        top: -10px;
        width: 628px;
        height: 126px;
        background-size: 100% 100%;
        background-repeat: no-repeat;

        &.de {
          background-image: url(~@/assets/img/bonus-slot/bs-back-head-de.png);
        }

        &.en {
          background-image: url(~@/assets/img/bonus-slot/bs-back-head-en.png);
        }
      }

      .bottom-toggle {
        position: absolute;
        right: 165px;
        top: 633px;
        z-index: 21;
        color: white;
        font-family: Ubuntu-Bold;
        text-transform: uppercase;
        cursor: pointer;
        filter: drop-shadow(0 -2px 6px rgba(77, 229, 255, 0.59));
        width: 319px;
        height: 76px;
        line-height: 4.4;
        font-size: 18px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url(~@/assets/img/bonus-slot/btn-front-payouttable.png);

        &:hover {
          filter: drop-shadow(0 -8px 8px #52cbee);
        }
      }

      .bottom-button {
        position: absolute;
        top: 631px;
        background-image: url(~@/assets/img/mission/mission-green-button-kopie.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 378px;
        height: 72px;
        line-height: 86px;
        cursor: pointer;
        padding-left: 50px;
        padding-right: 50px;
        padding-bottom: 5px;

        &:hover {
          background-image: url(~@/assets/img/mission/mission-green-button-hover-kopie.png);
        }

        .text {
          color: white;
          font-size: 21px;
          text-transform: uppercase;
          font-family: Ubuntu-Bold;
          line-height: 1.1;
          text-align: left;
          filter: drop-shadow(0px -2px 8px rgba(10, 39, 0, 0.76));
          text-shadow: 1px 1px 1px rgba(10, 39, 0, 0.76),
            0 0 25px rgba(10, 39, 0, 0.76), 0 0 1px rgba(10, 39, 0, 0.76);
        }

        .price {
          color: white;
          font-size: 36px;
          text-transform: uppercase;
          font-family: Ubuntu-Bold;
          text-align: center;
          filter: drop-shadow(0px -2px 8px rgba(10, 39, 0, 0.76));
          text-shadow: 1px 1px 1px rgba(10, 39, 0, 0.76),
            0 0 25px rgba(10, 39, 0, 0.76), 0 0 1px rgba(10, 39, 0, 0.76);
        }

        .potion-crown-icon {
          width: 64px;
          height: 54px;
          background-image: url(~@/assets/img/common/crown6.png);
          background-size: 100% 100%;
          background-repeat: no-repeat;
          margin-top: -4px;
          text-shadow: 1px 1px 1px rgba(10, 39, 0, 0.76),
            0 0 25px rgba(10, 39, 0, 0.76), 0 0 1px rgba(10, 39, 0, 0.76);
        }
      }

      .sub-headline {
        top: 50px;
        height: 92px;
        width: 393px;
        background-image: url(~@/assets/img/bonus-slot/bs-headline-scrolltext-back.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        line-height: 92px;
        z-index: 20;

        .scroll-text-content {
          height: 35px;
          width: 335px;
          overflow: hidden;
          position: relative;

          .scroll-text {
            background-size: 100% 100%;
            background-repeat: no-repeat;
          }

          .text1 {
            &.de {
              width: 314px;
              height: 31px;
              background-image: url(~@/assets/img/bonus-slot/bs-headline-scrolltext-firstline-de.png);
            }

            &.en {
              width: 256px;
              height: 31px;
              background-image: url(~@/assets/img/bonus-slot/bs-headline-scrolltext-firstline-en.png);
            }
          }

          .text2 {
            &.de {
              width: 301px;
              height: 31px;
              background-image: url(~@/assets/img/bonus-slot/bs-headline-scrolltext-secondline-de.png);
            }

            &.en {
              width: 235px;
              height: 31px;
              background-image: url(~@/assets/img/bonus-slot/bs-headline-scrolltext-secondline-en.png);
            }
          }
        }
      }

      .item-flag {
        top: 128px;
        left: -565px;
      }

      .item-dragon {
        top: 128px;
        right: -565px;
      }

      .item-castle {
        top: 213px;
        left: -565px;
      }

      .item-treasure {
        top: 213px;
        right: -565px;
      }

      .item-medal {
        top: 298px;
        left: -565px;
      }

      .item-prince {
        top: 298px;
        right: -565px;
      }

      .item-princess {
        top: 400px;
        left: -500px;
      }

      .item-queen {
        top: 400px;
      }

      .item-king {
        top: 400px;
        right: -500px;
      }

      .item-crown {
        top: 530px;
        left: -500px;
      }

      .item-map {
        top: 530px;
        left: 40px;
      }

      .item-diamond {
        top: 530px;
        right: -670px;
      }

      .item-normal {
        width: 240px;
        z-index: 99;

        .bg-glow-item-normal {
          width: 266px;
          height: 102px;
          background-image: url(~@/assets/img/bonus-slot/paytable-symbol-dis-glow-green.png);
          background-size: 100% 100%;
          background-repeat: no-repeat;
          position: absolute;
          z-index: 5;
          top: -16px;
          left: -14px;
          opacity: 0;
        }

        .icon-bg {
          width: 236px;
          height: 71px;
          background-image: url(~@/assets/img/bonus-slot/bs-paytable-symbol-display.png);
          background-size: 100% 100%;
          background-repeat: no-repeat;
          position: relative;
          z-index: 10;

          .amount-container {
            top: 18px;
            right: 45px;
            text-align: right;

            span {
              font-family: Ubuntu-Bold;
              font-size: 22px;
              color: white;
              filter: drop-shadow(0 -2px 8px rgba(77, 229, 255, 0.4));
            }
          }

          .amount-icon {
            top: 26px;
            right: -185px;
            height: 18px;
            width: 18px;
            background-image: url(~@/assets/img/common/coin1.png);
            background-size: 100% 100%;
            background-repeat: no-repeat;
          }

          .level-icon {
            position: absolute;
            width: 70px;
            height: 70px;
            top: 0;
            left: -1px;
          }

          .lvl-text-container {
            top: -10px;
            right: -115px;
            z-index: 3;

            .sub1 {
              color: #2da304;
              font-size: 18px;
              font-family: Ubuntu-Medium;
              display: inline;
              text-transform: uppercase;
            }

            .sub2 {
              color: #43ff00;
              font-size: 18px;
              font-family: Ubuntu-Medium;
              display: inline;
            }
          }
        }
      }

      .item-small {
        width: 240px;
        z-index: 99;

        .bg-glow-item-small {
          width: 211px;
          height: 102px;
          background-image: url(~@/assets/img/bonus-slot/paytable-symbol-dis-glow-green-2.png);
          background-size: 100% 100%;
          background-repeat: no-repeat;
          position: absolute;
          z-index: 5;
          top: -16px;
          left: -57px;
          opacity: 0;
        }

        .icon-bg {
          width: 180px;
          height: 71px;
          background-image: url(~@/assets/img/bonus-slot/bs-paytable-symbol-display-2.png);
          background-size: 100% 100%;
          background-repeat: no-repeat;
          position: relative;
          z-index: 10;

          .amount-container {
            top: 18px;
            left: 60px;
            text-align: center;
            z-index: 5;

            span {
              font-family: Ubuntu-Bold;
              font-size: 22px;
              color: white;
              filter: drop-shadow(0 -2px 8px rgba(77, 229, 255, 0.4));
            }
          }

          .amount-icon {
            top: 26px;
            right: -185px;
            height: 18px;
            width: 18px;
            background-image: url(~@/assets/img/common/coin1.png);
            background-size: 100% 100%;
            background-repeat: no-repeat;
          }

          .level-icon {
            position: absolute;
            width: 70px;
            height: 70px;
            top: 0;
            left: -1px;
          }

          .lvl-text-container {
            top: -10px;
            right: -55px;

            .sub1 {
              color: #2da304;
              font-size: 18px;
              font-family: Ubuntu-Medium;
              display: inline;
              text-transform: uppercase;
            }

            .sub2 {
              color: #43ff00;
              font-size: 18px;
              font-family: Ubuntu-Medium;
              display: inline;
            }
          }
        }

        .elixir-counter-container {
          position: absolute;
          top: -4px;
          right: 0;
          width: 50px;
          height: 50px;

          .elixir-icon {
            width: 16px;
            height: 16px;
            background-image: url(~@/assets/img/bonus-slot/bs-paytable-icon-potion-small.png);
            background-size: 100% 100%;
            background-repeat: no-repeat;
          }

          .elixir-lights {
            position: relative;
            margin-top: 20px;
            line-height: 1.2;

            .elixir-light {
              height: 10px;
              width: 10px;
              border: 2px solid #43ff00;
              border-radius: 50%;
              display: inline-block;
              margin: 0 3px;

              &.off {
                background-color: transparent;
              }

              &.on {
                background-color: #43ff00;
              }
            }
          }
        }
      }

      .activate-elixir-container {
        top: 140px;

        .amount {
          right: -50px;
          text-align: right;
          width: 100px;
          font-family: Ubuntu-bold;
          font-size: 24px;
          color: white;
          text-shadow: 0 0 6px rgba(67, 255, 0, 1),
            0 0 6px rgba(67, 255, 0, 0.8);
          z-index: 10;
        }

        .icon {
          top: -100px;
        }

        .activate-button {
          background-image: url(~@/assets/img/bonus-slot/bs-btn-activate-elixir.png);
          background-size: 100% 100%;
          background-repeat: no-repeat;
          top: 70px;
          width: 237px;
          height: 96px;
          cursor: pointer;
          font-size: 24px;
          font-family: Ubuntu-bold;
          color: white;
          text-shadow: 0 0 8px rgba(67, 255, 0, 0.6),
            0 0 8px rgba(67, 255, 0, 0.6);
          text-transform: uppercase;
          line-height: 96px;
          user-select: none;
          z-index: 10;

          &:hover {
            z-index: 10;
            background-image: url(~@/assets/img/bonus-slot/bs-btn-activate-elixir-hover.png);
          }
        }

        .info-text {
          color: white;
          font-size: 20px;
          font-family: Ubuntu;
          width: 248px;
          top: 150px;
          line-height: 1.2;

          .green-text {
            color: #43ff00;
            font-size: 20px;
            font-family: Ubuntu;
          }
        }
      }

      .down-elixir-box-bg {
        position: absolute;
        top: 470px;
        left: 235px;
        width: 582px;
        height: 160px;
        z-index: 1;

        .box-bg1 {
          width: 582px;
          height: 160px;
          background: url(~@/assets/img/bonus-slot/6-ex-symbols-background.png)
            bottom left/50% 100% no-repeat;
          position: absolute;
          left: 0;
          top: 0;
          transform: scaleX(-1);
        }

        .box-bg2 {
          width: 582px;
          height: 160px;
          background: url(~@/assets/img/bonus-slot/6-ex-symbols-background.png)
            bottom left/50% 100% no-repeat;
          position: absolute;
          right: 0;
          top: 0;
        }

        .text {
          z-index: 2;
          color: #c8c8c8;
          font-family: Ubuntu;
          font-size: 18px;
          top: 20px;
        }
      }
    }
  }
}
</style>
