var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modal-olympia-event",attrs:{"id":"modal-olympia-event","hide-footer":true,"modal-class":"custom-modal1"},on:{"shown":_vm.setUpContent,"hidden":_vm.clearContent},scopedSlots:_vm._u([{key:"modal-header",fn:function(ref){
var close = ref.close;
return (!_vm.loading)?[(!_vm.animReady)?_c('div',{staticClass:"gfg-icon"}):_vm._e(),_c('AnimationEventGoforgold',{on:{"done":function($event){_vm.animReady = true}}}),_c('div',{staticClass:"clickable",attrs:{"id":"help-button"},on:{"click":function($event){return _vm.$bvModal.show('modal-olympia-tutorial')}}}),_c('div',{staticClass:"clickable text-right",class:[_vm.isToday ? 'disabled' : ''],attrs:{"id":"matchday-box"},on:{"click":_vm.selectCurrentMatchday}},[_c('div',{class:[_vm.isToday ? 'disabled' : ''],attrs:{"id":"today-button"}},[_c('span',{staticClass:"text-uppercase bold"},[_vm._v(_vm._s(_vm.$t("teams.chat.today")))])]),_c('div',{attrs:{"id":"matchday-headline"}},[_c('span',{staticClass:"text-uppercase bold"},[_vm._v(_vm._s(_vm.$t("events.olympics.matchday>one"))+" "+_vm._s(_vm.matchdayIndex + 1)+": ")]),_c('span',{staticClass:"blue bold"},[_vm._v(_vm._s(_vm.selectedMatchdayDate))])]),_c('div',{staticClass:"d-inline-flex align-items-center",attrs:{"id":"matchday-subline"}},[_c('span',{staticClass:"text-uppercase bold",style:({ color: _vm.sublineColor })},[_vm._v(_vm._s(_vm.matchdaySubline))]),(
            _vm.isToday &&
            _vm.selectedMatchday &&
            !_vm.packagePurchased &&
            _vm.isSelectedMatchdayActive
          )?[_c('span',{staticClass:"mr-1"},[_vm._v(":")]),_c('CountdownDigital',{staticClass:"blue bold",attrs:{"time":_vm.matchdayEndTime,"with-time-diff":true}})]:_vm._e()],2)]),_c('a',{staticClass:"close-button",attrs:{"href":"#"},on:{"click":function($event){return close()}}})]:undefined}}],null,true)},[(!_vm.loading)?_c('template',{slot:"default"},[_c('div',{staticClass:"container-fluid"},[_c('b-row',{attrs:{"no-gutters":"","align-v":"end","align-h":"between"}},[_c('b-col',{attrs:{"id":"matchdays-col","cols":"3"}},[_c('div',{staticClass:"matchday-entry d-inline-flex align-items-center justify-content-center"},[_vm._v(" "+_vm._s(_vm.$t("events.olympics.matchday>other"))+" ")]),_c('div',{attrs:{"id":"matchday-scroll-wrapper"}},_vm._l((_vm.matchdays),function(matchday,index){return _c('ModalOlympiaMatchdayEntry',{key:index,attrs:{"index":index,"matchday":matchday,"selected-matchday-index":_vm.matchdayIndex,"todays-matchday-index":_vm.todaysMatchdayIndex,"ingame-events":_vm.ingameEvents,"user-raffles":_vm.userRaffles,"active-matchday-index":_vm.activeMatchdayIndex},on:{"matchdaySelected":_vm.handleMatchdaySelect}})}),1),_c('div',{staticClass:"matchday-entry scroll-arrow clickable",on:{"click":function($event){return _vm.scrollDown(100)}}})]),_c('ModalOlympiaDayCols',{attrs:{"matchday":_vm.selectedMatchday,"mega-package":_vm.megaPackage,"normal-package":_vm.normalPackage,"matchday-ingame-event":_vm.matchdayIngameEvent,"is-today":_vm.isToday,"is-yesterday":_vm.isYesterday,"day-one-date":_vm.selectedMatchdayDate,"day-two-date":_vm.dayTwoDate,"day-three-date":_vm.dayThreeDate,"is-active":_vm.isSelectedMatchdayActive},on:{"buy-package":_vm.handleBuyPackage,"bonus-collected":_vm.handleBonusCollect}}),_c('ModalOlympiaTutorial')],1)],1)]):_vm._e(),(_vm.loading)?_c('Spinner',{staticClass:"dead-center"}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }