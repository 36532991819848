<template>
  <div id="settings" class="position-relative">
    <div
      class="settings-menu-icon"
      name="settings-menu-icon"
      @click="
        () => {
          playSoundTab();
          firebaseClickItemMenu('menu_settings_button');
          open = !open;
        }
      "
    ></div>
    <transition name="slide">
      <div v-if="open" class="settings-list container">
        <b-row align-h="center">{{ $t("settings.text") }}</b-row>
        <b-row align-h="center" name="settings-sound" @click="toggleMusic">
          <b-col class="settings-icon">
            <img draggable="false" src="@/assets/img/settings/icon-music.svg" />
          </b-col>
          <b-col
            ><span class="settings-label">{{
              $t("lobby.navigation.music")
            }}</span></b-col
          >
          <b-col
            ><div class="settings-controller">
              <div
                class="settings-indicator"
                :class="{ on: isMusicPlaying }"
              ></div></div
          ></b-col>
        </b-row>
        <b-row
          align-h="center"
          name="settings-sound"
          @click="toggleSoundEffect"
        >
          <b-col class="settings-icon">
            <img
              draggable="false"
              src="@/assets/img/settings/icon-soundfx.svg"
            />
          </b-col>
          <b-col
            ><span class="settings-label">{{
              $t("lobby.navigation.sound")
            }}</span></b-col
          >
          <b-col
            ><div class="settings-controller">
              <div
                class="settings-indicator"
                :class="{ on: isSoundEffectPlaying }"
              ></div></div
          ></b-col>
        </b-row>

        <b-row
          name="settings-profile"
          @click="navigateFromSettings('/profile')"
        >
          <b-col class="settings-icon">
            <img src="@/assets/img/settings/icon-profile.svg" />
          </b-col>
          <b-col
            ><span class="settings-label">{{
              $t("lobby.navigation.profile")
            }}</span></b-col
          >
        </b-row>

        <b-row align-h="center" name="settings-lang">
          <b-col class="settings-icon">
            <img
              draggable="false"
              src="@/assets/img/settings/icon-translate.svg"
            />
          </b-col>
          <b-col
            ><span class="settings-label" @click="toggleLanguages">{{
              $t("settings.language")
            }}</span></b-col
          ><b-col id="languages-col">
            <img
              v-for="lang in languages"
              :key="lang.name"
              draggable="false"
              :alt="lang.name"
              :src="lang.url"
              class="language-flag"
              :class="{ on: lang.name === currentLanguage }"
              @click="changeCurrentLanguage(lang.name)"
          /></b-col>
        </b-row>
        <b-row align-h="center" name="settings-sound" @click="toggleInfos">
          <b-col class="settings-icon">
            <img
              draggable="false"
              src="@/assets/img/settings/tooltip-icon.png"
            />
          </b-col>
          <b-col
            ><span class="settings-label">{{
              $t("settings.help")
            }}</span></b-col
          >
          <b-col
            ><div class="settings-controller">
              <div
                class="settings-indicator"
                :class="{ on: infoEnabled }"
              ></div></div
          ></b-col>
        </b-row>
        <b-row
          v-if="$store.state.locale === 'de'"
          name="settings-help"
          @click="navigateFromSettings('/faq')"
        >
          <b-col class="settings-icon">
            <img src="@/assets/img/settings/icon-help.svg" />
          </b-col>
          <b-col><span class="settings-label">FAQ</span></b-col>
        </b-row>
        <b-row
          name="settings-contact"
          @click="navigateFromSettings('/contact')"
        >
          <b-col class="settings-icon">
            <img src="@/assets/img/settings/icon-email.svg" />
          </b-col>
          <b-col
            ><span class="settings-label">{{
              $t("support.contact.tab_title")
            }}</span></b-col
          >
        </b-row>
        <VFBLoginScope
          v-if="$store.getters.facebookEnabled"
          :app-id="facebookAppId"
        >
          <b-row
            slot-scope="scope"
            name="settings-logout"
            @click="handleLogout(scope)"
          >
            <b-col class="settings-icon">
              <img src="@/assets/img/settings/icon-logout.svg" />
            </b-col>
            <b-col @click="playSoundTab"
              ><span class="settings-label">
                {{ $t("settings.logout") }}
              </span></b-col
            >
          </b-row></VFBLoginScope
        >
        <b-row v-else name="settings-logout" @click="handleLogout()">
          <b-col class="settings-icon">
            <img src="@/assets/img/settings/icon-logout.svg" />
          </b-col>
          <b-col @click="playSoundTab"
            ><span class="settings-label">
              {{ $t("settings.logout") }}
            </span></b-col
          > </b-row
        ><b-row class="mt-3" @click="navigateFromSettings('/tac')">
          <span class="legal-link">{{ $t("legal.tac") }}</span>
        </b-row>

        <b-row @click="navigateFromSettings('/privacy')">
          <span class="legal-link">{{ $t("legal.privacy") }}</span></b-row
        ><b-row class="mb-1" @click="navigateFromSettings('/imprint')">
          <span class="legal-link">{{ $t("legal.imprint") }}</span></b-row
        >
        <div id="version-number" class="position-absolute">
          {{ $store.getters.getAppVersion }}
        </div>
      </div></transition
    >
  </div>
</template>
<script>
import { VFBLoginScope } from "vue-facebook-login-component";
import { getConfigWithLocale } from "@/API/general.js";

export default {
  name: "SettingsMenu",
  components: { VFBLoginScope },
  data() {
    return {
      currentLanguage: "de",
      open: false,
      languages: [
        {
          name: "de",
          url: require("@/assets/img/settings/de.png"),
        },
        {
          name: "en",
          url: require("@/assets/img/settings/en.png"),
        },
      ],
      facebookAppId: process.env.VUE_APP_FACEBOOK_APP_ID,
    };
  },
  computed: {
    activeLanguage: function () {
      return this.languages.filter(
        (lang) => lang.name === this.currentLanguage
      )[0];
    },
    isMusicPlaying() {
      return this.$store.getters["sound/getMusicPreference"];
    },
    isSoundEffectPlaying() {
      return this.$store.getters["sound/getSoundEffectPreference"];
    },
    infoEnabled() {
      return this.$store.getters.infoPreference;
    },
  },
  watch: {
    $route(to, from) {
      this.open = false;
    },
  },
  mounted() {
    this.currentLanguage = this.$store.state.locale;
  },
  methods: {
    handleLogout(scope) {
      this.stopAllSounds();
      this.open = false;
      if (scope && scope.connected) {
        scope.logout();
      }
      this.$store.dispatch("setIsPageLoading", true).then(() => {
        if (this.$route.path !== "/") {
          this.$router.push("/");
        }
        this.$store.dispatch("destroyStore");
      });
    },
    changeCurrentLanguage: function (lang) {
      this.open = false;
      this.playSoundTab();
      this.currentLanguage = lang;
      this.$i18n.i18next.changeLanguage(lang);
      this.$store.dispatch("setLocale", lang);
      let localeQuery = lang + "_" + lang.toUpperCase();
      // send updated locale to backend middleware via query param
      getConfigWithLocale(localeQuery).catch((e) => console.log(e));
      if (lang === "en" && this.$route.name === "faq") {
        this.$router.push("/contact");
      }
    },
    toggleMusic: function () {
      if (this.isMusicPlaying) {
        this.$store.dispatch("sound/updateMusic", false);
      } else {
        this.$store.dispatch("sound/updateMusic", true);
      }
    },
    toggleSoundEffect: function () {
      if (this.isSoundEffectPlaying) {
        this.$store.dispatch("sound/updateSoundEffect", false);
      } else {
        this.$store.dispatch("sound/updateSoundEffect", true);
      }
    },
    toggleInfos: function () {
      if (this.infoEnabled) {
        this.$store.commit("setInfoPreference", false);
      } else {
        this.$store.commit("setInfoPreference", true);
      }
    },
    toggleLanguages() {
      if (this.currentLanguage === "de") {
        this.changeCurrentLanguage("en");
      } else {
        this.changeCurrentLanguage("de");
      }
    },
    navigateFromSettings(path) {
      this.playSoundTab();
      this.open = false;
      this.$router.push(path);
    },
  },
};
</script>

<style scoped lang="scss">
#settings {
  pointer-events: auto;
  cursor: pointer;
  z-index: 1000;
  &:focus {
    outline: none;
  }
  .settings-menu-icon {
    width: 60px;
    height: 60px;
    transition: 0.2s ease-in-out;
    z-index: 1010;
    position: relative;
    -webkit-transform: translate3d(0, 0, 0);
    #settings-icon {
      position: absolute;
      top: 50px;
      left: 10px;
      transition: transform 0.5s ease-in-out;
      transform: rotate(0);
      &.rotate {
        transform: rotate(120deg);
      }
    }

    &:hover {
      filter: $hover-shadow;
    }
  }
  .slide-leave-active,
  .slide-enter-active {
    transition: 0.5s;
  }
  .slide-enter {
    transform: translateY(-100%);
  }
  .slide-leave-to {
    transform: translateY(-100%);
  }
  .settings-list {
    display: block;
    position: absolute;
    right: 4.063rem;
    top: 0;
    width: 243px;
    max-width: 243px;
    border: 1px solid $light-blue;
    background: linear-gradient(#0e1c24, #173242);
    padding: 0 43px 16px 16px;
    border-radius: 8px;
    opacity: 1;
    font-size: 14px;
    text-transform: uppercase;
    color: $light-blue;

    .row {
      margin-top: 11px;
      margin-bottom: 11px;
      &:hover > .settings-icon {
        filter: drop-shadow(0 0 16px $light-blue);
        cursor: pointer;
      }

      .settings-icon {
        max-width: 20px;
        height: 16px;
      }

      img {
        height: 100%;
        width: 16px;
      }
      #languages-col {
        display: flex;
        justify-content: space-around;
        padding: 0;
        font-size: 16px;
      }
      .settings-controller {
        width: 80%;
        min-width: 50px;
        height: 16px;
        border: 1px solid $light-blue;
        border-radius: 8px;
        position: relative;
        .settings-indicator {
          width: 14px;
          height: 14px;
          border-radius: 50%;
          background-color: $light-blue;
          opacity: 0.3;
          position: absolute;
          left: 0;
          &.on {
            left: auto;
            right: 0;
            opacity: 1;
          }
        }
      }
      .language-flag {
        width: 24px;
        height: 16px;
        &.on,
        &:hover {
          filter: drop-shadow(0 0 4px $light-blue);
        }
      }
    }
    #language-select {
      height: 30px;
      align-items: center;
    }
  }
}

.collapse {
  .settings-icon-content {
    max-width: 35px;
  }
}
.legal-link {
  font-size: 12px;
  line-height: 0.5;
  margin-left: 16px;
  width: 100%;
  height: 100%;
}
#version-number {
  bottom: 2px;
  right: 2px;
  font-size: 10px;
  color: grey;
  opacity: 0.5;
}
</style>
