<template>
  <div class="bundesliga-badge-container to-center-abs">
    <div class="badge-left-container">
      <div class="badge-left">
        <div class="text-head to-center-abs">
          {{ $t("events.bundesliga.package_notification.info_box") }}
        </div>
        <div class="reward-container-left to-center-abs">
          <div v-if="rewards.gold" class="deal-item w-100 reward-height">
            <span class="pr-3"> {{ formatNumber(rewards.gold * 2) }}</span>
            <div class="deal-icon gold big"></div>
          </div>
          <div v-if="rewards.crown" class="deal-item w-50 justify-content-end">
            <span> {{ formatNumber(rewards.crown * 2) }}</span>
            <div class="deal-icon crown big"></div>
          </div>
          <div
            v-if="rewards.loyalty"
            class="deal-item w-50 justify-content-start pl-5"
          >
            <span> {{ formatNumber(rewards.loyalty * 2) }}</span>
            <div class="deal-icon loyalty big"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="change-badge to-center-abs"></div>

    <div class="badge-right-container">
      <div class="badge-right">
        <div class="text-head to-center-abs">
          <img
            :src="
              require(`@/assets/img/events/bundesliga/bonus-pack-headline-${$store.state.locale}.png`)
            "
            class="w-100"
          />
        </div>
        <div class="reward-container-right to-center-abs">
          <div v-if="rewards.gold" class="deal-item2 w-100 reward-height">
            <div class="deal-icon triple-gold center less-space"></div>
            <span class="big">{{ formatNumber(rewards.gold) }}</span>
          </div>
          <div v-if="rewards.crown" class="deal-item w-50 justify-content-end">
            <span class="small"> {{ formatNumber(rewards.crown) }}</span>
            <div class="deal-icon crown small"></div>
          </div>
          <div
            v-if="rewards.loyalty"
            class="deal-item w-50 justify-content-start pl-3"
          >
            <span class="small"> {{ formatNumber(rewards.loyalty) }}</span>
            <div class="deal-icon loyalty small"></div>
          </div>
        </div>
        <div
          class="buy-button to-center-abs"
          :class="{ 'no-buy': !buyable }"
          @click="buyPackage"
        >
          <div class="w-100">
            <span>{{ $t("events.bundesliga.package.buy_button_1") }}</span>
          </div>
          <div
            class="w-100 d-inline-flex align-items-center justify-content-center"
          >
            <span>{{ $t("events.bundesliga.package.buy_button_2") }}</span>
            <span class="price"
              >{{ formatPrice(offerPackage.package.price) }} €
            </span>
            <div
              v-if="oldPrices && oldPrices[0]"
              class="offer-old-price ml-3 right"
            >
              {{ formatPrice(oldPrices[0]) }} €
              <div class="offer-red-line to-center-abs"></div>
            </div>
            <div
              v-if="moreContent && moreContent[0]"
              class="big-badge-more-percent smaller"
            >
              <span class="big">{{ moreContent[0] }}</span>
              {{ $t("shop.offers.more_contents2") }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="foot-text">
      <p class="sub1 bold mb-0">
        {{ $t("events.bundesliga.package_notification.footnote_1") }}
      </p>
      <p class="sub2">
        {{ $t("events.bundesliga.package_notification.footnote_2") }}
      </p>
    </div>
  </div>
</template>

<script>
import eventPopups from "@/mixins/eventPopups.js";
import getRewardsFromPackage from "@/mixins/paymentFunctions.js";
import AnimationFullRewardEarned from "@/components/animation/animation-full-reward-earned";
import dateFunctions from "@/mixins/dateFunctions.js";
export default {
  name: "BundesligaPackNoteBadge",
  components: {},
  mixins: [
    eventPopups,
    getRewardsFromPackage,
    AnimationFullRewardEarned,
    dateFunctions,
  ],
  props: {
    offerPackage: {
      type: Object,
      default: null,
    },
    buyable: {
      type: Boolean,
      default: true,
    },
    oldPrices: { type: Array, default: () => [] },
    moreContent: { type: Array, default: () => [] },
  },

  computed: {
    rewards() {
      if (!this.offerPackage) {
        return;
      }

      return this.getRewardsFromPackage(this.offerPackage);
    },
  },

  methods: {
    buyPackage() {
      if (!this.buyable) {
        return;
      }

      let packageObj = this.offerPackage;
      packageObj.rewards = this.rewards;
      this.$store.commit("shop/setShopChosenPackage", packageObj);
      this.$bvModal.show("modal-payment");
      this.$bvModal.hide(this.modalId);
    },
  },
};
</script>
<style lang="scss" scoped>
.bundesliga-badge-container {
  .less-space {
    margin-bottom: -15px;
  }

  .deal-icon,
  .deal-icon2 {
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-left: 5px;
    &.gold {
      background-image: url(~@/assets/img/common/coin1.png);
      &.big {
        width: 60px;
        height: 60px;
      }
    }
    &.triple-gold {
      width: 110px;
      height: 69px;
      background-image: url(~@/assets/img/common/three-coins2.png);
    }
    &.crown {
      background-image: url(~@/assets/img/common/crown6.png);

      &.small {
        width: 50px;
        height: 37px;
      }
      &.big {
        width: 91px;
        height: 69px;
      }
    }
    &.loyalty {
      background-image: url(~@/assets/img/common/loyalty-bonus.png);

      &.small {
        width: 40px;
        height: 40px;
      }
      &.big {
        width: 79px;
        height: 80px;
      }
    }
    &.center {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .badge-left-container {
    position: absolute;
    top: 0;
    left: 70px;

    .badge-left {
      width: 579px;
      height: 305px;
      position: relative;
      background: url(~@/assets/img/events/bundesliga/bonus-info-panel-1-bg.png)
        top center/100% 100% no-repeat;

      .text-head {
        top: 13px;
        font-family: Ubuntu-Bold;
        font-size: 22px;
        color: white;
        text-align: center;
        text-transform: uppercase;
        padding: 0 50px;
        line-height: 1.2;
      }

      .reward-container-left {
        top: 100px;

        .reward-height {
          height: 80px;
        }

        .deal-item {
          display: inline-flex;
          align-items: center;
          justify-content: center;

          span {
            font-family: Ubuntu-bold;
            font-size: 69px;
            background-clip: text;
            -webkit-background-clip: text;
            color: transparent;
            background-image: linear-gradient(to bottom, #f7ffff, #ffee51);
            text-shadow: none;
            filter: drop-shadow(0 0 8px #0a2700);
          }
        }

        .deal-item2 {
          display: block;
        }
      }
    }
  }

  .change-badge {
    top: 20px;
    left: 245px;
    width: 226px;
    height: 226px;
    background: url(~@/assets/img/events/bundesliga/bonus-info-badge-x-2.png)
      top center/100% 100% no-repeat;
    z-index: 5;
  }

  .badge-right-container {
    position: absolute;
    top: 0;
    right: 85px;

    .badge-right {
      width: 320px;
      height: 314px;
      position: relative;
      background: url(~@/assets/img/events/bundesliga/rectangle-copy-3.png) top
        center/100% 100% no-repeat;

      .text-head {
        top: 20px;
        width: 300px;
        height: 70px;
      }

      .reward-container-right {
        top: 100px;

        .reward-height {
          height: 100px;
        }

        .deal-item,
        .deal-item2 {
          display: inline-flex;
          align-items: center;
          justify-content: center;

          span {
            font-family: Ubuntu-bold;
            background-clip: text;
            -webkit-background-clip: text;
            color: transparent;
            background-image: linear-gradient(to bottom, #f7ffff, #ffee51);
            text-shadow: none;
            filter: drop-shadow(0 0 8px #0a2700);

            &.big {
              font-size: 40px;
            }
            &.small {
              font-size: 37px;
            }
          }
        }

        .deal-item2 {
          display: block;
        }
      }

      .buy-button {
        bottom: -60px;
        width: 297px;
        height: 128px;
        background: url(~@/assets/img/events/bundesliga/bonus-info-btn-buy-offer.png)
          top center/100% 100% no-repeat;
        font-family: Ubuntu-Bold;
        color: #43ff00;
        font-size: 23px;
        text-transform: uppercase;
        padding: 28px 22px 0 30px;
        line-height: 1.2;
        cursor: pointer;

        &.no-buy {
          cursor: default;
        }

        span {
          font-family: Ubuntu-Bold;
          color: #43ff00;
          font-size: 23.4px;
          text-transform: uppercase;
          text-shadow: 0 0 18px rgba(67, 255, 0, 0.3),
            0 0 18px rgba(67, 255, 0, 0.3);
          padding-left: 10px;
          &.price {
            font-size: 40px;
            text-shadow: 0 0 18px #43ff00, 0 0 8px #0a2700;
          }
        }

        .offer-old-price {
          top: -11px;
          right: -65px;
        }
        .big-badge-more-percent {
          top: -300px;
          right: -120px;
        }
      }
    }
  }
  .foot-text {
    position: absolute;
    left: 58px;
    top: 320px;
    width: 600px;
    text-align: center;
    line-height: 1;

    .sub1 {
      font-size: 18px;
      color: #4de5ff;
    }
    .sub2 {
      font-family: Ubuntu;
      font-size: 18px;
      color: #5499a4;
      margin-top: 4px;
    }
  }
}
</style>
