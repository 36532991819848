var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$route.name && _vm.$route.name.endsWith('game') ? 'resize-header' : '',attrs:{"id":"header","name":"header"}},[_c('b-navbar',{staticClass:"to-center-abs",attrs:{"id":"header-content"}},[_c('b-navbar-nav',{attrs:{"id":"nav-area-left"}},[_c('b-nav-item',{staticClass:"nav-item-home mr-0"},[_c('router-link',{attrs:{"id":"header-home-container","to":"/"},nativeOn:{"click":function($event){return _vm.handleHomeButtonClick.apply(null, arguments)}}})],1),_c('b-nav-item',{staticClass:"nav-item-logo ml-0"},[_c('router-link',{attrs:{"id":"header-logo-container","to":"/"},nativeOn:{"click":function($event){return _vm.handleHomeButtonClick.apply(null, arguments)}}})],1)],1),_c('b-navbar-nav',{attrs:{"id":"nav-area-middle"}},[(_vm.headerDetailsUnlocked)?_c('b-nav-item',{staticClass:"nav-item-league ml-0",on:{"click":function($event){return _vm.$router.push('/league')}}},[_c('div',{staticClass:"d-flex align-items-center clickable",attrs:{"id":"header-league-container","name":"header-item-league"},on:{"click":function () {
              _vm.playSoundTab();
              _vm.firebaseClickItemHeader('header_user_league_button');
            }}},[_c('img',{staticClass:"header-item-icon",attrs:{"id":"header-league-icon","draggable":"false","src":require("@/assets/img/header/header-league-icon.png")}}),_c('img',{attrs:{"id":"header-league-movement","src":require(("@/assets/img/header/arrow-league-" + _vm.userLeagueMovement + ".png"))}}),(_vm.userLeaguePosition)?_c('div',{attrs:{"id":"header-league-text-field"}},[_vm._v(" "+_vm._s(_vm.userLeaguePosition)+" ")]):_vm._e()])]):_vm._e(),_c('b-nav-item',{staticClass:"nav-item-box ml-0"},[_c('div',{staticClass:"to-center-abs cursor-default",attrs:{"id":"header-center-container"}},[_c('div',{staticClass:"clickable",attrs:{"id":"header-gold-container"},on:{"click":function () {
                _vm.showShop(0);
                _vm.firebaseClickItemHeader('header_gold_display');
              }}},[_c('div',{staticClass:"header-number-bar"},[(_vm.user.gold)?_c('div',{staticClass:"header-number-text big",attrs:{"name":"header-item-gold"}},[_vm._v(" "+_vm._s(_vm.formatNumber(_vm.user.gold.amount))+" ")]):_vm._e()])]),_c('div',{staticClass:"clickable",attrs:{"id":"header-crown-container"},on:{"click":function () {
                _vm.showShop(1);
                _vm.firebaseClickItemHeader('header_crown_display');
              }}},[_c('div',{staticClass:"header-number-bar"},[(_vm.user.crowns)?_c('div',{staticClass:"header-number-text big",attrs:{"name":"header-item-crown"}},[_vm._v(" "+_vm._s(_vm.formatNumber(_vm.user.crowns.amount))+" ")]):_vm._e()])]),_c('div',{staticClass:"clickable",attrs:{"id":"header-elixir-container"},on:{"click":_vm.showBonusSlotElixirPage}},[_c('div',{staticClass:"header-number-bar"},[_c('div',{attrs:{"id":"header-elixir-icon"}}),_c('div',{staticClass:"header-number-text small",attrs:{"name":"header-item-elixir"}},[_vm._v(" "+_vm._s(_vm.formatNumber(_vm.amountElixir))+" ")])])]),_c('div',{staticClass:"clickable",attrs:{"id":"header-avatar-container","name":"header-item-avatar"},on:{"click":function () {
                _vm.$router.push('/profile');
                _vm.playSoundTab();
                _vm.firebaseClickItemHeader('header_profile_button');
              }}},[_c('div',{staticClass:"avatar-section"},[_c('div',{staticClass:"avatar-frame"},[_c('img',{staticClass:"to-center-abs",attrs:{"id":"header-avatar-icon","draggable":"false","src":_vm.user.avatar_url}}),_c('div',{staticClass:"dead-center",attrs:{"id":"avatar-hover-text"}},[_vm._v(" "+_vm._s(_vm.$t("lobby.navigation.profile"))+" ")])])])]),_c('div',{attrs:{"id":"header-exp-container"}},[_c('div',{staticClass:"progress-bar-section"},[_c('div',{staticClass:"icon-expcrown"}),_c('animation-expboost'),_c('div',{staticClass:"progress-fill",style:({ width: _vm.currentExpPercent })}),(_vm.user.experience)?_c('div',{staticClass:"text-below-level-progress"},[_vm._v(" Level "+_vm._s(_vm.formatNumber(_vm.user.experience.level))+" ")]):_vm._e()],1)])])]),_c('b-nav-item',{staticClass:"nav-item-shop mr-0",attrs:{"href":"#"}},[_c('div',{staticClass:"shop-item position-relative"},[_c('div',{staticClass:"position-relative ok-button-green clickable",attrs:{"id":"header-shop-container"},on:{"click":function () {
                _vm.playSoundTab();
                _vm.showShop();
                _vm.firebaseClickItemHeader('header_shop_button');
              }}},[_c('div',{staticClass:"icon-gold-three"}),_c('span',{staticClass:"d-flex align-items-center justify-content-center"},[_vm._v(" "+_vm._s(_vm.$t("header.shop_button"))+" ")])]),(_vm.isSpecialOfferActive)?_c('div',{staticClass:"special-offer-container"},[_c('SpecialOfferBadge')],1):_vm._e(),(_vm.showInfo)?_c('div',{staticClass:"more-info to-center-abs"},[_vm._v(" "+_vm._s(_vm.$t("header.more"))+" ")]):_vm._e()])])],1),_c('b-navbar-nav',{attrs:{"id":"nav-area-right"}},[_c('b-nav-item',{staticClass:"nav-item-settings"},[_c('div',{staticClass:"clickable",attrs:{"id":"header-settings-container"}},[_c('SettingsMenu')],1)]),_c('modal-shop',{attrs:{"preselected-tab":_vm.preselectedTab}}),_c('modal-payment')],1)],1),_c('AnimationSpineRewardFlyHeader')],1)}
var staticRenderFns = []

export { render, staticRenderFns }