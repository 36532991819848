<template>
  <div
    class="special-container position-relative clickable"
    :class="{ pulsating: isPulsing }"
    @click="openModal"
  >
    <div class="item-content">
      <div class="icon-container">
        <img draggable="false" :src="vaultImg" class="icon" />
      </div>
      <div v-if="showBadge" class="new-icon">!</div>
      <div class="banner-text">{{ $t("footer.vault") }}</div>
    </div>
    <ModalTreasury
      v-if="treasuryData"
      :treasury="treasuryData"
      @handle-fetch-treasury-data="fetchTreasuryData"
      @handle-update-treasury-percentage="updateTreasuryPercentage"
    />
  </div>
</template>
<script>
import ModalTreasury from "@/components/treasury/modal-treasury";
import * as treasuryAPI from "@/API/treasury";

export default {
  name: "ItemMenuTreasury",
  components: {
    ModalTreasury,
  },
  data() {
    return {
      treasuryData: {},
    };
  },

  computed: {
    generalConfig: function () {
      return this.$store.getters["getGeneralConfig"];
    },
    vaultImg() {
      let percentage = this.treasuryData.percentage;
      if (percentage < 25) {
        return require("@/assets/img/footer/vault_state_01.png");
      } else if (percentage >= 25 && percentage < 99) {
        return require("@/assets/img/footer/vault_state_02.png");
      } else if (percentage >= 99) {
        return require("@/assets/img/footer/vault_state_03.png");
      } else {
        return null;
      }
    },
    showBadge() {
      return this.treasuryData.percentage >= 25;
    },

    isPulsing() {
      return this.$store.getters["treasury/getPulseMenuIcon"];
    },
  },
  watch: {},
  mounted() {
    this.fetchTreasuryData();
  },
  methods: {
    openModal() {
      this.$bvModal.show("modal-treasury");
    },

    fetchTreasuryData() {
      treasuryAPI
        .getTreasuryData()
        .then((res) => (this.treasuryData = res.data.data.treasury))
        .catch((e) => console.log(e));
    },
    updateTreasuryPercentage(percentage) {
      this.treasuryData.percentage = percentage;
    },
  },
};
</script>
<style scoped lang="scss">
.special-container {
  .item-content {
    background-color: #050d1c;
    border-radius: 0.75rem;
    border: 0.125rem solid rgba(77, 229, 255, 0.9);
    box-shadow: inset 0 0 0.25rem $light-blue;
    height: 3.75rem;
    width: 3.75rem;
    overflow: hidden;

    .icon-container {
      padding: 0.4rem 0 0 0;
      height: 3.438rem;
      .icon {
        display: block;
        width: 96%;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .new-icon {
      position: absolute;
      top: 0.375rem;
      right: -0.625rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 0.125rem;
      background: linear-gradient(to bottom, #ff0000, #3a0000);
      height: 1.5rem;
      width: 1.5rem;
      color: white;
      font-size: 1.313rem;
      font-family: Ubuntu-bold;
      border-radius: 50%;
    }

    &:hover {
      .banner-text {
        display: flex;
      }
    }
    .banner-text {
      display: none;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 1.25rem;
      left: 5.063rem;
      min-width: 11rem;
      width: 100%;
      height: 2rem;
      background-color: rgba(5, 13, 28, 0.8);
      border: 0.063rem solid rgba(77, 229, 255, 0.5);
      border-radius: 0.25rem;
      box-shadow: inset 0 0 0.125rem $light-blue;
      color: white;
      font-size: 0.875rem;
      font-family: Ubuntu-Medium;
      text-transform: uppercase;
      z-index: 999;
    }
  }
}
</style>
