<template>
  <div
    class="special-container position-relative clickable"
    :class="{ pulsatingxxx: !bonusSlotTicking }"
    @click="openModal"
  >
    <div class="item-content">
      <div class="icon-container col">
        <img
          draggable="false"
          class="icon"
          src="@/assets/img/nav-menu/nav-icon-bonusslot.png"
        />
      </div>
      <div v-if="!bonusSlotTicking" class="new-icon">!</div>
      <div class="banner-text">{{ $t("bonus_slot.title") }}</div>
    </div>
    <div v-if="bonusSlotTicking" class="footer-countdown to-center-abs">
      <span class="countdown-numbers to-center-abs">
        <CountdownDigital
          :time="bonusEndDate"
          @over.once="$store.commit('bonusslot/setBonusSlotEndDate', 0)"
        />
      </span>
    </div>
    <div v-if="!bonusSlotTicking" class="news-badge">!</div>
    <modal-bonus-slot />
  </div>
</template>
<script>
import ModalBonusSlot from "@/components/bonus-slot/modal-bonus-slot";
import CountdownDigital from "@/components/base/countdown-digital";

export default {
  name: "ItemMenuBonusSlot",
  components: {
    ModalBonusSlot,
    CountdownDigital,
  },

  computed: {
    generalConfig: function () {
      return this.$store.getters["getGeneralConfig"];
    },
    bonusEndDate: function () {
      let endDateString = this.$store.getters["bonusslot/getBonusSlotEndDate"];
      if (endDateString == null || endDateString == 0) {
        return;
      }

      let now = new Date().getTime() + 1000;
      let then = new Date(endDateString).getTime();
      if (then - now <= 0) {
        return "Jetzt!";
      }

      return then;
    },
    bonusSlotTicking() {
      return !!(this.bonusEndDate && this.bonusEndDate !== "Jetzt!");
    },
  },
  watch: {},
  mounted() {},
  methods: {
    openModal() {
      this.$store.commit("bonusslot/setForceShowElixirPage", false);
      this.$bvModal.show("modal-bonus-slot");
    },
  },
};
</script>
<style scoped lang="scss">
.special-container {
  .item-content {
    background-color: #050d1c;
    border-radius: 0.75rem;
    border: 0.125rem solid rgba(77, 229, 255, 0.9);
    box-shadow: inset 0 0 0.25rem $light-blue;
    height: 3.75rem;
    width: 3.75rem;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 1.25rem;

    .icon-container {
      padding: 0;
      height: 3.438rem;
      .icon {
        height: 3.125rem;
      }
    }

    .new-icon {
      position: absolute;
      top: 0.375rem;
      right: -0.625rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 0.125rem;
      background: linear-gradient(to bottom, #ff0000, #3a0000);
      height: 1.5rem;
      width: 1.5rem;
      color: white;
      font-size: 1.313rem;
      font-family: Ubuntu-bold;
      border-radius: 50%;
    }

    &:hover {
      .banner-text {
        display: flex;
      }
    }
    .banner-text {
      display: none;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 1.25rem;
      left: 5.063rem;
      min-width: 11rem;
      width: 100%;
      height: 2rem;
      background-color: rgba(5, 13, 28, 0.8);
      border: 0.063rem solid rgba(77, 229, 255, 0.5);
      border-radius: 0.25rem;
      box-shadow: inset 0 0 0.125rem $light-blue;
      color: white;
      font-size: 0.875rem;
      font-family: Ubuntu-Medium;
      text-transform: uppercase;
      z-index: 999;
    }
  }
  .footer-countdown {
    text-align: center;
    left: -0.7rem;
    bottom: 0.813rem;
    height: 1.625rem;
    width: 5rem;
    background-image: radial-gradient(circle at top, #ff0000 -45%, #3a0000);
    border-radius: 0.125rem;
    box-shadow: inset 0 0 0.438rem #ff0000, inset 0 0 0.25rem #ff0000,
      inset 0 0 0.125rem #ff0000;

    .countdown-numbers {
      font-family: Ubuntu-Bold;
      color: white;
      font-size: 1rem;
      text-align: center;
    }

    img {
      max-height: 1.313rem;
      position: absolute;
      left: 0.25rem;
      top: -0.063rem;
    }
  }
}
</style>
