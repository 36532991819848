<template>
  <b-modal
    id="modal-bundesliga-pack-note"
    ref="modal-bundesliga-pack-note"
    :hide-footer="true"
    modal-class="custom-modal1"
  >
    <template
      v-if="!loading && offerPackage"
      slot="modal-header"
      slot-scope="{ close }"
    >
      <div class="headline w-100 text-center">
        <div class="sub1 bold">
          {{ $t("events.bundesliga.package_notification.headline_1") }}
        </div>
        <div class="sub2 bold">
          {{ $t("events.bundesliga.package_notification.headline_2") }}
        </div>
      </div>
      <a href="#" class="close-button" @click="close()"> </a>
    </template>

    <template v-if="!loading && offerPackage" slot="default">
      <div class="badge-container w-100">
        <BundesligaPackNoteBadge
          :offer-package="offerPackage"
          :old-prices="oldPrices"
          :more-content="moreContent"
        ></BundesligaPackNoteBadge>
      </div>
    </template>
    <Spinner v-if="loading" class="dead-center" />
  </b-modal>
</template>

<script>
import getRewardsFromPackage from "@/mixins/paymentFunctions.js";
import AnimationFullRewardEarned from "@/components/animation/animation-full-reward-earned";
import Spinner from "@/components/animation/animation-spinner.vue";
import BundesligaPackNoteBadge from "@/components/events/bundesliga/bundesliga-pack-note-badge";
export default {
  name: "ModalBundesligaPackNote",
  components: {
    BundesligaPackNoteBadge,
    Spinner,
  },
  mixins: [getRewardsFromPackage, AnimationFullRewardEarned],

  data() {
    return {
      modalId: "modal-bundesliga-pack-note",
      loading: true,
    };
  },

  computed: {
    oldPrices() {
      const offer = this.$store.state.events.activeEventOffer;
      if (offer.configuration) {
        const conf = JSON.parse(offer.configuration);
        return conf.old_prices;
      }

      return [];
    },
    moreContent() {
      const offer = this.$store.state.events.activeEventOffer;
      if (offer.configuration) {
        const conf = JSON.parse(offer.configuration);
        return conf.more_content;
      }

      return [];
    },
    offerPackage() {
      const offer = this.$store.state.events.activeEventOffer;
      if (offer && offer.packages && offer.packages.length > 0) {
        return offer.packages[0];
      }
      return null;
    },
    rewards() {
      if (!this.offerPackage) {
        return;
      }

      return this.getRewardsFromPackage(this.offerPackage);
    },
  },
  mounted() {
    // this.$bvModal.show("modal-bundesliga-pack-note");
    this.loading = false;
  },
  methods: {
    buyPackage() {
      let packageObj = this.offerPackage;
      packageObj.rewards = this.rewards;
      this.$store.commit("shop/setShopChosenPackage", packageObj);
      this.$bvModal.show("modal-payment");
      this.$bvModal.hide(this.modalId);
    },
  },
};
</script>
<style lang="scss">
$modal-width: 1121px;
$modal-height: 630px;

#modal-bundesliga-pack-note {
  .modal-dialog {
    min-width: $modal-width;
    max-width: $modal-width;
    width: 100%;
    height: $modal-height;
    padding: 44px 0 44px 0;
    margin: 40px auto;
    background: linear-gradient(
      to bottom,
      rgba(12, 30, 60, 0),
      rgba(5, 13, 28, 0.54) 37%,
      #050d1c
    );

    @media (max-height: 850px), (max-width: 1250px) {
      transform: scale(0.8);
      top: -40px;
      margin-bottom: -20%;
    }
    @media (max-height: 770px), (max-width: 1100px) {
      transform: scale(0.7);
      top: -100px;
      margin-bottom: -30%;
    }
    @media (max-width: 1100px) {
      left: -5%;
    }
  }
  .modal-header {
    margin-top: 0;
    .close-button {
      width: 40px;
      height: 40px;
      opacity: 0.5;
      right: 10px;
    }
  }

  .modal-body {
    margin-top: 0 !important;
    padding: 0;
    width: 100%;
    height: 100%;
  }

  .modal-content {
    width: 100%;
    height: 100%;
    background: none;
    border: none;

    .headline {
      top: 50px;
      color: white;
      text-shadow: 0 0 16px rgba(77, 229, 255, 0.71),
        0 0 8px rgba(77, 229, 255, 0.71);
      text-transform: uppercase;
      line-height: 1.2;

      .sub1 {
        font-size: 42px;
      }
      .sub2 {
        font-size: 80px;
      }
    }
  }
}
</style>
