import { optionsFunction } from "./_config";
const axios = require("axios");

export const fetchMissionen = () =>
  axios
    .get(`/api/missions`, optionsFunction())
    .then((res) => res.data.data)
    .catch((e) => console.log(e));

export const startMission = () =>
  axios
    .post(`/api/missions`, {}, optionsFunction())
    .then((res) => res.data.data)
    .catch((e) => console.log(e));

export const exchangeMission = () =>
  axios
    .post(`/api/missions/exchange`, {}, optionsFunction())
    .then((res) => res.data.data);

export const testRaise = () =>
  axios
    .post(`/api/tests/mission`, {}, optionsFunction())
    .then((res) => res.data.data)
    .catch((e) => console.log(e));
