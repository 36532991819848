<template>
  <div>
    <b-modal
      id="modal-treasury-info"
      :hide-footer="true"
      modal-class="custom-modal1"
    >
      <template slot="modal-header" slot-scope="{ close }">
        <a href="#" class="close-button" @click="close()"> </a>
      </template>

      <template slot="default"
        ><b-row align-h="center" align-v="center" class="h-100"
          ><b-col class="d-flex h-100 flex-column align-items-center"
            ><div class="info-col-field gold w-100"></div>
            <div class="treasury-info-heading info-col-field w-100">
              {{ $t("treasury.info_panel.col_1.headline") }}
            </div>
            <div class="treasury-info-text info-col-field medium w-100">
              {{ $t("treasury.info_panel.col_1.description") }}
            </div></b-col
          ><b-col class="d-flex h-100 flex-column align-items-center"
            ><div class="info-col-field progress-info w-100"></div>
            <div class="treasury-info-heading info-col-field w-100">
              {{ $t("treasury.info_panel.col_2.headline") }}
            </div>
            <div class="treasury-info-text info-col-field medium w-100">
              {{ $t("treasury.info_panel.col_2.description") }}
            </div></b-col
          ><b-col class="d-flex h-100 flex-column align-items-center"
            ><div class="info-col-field key w-100"></div>
            <div class="treasury-info-heading info-col-field w-100">
              {{ $t("treasury.info_panel.col_3.headline") }}
            </div>
            <div class="treasury-info-text info-col-field medium w-100">
              {{ $t("treasury.info_panel.col_3.description") }}
            </div></b-col
          ></b-row
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "ModalTreasuryInfo",

  mounted: function () {
    // this.$bvModal.show("modal-chest-info");
  },
};
</script>
<style lang="scss">
$modal-width: 972px;
$modal-height: 470px;

#modal-treasury-info {
  .modal-dialog {
    max-width: $modal-width;
    width: 100%;
    height: $modal-height;
    top: 10%;
  }

  .modal-content {
    width: 100%;
    height: 100%;
    background: url(~@/assets/img/mission/rectangle.png) no-repeat;
    background-size: 100% 100%;
  }

  .close-button {
    position: absolute;
    right: -25px;
    top: -25px;
    width: 54px;
    height: 54px;
    max-width: none;
    background: url(~@/assets/img/mission/mission-close-button.png) no-repeat;
    background-size: 100% 100%;

    &:hover {
      background-image: url(~@/assets/img/mission/mission-close-button-hover.png);
    }
  }
  .info-col-field {
    height: 33%;

    &.gold {
      background: url(~@/assets/img/treasury/treasury-info-gold.png)
        center/220px 142px no-repeat;
    }
    &.progress-info {
      background: url(~@/assets/img/treasury/treasury-info-progress.png)
        center/242px 62px no-repeat;
    }
    &.key {
      background: url(~@/assets/img/treasury/treasury-info-key.png) center/162px
        139px no-repeat;
    }
  }
  .treasury-info-heading {
    font-family: Cinzel-Bold;
    font-size: 32px;
    color: #fad5a8;
    display: flex;
    align-items: center;
    line-height: 1;
  }
  .treasury-info-text {
    font-size: 24px;
    color: #c8c8c8;
    line-height: 31px;
  }
}
</style>
