<template>
  <div id="bundesliga-team-badge">
    <div
      id="display-top-row"
      class="d-inline-flex justify-content-center align-items-baseline"
    >
      <div
        class="team-head left text-center"
        :class="[aSelected || !selectedOption ? 'active' : '']"
      >
        <span class="text-uppercase bold">{{
          $t("events.bundesliga.teams.home")
        }}</span>
      </div>
      <div id="display-box">
        <span class="bold text-uppercase"
          >{{ $t("events.bundesliga.headline") }}

          <!--TODO insert correct date here: -->

          {{ createLocalizedDate(eventData.end_at) }}</span
        >
      </div>
      <div
        class="team-head right"
        :class="[bSelected || !selectedOption ? 'active' : '']"
      >
        <span class="text-uppercase bold">{{
          $t("events.bundesliga.teams.away")
        }}</span>
      </div>
    </div>
    <div
      id="display-bottom-row"
      class="d-inline-flex justify-content-center align-items-baseline"
    >
      <template v-if="aSelected">
        <AnimationEventBundesligaIcon />
        <div v-if="betLocked" class="lock-button locked small"></div>
      </template>
      <template v-if="bSelected">
        <AnimationEventBundesligaIcon position="right" />
        <div v-if="betLocked" class="lock-button locked small right"></div>
      </template>

      <div
        class="team-name left d-inline-flex align-items-center justify-content-center"
        :class="[aSelected ? 'active' : '']"
      >
        <span class="text-uppercase bold">{{ nameTeamA }}</span>
      </div>
      <div v-if="!drawSelected" id="vs-badge" class="to-center-abs">
        <span class="test-uppercase bold">VS.</span>
      </div>
      <div
        v-else
        id="draw-selected-box"
        class="to-center-abs d-inline-flex align-items-center"
      >
        <div v-if="betLocked" class="lock-button locked small"></div>
        <AnimationEventBundesligaIcon position="draw-box" />
        <span class="text-uppercase bold">{{
          $t("events.bundesliga.dropdown.draw")
        }}</span>
      </div>
      <div
        class="team-name right d-inline-flex align-items-center justify-content-center"
        :class="[bSelected ? 'active' : '']"
      >
        <span class="text-uppercase bold"> {{ nameTeamB }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import eventPopups from "@/mixins/eventPopups.js";
import getRewardsFromPackage from "@/mixins/paymentFunctions.js";
import AnimationFullRewardEarned from "@/components/animation/animation-full-reward-earned";
import dateFunctions from "@/mixins/dateFunctions.js";
import AnimationEventBundesligaIcon from "@/components/animation/animation-spine-event-bundesliga-icon.vue";

export default {
  name: "BundesligaTeamBadge",
  components: {
    AnimationEventBundesligaIcon,
  },
  mixins: [
    eventPopups,
    getRewardsFromPackage,
    AnimationFullRewardEarned,
    dateFunctions,
  ],
  props: {
    selectedOption: {
      type: Object,
      default: null,
    },
    eventData: {
      type: Object,
      default: null,
    },
    betLocked: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    nameTeamA() {
      return this.shortenString(this.eventData.payload.home, 25);
    },
    nameTeamB() {
      return this.shortenString(this.eventData.payload.away, 25);
    },
    aSelected() {
      return this.selectedOption && this.selectedOption.option === "A";
    },
    bSelected() {
      return this.selectedOption && this.selectedOption.option === "B";
    },

    drawSelected() {
      return this.selectedOption && this.selectedOption.option === "C";
    },
  },
  methods: {
    createLocalizedDate(dateString) {
      return new Date(dateString).toLocaleDateString(this.$store.state.locale, {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
#bundesliga-team-badge {
  #display-top-row {
    height: 105px;
    width: 755px;
    position: relative;

    #display-box {
      width: 476px;
      height: 100%;
      padding-top: 30px;
      z-index: 1;
      background: url(~@/assets/img/events/bundesliga/bl-date-display-bg.svg)
        center/100% 100% no-repeat;

      span {
        font-size: 30.2px;
        color: white;
        text-shadow: 0 0 7px rgba(255, 169, 0, 0.7),
          0 0 7px rgba(255, 169, 0, 0.72);
      }
    }

    .team-head {
      width: 283px;
      height: 80px;
      background: url(~@/assets/img/events/bundesliga/bl-badge-bg-inactive-right.svg)
        center/100% 100% no-repeat;
      z-index: 0;
      position: absolute;
      right: 0;
      left: auto;
      bottom: 0;
      padding-top: 25px;

      span {
        font-size: 20.2px;
      }

      &.left {
        left: 0;
        right: auto;
        background: url(~@/assets/img/events/bundesliga/bl-badge-bg-inactive-left.svg)
          center/100% 100% no-repeat;
        padding-right: 85px;
      }

      &.right {
        padding-left: 85px;
      }

      &.active {
        background: url(~@/assets/img/events/bundesliga/bl-badge-bg-active-right.svg)
          center/100% 100% no-repeat;
        transform: none;

        &.left {
          background: url(~@/assets/img/events/bundesliga/bl-badge-bg-active-left.svg)
            center/100% 100% no-repeat;
        }
      }
    }
  }

  #display-bottom-row {
    height: 83px;
    width: 1028px;
    margin-top: -35px;
    z-index: 1;
    position: relative;

    span {
      font-size: 24.2px;
    }

    .team-name {
      height: 100%;
      width: 514px;
      background: url(~@/assets/img/events/bundesliga/bl-badge-bg-inactive-right.svg)
        center/100% 100% no-repeat;
      margin-left: -10px;
      position: relative;

      &.left {
        background: url(~@/assets/img/events/bundesliga/bl-badge-bg-inactive-left.svg)
          center/100% 100% no-repeat;
        margin-right: -10px;
        margin-left: 0;
      }

      &.active {
        background: url(~@/assets/img/events/bundesliga/bl-badge-bg-active-left.svg)
          center/100% 100% no-repeat;
        transform: none;

        &.right {
          background: url(~@/assets/img/events/bundesliga/bl-badge-bg-active-right.svg)
            center/100% 100% no-repeat;
        }
      }
    }

    #vs-badge {
      top: 15px;
      padding-top: 7px;
      background-image: linear-gradient(to bottom, #369fb6, #050d1c),
        linear-gradient(to bottom, #03414b, #4de5ff);

      background-origin: border-box;
      background-clip: padding-box, border-box;
      background-size: 100% 100%;
      border: double 1.9px transparent;
      width: 97px;
      height: 48px;
      z-index: 1;
      border-radius: 30.5px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.5), 0 0 10px rgba(0, 0, 0, 0.5),
        inset 0 0 14px rgba(0, 0, 0, 0.69), inset 0 0 14px rgba(0, 0, 0, 0.69),
        inset 0 0 9px #000000;
    }

    #draw-selected-box {
      width: 304px;
      height: 48px;
      background: url(~@/assets/img/events/bundesliga/bl-draw-result-bg.svg)
        center/100% 100% no-repeat;
      z-index: 1;
      top: 17px;

      span {
        margin-left: -35px;
      }
    }
  }
}
</style>
