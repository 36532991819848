<template>
  <div
    id="canvas-container-animation-coins"
    ref="canvascontainer"
    class="coins-spine"
  ></div>
</template>

<script>
import AnimationSpineBaseFunctions from "@/components/animation/animation-spine-base-functions";
import { Spine } from "pixi-spine";

export default {
  name: "AnimationSpineCoins",
  mixins: [AnimationSpineBaseFunctions],
  props: {
    loop: {
      type: Boolean,
      default: true,
    },
    repeat: {
      //how many times. default infinity
      type: Number,
      default: -1,
    },
    run: {
      type: Boolean,
      default: true,
    },
    size: {
      //L, M or S
      type: String,
      default: "L",
    },
  },
  data() {
    return {
      unsubscribeEvents: [],
      version: 1,
      assetPath: "/assets/spines/coins/",
      destroyApp: false,
    };
  },

  watch: {
    run: function (newVal) {
      if (newVal) {
        this.destroyApp = true;
        this.play();
      }
    },
  },
  beforeDestroy() {
    //destroy event listeners
    for (let i = 0; i < this.unsubscribeEvents.length; i++) {
      this.unsubscribeEvents[i]();
    }

    this.destroyApp = true;
  },
  mounted: function () {
    this.play();
  },

  methods: {
    play: function () {
      if (!this.run) {
        return;
      }
      const canvasEl = document.getElementById("canvas-animation-coins");

      const assets = [
        ["main", this.assetPath + "coin-firework.json?v=" + this.version],
      ];
      let options = {
        width: 1398,
        height: 783.76,
        antialias: true,
        backgroundAlpha: 0,
        autoResize: true,
      };

      const app = this.createCanvas(
        options,
        assets,
        this.$refs.canvascontainer.id
      );
      app.view.id = "canvas-animation-coins";

      app.ticker.add(() => {
        if (this.destroyApp) {
          this.destroyApp = false;
          this.destroyAll(app);
        }
      });

      const setup = (loader, res) => {
        let spineData = this.getSpineData(res);

        spineData.forEach((data) => {
          const spine = new Spine(data);

          // set the position
          spine.x = app.screen.width + data.x;
          spine.y = app.screen.height + data.y;

          //add spine to stage
          app.stage.addChild(spine);

          //run animation
          let animation;
          let setupAnimation = () => {
            //choose animation

            animation = this.getAnimationByName(data, "size-" + this.size);
            spine.state.setAnimation(0, animation.name);

            //set animation end
            spine.state.getCurrent(0).animationEnd = animation.duration;
          };
          setupAnimation();

          // Detach event listeners
          this.unsubscribeEvents.push(() => {
            spine.state.getCurrent(0).onEvent = null;
          });

          //add loop
          if (this.loop) {
            spine.state.getCurrent(0).loop = true;
          }

          //ticker
          app.ticker.add(() => {
            if (this.destroyApp) {
              this.destroyApp = false;
              this.destroyAll(app);
            }
          });

          //repeat x times only
          if (this.repeat !== -1) {
            setTimeout(() => {
              if (spine.state) {
                spine.state.getCurrent(0).loop = false;
              }
            }, animation.duration * this.repeat * 1000);
          }
        });
      };

      app.loader.load(setup);
      app.loader.onComplete.add(() => {
        this.$emit("done");
      });

      //remove old chest when new loaded
      app.loader.onComplete.add(() => {
        if (canvasEl) {
          this.removePrevCanvas(this.$refs.canvascontainer.id);
        }
      });
    },
  },
};
</script>

<style lang="scss">
#canvas-container-animation-coins {
  pointer-events: none;
  z-index: 1;
}
</style>
