<template>
  <b-modal
    id="modal-bundesliga-bet-note"
    ref="modal-bundesliga-bet-note"
    :hide-footer="true"
    :hide-header="true"
    hide-backdrop
    modal-class="custom-modal1"
  >
    <template slot="default">
      <div id="modal-box" class="to-center-abs">
        <div
          role="button"
          href="#"
          class="close-button"
          @click="$emit('hide-bet-note')"
        ></div>
        <DecoCorners color-variant="blue" />
        <div
          class="w-100 d-inline-flex align-items-center justify-content-center"
        >
          <div class="icon-hand-denied bg-img"></div>
          <div
            id="no-mega-bonus"
            class="bg-img position-relative"
            :class="$store.state.locale"
          >
            <div class="w-100 position-absolute bg-img strikethrough"></div>
          </div>
        </div>
        <p class="bold">
          {{ $t("events.bundesliga.bet_alert.text") }}
        </p>
      </div>
    </template>
  </b-modal>
</template>

<script>
import DecoCorners from "@/components/base/deco-corners.vue";
export default {
  name: "ModalBundesligaBetNote",
  components: { DecoCorners },

  data() {
    return {
      modalId: "modal-bundesliga-bet-note",
    };
  },
};
</script>
<style lang="scss">
$modal-width: 1122px;
$modal-height: 666px;

#modal-bundesliga-bet-note {
  .modal-dialog {
    min-width: $modal-width;
    max-width: $modal-width;
    width: 100%;
    height: $modal-height;
    background: url(~@/assets/img/events/bundesliga/mask-notification.png)
      center/100% 100% no-repeat;
    @media (max-height: 850px), (max-width: 1250px) {
      transform: scale(0.8);
      top: -40px;
      margin-bottom: -20%;
    }
    @media (max-height: 770px), (max-width: 1100px) {
      transform: scale(0.7);
      top: -100px;
      margin-bottom: -30%;
    }
    @media (max-width: 1100px) {
      left: -5%;
    }
  }

  .modal-body {
    margin-top: 0 !important;
    padding: 0;
    width: 100%;
    height: 100%;

    #modal-box {
      width: 605px;
      height: 202px;
      top: 290px;

      text-align: center;
      background-color: transparent;
      background-image: linear-gradient(#205f72, #050d1c),
        linear-gradient(#050d1c, #205f72);
      border: double 1px transparent;
      background-origin: border-box;
      background-clip: padding-box, border-box;
      background-size: 100% 100%;
      p {
        margin: 0;
        color: white;
        font-size: 23.5px;
      }
      .close-button {
        right: -16px;
        top: -16px;
        width: 43px;
        height: 43px;
      }
      .icon-hand-denied {
        width: 125px;
        height: 125px;
        background-image: url(~@/assets/img/common/icn-notification-deny.svg);
      }
      #no-mega-bonus {
        width: 211px;
        height: 68px;
        background-image: url(~@/assets/img/events/bundesliga/bonus-pack-headline-de.png);
        &.en {
          background-image: url(~@/assets/img/events/bundesliga/bonus-pack-headline-en.png);
        }
        .strikethrough {
          height: 60px;
          background-image: url(~@/assets/img/common/notification-deny-stroke.svg);
        }
      }
    }
  }

  .modal-content {
    width: 100%;
    height: 100%;
    background: none;
    border: none;
  }
}
</style>
