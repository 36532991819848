<template>
  <div id="modal-bundesliga-lose" class="container-fluid">
    <div class="lose-panel to-center-abs">
      <div class="lose-headline to-center-abs">
        {{ $t("events.bundesliga.win_screen.results") }} {{ tipDate }}
      </div>
      <div class="lose-name">
        <div class="lose-text-blue text-left">
          {{ resultText }}
        </div>
        <div class="lose-text-white text-left">
          {{ actualResult }}
        </div>
      </div>
      <div class="lose-name">
        <div class="lose-text-blue text-right">
          {{ $t("events.bundesliga.lose_screen.own_tip") }}
        </div>
        <div class="lose-text-white text-right">
          {{ userTip }}
        </div>
      </div>
    </div>

    <div class="right-side">
      <div class="headline to-center-abs">
        <div class="sub1">
          {{ $t("events.bundesliga.lose_screen.headline_1") }}
        </div>
        <div class="sub2">
          {{ $t("events.bundesliga.lose_screen.headline_2") }}
        </div>
      </div>

      <div
        v-if="$store.getters['events/getNextFootballEvent']"
        class="timer-container to-center-abs"
      >
        <div
          class="timer-countdown w-50"
          :class="{
            small: isDateMoreThanOneDay(nextTipDate),
          }"
        >
          <CountdownDigital :time="nextTipDate" :with-time-diff="true"/>
        </div>
        <div class="timer-text">
          {{ $t("events.bundesliga.win_screen.countdown") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CountdownDigital from "@/components/base/countdown-digital";
import dateFunctions from "@/mixins/dateFunctions.js";

export default {
  name: "ModalBundesligaLose",
  components: {
    CountdownDigital,
  },
  mixins: [dateFunctions],
  props: {
    eventData: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    tipDate() {
      let result = new Date(this.eventData.end_at);
      return result.toLocaleDateString(this.$store.state.locale, {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      });
    },
    resultText() {
      if (this.$store.getters["events/getIsDraw"]) {
        return this.$t("events.bundesliga.lose_screen.result");
      } else {
        return this.$t("events.bundesliga.lose_screen.winner");
      }
    },

    actualResult() {
      let result = this.$store.getters["events/getActualResult"];
      if (result === "draw") {
        return this.$t("events.bundesliga.dropdown.draw");
      } else {
        return this.shortenString(result, 25);
      }
    },
    userTip() {
      let tip = this.$store.getters["events/getUserTip"];
      if (tip === "draw") {
        return this.$t("events.bundesliga.dropdown.draw");
      } else {
        return this.shortenString(tip, 25);
      }
    },

    nextTipDate() {
      let result = new Date(
        this.$store.getters["events/getNextFootballEvent"].start_at
      );
      return result.getTime();
    },
  },
};
</script>
<style lang="scss">
$modal-width: 1121px;
$modal-height: 630px;

#modal-bundesliga-lose {
  .lose-panel {
    top: 40px;
    width: 903px;
    height: 152px;
    background: url(~@/assets/img/events/bundesliga/lose-result-panel.png)
      bottom center/100% 100% no-repeat;

    .lose-headline {
      top: 24px;
      font-family: Ubuntu-Bold;
      font-size: 23px;
      text-transform: uppercase;
      color: white;
    }

    .lose-name {
      position: absolute;
      width: 100%;
      padding: 0 60px;
      top: 60px;
      text-transform: uppercase;

      .lose-text-blue {
        font-family: Ubuntu-Bold;
        font-size: 17px;
        color: #4de5ff;
      }

      .lose-text-white {
        font-family: Ubuntu-Bold;
        font-size: 23px;
        color: white;
      }
    }
  }

  .left-side {
    width: 45%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  .right-side {
    width: 57%;
    height: 100%;
    position: absolute;
    right: 0;

    .headline {
      top: 210px;
      right: 30px;
      text-transform: uppercase;
      text-shadow: 0 0 18px rgba(77, 229, 255, 0.71),
        0 0 18px rgba(77, 229, 255, 0.71);
      line-height: 1.4;

      .sub1 {
        font-family: Ubuntu-Bold;
        font-size: 40px;
      }
      .sub2 {
        font-family: Ubuntu-Bold;
        font-size: 53px;
        margin-top: 20px;
        line-height: 1.2;
      }
    }

    .timer-container {
      top: 480px;
      width: 370px;
      height: 76px;
      background: url(~@/assets/img/events/bundesliga/timer-next-tipp.png)
        bottom center/100% 100% no-repeat;
      right: 30px;

      .timer-countdown {
        position: absolute;
        left: 10px;
        top: 10px;
        font-family: Ubuntu-Bold;
        font-size: 37px;
        color: #ff4040;

        &.small {
          font-size: 26px;
          top: 18px;
        }
      }
      .timer-text {
        position: absolute;
        right: 25px;
        top: 18px;
        text-align: left;
        line-height: 1.3;
        font-family: Ubuntu-Bold;
        font-size: 17px;
        color: white;
        text-transform: uppercase;
        width: 40%;
      }
    }
  }
}
</style>
