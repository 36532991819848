<template>
  <div>
    <b-modal
      id="modal-select-avatar"
      :hide-footer="true"
      modal-class="custom-modal2"
      content-class="rectangular-dark-blue-modal"
      @hidden="$emit('avatarSelected')"
    >
      <template slot="modal-header" slot-scope="{ close }">
        <h1>{{ $t("profile.change_avatar") }}</h1>
        <a v-if="closeNeeded" href="#" class="close-button" @click="close()">
        </a>
        <DecoCorners color-variant="gold" />
      </template>

      <div class="container">
        <b-row class="avatar-row" align-h="center">
          <b-col
            v-for="(avatar, index) in avatars"
            :key="avatar.key"
            :name="'Avatar' + index"
            class="avatar-field"
            cols="3"
            @click="handleAvatarSelect(avatar.key)"
          >
            <img
              draggable="false"
              src="@/assets/img/avatar/avatar-selection-bg.png"
              class="avatar-bg to-center-abs"
            />

            <!-- facebook avatar has a different frame than standard ones -->

            <!-- <div v-if="avatar.key === 'fb'" class="fb-avatar-frame dead-center">
              <img class="fb-avatar dead-center" :src="avatar.url" />

              <img
                src="@/assets/img/common/fb-logo-shadow.png"
                class="fb-logo to-center-abs"
              />
            </div> -->
            <img
              draggable="false"
              :src="avatar.url"
              class="avatar-face to-center-abs"
            />
            <div
              class="avatar-name to-center-abs bold text-uppercase d-inline-flex align-items-center justify-content-center"
            >
              {{ avatarName(avatar.key) }}
            </div>
          </b-col>

          <!-- show placeholder facebook avatar if user is not connected to facebook -->
          <!-- <VFBLoginScope
            @sdk-init="handleFbInit"
            @login="connectToFacebook"
            :app-id="facebookAppId"
          >
            <b-col
              slot-scope="scope"
              @click="handleFbLogin(scope)"
              v-if="!facebookUser && showFacebookConnect"
              key="facebook-placeholder.png"
              class="avatar-field"
              cols="3"
              ><div id="fb-logo" class="to-center-abs"></div>
              <div id="fb-text-field" class="dead-center">
                <p class="text-white bold text-uppercase">
                  {{ $t("profile.avatar.fb_connect") }}
                </p>
              </div>
              <div class="facebook-avatar-wrapper">
                <img
                  src="@/assets/img/avatar/avatar-selection-bg.png"
                  class="avatar-bg to-center-abs"
                />
              </div>
              <div
                id="fb-bonus-field"
                class="gradient-indent to-center-abs d-inline-flex align-items-center justify-content-around bold"
              >
                + 50.000 <img src="@/assets/img/common/coin1.png" />
              </div>
              <div class="facebook-button-wrapper"></div></b-col></VFBLoginScope
        > -->
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import * as profileAPI from "@/API/profile.js";
// import { VFBLoginScope } from "vue-facebook-login-component";

import DecoCorners from "@/components/base/deco-corners.vue";
export default {
  name: "ModalSelectAvatar",
  components: { DecoCorners },
  props: {
    avatars: {
      type: Array,
      required: true,
    },
    closeNeeded: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showFacebookConnect: false,
      // facebookAppId: process.env.VUE_APP_FACEBOOK_APP_ID
    };
  },
  computed: {
    // facebookUser() {
    //   if (
    //     this.$store.getters["user/currentUser"] &&
    //     this.$store.getters["user/currentUser"].facebook_id
    //   ) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
    // fbAvatar() {
    //   return this.$store.getters["user/currentUser"].facebook_avatar;
    // }
  },
  methods: {
    handleAvatarSelect: async function (key) {
      await profileAPI
        .selectNewAvatar(key)
        .then((res) => {
          this.$store.commit("user/updateUserObject", res.data.data.user);
          this.$bvModal.hide("modal-select-avatar");
        })
        .catch((e) => {
          console.log(e);
        });
    },
    avatarName(key) {
      let name = key.split(".")[0];
      if (name === "maidservant") {
        return this.$t("avatar.maid");
      } else {
        return this.$t(`avatar.${name}`);
      }
    },
    // handleFbLogin(scope) {
    //   scope.login();
    // },
    // handleFbInit(e) {
    //   if (window.FB !== undefined) {
    //     this.showFacebookConnect = true;
    //   }
    // },
    // connectToFacebook(e) {
    //   if (e.status === "connected") {
    //     profileAPI
    //       .connectToFacebook(e.authResponse.accessToken)
    //       .then(res => {
    //         this.$store.commit("user/updateUserObject", res.data.data.user);
    //         this.refetchAvatars();
    //       })
    //       .catch(e => {
    //         if (e.data.id === "fb_already_on_different_user") {
    //           this.openAlertWindow(
    //             "already-connected",
    //             this.$t("error.fb_already_on_different_user")
    //           );
    //         } else {
    //           this.refreshModal();
    //         }
    //       });
    //   } else {
    //     console.log(e);
    //   }
    // },
    // refreshModal() {
    //   this.$bvModal.hide("modal-select-avatar");
    //   this.$bvModal.show("modal-select-avatar");
    // },
    // refetchAvatars() {
    //   this.$emit("refetchAvatars");
    //   this.$bvModal.hide("modal-select-avatar");
    // }
  },
};
</script>
<style lang="scss">
#modal-select-avatar {
  .modal-dialog {
    max-width: 900px;
    height: 560px;
  }
  .modal-header {
    justify-content: center;

    h1 {
      font-family: Cinzel-decorative-bold;
      font-size: 36px;
      color: #fff8b7;
    }
  }

  .avatar-row {
    .avatar-field {
      width: 160px;
      height: 160px;
      margin-bottom: 32px;
      &:hover {
        cursor: pointer;
        .avatar-bg {
          filter: drop-shadow(0px 0px 4px $light-blue);
        }
      }
      .avatar-bg {
        width: 168px;
        height: 188px;
      }
      .avatar-face {
        max-width: 90%;
        max-height: 100%;
        top: 0;
      }
      .avatar-name {
        width: 100%;
        height: 32px;
        background: url(~@/assets/img/avatar/avatar-name-bg.svg) center/100%
          100%;
        bottom: 0;
        color: #fff8b7;
        -webkit-transform: translate3d(0, 0, 0);
      }

      // .facebook-button-wrapper {
      //   margin-bottom: -60px;
      //   width: 192px;
      // }
      // #fb-logo {
      //   width: 60px;
      //   height: 60px;
      //   background: url(~@/assets/img/avatar/fb-logo.svg) center/100% 100%
      //     no-repeat;
      //   z-index: 5;
      //   top: -5px;
      // }
      // #fb-text-field {
      //   width: 108px;
      //   height: 60px;
      //   z-index: 5;
      //   p {
      //     font-size: 18px;
      //     line-height: 1.1;
      //     margin-top: 5px;
      //   }
      // }
      // #fb-bonus-field {
      //   height: 40px;
      //   width: 100%;
      //   z-index: 5;
      //   bottom: 0;
      //   color: #fff8b7;
      //   font-size: 32px;
      //   img {
      //     width: 23px;
      //     height: 23px;
      //   }
      // }
    }
  }
}
</style>
