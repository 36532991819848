<template>
  <div
    id="canvas-container-animation-spine-event-bundesliga-arrow"
    ref="canvascontainer"
    class="canvas-container-animation-spine-event-bundesliga-arrow"
    :class="scootOver ? 'scoot-over' : ''"
  ></div>
</template>

<script>
import AnimationSpineBaseFunctions from "@/components/animation/animation-spine-base-functions";
import { Spine } from "pixi-spine";

export default {
  name: "AnimationIconWinterbonusjagd",
  mixins: [AnimationSpineBaseFunctions],
  props: {
    loop: {
      type: Boolean,
      default: true,
    },
    run: {
      type: Boolean,
      default: true,
    },
    scootOver: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      unsubscribeEvents: [],
      destroyApp: false,
    };
  },

  watch: {
    run: function () {
      this.play();
    },
  },
  mounted() {
    this.play();
  },
  beforeDestroy() {
    //destroy event listeners
    for (let i = 0; i < this.unsubscribeEvents.length; i++) {
      this.unsubscribeEvents[i]();
    }

    this.destroyApp = true;
  },

  methods: {
    play: function () {
      const canvasEl = document.getElementById(
        "canvas-animation-spine-event-bundesliga-arrow"
      );

      const assets = [
        [
          "tipking-arrow",
          "/assets/spines/events/bundesliga/tipking-arrow-guide.json",
        ],
      ];
      const options = {
        width: 120,
        height: 112,
        antialias: true,
        backgroundAlpha: 0,
        autoResize: true,
      };

      const app = this.createCanvas(
        options,
        assets,
        this.$refs.canvascontainer.id
      );
      app.view.id = "canvas-animation-spine-event-bundesliga-arrow";

      app.ticker.add(() => {
        if (this.destroyApp) {
          this.destroyApp = false;
          this.destroyAll(app);
        }
      });

      const setup = (loader, res) => {
        let spineData = this.getSpineData(res);

        spineData.forEach((data) => {
          const spine = new Spine(data);

          //skeleton and animation configs
          let animationName = "main";

          // set the position
          spine.x = app.screen.width / 2;
          spine.y = app.screen.height - 50;

          //add spine to stage
          app.stage.addChild(spine);

          let animation = this.getAnimationByName(data, animationName);
          //run animation
          spine.state.setAnimation(0, animationName);

          //show first frame when not running
          if (!this.run) {
            spine.state.getCurrent(0).animationEnd = 0;
            return;
          }

          //set animation end
          spine.state.getCurrent(0).animationEnd = animation.duration;

          // Detach event listeners
          this.unsubscribeEvents.push(() => {
            spine.state.getCurrent(0).onEvent = null;
          });

          //add loop
          if (this.loop) {
            spine.state.getCurrent(0).loop = true;
          }
        });
      };

      app.loader.load(setup);

      //remove old chest when new loaded
      app.loader.onComplete.add(() => {
        if (canvasEl) {
          this.removePrevCanvas(this.$refs.canvascontainer.id);
        }
      });
    },
  },
};
</script>

<style lang="scss">
.canvas-container-animation-spine-event-bundesliga-arrow {
  pointer-events: none;
  position: absolute;
  left: 300px;
  top: -66px;
  z-index: 100;
  &.scoot-over {
    left: 570px;
  }

  canvas {
    position: absolute;
  }
}
</style>
