<template>
  <div class="earning-container mx-auto">
    <img
      class="earning-icon to-center-abs"
      draggable="false"
      :src="require('@/assets/img/common/' + icon)"
      :style="earningStyle"
    />

    <div v-if="iconText" class="icon-text text-center">
      {{ iconText }}
    </div>

    <div
      class="earning-btn to-center-abs gold-display text-center"
      :class="size"
    >
      <span
        class="earning-amount"
        :class="[
          size,
          lockedInfo && lockedInfo === 'unlocked' ? 'green' : 'text-white bold',
        ]"
      >
        <slot name="earning-amount-or-text"> </slot>
        <img v-if="earning === 'facebook'" src="@/assets/img/common/coin1.png"
      /></span>
      <img
        v-if="lockedInfo && lockedInfo === 'unlocked'"
        src="@/assets/img/cards/icon-set-complete.png"
      />
      <img
        v-else-if="lockedInfo && lockedInfo === 'locked'"
        src="@/assets/img/common/lock-close.png"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "EarningShield",
  props: {
    earning: {
      type: String,
      default: "gold",
    },
    size: {
      type: String,
      default: "",
    },
    lockedInfo: {
      type: String,
      default: "",
    },
    iconText: {
      type: String,
      default: "",
    },
  },
  computed: {
    icon: function () {
      switch (this.earning) {
        case "gold":
          return "coin1.png";
        case "elixir":
          return "elixir.png";
        case "card":
          return "cardstack-3.png";
        case "card-collect":
          return "cardstack-3.png";
        case "crown":
          return "crown6.png";
        case "loyalty":
          return "icon-lp.png";
        case "loyalty-bonus":
          return "loyalty-bonus.png";
        case "xpboost":
          return "booster.png";
        case "levelup":
          return "levelup-badge.png";
        case "support":
          return "ritter-gold.png";
        case "dailylogin":
          return "dailylogin-card.png";
        case "user-league":
          return "badge-user-league.png";
        case "facebook":
          return "facebook.png";
        case "chest-gold":
          return "chest-gold.png";
        case "chest-silver":
          return "chest-silver.png";
        case "chest-bronze":
          return "chest-bronze.png";
        case "top-10":
          return "facebook.png";
        default:
          return "coin1.png";
      }
    },
    earningStyle: function () {
      switch (this.earning) {
        case "dailylogin":
          return { "max-width": "56px" };
        case "support":
          return { "max-width": "70px", top: "9%" };
        case "gold":
          return { "max-width": "45%" };
        case "crown":
          return { "max-width": "60%" };
        case "card":
          return { "max-width": "55%", top: "14%" };
        case "card-collect":
          return { "max-width": "55%", top: "14%" };
        case "loyalty":
          return { "max-width": "55%" };
        case "loyalty-bonus":
          return { "max-width": "65%", top: "7%" };
        case "levelup":
          return { "max-width": "65%", top: "12%" };
        case "user-league":
          return { "max-width": "50%", top: "8%" };
        case "top-10":
          return { display: "none" };
        default: {
          return { "max-width": "50%" };
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.earning-container {
  position: relative;
  height: 100%;
  width: 100%;
  min-height: 128px;
  margin: 0 8px 0 8px;
  background: url(~@/assets/img/common/earning-shield-bg.png) center/100% 100%
    no-repeat;

  .earning-icon {
    top: 20%;
  }

  .icon-text {
    font-family: Ubuntu-Bold;
    font-size: 28.3px;
    color: white;
    padding-top: 20px;
  }

  .earning-btn {
    bottom: 20%;
    font-size: 16px;
    padding-top: 2px;
    &.gold-display {
      background-color: black;
      height: 32px;
      border-radius: 8px;
      border: 2px solid $light-blue;
      box-shadow: 0 0 16px rgba(77, 229, 255, 0.6),
        0 0 8px rgba(77, 229, 255, 0.6), 0 0 8px rgba(77, 229, 255, 0.6),
        0 0 4px rgba(0, 94, 110, 0.8), 0 0 4px rgba(0, 94, 110, 0.8);
    }
    .earning-amount {
      text-shadow: 0 0 16px rgba(77, 229, 255, 0.6),
        0 0 8px rgba(77, 229, 255, 0.6);
    }

    &.lg {
      height: 35px;
    }
    img {
      max-width: 35px;
      position: absolute;
      right: -15px;
      top: -5px;
    }
  }

  .earning-amount.lg {
    font-size: 21px;
  }
  .green {
    color: $highlight-green;
    font-family: Ubuntu-Bold;
  }
}
</style>
