<template>
  <div
    v-if="expboostActive && expEndDate"
    id="expboost-container"
    v-b-popover.click.bottom="$t('header.expbooster_info', { 0: 50 })"
    class="to-center-abs clickable"
  >
    <div id="expboost-image-gif">
      <img
        src="@/assets/img/animation/gifs/kleinekrone-ani-levelturbo.gif"
        class="w-100"
      />
    </div>
    <CountdownDigital :time="expEndDate" prefix="- " @over.once="resetTimer" />
  </div>
</template>

<script>
import AnimationBaseFunctions from "./animation-base-functions";
import CountdownDigital from "../base/countdown-digital";

export default {
  name: "AnimationExpboost",
  components: {
    CountdownDigital,
  },
  mixins: [AnimationBaseFunctions],

  computed: {
    expboostActive: function () {
      const expDate = this.$store.getters["user/getExpboostEndDate"];

      if (expDate) {
        const now = new Date().getTime();
        const target = new Date(expDate).getTime();
        const distance = target - now;
        if (distance < 0) {
          return false;
        }
      }

      return true;
    },

    expEndDate: function () {
      const expDate = this.$store.getters["user/getExpboostEndDate"];

      if (!expDate) {
        return;
      }

      return new Date(expDate).getTime();
    },
  },
  methods: {
    resetTimer: function () {
      let now = new Date().toISOString();
      this.$store.commit("user/setExpboostEndDate", now);
    },
    closePopover() {
      this.$refs.messageExpBoosterPopover.$emit("close");
    },
  },
};
</script>

<style lang="scss">
#expboost-container {
  z-index: 999;
  top: -20px;

  #expboost-image-gif {
    width: 74px;
    display: block;
    background-repeat: no-repeat;
    top: 0;
    left: 0;
    margin-left: -25%;
    margin-right: auto;
  }

  .countdown-digital {
    position: absolute;
    top: 50px;
    z-index: 999;
    margin-left: -100%;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    color: #4de5ff;
    font-family: Ubuntu-Bold;
    font-size: 20px;
  }
}
</style>
