<template>
  <div
    class="content-tab position-relative d-flex align-items-center justify-content-center px-3 py-1 clickable"
    :class="{ active: active }"
    @click="click"
  >
    <div>
      <span
        class="label text-uppercase bold d-flex align-items-center justify-content-center"
      >
        {{ label }}
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: "NavTabCustomAction",
  props: {
    label: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
    clickEvent: { type: Boolean, default: false },
  },
  methods: {
    click: function () {
      if (this.clickEvent) {
        this.$emit("click-event");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.content-tab {
  height: 2rem;
  min-width: 10rem;
  z-index: 0;
  margin-right: 0.625rem;
  background-image: linear-gradient(
    to bottom,
    rgba(22, 63, 81, 0.5),
    rgba(5, 13, 28, 0.5)
  );
  font-size: 1rem;
  &.disabled {
    cursor: default;
  }

  &:hover {
    text-decoration: none;
  }
  &.active {
    background-image: linear-gradient(
      to bottom,
      rgb(22, 63, 81),
      rgb(5, 13, 28)
    );
    pointer-events: none;
    .label {
      color: white;
      text-shadow: 0 0 0.5rem rgba(77, 229, 255, 0.8);
    }
  }

  .label {
    color: #5499a4;
    font-size: 0.875rem;
  }
}
</style>
