<template>
  <div
    v-if="isLoggedIn && sliderData && sliderData.length"
    id="banner-container"
    class="h-100 w-100 position-relative"
  >
    <div class="banner-fixed-container h-100 w-100">
      <div class="banner-carousel-container h-100">
        <b-carousel
          id="carousel-1"
          v-model="slide"
          :interval="6000"
          indicators
          controls
          fade
          @sliding-start="onSlideStart"
          @sliding-end="onSlideEnd"
        >
          <b-carousel-slide
            v-for="slider in sliderData"
            :key="slider.id"
            class="bg-design"
            :style="{
              'background-image': `url('${getsliderUrl(slider)}')`,
            }"
          >
            <div
              class="w-100 h-100"
              :class="'slider-type-' + slider.type.toLowerCase()"
            >
              <!--            button disabled-->
              <div
                v-if="
                  slider.configuration &&
                  slider.configuration.min_level &&
                  user.level < slider.configuration.min_level
                "
                class="disabled"
              >
                <div
                  class="btn ok-button-green round-new disabled"
                  :class="slider.configuration.button_position"
                >
                  {{
                    $t("lobby.slider.button_text_min_level", {
                      0: slider.configuration.min_level,
                    })
                  }}
                </div>
              </div>

              <div
                v-else-if="slider.configuration"
                class="btn ok-button-green round-new"
                :class="slider.configuration.button_position"
                @click="doAction(slider.type, slider.value)"
              >
                {{ $t(slider.configuration.button_text) }}

                <!--            particle animation-->
                <template
                  v-if="false && slider.configuration.animation_particle"
                >
                  <div
                    v-for="circle in 50"
                    :key="circle"
                    class="animation-circle-container"
                  >
                    <div class="circle"></div>
                  </div>
                </template>
              </div>

              <!--              slider text normal-->
              <div
                v-if="slider.configuration"
                class="slider-text to-center-abs"
              >
                <div
                  v-if="slider.configuration.center_text1"
                  class="text1 glow"
                >
                  {{ $t(slider.configuration.center_text1) }}
                </div>
                <div
                  v-if="slider.configuration.center_text2"
                  class="text2 glow"
                >
                  {{ $t(slider.configuration.center_text2) }}
                </div>
              </div>

              <!--              slider text custom-->
              <template v-if="slider.configuration.special_texts">
                <div
                  v-for="special in slider.configuration.special_texts"
                  :key="special.text"
                  class="custom-slider-text to-center-abs"
                  :class="special.class"
                  :style="special.style"
                >
                  {{ $t(special.text) }}
                </div>
              </template>

              <!--                  countdown-->
              <div
                v-if="slider.configuration.expired"
                class="expired-countdown to-center-abs"
              >
                <span class="countdown-numbers">
                  <CountdownDigital
                    :time="new Date(slider.configuration.expired).getTime()"
                    :with-time-diff="true"
                    @over.once="
                      $store.commit('bonusslot/setBonusSlotEndDate', 0)
                    "
                  />
                </span>
              </div>
            </div>
          </b-carousel-slide>
        </b-carousel>
      </div>
    </div>
  </div>
</template>
<script>
import * as GamesAPI from "@/API/games";
import CountdownDigital from "@/components/base/countdown-digital";

export default {
  name: "BannerCarousel",
  components: { CountdownDigital },
  data() {
    return {
      slide: 0,
      sliding: null,
      sliderData: null,
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters["auth/isLoggedIn"];
    },
    route() {
      return this.$route.path;
    },
    user() {
      return this.$store.getters["user/currentUser"];
    },
  },

  mounted() {
    this.fetchSliderContent();
  },

  methods: {
    getsliderUrl(slider) {
      if (!slider.banner || slider.banner.length <= 0) {
        return slider.url;
      }

      if (this.$store.state.locale === "de") {
        return slider.banner[0].url;
      }

      return slider.banner[1].url;
    },
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },

    fetchSliderContent() {
      GamesAPI.getSlider()
        .then((res) => {
          this.sliderData = res.data.data.sliders;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    doAction(type, value) {
      switch (type) {
        case "EVENT":
          const event = document.getElementById(`event-${value}`);
          if (event) {
            event.click();
          }
          break;
        case "GAME":
          this.$router.push(`/game/${value}`);
          break;
        case "FEATURE":
          this.$router.push(value);
          break;
        case "CAMPAIGN":
          if (!value) {
            this.$bvModal.show("modal-shop");
            return;
          }
          this.$bvModal.show(value);
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style scoped lang="scss">
#banner-container {
  .banner-fixed-container {
    .banner-carousel-container {
      position: relative;
      z-index: 10;
      width: 100%;

      .glow {
        text-shadow: 0 0 1rem rgba(77, 229, 255, 0.6),
          0 0 0.5rem rgba(77, 229, 255, 0.6), 0 0 0.5rem rgba(77, 229, 255, 0.6),
          0 0 0.25rem rgba(0, 94, 110, 0.8), 0 0 0.25rem rgba(0, 94, 110, 0.8);
      }

      .btn {
        color: white;
        position: absolute;
        top: 69%;
        width: 20rem;
        min-height: 3rem;
        line-height: 1.2;
        padding: 0.625rem 0.938rem 0.625rem 0.938rem;
        font-size: 1.5rem;
        font-family: Ubuntu-Bold;
        text-shadow: 0 0 0 0.625rem #42ff00, 0 0 0 0.625rem #42ff00,
          0 0 0 0.5rem #0b2b00, 0 0 0 0.5rem #165400;
        border-radius: 1.5rem;
        overflow: hidden;

        &.left {
          left: 6.25rem;
        }
        &.center {
          left: 0;
          right: 0;
          margin-left: auto;
          margin-right: auto;
        }
        &.right {
          right: 6.25rem;
        }
      }

      //ANIMATION
      .animation-circle-container {
        $particleNum: 50;
        $particleColor: hsl(180, 100%, 80%);

        position: absolute;
        transform: translateY(-10vh);
        animation-iteration-count: infinite;
        animation-timing-function: linear;

        .circle {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          mix-blend-mode: screen;
          background-image: radial-gradient(
            hsl(180, 100%, 80%),
            hsl(180, 100%, 80%) 10%,
            hsla(180, 100%, 80%, 0) 56%
          );

          animation: scale-frames 3s infinite;

          @keyframes scale-frames {
            0% {
              transform: scale3d(0.4, 0.4, 1);
            }

            50% {
              transform: scale3d(2.2, 2.2, 1);
            }

            100% {
              transform: scale3d(0.4, 0.4, 1);
            }
          }
        }

        $particleBaseSize: 8;

        @for $i from 1 through $particleNum {
          &:nth-child(#{$i}) {
            $circleSize: random($particleBaseSize);
            width: $circleSize + px;
            height: $circleSize + px;

            $startPositionY: random(10) + 10;
            $framesName: "move-frames-" + $i;
            $moveDuration: 8000 + random(9000) + ms;

            animation-name: #{$framesName};
            animation-duration: $moveDuration;
            //animation-delay: random(37000) + ms;

            @keyframes #{$framesName} {
              from {
                transform: translate3d(
                  #{-5rem + random(25)},
                  #{$startPositionY + px},
                  0
                );
              }

              to {
                transform: translate3d(
                  #{-5rem + random(25)},
                  #{- $startPositionY - random(200) + px},
                  0
                );
              }
            }

            .circle {
              animation-delay: random(4000) + ms;
            }
          }
        }
      }

      .expired-countdown {
        width: 14.75rem;
        height: 5.438rem;
        background: url(~@/assets/img/hero-images/wbj-timer.png) center/100%
          100% no-repeat;
        top: 72%;
        left: -54rem;
        padding: 2.1rem 1rem 1rem 4.5rem;
        text-align: center;

        .countdown-numbers {
          font-family: Ubuntu-Medium;
          color: white;
          font-size: 1.2rem;
          text-align: center;
        }
      }

      .slider-text {
        top: 51.4%;
        line-height: 1.2;
        text-transform: uppercase;

        .text1 {
          font-size: 1.313rem;
        }

        .text2 {
          font-size: 2rem;
        }
      }

      .custom-slider-text {
        line-height: 1.2;
        text-transform: uppercase;
      }
    }
  }
}
</style>

<style lang="scss">
#banner-container {
  .carousel,
  .carousel-inner {
    height: 100%;
  }
  .carousel-caption {
    width: 100%;
    max-width: 143.75rem;
    height: 27.5rem;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin-left: auto;
    margin-right: auto;
  }

  .bg-design {
    height: 440px;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 143.75rem 27.5rem;
  }

  .img-fluid {
    height: 27.5rem;
    max-width: 3000px;
    margin-left: auto;
    margin-right: auto;
  }

  .btn {
    &.disabled {
      z-index: 10;
      opacity: 1;
      background-image: linear-gradient(180deg, #ffffff 14%, #000000 100%);
      box-shadow: 0 0 0.313rem rgba(0, 0, 0, 0.93),
        0 0 0.313rem rgba(0, 0, 0, 0.93);
    }
  }

  .carousel-indicators {
    li {
      background-color: transparent;
      border: 0.125rem solid #f9feff;
      border-radius: 50%;
      width: 1rem;
      height: 1rem;
      margin-left: 1.25rem;
      margin-right: 1.25rem;
      cursor: pointer;

      &.active {
        background-color: #4de5ff;
      }

      &:hover {
        filter: $hover-shadow;
        z-index: inherit;
      }
    }
  }

  .carousel-control-prev {
    cursor: pointer;

    &:hover {
      filter: $hover-shadow;
    }
    .carousel-control-prev-icon {
      background-image: url(~@/assets/img/lobby/carousel/slider-navi-arrow-next.png);
      width: 1.75rem;
      height: 1.938rem;
      -moz-transform: scale(-1, 1);
      -webkit-transform: scale(-1, 1);
      -o-transform: scale(-1, 1);
      -ms-transform: scale(-1, 1);
      transform: scale(-1, 1);
    }
  }

  .carousel-control-next {
    cursor: pointer;

    &:hover {
      filter: $hover-shadow;
    }
    .carousel-control-next-icon {
      background-image: url(~@/assets/img/lobby/carousel/slider-navi-arrow-next.png);
      width: 1.75rem;
      height: 1.938rem;
    }
  }
}
</style>
