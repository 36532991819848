<template>
  <div id="modal-bundesliga-bet" class="container-fluid">
    <b-row no-gutters align-v="center" align-h="center">
      <BundesligaTeamBadge
        :selected-option="selectedOption"
        :event-data="eventData"
        :bet-locked="betLocked"
      ></BundesligaTeamBadge
      ><b-col cols="2"></b-col
      ><b-col
        id="team-select"
        class="d-inline-flex align-items-center justify-content-between"
      >
        <span class="select-label text-uppercase bold">{{
          $t("events.bundesliga.dropdown.label")
        }}</span>
        <div
          v-if="!teamSelectOpen"
          id="dropdown-label"
          class="d-inline-flex align-items-center"
          :class="[betLocked ? 'disabled' : '']"
          @click="teamSelectOpen = !teamSelectOpen"
        >
          <span class="text-uppercase bold">{{ dropdownLabel }}</span>
          <div v-if="selectedOption" class="checkmark white ml-1"></div>
        </div>
        <AnimationEventBundesligaMenu
          :run="!selectedOption"
          :disabled="betLocked"
          @toggle-dropdown="teamSelectOpen = !teamSelectOpen"
        />
        <AnimationEventBundesligaArrow
          v-if="!betLocked"
          :scoot-over="!!selectedOption"
        />

        <div v-if="teamSelectOpen" id="team-select-dropdown">
          <div id="dropdown-contents">
            <ul>
              <li
                v-for="option in bettingOptions"
                :key="option.label"
                class="position-relative"
                @click="handleOptionSelect(option)"
              >
                <span class="text-uppercase bold"> {{ option.label }}</span>
                <div
                  v-if="
                    selectedOption && selectedOption.option === option.option
                  "
                  class="checkmark white position-absolute"
                  style="right: 20px; top: 17px"
                ></div>
              </li>

              <li
                class="text-uppercase bold pt-3 d-inline-flex align-items center"
                @click="teamSelectOpen = false"
              >
                <span> {{ $t("events.bundesliga.dropdown.cancel") }}</span>
                <div class="ml-1 cancel-button-orange"></div>
              </li>
            </ul>
          </div>
        </div>
      </b-col>
      <b-col id="lock-col" cols="3"
        ><div
          v-if="selectedOption"
          class="w-100 h-100 d-inline-flex align-items-center clickable"
          :class="[betLocked ? 'disabled' : '']"
          @click="$emit('lock-bet')"
        >
          <b-spinner v-if="betSpinner" class="mx-3" />
          <div v-else-if="betLocked" class="lock-button locked"></div>
          <AnimationEventBundesligaLock v-else />
          <span v-if="selectedOption" class="text-uppercase bold ml-3">{{
            lockText
          }}</span>
        </div>
      </b-col> </b-row
    ><b-row id="bottom-row" no-gutters
      ><b-col cols="4"
        ><div
          id="help-button"
          class="clickable"
          @click="$bvModal.show('modal-bundesliga-help')"
        ></div>
        <div
          id="countdown-box"
          class="to-center-abs d-flex flex-column"
          :class="[betLocked && packagePurchased ? 'green' : 'red']"
        >
          <span class="text-uppercase bold">{{ countdownText }}</span>
          <CountdownDigital
            :time="countdownTime"
            :with-time-diff="true"
          /></div></b-col
      ><b-col id="text-col" cols="5"
        ><span
          class="golden-gradient-text bold text-uppercase"
          style="font-size: 26.9px"
          >{{ $t("events.bundesliga.bonus_text_1") }}</span
        >
        <div
          class="w-100 d-inline-flex align-items-center justify-content-center"
        >
          <span
            class="golden-gradient-text bold text-uppercase"
            style="font-size: 48.9px"
            >{{ goldReward }}</span
          >
          <div class="icon gold" style="width: 43px; height: 43px"></div>
        </div>
        <span
          class="golden-gradient-text bold text-uppercase"
          style="font-size: 23.5px"
          >{{ $t("events.bundesliga.bonus_text_2") }}</span
        >
        <div
          class="w-100 d-inline-flex align-items-center justify-content-center"
        >
          <span class="golden-gradient-text bold" style="font-size: 58.8px">{{
            packageGold
          }}</span>
          <div class="icon gold"></div>
        </div>
        <div
          class="w-100 d-inline-flex align-items-center justify-content-center"
        >
          <span class="golden-gradient-text bold" style="font-size: 58.8px">{{
            packageCrowns
          }}</span>
          <div class="icon crown mr-3"></div>
          <span class="golden-gradient-text bold" style="font-size: 58.8px">{{
            packageLoyalty
          }}</span>

          <div v-if="packageLoyalty" class="icon loyalty"></div>
        </div>
        <span
          class="golden-gradient-text bold text-uppercase"
          style="font-size: 23.5px"
          >{{ $t("events.bundesliga.bonus_text_3") }}</span
        ></b-col
      ><b-col cols="3"
        ><div
          v-if="!purchasePossible && !packagePurchased"
          class="icon-denied position-absolute"
        ></div>
        <div
          id="bonus-panel-contents"
          :class="[!purchasePossible && !packagePurchased ? 'disabled' : '']"
          class="w-100 h-100 d-flex flex-column align-items-center justify-content-between"
        >
          <div id="bonus-panel-headline" :class="$store.state.locale"></div>
          <div class="icon gold large"></div>
          <div class="w-100 text-center">
            <span class="golden-gradient-text bold" style="font-size: 30.2px">{{
              packageGold
            }}</span>
          </div>
          <div
            class="w-100 d-inline-flex align-items-center justify-content-center"
            style="font-size: 27.6px"
          >
            <span class="golden-gradient-text bold">{{ packageCrowns }}</span>
            <div v-if="packageCrowns" class="icon crown small"></div>
            <span class="golden-gradient-text bold">{{ packageLoyalty }}</span>
            <div v-if="packageLoyalty" class="icon loyalty small"></div>
          </div>
          <div
            v-if="!packagePurchased"
            id="buy-button"
            class="clickable"
            @click="$emit('buy-package')"
          >
            <div class="w-100">
              <span class="text-uppercase bold">{{
                $t("events.bundesliga.package.buy_button_1")
              }}</span>
            </div>
            <div
              class="w-100 d-inline-flex align-items-center justify-content-center"
            >
              <span class="text-uppercase bold">{{
                $t("events.bundesliga.package.buy_button_2")
              }}</span
              ><span class="bold price ml-3 position-relative"
                >{{ packagePrice }} €

                <div
                  v-if="oldPrices && oldPrices[0]"
                  class="offer-old-price ml-3 right"
                >
                  {{ formatPrice(oldPrices[0]) }} €
                  <div class="offer-red-line to-center-abs"></div>
                </div>
                <div
                  v-if="moreContent && moreContent[0]"
                  class="big-badge-more-percent smaller"
                >
                  <span class="big">{{ moreContent[0] }}</span>
                  {{ $t("shop.offers.more_contents2") }}
                </div>
              </span>
            </div>
          </div>
          <div v-else class="checkmark green"></div>
        </div>
        <AnimationEventBundesligaBonuspanel
          :run="purchasePossible || packagePurchased"
        /> </b-col
    ></b-row>
    <ModalBundesligaHelp
      :gold-reward="goldReward"
      :offer-packages="offerPackages"
      :event-data="eventData"
    ></ModalBundesligaHelp>
    <ModalBundesligaBetNote
      @hide-bet-note="$bvModal.hide('modal-bundesliga-bet-note')"
    />
  </div>
</template>
<script>
import ModalBundesligaHelp from "@/components/events/bundesliga/modal-bundesliga-help";
import ModalBundesligaBetNote from "@/components/events/bundesliga/modal-bundesliga-bet-note.vue";
import CountdownDigital from "@/components/base/countdown-digital.vue";
import AnimationEventBundesligaMenu from "@/components/animation/animation-spine-event-bundesliga-menu.vue";
import AnimationEventBundesligaArrow from "@/components/animation/animation-spine-event-bundesliga-arrow.vue";
import AnimationEventBundesligaBonuspanel from "@/components/animation/animation-spine-event-bundesliga-bonuspanel.vue";
import BundesligaTeamBadge from "@/components/events/bundesliga/bundesliga-team-badge.vue";
import AnimationEventBundesligaLock from "@/components/animation/animation-spine-event-bundesliga-lock.vue";
export default {
  name: "ModalBundesligaBet",
  components: {
    ModalBundesligaHelp,
    ModalBundesligaBetNote,
    CountdownDigital,
    AnimationEventBundesligaMenu,
    AnimationEventBundesligaArrow,
    AnimationEventBundesligaBonuspanel,
    BundesligaTeamBadge,
    AnimationEventBundesligaLock,
  },
  props: {
    goldReward: {
      type: String,
      default: "",
    },
    offerPackages: { type: Array, default: () => [] },
    userEvent: { type: Object, default: () => {} },
    eventData: { type: Object, default: () => {} },
    packageLoyalty: { type: Number, default: 0 },
    packageGold: { type: String, default: "" },
    packageCrowns: { type: String, default: "" },
    packagePrice: { type: String, default: "" },
    oldPrices: { type: Array, default: () => [] },
    moreContent: { type: Array, default: () => [] },
    selectedOption: { type: Object, default: () => {} },
    bettingOptions: { type: Array, default: () => [] },
    betLocked: { type: Boolean, default: false },
    purchasePossible: { type: Boolean, default: false },
    packagePurchased: { type: Boolean, default: false },
    betSpinner: { type: Boolean, default: false },
  },
  data() {
    return {
      teamSelectOpen: false,
    };
  },
  computed: {
    selectedMatchdayDate() {
      if (!this.eventData) return;
      return this.createLocalizedDate(this.eventData.start_at);
    },

    matchdayEndTime() {
      return new Date(this.eventData.end_at).getTime();
    },

    dropdownLabel() {
      if (!this.selectedOption) {
        return this.$t("events.bundesliga.dropdown.select");
      } else {
        return this.selectedOption.label;
      }
    },

    lockText() {
      if (this.betLocked) {
        return this.$t("events.bundesliga.bet_placed");
      } else {
        return this.$t("events.bundesliga.place_bet");
      }
    },
    countdownText() {
      if (
        !this.betLocked ||
        (this.purchasePossible && !this.packagePurchased)
      ) {
        return this.$t("events.bundesliga.countdown_bet");
      } else {
        return this.$t("events.bundesliga.countdown_result");
      }
    },
    countdownTime() {
      if (!this.packagePurchased && this.purchasePossible) {
        return new Date(this.eventData.end_at).getTime();
      } else {
        let end = new Date(this.eventData.end_at);
        let date = end.getDate();
        end.setDate(date + 1);
        end.setHours(10);
        end.setMinutes(0);
        return new Date(end).getTime();
      }
    },
  },
  methods: {
    handleOptionSelect(option) {
      this.$emit("option-selected", option);
      this.teamSelectOpen = false;
    },
    createLocalizedDate(dateString) {
      return new Date(dateString).toLocaleDateString(this.$store.state.locale, {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      });
    },
  },
};
</script>
<style lang="scss">
#modal-bundesliga-bet {
  .lock-button {
    width: 97px;
    height: 97px;
    background: url(~@/assets/img/events/lock-active.png) center/100% 100%
      no-repeat;
    z-index: 2;
    &.locked {
      background: url(~@/assets/img/events/lock-inactive.png) center/62px 61px
        no-repeat;
      &.small {
        width: 42px;
        height: 41px;
        background-size: 100% 100%;
        position: absolute;
        left: 30px;
        &.right {
          left: auto;
          right: 30px;
        }
      }
    }
  }

  .icon {
    filter: drop-shadow(0 0 11px rgba(0, 0, 0, 0.5))
      drop-shadow(0 0 11px rgba(0, 0, 0, 0.5))
      drop-shadow(0 0 4px rgba(0, 0, 0, 0.8));
    margin-left: 5px;
    &.gold {
      width: 50px;
      height: 50px;
      background: url(~@/assets/img/common/coin1.png) center/100% 100% no-repeat;
      &.large {
        width: 128px;
        height: 80px;
        background: url(~@/assets/img/common/three-coins.png) center/100% 100%
          no-repeat;
      }
    }
    &.crown {
      height: 50px;
      width: 66px;
      background: url(~@/assets/img/common/crown6.png) center/100% 100%
        no-repeat;
      &.small {
        width: 29px;
        height: 22px;
      }
    }
    &.loyalty {
      width: 57px;
      height: 58px;
      background: url(~@/assets/img/common/loyalty-bonus.png) center/100% 100%
        no-repeat;
      &.small {
        width: 27px;
        height: 27px;
      }
    }
  }

  .checkmark {
    &.white {
      width: 21px;
      height: 21px;
      background: url(~@/assets/img/events/checkmark-white-small.svg)
        center/100% 100% no-repeat;
    }
    &.green {
      width: 66px;
      height: 66px;
      background: url(~@/assets/img/events/icn-checkmark-green.svg) center/100%
        100% no-repeat;
    }
  }
  .disabled {
    pointer-events: none;
  }
  #team-select {
    width: 642px;
    height: 95px;
    background: url(~@/assets/img/events/bundesliga/bl-select-bg.svg)
      center/100% 100% no-repeat;
    position: relative;
    padding-left: 35px;

    .select-label {
      font-size: 21.8px;
      width: 280px;
    }

    #dropdown-label {
      font-size: 20.2px;
      z-index: 1;
      margin-left: 50px;
      cursor: pointer;
    }
    #team-select-dropdown {
      width: 271px;
      height: 255px;
      background: url(~@/assets/img/events/bundesliga/bl-dropdown-bg.svg)
        center/100% 100% no-repeat;
      position: absolute;
      z-index: 10;
      right: 22px;
      top: 17px;
      #dropdown-contents {
        padding: 15px;
        ul {
          list-style: none;
          padding: 0;
          li {
            font-size: 23.5px;
            padding: 9px 0 9px 0;
            .cancel-button-orange {
              width: 30px;
              height: 30px;
              background: url(~@/assets/img/events/cancel-button-orange.svg)
                center/100% 100% no-repeat;
            }
            &:last-of-type {
              font-size: 20.2px;
            }
            cursor: pointer;
            &:not(:last-of-type) {
              border-bottom: 1.7px solid white;
              text-align: left;
            }
            &:hover {
              text-shadow: 0 0 15px white;
            }
          }
        }
      }
    }
  }
  #lock-col {
    height: 97px;
    span {
      text-shadow: 0 0 13px rgba(77, 229, 255, 0.71), 0 0 7px #4de5ff,
        0 0 3px #4de5ff;
      font-size: 17.6px;
    }
  }
  #bottom-row {
    height: 390px;
    .golden-gradient-text {
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
      background-image: -webkit-gradient(
        linear,
        left top,
        left bottom,
        #f7ffff,
        #ffee51
      );
      background-image: linear-gradient(to bottom, #f7ffff, #ffee51);
    }
    #help-button {
      background: url(~@/assets/img/events/gfg-btn-help-orange.png) center/100%
        100% no-repeat;
      height: 100px;
      width: 100px;
      z-index: 10;
      position: absolute;
      right: 0;
      top: 0;
    }
    #countdown-box {
      width: 241px;
      height: 60.5px;
      border-radius: 37.2px;
      bottom: 65px;
      z-index: 1;
      line-height: 1.2;
      padding-top: 5px;
      .countdown-digital {
        font-size: 30.2px;
        font-family: Ubuntu-bold;
      }
      &.red {
        background-image: linear-gradient(to bottom, #3a0000, #ff0000);
        box-shadow: 0 0 8px #ff0000, inset 0 0 5px #ff0000,
          inset 0 0 3px #ff0000;
      }
      &.green {
        background-image: linear-gradient(to bottom, #0a2700, #2dac00);
        border: 1.7px solid #2da304;
      }
    }
    #text-col {
      pointer-events: none;
    }
    .icon-denied {
      width: 120px;
      height: 120px;
      top: 110px;
      left: 50px;
      background: url(~@/assets/img/events/icn-denied-red.svg) center/100% 100%
        no-repeat;
      z-index: 10;
    }
    #bonus-panel-contents {
      padding: 65px 54px 55px 0;
      z-index: 1;
      position: relative;
      &.disabled {
        filter: grayscale(100) brightness(0.5);
      }

      #bonus-panel-headline {
        width: 211px;
        height: 67px;
        &.de {
          background: url(~@/assets/img/events/bundesliga/bl-bonus-pack-headline-de.png)
            center/100% 100% no-repeat;
        }
        &.en {
          background: url(~@/assets/img/events/bundesliga/bl-bonus-pack-headline-eng.png)
            center/100% 100% no-repeat;
        }
      }
    }
    #buy-button {
      width: 174px;
      height: 63.6px;
      background-image: linear-gradient(to bottom, #2dac00, #0a2700);
      border: 2.2px solid #43ff00;
      border-radius: 8.9px;
      box-shadow: 0 0 18px rgba(67, 255, 0, 0.3), 0 0 15px #43ff00;
      line-height: 1.2;
      padding-top: 5px;

      span {
        font-size: 15.9px;
        color: #43ff00;
        &.price {
          font-size: 27.3px;
          text-shadow: 0 0 13px #43ff00, 0 0 5px #0a2700;

          .offer-old-price {
            top: -55px;
            right: -130px;
          }
          .big-badge-more-percent {
            top: -354px;
            right: -130px;
          }
        }
      }
    }
  }
}
</style>
