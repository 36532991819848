<template v-if="config">
  <b-modal
    v-if="eventData"
    :id="modalId"
    ref="modal-spinwin-event"
    :hide-footer="true"
    modal-class="modal-spinwin-event"
    @hidden="onClose"
    @shown="onOpen"
  >
    <template slot="modal-header" slot-scope="{ close }">
      <div class="preload"></div>

      <a href="#" class="close-button" @click="close()"> </a>
    </template>

    <template slot="default">
      <div class="content event-modal-content">
        <div class="water-mask to-center-abs">
          <div
            class="bg-water mx-auto"
            :style="{ 'margin-top': topMargin }"
          ></div>
        </div>
        <div id="percentage-box" class="gradient-indent glow to-center-abs">
          {{ userProgress }} %
        </div>
        <div class="bg1"></div>
        <div class="bg2"></div>

        <div v-if="eventIsFinished" class="bg-coin"></div>
        <div v-else class="bg-glass"></div>

        <AnimationEventSpinWin
          :loop="true"
          :run="true"
          :animation-step="eventIsFinished ? 'done' : 'main'"
        ></AnimationEventSpinWin>

        <b-row
          no-gutters
          align-h="between"
          class="event-content-row position-absolute w-100"
        >
          <b-col
            v-if="!eventIsFinished"
            cols="3"
            class="event-col left d-flex flex-column align-items-center justify-content-center"
            ><p class="golden-gradient-text text-uppercase w-100">
              {{
                $t("events.spin.spins_in_days", {
                  0: eventPayload,
                  1: eventDurationDays,
                })
              }}
            </p>
            <div
              class="w-100 py-3 d-inline-flex align-items-center justify-content-around"
            >
              <div id="countdown-box">
                <div id="countdown-text-box" class="text-center text-uppercase">
                  <div class="w-100 yellow bolditalic">
                    {{ $t("shop.offers.countdown.time_remaining") }}
                  </div>
                  <div
                    class="w-100 yellow medium d-inline-flex align-items-center justify-content-around px-5 mt-1"
                  >
                    <span>T</span><span>S</span><span>M</span>
                  </div>
                  <CountdownDigital
                    class="to-center-abs mt-2"
                    :days-to-minutes="true"
                    :time="endTime"
                    :with-time-diff="true"
                    @over="handleEventCountdownEnd"
                  />
                </div>
              </div>
            </div>
            <p class="w-100 text-white big-text">
              {{ $t("events.spin.description") }}
            </p></b-col
          ><b-col
            v-if="!eventIsFinished"
            cols="4"
            class="event-col right d-flex flex-column align-items-center justify-content-center space-down"
          >
            <p class="text-1 golden-text text-uppercase">
              {{ $t("events.spin.reward_description") }}
            </p>
            <div
              v-for="reward in eventRewards"
              :key="reward.amount"
              class="d-inline-flex align-items-center justify-content-center w-100"
            >
              <span class="golden-gradient-text">{{
                formatNumber(reward.amount)
              }}</span>
              <div
                class="reward-icon ml-3"
                :style="{
                  'background-image': `url('${getRewardIcon(reward)}')`,
                }"
              ></div>
            </div>
            <p
              v-if="voucherVal && numberOfWinners > 1"
              class="text-2 golden-text text-uppercase"
            >
              {{
                $t("events.spin.extra_chance_with_numbers", {
                  0: numberOfWinners,
                })
              }}
            </p>
            <p
              v-else-if="voucherVal && numberOfWinners === 1"
              class="text-2 golden-text text-uppercase"
            >
              {{ $t("events.reward.chance_to_win") }}
            </p>

            <div v-if="voucherVal" id="raffle-box">
              <div class="grey-gradient-text big">{{ voucherVal }} €</div>
              <div class="grey-gradient-text medium">
                <template v-if="numberOfWinners === 1">
                  {{ $t("events.spin.extra_chance_1c_singular") }}
                </template>
                <template v-else>
                  {{ $t("events.spin.extra_chance_1c") }}
                </template>
              </div>
            </div>

            <div
              v-if="!eventIsFinished"
              role="button"
              class="w-100 start-button to-center-abs d-flex align-items-center justify-content-center"
              @click="handlePlayNowClick"
            >
              <span class="bold text-white text-uppercase">
                {{ $t("events.button_lets_go") }}</span
              >
            </div>
          </b-col>
        </b-row>
        <div v-if="!eventIsFinished" id="event-disclaimer-row">
          <div v-if="voucherVal" class="left-text">
            <p class="grey">
              {{ $t("events.raffle.disclaimer") }}
            </p>
          </div>
          <div v-if="!emailConfirmed && voucherVal" class="right-text">
            <p class="blue medium">
              {{ $t("events.raffle.email_confirm_info") }}
            </p>
          </div>
          <div v-if="!emailConfirmed && voucherVal" class="right-button">
            <button
              class="button-blue-outline"
              @click="handleEmailConfirmClick"
            >
              {{ $t("profile.email_confirm") }}
            </button>
          </div>
        </div>

        <template v-if="eventIsFinished">
          <div
            id="amount-display"
            class="to-center-abs d-inline-flex align-items-center justify-content-between"
          >
            <span class="golden-gradient-text"
              >+ {{ formatNumber(eventRewards[0].amount) }}
              <div
                class="reward-icon"
                :style="{
                  'background-image': `url('${getRewardIcon(
                    eventRewards[0]
                  )}')`,
                }"
              ></div>
            </span>

            <div
              class="reward-text to-center-abs golden-gradient-text text-uppercase"
            >
              {{ $t("events.battle.solved.headline_1b") }}
            </div>
          </div>

          <div v-if="voucherVal" id="raffle-box2">
            <p class="text grey-gradient-text small text-uppercase">
              {{ $t("events.battle.win_note") }}
            </p>
          </div>
        </template>
      </div>
    </template>
  </b-modal>
</template>

<script>
import AnimationEventSpinWin from "@/components/animation/animation-spine-event-spinwin.vue";
import CountdownDigital from "@/components/base/countdown-digital.vue";
import eventPopups from "@/mixins/eventPopups.js";
export default {
  name: "ModalSpinwinEvent",
  components: { AnimationEventSpinWin, CountdownDigital },
  mixins: [eventPopups],
  props: {
    modalId: {
      type: String,
      default: "modal-spinwin-event",
    },
  },

  computed: {
    topMargin() {
      let margin = 83 - this.userProgress;
      return `${margin}%`;
    },
    userProgress() {
      if (!this.userEvent) {
        return 0;
      } else {
        return Math.ceil(this.userEvent.progress);
      }
    },
    eventIsFinished() {
      return this.userProgress >= 100;
    },

    eventPayload() {
      return this.eventData.payload.spins;
    },

    voucherVal() {
      return this.getAmazonVoucherValueByEvent(this.eventData);
    },
  },

  mounted() {
    if (this.$route.path.startsWith("/spinwin")) {
      this.$bvModal.show(this.modalId);
    }
  },
};
</script>
<style lang="scss">
$modal-width: 1200px;
$modal-height: 688px;

.modal-spinwin-event {
  .modal-dialog {
    max-width: $modal-width;
    width: 100%;
    height: $modal-height;
    padding-top: 50px;

    @media (max-height: 800px), (max-width: 1250px) {
      transform: scale(0.9) !important;
      top: -40px;
      margin-bottom: -10%;
    }
    @media (max-height: 715px), (max-width: 1100px) {
      transform: scale(0.8) !important;
      top: -120px;
      margin-bottom: -20%;

      .info-badge-popover {
        top: 30px !important;
      }
    }

    @media (max-width: 1100px) {
      left: -50px;
    }
  }
  .modal-header {
    border: none !important;

    .close-button {
      right: 50px;
      top: 20px;
    }
  }

  .modal-body {
    margin-top: 0 !important;
    width: $modal-width;
    height: $modal-height;
  }

  .modal-content {
    width: 100%;
    height: 100%;
    background: none;
    color: white;
    text-align: center;
    z-index: 0;
    border: none;
    border-radius: 0;

    .bg-glass {
      margin-top: -15px;
      width: $modal-width;
      height: $modal-height;
      background: url(~@/assets/img/events/spin-o-meter-display.png) 50% 72%/353px
        353px no-repeat;
      position: absolute;
      left: 1px;
      top: 15px;
    }
    .bg-coin {
      margin-top: -15px;
      width: $modal-width;
      height: $modal-height;
      background: url(~@/assets/img/events/frame-with-coin.png) 50% 72%/353px
        353px no-repeat;
      position: absolute;
      left: 1px;
      top: 15px;
    }
    .bg1 {
      margin-top: -10px;

      width: $modal-width;
      height: $modal-height;
      background: url(~@/assets/img/events/spin-win-bg-main.png) bottom left/50%
        100% no-repeat;

      position: absolute;
      left: 1px;
      top: 15px;
    }
    .bg2 {
      margin-top: -10px;
      width: $modal-width;
      height: $modal-height;
      background: url(~@/assets/img/events/spin-win-bg-main.png) bottom left/50%
        100% no-repeat;

      position: absolute;
      right: 1px;
      top: 15px;
      transform: scaleX(-1);
    }
    .water-mask {
      margin-top: -10px;
      width: 353px;
      height: 353px;
      border-radius: 100%;
      overflow: hidden;
      bottom: 60px;
      #percentage-box {
        width: 112px;
        height: 51px;
        border-radius: 25.5px;
        top: -20px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Ubuntu-bold;
        font-size: 28px;
        text-shadow: 0 0 19px rgba(77, 229, 255, 0.75),
          0 0 9px rgba(77, 229, 255, 0.8);
      }

      .bg-water {
        margin-top: -10px;
        width: 455px;
        height: 410px;
        background: url(~@/assets/img/events/blue-fill.png) center/100% 100%
          no-repeat;
      }
    }
    #percentage-box {
      width: 112px;
      height: 51px;
      border-radius: 25.5px;
      top: 220px;
      z-index: 5;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: Ubuntu-bold;
      font-size: 28px;
      text-shadow: 0 0 19px rgba(77, 229, 255, 0.75),
        0 0 9px rgba(77, 229, 255, 0.8);
    }
  }
  .event-content-row {
    height: 350px;
    bottom: 105px;
    .event-col {
      position: absolute;

      &.left {
        top: 50px;
        left: 0;
      }

      &.right {
        top: 20px;
        right: 0;
      }

      &.space-down {
        padding-bottom: 110px;
      }

      .golden-gradient-text {
        font-family: Ubuntu-Bold;
        font-size: 48px;
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
        background-image: -webkit-gradient(
          linear,
          left top,
          left bottom,
          #f7ffff,
          #ffee51 58%,
          #8b5a00
        );
        background-image: linear-gradient(
          to bottom,
          #f7ffff,
          #ffee51 58%,
          #8b5a00
        );
        filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.5));
        line-height: 1;
      }
      .golden-text {
        font-family: Ubuntu-Bold;
        color: #fdf28d;
        text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
      }
      .number-badge {
        border-radius: 100%;
        width: 72px;
        height: 72px;
        color: $light-blue;
        font-size: 48px;
        font-family: Ubuntu-Bold;
        background-image: linear-gradient(to bottom, #12212b, #020203),
          linear-gradient(to bottom, #8b5a00, #ffee51 42%, #f7ffff);
        background-origin: border-box;
        background-clip: padding-box, border-box;
        background-size: 100% 100%;
        border: double 1px transparent;
      }
      .reward-icon {
        width: 56px;
        height: 56px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
      .start-button {
        height: 144px;
        background: url(~@/assets/img/buttons/shiny-green-btn.png) center/355px
          100% no-repeat;

        top: 350px;
        span {
          font-size: 28px;
        }
      }
      .button-blue-modern {
        height: 53px;
        border-radius: 31px;
        width: 217px;
        font-family: Ubuntu-Medium;
        font-size: 21px;
      }
      .countdown-digital {
        font-size: 36px;
      }
    }

    #countdown-box {
      width: 324px;
      height: 132px;
      z-index: 15;
      background: url(~@/assets/img/events/counter-display.png) top center/253px
        149px no-repeat;
      #countdown-text-box {
        padding-top: 5px;
        height: 100px;
        .yellow {
          color: #ffe600;
        }
        .bolditalic {
          font-family: Ubuntu-BoldItalic;
          font-size: 18px;
        }
        .countdown-digital {
          font-family: ds-digital-bold, monospace;
          font-size: 56px;
          position: absolute;
          filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.5));
        }
      }
    }

    .big-text {
      font-size: 21px;
      font-family: Ubuntu-Medium;
    }

    .text-1 {
      line-height: 1.2;
      width: 282px;
      font-size: 21px;
    }
    .text-2 {
      padding-top: 10px;
      font-size: 21px;
    }
    #raffle-box {
      width: 600px;
      height: 185px;
      position: absolute;
      right: -165px;
      top: 175px;
      text-transform: uppercase;
      text-align: right;
      line-height: 1.2;
      padding-right: 100px;
      padding-left: 150px;
    }
  }

  .grey-gradient-text {
    text-align: left;
    line-height: 1.2;
    font-family: Ubuntu-Bold;
    font-size: 21px;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      #ffffff,
      #979797
    );
    background-image: linear-gradient(to bottom, #ffffff, #979797);
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.5));
    &.big {
      font-size: 72px;
    }
    &.medium {
      font-size: 36px;
    }
    &.small {
      font-size: 32px;
    }
  }

  #event-disclaimer-row {
    position: absolute;
    left: 0;
    bottom: 0;
    font-size: 18px;
    line-height: 1;
    width: 100%;

    .grey {
      color: #8e8e8e;
      font-size: 18px;
    }
    .blue {
      font-size: 18px;
    }

    .left-text {
      position: absolute;
      left: 0;
      bottom: -120px;
      width: 265px;
      text-align: left;
      line-height: 1.2;
    }
    .right-text {
      position: absolute;
      left: 390px;
      bottom: -117px;
      width: 446px;
    }
    .right-button {
      position: absolute;
      right: 80px;
      bottom: -102px;

      button {
        width: 242px;
        height: 38px;
      }
    }
  }

  #amount-display {
    width: 656px;
    height: 149px;
    background: url(~@/assets/img/events/r-2-r-finish-amount-display.svg)
      center/100% 100% no-repeat;
    bottom: 115px;
    padding: 35px 75px 0 75px;
    z-index: 1;
    span {
      font-size: 72px;
      filter: drop-shadow(0 0 15px rgba(0, 0, 0, 0.7))
        drop-shadow(0 0 8px black);
      margin-left: auto;
      margin-right: auto;
      padding-right: 75px;
    }

    .reward-icon {
      width: 56px;
      height: 56px;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      position: absolute;
      top: 28px;
      right: 0;
    }

    .reward-text {
      font-size: 38px;
      top: 3px;
    }
  }

  #raffle-box2 {
    width: 100%;
    height: 185px;
    position: absolute;
    bottom: 0;
    top: 605px;
    line-height: 1.2;
    padding-left: 100px;
    padding-right: 100px;

    .text {
      z-index: 2;
      width: 100%;
      text-align: center;
    }

    .raffle-img {
      width: 260px;
      height: 253px;
      position: absolute;
      right: -15px;
      top: -130px;
      z-index: 1;
    }
  }
}
</style>

<style scoped lang="scss">
$modal-width: 1200px;
$modal-height: 688px;
#modal-spinwin-event {
  .content {
    height: $modal-height;
    position: relative;
    margin-left: auto;
    margin-right: auto;

    &.event-modal-content {
      width: $modal-width;
    }
  }
}
</style>
