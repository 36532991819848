<template>
  <div>
    <b-modal
      id="modal-chest-info"
      :hide-footer="true"
      modal-class="custom-modal1"
    >
      <template slot="modal-header" slot-scope="{ close }">
        <a href="#" class="close-button" @click="close()"> </a>
      </template>

      <template slot="default">
        <div class="info-side">
          <div class="info1">
            {{ $t("mission.chest_info.text1") }}
          </div>
          <div class="info2">
            {{ $t("mission.chest_info.text2") }}
          </div>
        </div>
        <div class="chest-infos">
          <div class="row">
            <div class="col-2"></div>
            <div v-for="chest in data" :key="chest.id" class="chest-col col-2">
              <div class="chest-img" :class="'chest-' + chest.level"></div>
              <div class="chest-name">
                <div class="sub1">
                  {{ $t("mission.chest_info.chest" + chest.level) }}
                </div>
                <div class="sub2">
                  {{ $t("mission.chest_info.chest_name") }}
                </div>
                <div class="chest-gold-img"></div>
                <div class="chest-gold-amount">
                  {{ formatNumber(chest.reward_gold) }}
                </div>
                <div class="chest-card-img"></div>
                <div class="chest-card-amount">
                  {{ formatNumber(chest.reward_cards_amount) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "ModalChestInfo",
  props: { data: { type: Array, default: null } },
  mounted: function () {
    // this.$bvModal.show("modal-chest-info");
  },
};
</script>
<style lang="scss">
$modal-width: 1103px;
$modal-height: 431px;

#modal-chest-info {
  .modal-dialog {
    max-width: $modal-width;
    width: 100%;
    height: $modal-height;
    top: 10%;
  }

  .modal-content {
    width: 100%;
    height: 100%;
    background: url(~@/assets/img/mission/rectangle.png) no-repeat;
    background-size: 100% 100%;
  }

  .close-button {
    position: absolute;
    right: -25px;
    top: -25px;
    width: 54px;
    height: 54px;
    max-width: none;
    background: url(~@/assets/img/mission/mission-close-button.png) no-repeat;
    background-size: 100% 100%;

    &:hover {
      background-image: url(~@/assets/img/mission/mission-close-button-hover.png);
    }
  }
}
</style>

<style lang="scss" scoped>
#modal-chest-info {
  .info-side {
    position: absolute;
    left: 20px;
    top: 50px;
    width: 21%;
    text-align: center;

    .info1 {
      font-family: Cinzel-Bold;
      font-size: 26px;
      background-image: linear-gradient(to bottom, white, rgb(250, 213, 168));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      line-height: 1.2;
    }

    .info2 {
      font-family: Ubuntu;
      font-size: 24px;
      color: rgb(250, 213, 168);
      line-height: 1.3;
      margin-top: 60px;
    }
  }

  .chest-infos {
    position: absolute;
    right: 20px;
    top: -20px;
    width: 90%;

    .chest-col {
      border-right: 1px solid rgb(64, 64, 64);
      .chest-img {
        background-repeat: no-repeat;
        pointer-events: none;
        width: 130px;
        height: 130px;

        &.chest-1 {
          background-image: url(~@/assets/img/animation/vanilla-mission/chest-1-wood-spritesheet-12fps-75-0.png);
          background-size: 470%;
          background-position: -19px 6px;
        }
        &.chest-2 {
          background-image: url(~@/assets/img/animation/vanilla-mission/chest-2-iron-spritesheet-12fps-75-0.png);
          background-size: 510%;
          background-position: -28px -6px;
        }
        &.chest-3 {
          background-image: url(~@/assets/img/animation/vanilla-mission/chest-3-silber-spritesheet-12fps-75-0.png);
          background-size: 530%;
          background-position: -33px -16px;
        }
        &.chest-4 {
          background-image: url(~@/assets/img/animation/vanilla-mission/chest-4-gold-spritesheet-12fps-75-0.png);
          background-size: 580%;
          background-position: -40px -30px;
        }
        &.chest-5 {
          background-image: url(~@/assets/img/animation/vanilla-mission/chest-5-king-spritesheet-12fps-75-0.png);
          background-size: 600%;
          background-position: -45px -43px;
        }
      }

      .chest-name {
        line-height: 1.2;
        text-transform: uppercase;
        margin-top: 10px;

        .sub1 {
          font-family: Cinzel-Bold;
          font-size: 32px;
          background-image: linear-gradient(
            to bottom,
            white,
            rgb(250, 213, 168)
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        .sub2 {
          font-family: Ubuntu;
          font-size: 21px;
          background-image: linear-gradient(
            to bottom,
            white,
            rgb(250, 213, 168)
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
      .chest-gold-img {
        width: 32px;
        height: 32px;
        background-image: url(~@/assets/img/common/coin1.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
      }

      .chest-gold-amount {
        font-family: Ubuntu-Bold;
        color: white;
        filter: drop-shadow(0 -2px 6px rgba(77, 229, 255, 0.59));
        font-size: 21px;
        margin-top: 5px;
      }

      .chest-card-img {
        width: 40px;
        height: 44px;
        background-image: url(~@/assets/img/common/cardstack-3.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
      }
      .chest-card-amount {
        font-family: Ubuntu-Bold;
        color: white;
        filter: drop-shadow(0 -2px 6px rgba(77, 229, 255, 0.59));
        font-size: 21px;
        margin-top: 5px;
      }
    }
  }
}
</style>
