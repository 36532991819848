<template>
  <div>
    <b-modal
      id="modal-payment"
      class="custom-modal2"
      :hide-footer="true"
      modal-class="custom-modal2 no-animation"
      content-class="rectangular-dark-blue-modal-no-shadow"
      @shown="onOpen"
      @hidden="onClose"
    >
      <template slot="modal-header" slot-scope="{ close }">
        <a href="#" class="close-button" @click="close()"> </a>
        <div class="head-icon to-center-abs"></div>
      </template>

      <template slot="default" slot-scope="{ close }">
        <div id="payment-container" class="to-center-abs">
          <div class="row h-100 p-0 m-0">
            <div class="col-5">
              <div class="left-side">
                <div class="back-option clickable" @click="close()">
                  <div class="back-arrow"></div>
                  <div class="back-text">
                    {{ $t(`shop.payment.back`) }}
                  </div>
                </div>
                <div class="headline1 to-center-abs">
                  {{ $t(`shop.payment.header1`) }}
                </div>

                <div v-if="packageName" class="package-name glow to-center-abs">
                  {{ packageName }}
                </div>
                <div v-else class="package-name glow to-center-abs">
                  {{ $t("header.offer") }}
                </div>
                <div
                  v-if="packageName && !shopChosenPackage.customImg"
                  class="package-image to-center-abs"
                >
                  <img
                    draggable="false"
                    :src="getImageSrc(shopChosenPackage.package.slug)"
                    class="h-100"
                  />
                  <div
                    v-if="getMoreContentLabel(shopChosenPackage)"
                    class="shop-more-percent"
                  >
                    {{ getMoreContentLabel(shopChosenPackage).value }}
                  </div>
                </div>
                <div
                  v-if="shopChosenPackage.customImg"
                  class="package-image to-center-abs"
                >
                  <img
                    draggable="false"
                    :src="getImageSrc(shopChosenPackage.customImg)"
                    class="h-100"
                  />
                </div>
                <div
                  v-if="
                    shopChosenPackage.rewards &&
                    shopChosenPackage.rewards.gold > 0
                  "
                  class="package-amount glow to-center-abs"
                >
                  {{ formatNumber(shopChosenPackage.rewards.gold) }} x

                  <div class="main-currency-icon gold">
                    <img src="@/assets/img/common/coin1.png" class="w-100" />
                  </div>
                </div>

                <div
                  v-if="
                    shopChosenPackage.rewards &&
                    shopChosenPackage.rewards.crown > 0
                  "
                  class="package-amount glow to-center-abs"
                >
                  {{ formatNumber(shopChosenPackage.rewards.crown) }} x

                  <div class="main-currency-icon crown">
                    <img src="@/assets/img/common/crown4.png" class="w-100" />
                  </div>
                </div>

                <div class="glow-box glowing-border to-center-abs">
                  <div class="package-bonus-text glow">
                    {{ $t(`shop.header.bonus`) }}
                  </div>
                  <div
                    v-if="shopChosenPackage.rewards"
                    class="package-bonus-icons"
                  >
                    <div class="row m-0 justify-content-center">
                      <div
                        v-if="shopChosenPackage.rewards.card"
                        class="col package-bonus-icon-card"
                      >
                        <div class="text">
                          {{ shopChosenPackage.rewards.card }} x
                        </div>
                        <div class="icon">
                          <img
                            src="@/assets/img/common/cardstack-3.png"
                            class="img-card"
                          />
                        </div>
                      </div>
                      <div
                        v-if="shopChosenPackage.rewards.loyalty"
                        class="col package-bonus-icon-loyalty"
                      >
                        <div class="text">
                          {{ shopChosenPackage.rewards.loyalty }} x
                        </div>
                        <div class="icon">
                          <img
                            src="@/assets/img/nav-menu/nav-icon-loyalty.png"
                            class="img-loyalty"
                          />
                        </div>
                      </div>
                      <div
                        v-if="shopChosenPackage.rewards.elixir"
                        class="col package-bonus-icon-elixir"
                      >
                        <div class="text">
                          {{ shopChosenPackage.rewards.elixir }} x
                        </div>
                        <div class="icon">
                          <img
                            src="@/assets/img/common/elixir.png"
                            class="img-elixir"
                          />
                        </div>
                      </div>
                      <div
                        v-if="shopChosenPackage.rewards.expbooster"
                        class="col package-bonus-icon-expbooster"
                      >
                        <div class="text">
                          {{
                            Math.round(
                              shopChosenPackage.rewards.expbooster / 60
                            )
                          }}
                          Min.
                        </div>
                        <div class="icon">
                          <img
                            src="@/assets/img/shop/level-turbo-6-x-copy@2x.png"
                            class="img-exp"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="package-price-text to-center-abs">
                  {{ $t(`shop.header.price`) }}
                </div>
                <div class="package-price-amount to-center-abs">
                  € {{ formatPrice(shopChosenPackage.package.price) }}
                </div>
              </div>
            </div>
            <div class="col-7">
              <div class="right-side">
                <div class="headline2 to-center-abs">
                  {{ $t(`shop.payment.header2`) }}
                </div>
                <div class="row methods-row to-center-abs">
                  <Spinner
                    v-if="loading"
                    size="medium"
                    context="Loading Payment Methods..."
                    classes="dead-center"
                  ></Spinner>
                  <div
                    v-for="method in adyenPaymentMethods.paymentMethods"
                    :key="method.name"
                    class="col-4 box-col clickable"
                    @click="showAdyenPaymentForm(method.type)"
                  >
                    <div
                      class="payment-box-bg-container"
                      :class="[
                        { 'glowing-border': method.type === chosenPayment },
                        method.type,
                      ]"
                    >
                      <div
                        class="payment-image to-center-abs"
                        :class="method.type.toLowerCase()"
                      ></div>
                      <div class="payment-name glow to-center-abs">
                        {{ $t(`payment.methods.${method.type.toLowerCase()}`) }}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-show="chosenPayment !== null"
                  class="row pay-row to-center-abs"
                >
                  <a
                    href="#"
                    class="close-button"
                    @click="chosenPayment = null"
                  >
                  </a>
                  <div
                    class="col-12 d-flex align-items-center justify-content-center"
                  >
                    <div
                      v-show="chosenPayment && chosenPayment !== 'paypal'"
                      id="component-container"
                      ref="component-container"
                    ></div>
                    <div
                      v-show="chosenPayment === 'paypal'"
                      id="paypal-button-container"
                      class="w-100"
                    ></div>
                  </div>

                  <div
                    v-if="chosenPayment && chosenPayment !== 'paypal'"
                    class="col-12"
                  >
                    <DecoButton color-variant="green" @click="submitButton">
                      {{
                        $t(`shop.payment.pay_button`, {
                          0: formatPrice(shopChosenPackage.package.price) + "€",
                        })
                      }}
                    </DecoButton>
                  </div>
                </div>

                <div
                  v-if="!chosenPayment"
                  class="text-choose-payment glow to-center-abs"
                >
                  {{ $t(`shop.payment.choose_text`) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </b-modal>
    <modal-buy-complete></modal-buy-complete>
  </div>
</template>

<script>
import * as paymentAPI from "@/API/payment.js";
import DecoButton from "@/components/base/deco-button.vue";
import Spinner from "@/components/animation/animation-spinner";
import ModalBuyComplete from "@/components/shop/modal-buy-complete";
import getMoreContentLabel from "@/mixins/paymentFunctions.js";
import AdyenCheckout from "@adyen/adyen-web";
import "@adyen/adyen-web/dist/adyen.css";

export default {
  name: "ModalPayment",
  components: {
    ModalBuyComplete,
    DecoButton,
    Spinner,
  },
  mixins: [getMoreContentLabel],
  data() {
    return {
      loading: true,
      paypalScriptLoaded: null,
      adyenPaymentMethods: [],
      adyenCheckout: {},
      adyenState: {},
      adyenComponent: null,
      paypalOrderId: null,
      paypalAuthorizationID: null,
      chosenPayment: null,
      vat: null,
      vatAmout: null,
      packageName: null,
    };
  },

  computed: {
    environment: function () {
      let result = "test";
      if (process.env.NODE_ENV === "production") {
        result = "live";
      }
      return result;
    },

    getLocale: function () {
      let locale = this.$store.getters.getLocale;
      switch (locale) {
        case "de":
          locale += "_DE";
          break;
        case "en":
          locale += "_US";
          break;
      }

      return locale;
    },
    shopChosenPackage: function () {
      return this.$store.getters["shop/getShopChosenPackage"];
    },

    lastPaymentReference: function () {
      return this.$store.getters["shop/getLastPaymentReference"];
    },
    uriHasParameters: function () {
      return this.$route.fullPath.indexOf("?") !== -1;
    },
    uriHasRedirectParameter: function () {
      return this.$route.fullPath.indexOf("redirectResult") !== -1;
    },
    adyenRedirectedBack: function () {
      return (
        this.uriHasParameters &&
        this.uriHasRedirectParameter &&
        this.lastPaymentReference
      );
    },
  },

  watch: {
    adyenRedirectedBack: {
      handler(data) {
        if (data) {
          //adyen: redirected back to site. send additional details
          this.sendAdditionalDetails();
        }
      },
    },
  },

  mounted: function () {
    // this.$bvModal.show("modal-payment");
    if (this.adyenRedirectedBack) {
      this.sendAdditionalDetails();
    }
  },

  methods: {
    onOpen() {
      this.setPackageName();

      this.chosenPayment = null;
      this.$router.replace(this.$route.fullPath.split("?")[0]).catch(() => {});

      const configuration = {
        locale: this.getLocale,
        environment: this.environment,
        clientKey: process.env.VUE_APP_PAYMENT_ADYEN_CLIENT_KEY,
      };

      this.createAdyenCheckout(configuration)
        .then(() => {
          this.fetchPaymentMethods().then(() => {
            this.createPaypalButton();
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onClose() {
      let modalBuyComplete = document.getElementById("modal-buy-complete");

      if (this.shopChosenPackage.callback && !modalBuyComplete) {
        this.shopChosenPackage.callback();
      }
    },
    getImageSrc: function (slug) {
      try {
        return require("@/assets/img/shop/" + slug + ".png");
      } catch (e) {
        console.log(e);
        return "";
      }
    },
    setPackageName() {
      if (this.shopChosenPackage.customName) {
        this.packageName = this.shopChosenPackage.customName;
        return;
      }

      if (
        this.$i18n.i18next.exists(
          `shop.items.${this.shopChosenPackage.package.slug}`
        )
      ) {
        this.packageName = this.$t(
          `shop.items.${this.shopChosenPackage.package.slug}`
        );
        return;
      }

      this.packageName = null;
    },

    //this creates the adyen checkout object. We need it before checkout (credit card enterering)
    // and when entering checkout
    async createAdyenCheckout(configuration) {
      return new Promise((resolve) => {
        AdyenCheckout(configuration).then((res) => {
          this.adyenCheckout = res;
          resolve();
        });
      });
    },

    addPaypalScript() {
      return new Promise((resolve) => {
        //add paypal script
        let paypalScript = document.createElement("script");
        paypalScript.setAttribute(
          "src",
          "https://www.paypal.com/sdk/js?intent=capture&client-id=" +
            process.env.VUE_APP_PAYPAL_CLIENT_ID +
            "&currency=" +
            this.shopChosenPackage.package.currency
        );
        paypalScript.onload = () => {
          resolve();
        };
        document.head.appendChild(paypalScript);
      });
    },

    //create paypal buttons and listen to onApprove (when redirected back to page) event
    createPaypalButton: async function () {
      this.addPaypalScript().then(() => {
        const price = parseFloat(this.shopChosenPackage.package.price);
        const priceInCents = price;
        const currency = this.shopChosenPackage.package.currency;

        let amountExcludingTax = priceInCents / this.vat;
        amountExcludingTax =
          Math.round(amountExcludingTax * 100 + Number.EPSILON) / 100;

        let taxAmount = price - amountExcludingTax;
        taxAmount = Math.round(taxAmount * 100 + Number.EPSILON) / 100;
        this.vatAmout = taxAmount;

        paypal
          .Buttons({
            fundingSource: paypal.FUNDING.PAYPAL,
            createOrder: function (data, actions) {
              return actions.order.create({
                purchase_units: [
                  {
                    amount: {
                      value: price,
                      breakdown: {
                        item_total: {
                          currency_code: currency,
                          value: amountExcludingTax,
                        },
                        tax_total: {
                          currency_code: currency,
                          value: taxAmount,
                        },
                      },
                    },
                  },
                ],
              });
            },
            onApprove: (data) => {
              this.paypalOrderId = data.orderID;
              this.fetchPaypalCapture();
            },
            onError: function (err) {
              this.displayErrorMessage();

              console.log(err);
            },
          })
          .render("#paypal-button-container");

        this.loading = false;
      });
    },

    fetchPaypalCapture: async function () {
      await paymentAPI
        .fetchPaypalPaymentCapture(
          this.shopChosenPackage.id,
          this.paypalOrderId
        )
        .then((result) => {
          if (result.result.result.status !== "COMPLETED") {
            this.paymentNotComplete(result); // 0=> general error
            return;
          }

          let paymentInfos = Object.assign(this.shopChosenPackage);
          paymentInfos.offer_id = this.shopChosenPackage.id;
          paymentInfos.price = this.shopChosenPackage.package.price;
          paymentInfos.tax = this.vat;
          paymentInfos.taxAmount = this.vatAmout;
          paymentInfos.currency = this.shopChosenPackage.package.currency;
          paymentInfos.payment_method = this.chosenPayment;
          paymentInfos.transaction_reference = result.transaction_reference;

          this.$store.commit("shop/setLastPaymentReference", paymentInfos);
          this.paymentComplete(result.result, result.booking_result);
        })
        .catch((e) => console.log(e));
    },

    //get adyen payment methods
    fetchPaymentMethods: async function () {
      const offer_id = this.shopChosenPackage.id;
      const locale = this.getLocale;

      let response = await paymentAPI
        .fetchAdyenPaymentMethods(offer_id, locale)
        .then((result) => {
          this.adyenPaymentMethods = result.methods;
          this.vat = this.calculateVat(result.vat);
          this.adyenPaymentMethods.paymentMethods.unshift({ type: "paypal" }); //add paypal payment method

          this.googleTagLogShopAddToCard(this.shopChosenPackage);

          this.doAdyenCheckout();
        })
        .catch((e) => {
          console.log(e);
          this.displayErrorMessage();
        });
      return response;
    },

    doAdyenCheckout: function () {
      const price = parseInt(
        this.shopChosenPackage.package.price.toString().replace(".", "")
      );
      const currency = this.shopChosenPackage.package.currency;

      //set adyen configuration
      const configuration = {
        locale: this.getLocale,
        environment: this.environment,
        clientKey: process.env.VUE_APP_PAYMENT_ADYEN_CLIENT_KEY,
        paymentMethodsResponse: this.adyenPaymentMethods,
        onChange: this.handleOnChange,
        showPayButton: false,
        amount: {
          value: price,
          currency: currency,
        },
        onAdditionalDetails: this.handleOnAdditionalDetails,
        onSubmit: this.handleOnSubmit,
      };

      this.createAdyenCheckout(configuration);
    },

    handleOnChange: function (currentState, currentComponent) {
      this.adyenState = currentState; // Provides the data that you need to pass in the `/payments` call.
      this.adyenComponent = currentComponent; // Provides the active component instance that called this event.
    },

    handleOnAdditionalDetails: function (currentState, currentComponent) {
      this.adyenState = currentState; // Provides the data that you need to pass in the `/payments` call.
      this.adyenComponent = currentComponent; // Provides the active component instance that called this event.
    },

    handleOnSubmit: function (currentState, currentComponent) {
      this.adyenState = currentState; // Provides the data that you need to pass in the `/payments` call.
      this.adyenComponent = currentComponent; // Provides the active component instance that called this event.

      console.log("SEND: ", this.adyenState);
      console.log("SEND: ", this.adyenComponent);
      this.sendAdyenPayment();
    },

    submitButton: function () {
      this.playSoundButtonCommon();

      const type = this.adyenComponent.data.paymentMethod.type;
      if (type === "paypal") {
        return;
      }

      this.adyenComponent.submit();
    },

    //create the payment form
    showAdyenPaymentForm: function (paymentType) {
      this.playSoundTab();
      this.chosenPayment = paymentType;
      this.googleTagLogShopCheckoutStep1(
        this.shopChosenPackage,
        this.chosenPayment
      );

      if (paymentType === "paypal") {
        return;
      }

      this.adyenComponent = this.adyenCheckout
        .create(paymentType)
        .mount(this.$refs["component-container"]);
    },

    //send a payment to server
    sendAdyenPayment: async function () {
      const offer_id = this.shopChosenPackage.id;
      const payment_methods = JSON.stringify(
        this.adyenState.data.paymentMethod
      );
      const return_url = process.env.VUE_APP_HOST + this.$route.fullPath;

      await paymentAPI
        .sendAdyenPayment(offer_id, payment_methods, return_url)
        .then((result) => {
          if (result.status === 500) {
            this.paymentNotComplete(result);
            return;
          }

          const data = result.result;

          let paymentInfos = Object.assign(this.shopChosenPackage);
          paymentInfos.details = data.details;
          paymentInfos.paymentData = data.paymentData;
          paymentInfos.transaction_reference = result.transaction_reference;
          paymentInfos.redirect =
            data.action && data.action.type == "redirect" ? true : false;
          paymentInfos.offer_id = offer_id;
          paymentInfos.price = this.shopChosenPackage.package.price;
          paymentInfos.tax = this.vat;
          paymentInfos.taxAmount = this.vatAmout;
          paymentInfos.currency = this.shopChosenPackage.package.currency;
          paymentInfos.payment_method = this.chosenPayment;
          paymentInfos.transaction_reference = result.transaction_reference;

          this.$store.commit("shop/setLastPaymentReference", paymentInfos);

          if (data.action) {
            // additional steps required
            this.adyenCheckout
              .createFromAction(data.action)
              .mount(this.$refs["component-container"]);
          } else {
            this.checkPaymentResult(data, result.booking_result);
          }
        })
        .catch((e) => console.log(e));
    },

    sendAdditionalDetails: async function () {
      let lasRef = this.lastPaymentReference;
      if (lasRef.redirect) {
        const parameters = this.$route.fullPath.split("?")[1];
        const details = this.urlParamsToJson(parameters);
        lasRef.details = JSON.stringify(details);
      }

      await paymentAPI
        .sendAdyenPaymentDetails(
          lasRef.offer_id,
          lasRef.transaction_reference,
          lasRef.details,
          lasRef.paymentData
        )
        .then((result) => {
          this.loading = false;
          this.checkPaymentResult(result.result, result.booking_result);
        })
        .catch((e) => this.paymentNotComplete(e.data));
    },

    checkPaymentResult: function (result, bookingResult) {
      switch (result.resultCode) {
        case "Authorised":
          this.paymentComplete(result, bookingResult);
          return;
        case "Refused":
          this.paymentNotComplete(result);
          return;
        case "Received":
          this.paymentNotComplete(result);
          return;
        default:
          this.paymentNotComplete(result);
          return;
      }
    },

    paymentComplete: function (result, bookingResult) {
      //set booked rewards
      let rewards = this.bookingResultsToRewards(bookingResult);
      this.$store.commit("shop/setBookingResult", rewards);

      //remove parameters from uri
      this.$router.replace(this.$route.fullPath.split("?")[0]).catch(() => {});

      //show final modal
      this.$bvModal.show("modal-buy-complete");
      // console.log("payment complete");
      // console.log(result);

      this.firebasePurchase(this.lastPaymentReference);
      this.googleTagLogShopPurchased(this.lastPaymentReference);
      this.branchIoLogPurchased(this.lastPaymentReference);
    },
    paymentNotComplete: function (result) {
      //remove parameters from uri
      this.$router.replace(this.$route.fullPath.split("?")[0]).catch(() => {});

      let errorMessage;
      if (result.status === 500) {
        //server error
        let error = result.data.id.split(" ").join("_").toLowerCase();
        errorMessage = this.$t("payment.error." + error);
      } else {
        //direct payment error
        let errorCode = result.refusalReasonCode;
        if (errorCode === "0") {
          //general error. try to find specific one
          const reason = result.refusalReason
            .split(" ")
            .join("_")
            .toLowerCase();
          errorMessage = this.$t("payment.error." + reason);
        } else {
          errorMessage = this.$t("payment.error.code_" + errorCode);
        }
      }

      //fallback

      this.$store.commit("popups/setAlertBannerContent", {
        alertText: errorMessage,
        alertHeading: this.$t("alerts.title.error"),
        variant: "danger",
        type: "danger",
      });
      console.error(result);
    },

    urlParamsToJson: function (urlString) {
      const urlParams = new URLSearchParams(urlString);
      const entries = urlParams.entries();

      let result = {};
      for (let entry of entries) {
        // each 'entry' is a [key, value] tupple
        const [key, value] = entry;
        result[key] = value;
      }
      return result;
    },

    calculateVat: function (vat) {
      if (!vat) {
        return 1;
      }
      const percent = parseInt(vat);
      return (100 + percent) / 100;
    },

    bookingResultsToRewards: function (bookingResult) {
      if (!bookingResult) {
        return;
      }

      let rewardObj = {
        gold: 0,
        crown: 0,
        loyalty: 0,
        elixir: 0,
        card: 0,
        cardRewards: [],
        expbooster: 0,
      };

      for (let props in bookingResult) {
        let item = bookingResult[props];

        switch (item.type) {
          case "gold":
            rewardObj.gold += item.amount;
            break;
          case "crown":
            rewardObj.crown += item.amount;
            break;
          case "loyalty":
            rewardObj.loyalty += item.amount;
            break;
          case "elixir":
            rewardObj.elixir += item.amount;
            break;
          case "expbooster":
            rewardObj.expbooster += item.amount;
            break;
          case null:
            rewardObj.card += item.amount;
            for (let i = 0; i < item.booked.length; i++) {
              let card = item.booked[i];
              rewardObj.cardRewards.push({
                reward: "card",
                cardId: card.card.id,
                amount: card.amount,
              });
            }
            break;
        }
      }

      return rewardObj;
    },
  },
};
</script>
<style lang="scss">
$modal-width: 1200px;
$modal-height: 632px;

#modal-payment {
  .modal-header {
    .head-icon {
      top: -95px;
      background: url(~@/assets/img/shop/shop-visual-default.png) center/100%
        100% no-repeat;
      width: 480px;
      height: 182px;
    }
  }

  .modal-dialog {
    top: 80px;
    max-width: $modal-width;
    height: $modal-height;

    @media (max-height: 785px) {
      transform: scale(0.9);
      top: 40px;
      margin-bottom: -10%;
    }

    @media (max-height: 700px), (max-width: 1200px) {
      transform: scale(0.8);
      top: 0;
    }

    @media (max-width: 1100px) {
      left: -80px;
    }
  }

  .modal-content {
    width: $modal-width;
    box-shadow: 0 0 26px rgba(77, 229, 255, 0.6),
      0 0 26px rgba(77, 229, 255, 0.6), 0 0 26px rgba(77, 229, 255, 0.6),
      0 0 26px rgba(77, 229, 255, 0.6), 0 0 13px rgba(77, 229, 255, 0.6),
      0 0 13px rgba(77, 229, 255, 0.6), 0 0 6px rgba(0, 94, 110, 0.8),
      0 0 6px rgba(0, 94, 110, 0.8);
    border: 2px solid white;
    border-radius: 16px;
  }

  .close-button {
    top: -80px;
    right: -50px;
  }

  .spinner-container {
    top: -150px;
    left: 40px;
  }

  .pay-row {
    .deco-button {
      width: 100%;
      height: 45px;
      font-size: 22.4px;
      font-family: Ubuntu;
      padding-bottom: 3px;

      .deco-gem {
        display: none;
      }
    }

    .adyen-checkout__label__text {
      color: white;
      text-align: left;
    }

    .adyen-checkout__button {
      display: none;
    }
  }
}
</style>

<style scoped lang="scss">
#modal-payment {
  .glow {
    text-shadow: 0 0 16px rgba(77, 229, 255, 0.6),
      0 0 8px rgba(77, 229, 255, 0.6), 0 0 8px rgba(77, 229, 255, 0.6),
      0 0 4px rgba(0, 94, 110, 0.8), 0 0 4px rgba(0, 94, 110, 0.8);
  }

  .glowing-border {
    box-shadow: 0 0 26px rgba(77, 229, 255, 0.6),
      0 0 26px rgba(77, 229, 255, 0.6), 0 0 26px rgba(77, 229, 255, 0.6),
      0 0 26px rgba(77, 229, 255, 0.6), 0 0 13px rgba(77, 229, 255, 0.6),
      0 0 13px rgba(77, 229, 255, 0.6), 0 0 6px rgba(0, 94, 110, 0.8),
      0 0 6px rgba(0, 94, 110, 0.8) !important;
  }

  #payment-container {
    height: 107%;
    width: 100%;
    top: -7%;
    padding-top: 20px;
  }

  .header-row {
    width: 106%;
    margin-left: -3%;
  }

  .left-side {
    padding-top: 12%;
    margin-top: -1.7%;
    height: 100%;

    .back-option {
      position: absolute;
      display: flex;
      width: 135px;
      height: 40px;
      left: 25px;
      top: 15px;
      background: url(~@/assets/img/shop/btn-shop-back.png) center/100% 100%
        no-repeat;
      padding: 5px 0 0 45px;

      .back-arrow {
        position: absolute;
        top: 8px;
        left: 16px;
        width: 0;
        height: 0;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-right: 15px solid white;
        margin-top: 3px;
      }
      .back-text {
        font-family: Ubuntu-Medium;
        font-size: 18px;
        color: white;
        text-transform: uppercase;
      }
    }

    .headline1 {
      color: #fff8b7;
      font-size: 16px;
      font-family: Ubuntu-Bold;
      top: 80px;
      text-transform: uppercase;
    }

    .package-name {
      font-family: Ubuntu-Bold;
      font-size: 36px;
      text-align: center;
      text-transform: uppercase;
      top: 120px;
    }

    .package-image {
      height: 82px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      top: 170px;

      .shop-more-percent {
        top: 10px;
        right: 110px;
        z-index: 1;
      }
    }

    .package-amount {
      font-family: Ubuntu-Bold;
      font-size: 48px;
      position: relative;
      display: inline-flex;
      text-align: center;
      line-height: 1;
      top: 210px;
    }

    .main-currency-icon {
      width: 48px;
    }

    .glow-box {
      border: 2px solid white;
      border-radius: 16px;
      width: 370px;
      height: 104px;
      margin-left: auto;
      margin-right: auto;
      top: 380px;

      .package-bonus-text {
        font-family: Ubuntu-Bold;
        font-size: 21px;
        text-align: center;
        margin-top: 10px;
        text-transform: uppercase;
      }

      .package-bonus-icons {
        width: 100%;

        .text {
          color: white;
          font-family: Ubuntu-Bold;
          font-size: 21px;
          white-space: nowrap;
          margin-top: auto;
          margin-bottom: auto;
          display: inline;
        }

        .icon {
          filter: drop-shadow(0 2px 12px #4de5ff);
          display: inline;

          .img-card {
            height: 46px;
          }
          .img-loyalty {
            height: 45px;
          }

          .img-elixir {
            height: 50px;
          }

          .img-exp {
            height: 50px;
          }
        }
      }
    }

    .package-price-text {
      font-family: Ubuntu-Bold;
      font-size: 16px;
      text-transform: uppercase;
      color: #fff8b7;
      top: 505px;
    }

    .package-price-amount {
      background: #000000;
      border-radius: 8px;
      height: 48px;
      width: 216px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      line-height: 48px;
      color: #4de5ff;
      font-family: Ubuntu;
      font-size: 32px;
      box-shadow: 0 0 1px #074f5c;
      top: 535px;
    }
  }

  .right-side {
    padding-top: 12%;
    margin-top: -1.7%;
    height: 100%;

    .headline2 {
      color: #fff8b7;
      font-size: 16px;
      font-family: Ubuntu-Bold;
      top: 90px;
      text-transform: uppercase;
    }

    .methods-row {
      height: 60%;
      top: 140px;
      width: 680px;

      .box-col {
        cursor: pointer;
      }

      .payment-box-bg-container {
        width: 196px;
        height: 160px;
        background: linear-gradient(#274b60, #0a1d28) center/100% 100% no-repeat;
        box-shadow: 0 0 32px rgba(0, 0, 0, 0.1), 0 0 16px rgba(0, 0, 0, 0.27),
          0 0 8px rgba(0, 0, 0, 0.19);
        border-radius: 16px;
        position: relative;

        .payment-image {
          background-size: 100%;
          background-repeat: no-repeat;
          background-position: center;
          height: 124px;

          &.paysafecard {
            background-image: url(~@/assets/img/shop/paysafecard.png);
            width: 160px;
            background-position: center;
          }
          &.giropay {
            background-image: url(~@/assets/img/shop/giropay.png);
            width: 140px;
          }
          &.klarna {
            background-image: url(~@/assets/img/shop/rechnung.png);
            width: 120px;
          }
          &.directebanking {
            background-image: url(~@/assets/img/shop/sofort-white.png);
            width: 120px;
          }
          &.scheme {
            background-image: url(~@/assets/img/shop/visa-white.png),
              url(~@/assets/img/shop/mastercard-white@2x.png),
              url(~@/assets/img/shop/axp.png);
            background-position: center 20px, 110px 65px, 25px 65px;
            background-size: 50%, 30%, 23%;
            width: 100%;
          }
          &.paypal {
            background-image: url(~@/assets/img/shop/paypal2.png);
            width: 140px;
          }
        }

        .payment-name {
          font-size: 16px;
          font-family: Ubuntu-Medium;
          text-transform: uppercase;
          bottom: 10px;
        }
      }

      .complete-check {
        width: 34px;
        position: absolute;
        top: -4px;
        right: -5px;
      }
    }
  }

  .pay-row {
    height: 252px;
    width: 651px;
    left: 12px;
    top: 330px;
    text-align: center;
    background-image: linear-gradient(#274b60, #0a1d28);
    box-shadow: 0 0 32px rgba(0, 0, 0, 0.1), 0 0 16px rgba(0, 0, 0, 0.27),
      0 0 8px rgba(0, 0, 0, 0.19);
    border-radius: 16px;

    .close-button {
      top: -20px;
      right: -20px;
    }

    .deco-button {
      width: 100%;
      height: 45px;
      font-size: 22.4px;
      font-family: Ubuntu;

      .deco-gem {
        display: none;
      }
    }
  }

  .text-choose-payment {
    top: 530px;
    font-size: 24px;
    text-align: center;
    font-family: Ubuntu-Bold;
    text-transform: uppercase;
  }

  .custom-table-head-left {
    width: 103%;
    left: -3%;
    top: 10px;
    z-index: 1;
  }
  .custom-table-head-right {
    width: 103%;
    right: -3%;
    top: 10px;
    z-index: 1;
  }
}
</style>
