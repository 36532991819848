<template>
  <div
    v-show="!loading"
    id="canvas-container-animation-event-goforgold"
    ref="canvascontainer"
    class="canvas-container-animation-event-goforgold"
  ></div>
</template>

<script>
import AnimationSpineBaseFunctions from "@/components/animation/animation-spine-base-functions";
import { Spine } from "pixi-spine";

export default {
  name: "AnimationEventGoforgold",
  mixins: [AnimationSpineBaseFunctions],
  props: {
    loop: {
      type: Boolean,
      default: true,
    },
    run: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      unsubscribeEvents: [],
      destroyApp: false,
      loading: true,
    };
  },
  computed: {
    assets() {
      return [
        [
          "goforgold",
          "/assets/spines/events/goforgold/goforgold-logo-animation.json",
        ],
      ];
    },
  },
  mounted() {
    this.play();
  },
  beforeDestroy() {
    //destroy event listeners
    for (let i = 0; i < this.unsubscribeEvents.length; i++) {
      this.unsubscribeEvents[i]();
    }

    this.destroyApp = true;
  },
  methods: {
    play: function () {
      const assets = this.assets;
      const options = {
        width: 680,
        height: 350,
        antialias: true,
        backgroundAlpha: 0,
        autoResize: false,
      };

      const app = this.createCanvas(
        options,
        assets,
        this.$refs.canvascontainer.id
      );
      app.view.id = "canvas-animation-event-goforgold";

      app.ticker.add(() => {
        if (this.destroyApp) {
          this.destroyApp = false;
          this.destroyAll(app);
        }
      });

      const setup = (loader, res) => {
        let spineData = this.getSpineData(res);

        spineData.forEach((data) => {
          const spine = new Spine(data);

          // set the position
          spine.x = app.screen.width / 2;
          spine.y = app.screen.height - 190;

          //add spine to stage
          app.stage.addChild(spine);

          //run animation
          let animationName = "main";

          if (!this.run) {
            animationName = "idle";
          }
          let animation = this.getAnimationByName(data, animationName);
          spine.state.setAnimation(0, animation.name);

          //set animation end
          spine.state.getCurrent(0).animationEnd = animation.duration;

          // Detach event listeners
          this.unsubscribeEvents.push(() => {
            spine.state.getCurrent(0).onEvent = null;
          });

          //add loop
          if (this.loop) {
            spine.state.getCurrent(0).loop = true;
          }
        });
      };

      app.loader.load(setup);
      app.loader.onComplete.add(() => {
        this.$emit("done");
      });
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
.canvas-container-animation-event-goforgold {
  pointer-events: none;
}
#canvas-animation-event-goforgold {
  position: absolute;
  left: -71px;
  top: -46px;
  z-index: 5;
}
</style>
