<template>
  <b-modal
    :id="modalId"
    ref="modal-winter-bonus-jagd"
    :hide-footer="true"
    modal-class="modal-winter-bonus-jagd"
    @shown="onOpen"
    @hidden="onCloseCustom"
  >
    <template slot="modal-header" slot-scope="{ close }">
      <a href="#" class="close-button" @click="close()"> </a>
    </template>

    <template slot="default">
      <Spinner
        v-if="loading"
        size="medium"
        context="Loading Event..."
        classes="dead-center"
      ></Spinner>
      <div class="preload"></div>
      <div class="bg main"></div>
      <div class="bg snow-left"></div>
      <div class="bg snow-right"></div>
      <div class="bg snow-front"></div>
      <div class="bg border1 top"></div>
      <div class="bg border1 bottom"></div>
      <div class="bg border2 left"></div>
      <div class="bg border2 right"></div>

      <div class="logo">
        <div class="main"></div>
        <div class="bonus" :class="$store.state.locale"></div>
      </div>

      <template v-if="!loading">
        <div class="signs">
          <div class="text1">
            {{ $t("events.winter.kingspass") }}
            <div
              :class="[isKingspassActive ? 'check-icon' : 'lock-icon']"
            ></div>
          </div>
          <div class="text2">
            {{ $t("events.winter.freepass") }}
          </div>
        </div>

        <div v-if="kingsPassData" class="task-panel-container">
          <template
            v-if="
              getPriceOfLevel(currentLevel + 1) > 0 &&
              userProgress >= 100 &&
              !isLevelBlockerExpired(kingspassLevels[currentLevel + 1])
            "
          >
            <div class="task-text" @click="openLevelBuyModal(currentLevel + 1)">
              {{
                $t("events.winter.task_text.unlock_levels", {
                  0: currentLevel + 2,
                  1: getPriceOfLevel(currentLevel + 1),
                })
              }}
            </div>
          </template>

          <template
            v-else-if="
              currentLevel < kingspassLevels.length - 1 ||
              (currentLevel === kingspassLevels.length - 1 &&
                userProgress < 100)
            "
          >
            <div class="task-text" @click="startRandomSlot">
              <div class="text1 text-center">
                {{ $t("events.winter.task", { 0: currentLevel + 1 }) }}
              </div>

              <div v-if="kingspassLevels" class="text2 text-center">
                {{
                  $t("events.winter.task_type." + currentTaskType, {
                    0: currentTaskWinAmount,
                  })
                }}
              </div>
            </div></template
          >

          <template v-else>
            <div class="task-text">
              {{ $t("events.winter.event_completed") }}
            </div>
          </template>
          <div class="ticket-icon position-absolute"></div>
          <div class="ticket-counter position-absolute text-center">
            {{ ticketCount }}
          </div>

          <div class="timer d-inline-flex align-items-center">
            <div class="icon-hourglass bg-img"></div>
            <countdown-digital
              :fixed-width="true"
              :time="endDate"
              :days-to-hours="true"
              :with-time-diff="true"
            ></countdown-digital>
          </div>
          <template v-if="isKingspassActive">
            <div
              class="king-pass-button to-center-abs d-inline-flex align-items-center justify-content-around"
            >
              <div class="text1">
                {{ $t("events.winter.kingspass_activated") }}
              </div>

              <div class="check-icon"></div>
            </div>
          </template>
          <template v-else>
            <div
              class="king-pass-button to-center-abs d-inline-flex align-items-center active pointer"
              @click="openKingspassBuyModal"
            >
              <div class="text1">
                {{ $t("events.winter.kingspass_activate") }}
              </div>

              <div class="text2">
                {{ priceKingsPass }}
                <div class="crown-icon"></div>
              </div>
            </div>
          </template>
          <div class="help-button" @click="openHelpModal"></div>
        </div>

        <div
          v-if="levelRewards"
          ref="rewards-container"
          class="rewards-container to-center-abs"
        >
          <div class="rewards-content">
            <template v-for="(level, index) in eventData.levels">
              <!--            lock panel-->
              <div
                v-if="
                  !isLevelBlockerExpired(level) &&
                  level.crowns > 0 &&
                  index - 1 === currentLevel &&
                  userProgress >= 100
                "
                :key="'panel' + index"
                class="lock-panel"
              >
                <div class="bg">
                  <div class="text1 to-center-abs">Level</div>
                  <div class="text2 to-center-abs">
                    {{ index + 1 }} -
                    {{
                      index + 10 >= levelRewards.length - 1
                        ? levelRewards.length
                        : index + 10
                    }}
                  </div>
                </div>
                <div
                  class="unlock-button to-center-abs"
                  @click="openLevelBuyModal(index)"
                >
                  <div class="button-text1 to-center-abs">
                    {{ $t("events.winter.level_unlock") }}
                  </div>
                  <div class="button-text2 to-center-abs">
                    <span class="position-relative">
                      {{ getPriceOfLevel(index) }}
                      <div class="crown-icon"></div>
                    </span>
                  </div>
                </div>
              </div>

              <!--            reward-->
              <div
                :id="'reward-panel' + index"
                :key="'reward' + index"
                class="reward"
              >
                <div class="bg" :class="getRewardType(index + 1)"></div>
                <div
                  v-if="currentLevel + 1 <= index"
                  class="frozen to-center-abs"
                ></div>
                <div class="number to-center-abs">{{ index + 1 }}</div>
                <template
                  v-if="
                    level.level < currentLevel ||
                    (level.level === currentLevel && userProgress === 100)
                  "
                >
                  <div class="collect-button to-center-abs">
                    {{ $t("events.winter.collect_button") }}
                  </div>
                </template>
                <template v-else>
                  <div class="progress-container to-center-abs">
                    <div class="progress-frame"></div>
                    <template v-if="level.level === currentLevel">
                      <div class="progress-bar">
                        <div
                          class="progress-fill"
                          :style="{ width: userProgress + '%' }"
                        ></div>
                      </div>
                      <div class="progress-percent-badge">
                        {{ Math.floor(userProgress) }}%
                      </div></template
                    >

                    <template v-if="level.level > currentLevel">
                      <div class="progress-bar">
                        <div class="progress-fill" :style="{ width: 0 }"></div>
                      </div>
                      <div class="progress-percent-badge">0%</div></template
                    >
                  </div>
                </template>

                <div
                  class="reward-kingspass to-center-abs d-inline-flex align-items-center justify-content-around pl-3"
                >
                  <BaseReward
                    v-for="kingspassReward in Object.values(
                      levelRewards[index]
                    )[1]"
                    :key="`kingspass-reward-${kingspassReward.id}`"
                    :reward-data="kingspassReward"
                    :shorten-gold-amount="true"
                    :is-kingspass="true"
                  />
                  <BaseReward
                    :custom-reward="true"
                    :reward-data="{
                      type: 'ticket',
                      amount: index + 1,
                      src: require('@/assets/img/events/winter-bonus-jagd/icn-reward-ticket.svg'),
                    }"
                    :is-kingspass="true"
                  />
                  <div
                    v-if="isKingspassActive"
                    class="complete-img"
                    :class="[
                      userProgress >= 100 || index < currentLevel
                        ? 'check-icon'
                        : '',
                    ]"
                  ></div>
                  <div v-else class="lock-icon"></div>
                </div>
                <div
                  class="reward-normal to-center-abs d-inline-flex align-items-center justify-content-around pl-3"
                >
                  <BaseReward
                    v-for="normalReward in Object.values(
                      levelRewards[index]
                    )[0]"
                    :key="`normal-reward-${normalReward.id}`"
                    :reward-data="normalReward"
                  />
                  <BaseReward
                    :custom-reward="true"
                    :reward-data="{
                      type: 'ticket',
                      amount: index + 1,
                      src: require('@/assets/img/events/winter-bonus-jagd/icn-reward-ticket.svg'),
                    }"
                  />
                  <div
                    class="complete-img"
                    :class="[
                      userProgress >= 100 || index < currentLevel
                        ? 'check-icon'
                        : '',
                    ]"
                  ></div>
                </div>
              </div>
            </template>
          </div>
        </div>

        <div class="scroll-button-container to-center-abs">
          <div class="button-left pointer" @click="scroll(-scrollSpeed)"></div>
          <div class="button-right pointer" @click="scroll(scrollSpeed)"></div>
        </div>

        <modal-winter-help />
        <modal-winter-buy-pass
          :amount="priceKingsPass"
          @buy-kingspass="buyKingspass"
        />
        <modal-winter-buy-level
          :amount="currentLevelPrice"
          @buy-level="unlockNextLevels"
        />
        <animation-winterbonusjagd-kingspass
          v-if="showKingspassAnimation"
          :loop="false"
          @kingspass-ani-completed="showKingspassAnimation = false"
        ></animation-winterbonusjagd-kingspass>
        <animation-winterbonusjagd-unlock-level
          v-if="showLevelUnlockAnimation"
          :loop="false"
          :level="currentLevel + 1"
          @kingspass-ani-completed="showLevelUnlockAnimation = false"
        ></animation-winterbonusjagd-unlock-level>
      </template>
    </template>
  </b-modal>
</template>

<script>
import eventPopups from "@/mixins/eventPopups.js";
import CountdownDigital from "@/components/base/countdown-digital";
import * as EventAPI from "@/API/events.js";
import ModalWinterHelp from "@/components/events/winter-bonus-jagd/modal-winter-help";
import Spinner from "@/components/animation/animation-spinner.vue";
import ModalWinterBuyPass from "@/components/events/winter-bonus-jagd/modal-winter-buy-pass";
import { fetchRandomGame } from "@/API/games";
import ModalWinterBuyLevel from "@/components/events/winter-bonus-jagd/modal-winter-buy-level";
import AnimationWinterbonusjagdKingspass from "@/components/animation/animation-spine-winterbonusjagd-kingspass";
import dateFunctions from "@/mixins/dateFunctions.js";
import AnimationParsingFunctions from "@/components/animation/animation-parsing-functions";
import AnimationWinterbonusjagdUnlockLevel from "@/components/animation/animation-spine-winterbonusjagd-unlock-level";
import BaseReward from "@/components/base/base-reward.vue";

export default {
  name: "ModalWinterBonusJagd",
  components: {
    AnimationWinterbonusjagdUnlockLevel,
    ModalWinterBuyLevel,
    ModalWinterBuyPass,
    ModalWinterHelp,
    CountdownDigital,
    Spinner,
    AnimationWinterbonusjagdKingspass,
    BaseReward,
  },
  mixins: [eventPopups, dateFunctions, AnimationParsingFunctions],
  props: {
    modalId: {
      type: String,
      default: "modal-winter-bonus-jagd",
    },
  },

  data() {
    return {
      intervalId: null,
      loading: true,
      levelRewards: [],
      scrollSpeed: 800,
      doScroll: false,
      kingsPassData: null,
      userData: null,
      isKingspassActive: false,
      currentLevelPrice: 0,
      showKingspassAnimation: false,
      showLevelUnlockAnimation: false,
    };
  },

  computed: {
    inSlotView() {
      return this.$route.name === "game";
    },

    kingspassLevels() {
      if (!this.kingsPassData) {
        return;
      }
      return this.kingsPassData.levels;
    },

    endDate() {
      return new Date(this.kingsPassData.end_at).getTime();
    },

    currentLevel() {
      if (!this.userData) {
        return 0;
      }

      return this.userData.level.level;
    },
    userProgress() {
      if (!this.userData) {
        return 0;
      }
      return this.userData.progress;
    },

    currentTaskType() {
      return this.kingspassLevels[this.currentLevel].task_type
        .split("\\")
        .slice(-1);
    },

    currentTaskWinAmount() {
      return this.formatNumber(
        this.kingspassLevels[this.currentLevel].payload.win,
        true
      );
    },

    ticketCount() {
      let counter = 0;
      for (let i = 0; i <= this.currentLevel; i++) {
        counter += i;
      }

      if (this.userData && this.userProgress >= 100) {
        counter += this.currentLevel + 1;
      }

      if (this.isKingspassActive) {
        counter *= 2;
      }

      return counter;
    },
    priceKingsPass() {
      if (!this.kingsPassData) {
        return;
      }
      return this.formatNumber(
        this.kingsPassData.tiers.filter((tier) =>
          tier.title.includes("Königspass")
        )[0].crowns
      );
    },
    tierKingsPassId() {
      return this.kingsPassData.tiers.filter((tier) =>
        tier.title.includes("Königspass")
      )[0].id;
    },
  },

  mounted() {
    if (this.$route.path.startsWith("/winter-bonus")) {
      this.$bvModal.show(this.modalId);
    }
  },

  beforeDestroy() {
    clearInterval(this.intervalId);
  },

  methods: {
    openHelpModal() {
      this.$bvModal.show("modal-winter-help");
    },

    //unlock next levels by removing level blocker
    unlockNextLevels() {
      EventAPI.kronenpassPurchaseLevel(
        this.kingsPassData.id,
        this.currentLevel + 1
      )
        .then((res) => {
          this.$bvModal.hide("modal-winter-buy-level");
          res.pass.level = { level: this.currentLevel + 1 };
          this.userData = res.pass;
          this.showLevelUnlockAnimation = true;
          this.$store.commit(
            "events/setCurrentLevelIndex",
            this.userData.level.level
          );
        })
        .catch((e) => console.log(e));
    },

    openKingspassBuyModal() {
      this.$bvModal.show("modal-winter-buy-pass");
    },
    openLevelBuyModal(index) {
      this.currentLevelPrice = this.getPriceOfLevel(index);
      this.$bvModal.show("modal-winter-buy-level");
    },

    //buy kingspass tier
    buyKingspass() {
      EventAPI.kronenpassPurchaseTier(
        this.kingsPassData.id,
        this.tierKingsPassId
      )
        .then(() => {
          if (this.currentLevel === 0) {
            this.showKingspassAnimation = true;
          }
          this.isKingspassActive = true;
          this.$bvModal.hide("modal-winter-buy-pass");
        })
        .catch((e) => {});
    },

    //get unlock price of level by index
    getPriceOfLevel(index) {
      if (index >= this.kingspassLevels.length) {
        index--;
      }

      return this.kingspassLevels[index].crowns;
    },

    //check, if blocker is expired, because required day is reached
    isLevelBlockerExpired(level) {
      if (!level.start_at) {
        return true;
      }

      const levelStart = new Date(level.start_at);
      const now = this.getCurrentDate();

      return this.isDateInPast(levelStart, now);
    },

    //scroll reward container to x position
    scroll(x) {
      if (this.doScroll) {
        return;
      }
      this.doScroll = true;
      const container = this.$refs["rewards-container"];
      container.scrollBy({ left: x, behavior: "smooth" });

      setTimeout(() => {
        this.doScroll = false;
      }, Math.abs(x) / 2);
    },

    //scroll to current task panel position
    scrollToCurrentPosition() {
      const rewardPanel = document.getElementById(
        "reward-panel" + this.currentLevel
      );
      if (!rewardPanel) {
        return;
      }

      const rewardPos = rewardPanel.offsetLeft;
      const container = this.$refs["rewards-container"];
      container.scroll(rewardPos - 330, 0);
      clearInterval(this.intervalId);
    },

    async fetchKronenPassRewards() {
      return await EventAPI.getKronenpassRewardsById(this.kingsPassData.id);
    },
    async fetchKronenPassUserData() {
      return await EventAPI.getKronenpassUserById(this.kingsPassData.id);
    },
    async fetchKronenPassTiers() {
      return await EventAPI.getKronenpassAllTiers(this.kingsPassData.id);
    },

    onOpen() {
      this.loading = true;

      //get kingspath data
      EventAPI.getKronenpassActive()
        .then((res) => {
          const passes = res.passes;
          this.kingsPassData = passes.filter((pass) =>
            pass.title.includes("Winter Event")
          )[0];

          //get other data
          let rewardsDataRaw;
          Promise.all([
            this.fetchKronenPassRewards(),
            this.fetchKronenPassUserData(),
            this.fetchKronenPassTiers(),
          ])
            .then((values) => {
              rewardsDataRaw = values[0].rewards;
              this.userData = values[1].passes[0];

              let level = 0;
              if (values[1].passes.length) {
                level = values[1].passes[0].level.level;
              }
              this.$store.commit("events/setCurrentLevelIndex", level);
              this.isKingspassActive = !!values[2].tiers.length;
            })
            .catch((e) => {
              console.log(e);
            })
            .finally(() => {
              this.levelRewards = Object.values(rewardsDataRaw);
              this.loading = false;

              this.intervalId = setInterval(() => {
                this.scrollToCurrentPosition();
              }, 100);
            });
        })
        .catch((e) => {
          console.log(e);
        });
    },

    onCloseCustom() {
      this.onClose();
      this.loading = true;
    },

    //check which reward panel has to be shown
    getRewardType(index) {
      if (index === 24 || index === 25 || index === 26) {
        return "xmas";
      }
      if (index === 31) {
        return "special";
      }

      return "normal";
    },

    startRandomSlot() {
      if (this.inSlotView) {
        return;
      }

      let randomGameId;
      fetchRandomGame()
        .then((res) => {
          randomGameId = res.data.data.game.id;
        })
        .catch((e) => {
          // use frontend random functionality as fallback
          const games = this.$store.getters["gameplay/playableGames"];
          const maxNum = games.length;
          let index = Math.floor(Math.random() * (maxNum + 1));
          randomGameId = games[index].id;
          console.log(e);
        })
        .finally(() => {
          this.$bvModal.hide(this.modalId);
          this.$router.push({ name: "game", params: { gameId: randomGameId } });
        });
    },
  },
};
</script>
<style lang="scss">
$modal-width: 1121px;
$modal-height: 715px;

.modal-winter-bonus-jagd {
  .modal-dialog {
    max-width: $modal-width;
    width: 100%;
    height: $modal-height;
    padding-top: 50px;

    @media (max-height: 820px), (max-width: 1250px) {
      transform: scale(0.9) !important;
      top: -50px;
    }
    @media (max-height: 730px), (max-width: 1100px) {
      transform: scale(0.8) !important;
      top: -130px;
    }

    @media (max-width: 1250px) {
      left: -100px;
    }
  }

  .modal-header {
    margin-top: 0;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    border: none !important;

    .close-button {
      width: 36px;
      height: 36px;
      right: 34px;
      top: 64px;
      z-index: 999;
      background: url(~@/assets/img/events/winter-bonus-jagd/btn-close.png)
        no-repeat;
      background-size: 100% 100%;
    }

    p {
      z-index: 1;
    }
  }

  .modal-content {
    width: 100%;
    height: 100%;
    background: none;
    color: white;
    text-align: center;
    z-index: 0;
    border: none;
    border-radius: 0;
  }

  .modal-body {
    width: $modal-width;
    height: $modal-height;
    margin-top: 3%;
  }
}
</style>

<style scoped lang="scss">
.modal-winter-bonus-jagd {
  .preload {
    height: 0;
    width: 0;
    background-image: url(~@/assets/img/events/winter-bonus-jagd/btn-help-down.png),
      url(~@/assets/img/events/winter-bonus-jagd/btn-unlock-level-down.png),
      url(~@/assets/img/events/winter-bonus-jagd/btn-kingspass-down.png);
  }

  * {
    font-family: kanit-semibold;
    text-transform: uppercase;
  }

  .img-center {
    margin-left: auto;
    margin-right: auto;
  }

  .pointer {
    cursor: pointer;
  }

  .ticket-icon {
    background: url(~@/assets/img/events/winter-bonus-jagd/icn-reward-ticket.svg)
      center/100% 100% no-repeat;
    width: 47px;
    height: 47px;
    filter: drop-shadow(0 0 7px rgba(0, 0, 0, 0.5))
      drop-shadow(0 0 3px rgba(0, 0, 0, 0.5));
  }

  .check-icon {
    position: absolute;
    width: 48px;
    height: 48px;
    background: url(~@/assets/img/events/winter-bonus-jagd/green-checkmark-circle.png)
      right/100% 100% no-repeat;
  }

  .lock-icon {
    position: absolute;
    width: 35px;
    height: 47px;
    background: url(~@/assets/img/events/winter-bonus-jagd/gold-lock.png)
      right/100% 100% no-repeat;
  }

  .bg {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;

    &.main {
      z-index: 1;
      background: url(~@/assets/img/events/winter-bonus-jagd/main-back.png)
        center/100% 100% no-repeat;
    }

    &.snow-left {
      z-index: 6;
      background: url(~@/assets/img/events/winter-bonus-jagd/snow-left.png)
        bottom left/538px 585px no-repeat;
    }

    &.snow-right {
      z-index: 12;
      background: url(~@/assets/img/events/winter-bonus-jagd/snow-front-right.png)
        bottom right/335px 402px no-repeat;
    }

    &.snow-front {
      z-index: 15;
      background: url(~@/assets/img/events/winter-bonus-jagd/snow-front.png)
        bottom center/1121px 225px no-repeat;
    }

    &.border1 {
      width: 1139px;
      height: 100%;
      background: url(~@/assets/img/events/winter-bonus-jagd/wbj-snow-cap-horizontal.png)
        top/100% 99px no-repeat;
      opacity: 1;
      z-index: 5100;
      left: -10px;

      &.top {
        top: -20px;
      }
      &.bottom {
        top: 20px;
        transform: rotateX(180deg);
      }
    }

    &.border2 {
      width: 100%;
      height: 631px;
      background: url(~@/assets/img/events/winter-bonus-jagd/wbj-snow-cap-side.png)
        right/28px 100% no-repeat;
      opacity: 1;
      z-index: 5000;

      &.left {
        left: -15px;
        transform: rotateY(180deg);
      }
      &.right {
        left: auto;
        right: -15px;
      }
    }
  }

  .logo {
    position: absolute;
    left: 34px;
    top: 25px;
    z-index: 30;

    .main {
      width: 390px;
      height: 169px;
      position: absolute;
      left: 11px;
      top: 0;
      z-index: 20;
      background: url(~@/assets/img/events/winter-bonus-jagd/wbj-logo-main.png)
        right/100% 100% no-repeat;
    }

    .bonus {
      width: 436px;
      height: 154px;
      position: absolute;
      left: 0;
      top: 108px;
      z-index: 21;

      &.en {
        background: url(~@/assets/img/events/winter-bonus-jagd/logotype-en.png)
          right/100% 100% no-repeat;
      }

      &.de {
        background: url(~@/assets/img/events/winter-bonus-jagd/logotype-de.png)
          right/100% 100% no-repeat;
      }
    }
  }

  .signs {
    width: 253px;
    height: 344px;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 10;
    background: url(~@/assets/img/events/winter-bonus-jagd/signs.png) left/100%
      100% no-repeat;
    pointer-events: none;

    .text1 {
      color: #613307;
      font-size: 20px;
      text-shadow: 0 0 6px #fffbd2;
      position: absolute;
      top: 49px;
      left: 44px;
      width: 147px;

      .check-icon {
        top: 16px;
        right: -24px;
      }
      .lock-icon {
        top: 7px;
        right: -14px;
      }
    }

    .text2 {
      color: #2c1500;
      font-size: 20px;
      text-shadow: 0 0 6px #c8745e;
      position: absolute;
      top: 169px;
      left: 44px;
      width: 147px;
    }
  }

  .task-panel-container {
    position: absolute;
    top: 28px;
    right: 100px;
    z-index: 20;
    width: 513px;
    height: 173px;
    background: url(~@/assets/img/events/winter-bonus-jagd/task-panel.png)
      left/100% 100% no-repeat;

    .task-text {
      position: absolute;
      left: 32px;
      top: 30px;
      width: 300px;
      cursor: pointer;

      .text1 {
        font-size: 18px;
      }

      .text2 {
        font-size: 26px;
        line-height: 1.1;
        margin-top: 2px;
      }
    }

    .ticket-icon {
      top: 28px;
      right: 112px;
    }

    .ticket-counter {
      font-size: 26px;
      top: 31px;
      right: 54px;
      width: 49px;
    }

    .king-pass-button {
      width: 385px;
      height: 50px;
      top: 136px;
      padding-left: 20px;
      padding-right: 10px;
      background: linear-gradient(
          to bottom,
          #ffffff,
          #48c919 20%,
          #2dac00 47%,
          #0a2700
        ),
        linear-gradient(to bottom, #8b5a00, #f7ffff 41%, #ffee51 75%, #8b5a00);
      border: double 2px transparent;
      background-origin: border-box;
      background-clip: padding-box, border-box;
      border-radius: 25.2px;

      &.active {
        &:hover {
          background: linear-gradient(
              to bottom,
              #0a2700,
              #2dac00 53%,
              #48c919 80%,
              #ffffff
            ),
            linear-gradient(
              to bottom,
              #8b5a00,
              #f7ffff 41%,
              #ffee51 75%,
              #8b5a00
            );

          border: double 2px transparent;
          background-origin: border-box;
          background-clip: padding-box, border-box;
          border-radius: 25.2px;
        }
      }

      .text1 {
        font-size: 20px;
        text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
      }

      .text2 {
        font-size: 25px;
        text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
        padding-left: 15px;
        padding-right: 40px;
        position: relative;
        text-align: right;
        width: 100px;

        .crown-icon {
          position: absolute;
          right: -28px;
          top: -4px;
          width: 52px;
          height: 42px;
          background: url(~@/assets/img/common/crown7.png) right/100% 100%
            no-repeat;
        }
      }

      .check-icon {
        right: 10px;
        top: 0;
      }
    }

    .help-button {
      position: absolute;
      right: -8px;
      top: -5px;
      z-index: 20;
      background: url(~@/assets/img/events/winter-bonus-jagd/btn-help.png)
        center/100% 100% no-repeat;
      height: 50px;
      width: 50px;
      cursor: pointer;

      &:hover {
        background: url(~@/assets/img/events/winter-bonus-jagd/btn-help-down.png)
          center/100% 100% no-repeat;
      }
    }
  }

  .timer {
    position: absolute;
    top: 87px;
    right: 30px;
    text-align: center;
    z-index: 20;
    line-height: 1.4;
    color: $light-blue;
    font-size: 17.6px;
    width: 140px;
    height: 25px;
    .icon-hourglass {
      width: 19px;
      height: 25px;
      background-image: url(~@/assets/img/common/icn-hourglas.svg);
    }
  }

  .rewards-container {
    width: 100%;
    height: 405px;
    bottom: 0;
    z-index: 8;
    overflow-x: scroll;
    overflow-y: hidden;

    .rewards-content {
      width: auto;
      display: inline-flex;
      padding-left: 150px;
      padding-right: 150px;

      .lock-panel {
        position: relative;
        width: 193px;
        height: 403px;
        display: inline-grid;
        margin-top: 35px;
        margin-left: 30px;

        .bg {
          width: 195px;
          height: 379px;
          background: url(~@/assets/img/events/winter-bonus-jagd/info-panel-level-lock.png)
            left/100% 100% no-repeat;
        }

        .text1 {
          top: 110px;
          font-size: 24px;
        }

        .text2 {
          top: 140px;
          font-size: 32px;
        }

        .unlock-button {
          top: 200px;
          width: 143px;
          height: 95px;
          background: url(~@/assets/img/events/winter-bonus-jagd/btn-unlock-level.png)
            left/100% 100% no-repeat;
          z-index: 2;
          cursor: pointer;

          &.disabled {
            background-image: linear-gradient(#000000, #ffffff);
            border: 1px solid #404040;
            pointer-events: none;
            box-shadow: none;
          }

          &:hover {
            background: url(~@/assets/img/events/winter-bonus-jagd/btn-unlock-level-down.png)
              left/100% 100% no-repeat;
          }

          .button-text1 {
            top: 10px;
            font-size: 18px;
            width: 100%;
            line-height: 1.1;
          }

          .button-text2 {
            top: 50px;
            font-size: 24px;
            padding-right: 40px;

            .crown-icon {
              position: absolute;
              right: -40px;
              top: 0;
              width: 40px;
              height: 34px;
              background: url(~@/assets/img/common/crown6.png) right/100% 100%
                no-repeat;
            }
          }
        }
      }

      .reward {
        position: relative;
        width: 360px;
        height: 405px;
        display: inline-grid;

        .bg {
          width: 404px;
          height: 405px;

          &.normal {
            background: url(~@/assets/img/events/winter-bonus-jagd/rewards-with-holder-big.png)
              left/100% 100% no-repeat;
          }

          &.xmas {
            background: url(~@/assets/img/events/winter-bonus-jagd/rewards-xmas-with-holder-big.png)
              left/100% 100% no-repeat;
          }

          &.special {
            background: url(~@/assets/img/events/winter-bonus-jagd/rewards-nye-with-holder-big.png)
              left/100% 100% no-repeat;
          }
        }

        .frozen {
          width: 345px;
          height: 291px;
          background: url(~@/assets/img/events/winter-bonus-jagd/rewards-big-locked-overlay.png)
            left/100% 100% no-repeat;
          z-index: 21;
          top: 95px;
          left: 30px;
        }

        .number {
          font-size: 26px;
          top: 14px;
          left: 40px;
          text-shadow: 0 0 3px rgba(31, 92, 135, 0.3);
        }

        .progress-container {
          width: 140px;
          height: 31px;
          top: 70px;
          left: 35px;

          .progress-frame {
            top: 0;
            width: 103px;
            height: 23px;
            background: url(~@/assets/img/events/winter-bonus-jagd/progress-top-frame.png)
              left/100% 100% no-repeat;
            position: absolute;
            left: 0;
            z-index: 5;
          }

          .progress-bar {
            //width: 103px;
            width: 95px;
            height: 23px;
            overflow: hidden;
            outline: 10px solid transparent;
            border-radius: 16px;
            border: double 2px transparent;
            background: url(~@/assets/img/events/winter-bonus-jagd/progress-bottom-bg.png)
              left/100% 100% no-repeat;

            .progress-fill {
              height: 100%;
              max-width: 100%;
              border-top-left-radius: 16px;
              border-bottom-left-radius: 16px;
              background-color: #43ff00;
            }
          }

          .progress-percent-badge {
            position: absolute;
            right: 0;
            top: -5px;
            z-index: 6;
            padding: 3px 0 0 7px;
            width: 54px;
            height: 34px;
            background: url(~@/assets/img/events/winter-bonus-jagd/progress-bg-percent.png)
              left/100% 100% no-repeat;
          }
        }

        .collect-button {
          font-size: 18px;
          width: 151px;
          height: 37px;
          top: 66px;
          left: 32px;
          text-align: center;
          padding-top: 2px;
          background: linear-gradient(
              to bottom,
              #ffffff,
              #48c919 20%,
              #2dac00 47%,
              #0a2700
            ),
            linear-gradient(
              to bottom,
              #8b5a00,
              #f7ffff 41%,
              #ffee51 75%,
              #8b5a00
            );
          border: double 2px transparent;
          background-origin: border-box;
          background-clip: padding-box, border-box;
          border-radius: 25.2px;
        }
      }
    }
  }

  .scroll-button-container {
    width: 94%;
    bottom: 100px;
    z-index: 20;

    .button-left {
      position: absolute;
      left: 0;
      width: 58px;
      height: 58px;
      background: url(~@/assets/img/events/winter-bonus-jagd/btn-scroll.png)
        left/100% 100% no-repeat;
    }

    .button-right {
      position: absolute;
      right: 0;
      width: 58px;
      height: 58px;
      background: url(~@/assets/img/events/winter-bonus-jagd/btn-scroll.png)
        left/100% 100% no-repeat;
      transform: scale3d(-1, 1, 1);
    }
  }

  .reward-kingspass {
    top: 130px;
    left: 40px;
    width: 300px;

    .complete-img {
      right: -15px;
      top: 55px;
    }

    .lock-icon {
      right: 4px;
      top: 52px;
    }
  }

  .reward-normal {
    top: 260px;
    left: 46px;
    width: 200px;

    .complete-img {
      right: -20px;
      top: 50px;
    }

    .reward-col {
      position: absolute;
      width: 100px;

      &.reward1 {
        top: -5px;
        left: 110px;
      }

      &.reward2 {
        top: -5px;
        left: 190px;
      }

      .icon-text {
        font-size: 20px;
        text-shadow: 0 0 3px rgba(31, 92, 135, 0.3);
        margin-top: 5px;
        text-transform: none;
        top: 40px;
      }
    }
  }
}
</style>
