import { optionsFunction } from "./_config";
const axios = require("axios");

export const getConfig = () => axios.get(`api/config`, optionsFunction());

export const getConfigWithLocale = (localeQuery) =>
  axios.get(`api/config?locale=${localeQuery}`, optionsFunction());

export const flushCache = () => {
  axios.post("api/tests/reset?cache=1", {}, optionsFunction());
};
