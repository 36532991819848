<template>
  <div id="app-content">
    <AlertBanner />
    <header>
      <TheHeader v-if="showHeader" />
    </header>
    <hero-component v-if="showHeroComponent"></hero-component>

    <aside class="left"><MenuPage v-if="showMenu" /></aside>
    <main :class="mainClass">
      <router-view
        v-if="!$store.getters['auth/isLoggedIn'] && $route.path === '/'"
        name="login"
        :class="isPageLoading ? 'invisible' : ''"
      />
      <router-view
        v-else
        :class="isPageLoading && $route.path !== '/' ? 'invisible' : ''"
      ></router-view>
      <Spinner
        v-if="isPageLoading && $route.path !== '/'"
        size="medium"
        context="Loading Map..."
        classes="dead-center"
      ></Spinner>
    </main>
    <aside class="right"><MenuEvent v-if="showEventsBox" /></aside>
  </div>
</template>
<script>
import Spinner from "@/components/animation/animation-spinner";
import AlertBanner from "@/components/base/alert-banner.vue";
import TheHeader from "@/components/the-header.vue";
import MenuPage from "@/components/lobby/menu-page.vue";
import MenuEvent from "@/components/lobby/menu-event.vue";
import HeroComponent from "@/components/hero-component.vue";
import checkLocale from "@/mixins/checkLocale.js";

export default {
  name: "MainContent",
  metaInfo() {
    return {
      title: "Kleine Krone - Dein Social Online Casino",
      meta: [
        {
          property: "og:title",
          content: "Kleine Krone - Dein Social Online Casino",
        },
        {
          name: "twitter:title",
          content: "Kleine Krone - Dein Social Online Casino",
        },
      ],
      link: [
        {
          vmid: "link-canonical",
          rel: "canonical",
          href: "https://www.kleinekrone.de",
        },
      ],
    };
  },
  components: {
    Spinner,
    AlertBanner,
    TheHeader,
    MenuPage,
    MenuEvent,
    HeroComponent,
  },
  mixins: [checkLocale],
  computed: {
    showEventsBox() {
      return (
        this.$store.state.gameplay.showEventBox &&
        this.eventsUnlocked &&
        this.$store.getters["auth/isLoggedIn"]
      );
    },

    isPopupRealWinner() {
      return this.$store.state.popups.activeKingspassRealWinnersPopupNew;
    },

    showHeader() {
      return (
        this.$route.path !== "/maintenance" &&
        this.$store.getters["auth/isLoggedIn"] &&
        !this.isManagementPage
      );
    },
    showHeroComponent() {
      return (
        this.$store.getters["auth/isLoggedIn"] &&
        !this.isGameView &&
        (this.$route.name === "home" ||
          this.$route.name === "home2" ||
          this.$route.path.startsWith("/games") ||
          this.$route.path.startsWith("/cards") ||
          this.$route.path.startsWith("/tournament") ||
          this.$route.path.startsWith("/teams") ||
          this.$route.path.startsWith("/league") ||
          this.$route.path.startsWith("/champions") ||
          this.$route.path.startsWith("/profile"))
      );
    },
    showMenu() {
      return (
        this.$route.path !== "/maintenance" &&
        this.$store.getters["auth/isLoggedIn"] &&
        !this.isManagementPage
      );
    },

    isManagementPage() {
      return (
        this.$route.matched[0] && this.$route.matched[0].path === "/management"
      );
    },
    isGameView() {
      return (
        this.$route.name === "game" ||
        this.$route.name === "kingspath-game" ||
        this.$route.name === "champions-game" ||
        this.$route.name === "tournament-game"
      );
    },

    isPageLoading: function () {
      return this.$store.getters.getIsPageLoading;
    },
    mainClass() {
      if (!this.showHeader || this.isGameView) {
        return "";
      }
      return "site-space";
    },
    alertBanners() {
      return this.$store.state.popups.alertBannerQueue;
    },
  },
  watch: {
    alertBanners() {
      if (this.alertBanners.length === 1) {
        this.$store.commit("popups/showFirstAlertBanner");
      } else if (this.alertBanners.length > 1) {
        this.$store.commit("popups/checkForDuplicateAlerts");
        this.$store.commit("popups/showFirstAlertBanner");
      }
    },
  },
  created() {
    if (this.$route.query.locale) {
      if (
        this.$route.query.locale === "de" ||
        this.$route.query.locale === "en"
      ) {
        this.$store.dispatch("setLocale", this.$route.query.locale);
      }
    } else {
      this.checkLocale();
    }
  },
};
</script>
