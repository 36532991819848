<template>
  <div
    class="event-item-container position-fixed d-flex flex-column align-items-start"
  >
    <div class="newscenter-item">
      <div class="item-content clickable" @click="showNewsModal">
        <div class="newscenter-icon"></div>
      </div>
      <div v-if="isNewMessages" class="new-message-icon"></div>
    </div>

    <div v-for="event in eventsInBox" :key="event.id" class="event-item">
      <div class="item-content clickable">
        <div
          v-if="(eventsInBox && eventsInBox.length > 0) || isPopupRealWinner"
          class="bg-img"
        >
          <event-badge-light :event-data="event"> </event-badge-light>

          <event-badge-light
            v-if="isPopupRealWinner"
            :winner-data="[]"
            :is-custom-badge="true"
          >
          </event-badge-light>
          <template v-if="$store.getters['events/getFootballEventData']">
            <ModalBundesligaPackNote />
          </template>
        </div>
      </div>
      <div v-if="isNew" class="new-icon">!</div>
    </div>
  </div>
</template>
<script>
import ModalBundesligaPackNote from "@/components/events/bundesliga/modal-bundesliga-pack-note";

import EventBadgeLight from "@/components/lobby/event-badge-light.vue";
export default {
  name: "MenuEvent",
  components: {
    EventBadgeLight,
    ModalBundesligaPackNote,
  },
  data() {
    return {
      intervalId: null,
    };
  },

  computed: {
    eventsInBox() {
      return this.$store.getters["events/shownEventBadges"];
    },
    isPopupRealWinner() {
      return this.$store.state.popups.activeKingspassRealWinnersPopupNew;
    },
    //TODO: get data
    isNew() {
      return false;
    },

    isNewMessages() {
      return this.$store.getters["news/unreadMessagesAvailable"];
    },
  },
  created() {
    this.intervalId = setInterval(() => {
      this.checkIfBadgesShouldBeDisplayed();
    }, 3600000);
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },

  methods: {
    checkIfBadgesShouldBeDisplayed() {
      let currentTime = new Date().getTime();
      const specialEvents = this.$store.state.events.specialEvents;
      const regularEvents = this.$store.state.events.regularEvents;
      let invisibleSpecialEvents = specialEvents.findIndex((event) => {
        let hiddenAt = new Date(event.hidden_at).getTime();
        return hiddenAt < currentTime;
      });
      let finishedRegularEvents = regularEvents.findIndex((event) => {
        let endAt = new Date(event.end_at).getTime();
        return endAt < currentTime;
      });
      if (invisibleSpecialEvents >= 0 || finishedRegularEvents >= 0) {
        this.$store.dispatch("events/getEvents");
      }
    },

    showNewsModal: function () {
      this.$bvModal.show("modal-news-center");
    },
  },
};
</script>

<style scoped lang="scss">
.event-item-container {
  z-index: 100;
  right: 0.638rem;
  top: 90px;

  .event-item {
    position: relative;
    display: flex;
    height: 4.25rem;

    .item-content {
      background-color: #050d1c;
      border-radius: 0.75rem;
      border: 0.125rem solid rgba(77, 229, 255, 0.9);
      box-shadow: inset 0 0 0.25rem $light-blue;
      width: 3.75rem;
      height: 3.75rem;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;

      .bg-img {
        transform: scale(0.7);
        padding-top: 0.313rem;
      }
    }

    .new-icon {
      position: absolute;
      top: 0.375rem;
      right: -0.625rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 0.125rem;
      background: linear-gradient(to bottom, #ff0000, #3a0000);
      height: 1.5rem;
      width: 1.5rem;
      color: white;
      font-size: 1.313rem;
      font-family: Ubuntu-bold;
      border-radius: 50%;
    }
  }

  .newscenter-item {
    position: relative;
    display: flex;
    height: 4.25rem;

    .item-content {
      background-color: #050d1c;
      border-radius: 0.75rem;
      border: 0.125rem solid rgba(77, 229, 255, 0.9);
      box-shadow: inset 0 0 0.25rem $light-blue;
      width: 3.75rem;
      height: 3.75rem;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;

      .newscenter-icon {
        width: 100%;
        height: 100%;
        background: url(~@/assets/img/notification/icn-nc-lobby.png) center
          center/4.8rem 4.8rem no-repeat;
      }
    }

    .new-message-icon {
      position: absolute;
      width: 2.5rem;
      height: 2.5rem;
      top: 0rem;
      left: -1.2rem;
      background: url(~@/assets/img/notification/icn-nc-lobby-indicator.png)
        bottom center/100% 100% no-repeat;
    }
  }
}
</style>
