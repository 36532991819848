<template>
  <div
    class="page-item-container position-fixed d-flex flex-column align-items-end"
  >
    <div
      v-for="item in menuItems"
      :key="item.bannerText"
      class="page-item"
      :name="'page-item-' + item.bannerText"
      :class="item.active && currentUserLevel >= item.level ? '' : 'disabled'"
      @click="firebaseClickItemMenu(item.firebaseEvent)"
    >
      <!--        mission item-->
      <template v-if="item.bannerText === 'footer.mission_text'">
        <item-menu-vanilla-mission />
      </template>

      <!--        bonus slot item-->
      <template v-else-if="item.bannerText === 'bonus_slot.title'">
        <item-menu-bonus-slot />
      </template>

      <!--        treasury item-->
      <template v-else-if="item.bannerText === 'footer.vault'">
        <item-menu-treasury />
      </template>

      <!--        other items-->
      <template v-else>
        <router-link
          :to="item.path"
          class="link-container clickable position-relative"
          :event="!pageLocked(item) ? 'click' : ''"
          @click.native="playSoundTab"
        >
          <div :class="item.isNew && pulsActiveChampions ? 'pulsating' : ''">
            <div
              class="item-content"
              @click="item.modal && openModal(item.modal)"
            >
              <img
                draggable="false"
                class="icon"
                :src="require('@/assets/img/nav-menu/' + item.img + '.png')"
              />
            </div>
            <div v-if="item.showBadge" class="news-badge">!</div>
            <div
              v-if="item.isNew"
              class="new-flag"
              :class="$store.state.locale"
            ></div>
          </div>
          <div class="banner-text">{{ $t(item.bannerText) }}</div>
        </router-link>

        <!--            lock-->
        <div
          v-if="pageLocked(item)"
          class="page-item-lock to-center-abs w-100 h-100"
        >
          <div class="grey-overlay"></div>

          <div class="icon-unlock-level to-center-abs">
            <!--            {{ $t("lobby.game.locked", { 0: item.level }) }}-->
            Level {{ item.level }}
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import ItemMenuVanillaMission from "@/components/lobby/item-menu-vanilla-mission";
import ItemMenuBonusSlot from "@/components/lobby/item-menu-bonus-slot";
import ItemMenuTreasury from "@/components/lobby/item-menu-treasury";

export default {
  name: "MenuPage",
  components: {
    ItemMenuTreasury,
    ItemMenuBonusSlot,
    ItemMenuVanillaMission,
  },
  data() {
    return {};
  },

  computed: {
    menuItems: function () {
      return [
        {
          bannerText: "lobby.navigation.trading_cards",
          img: "nav-icon-cards",
          path: "/cards",
          style: {},
          active: true,
          showBadge: false,
          level: this.entryLevelCards,
          firebaseEvent: "menu_trading_cards_button",
        },
        {
          bannerText: "lobby.navigation.kingspath",
          img: "nav-icon-kingspath",
          path: "/kingspath",
          showBadge: false,
          style: {},
          active: true,
          level: this.entryLevelKingspath,
          firebaseEvent: "menu_kingspath_button",
        },
        {
          bannerText: "footer.mission_text",
          img: "nav-icon-mission",
          path: "",
          modal: "modal-vanilla-mission",
          showBadge: false,
          style: {},
          active: true,
          level: 0,
          firebaseEvent: "footer_mission_button",
        },
        {
          bannerText: "lobby.navigation.friends",
          img: "nav-icon-friends",
          path: "/teams/friends",
          active: true,
          level: this.entryLevelTeams,
          firebaseEvent: "menu_friends_button",
          showBadge:
            this.$store.state.user.openFriendRequests.length > 0 ||
            this.$store.state.chat.unreadMessages > 0,
        },
        {
          bannerText: "lobby.navigation.user_league",
          img: "nav-icon-league",
          path: "/league",
          showBadge: false,
          active: true,
          level: this.entryLevelLeague,
          firebaseEvent: "menu_user_league_button",
        },

        {
          bannerText: "lobby.navigation.tournaments",
          img: "nav-icon-tournaments",
          path: "/tournament",
          showBadge: false,

          active: true,
          level: this.entryLevelTourney,
          firebaseEvent: "menu_tournaments_button",
        },
        {
          bannerText: "bonus_slot.title",
          img: "nav-icon-bonusslot",
          path: "#",
          modal: "modal-bonus-slot",
          showBadge: false,

          style: {},
          active: true,
          level: 0,
          firebaseEvent: "footer_bonus_slot_button",
        },
        {
          bannerText: "footer.vault",
          img: "nav-icon-mission",
          path: "",
          modal: "modal-treasury",
          showBadge: false,
          style: {},
          active: true,
          level: 0,
          firebaseEvent: "footer_treasury_button",
        },
        {
          bannerText: "champions.title",
          img: "nav-icon-champions",
          path: "/champions",
          showBadge: this.$store.state.champions.newHistoryEntries.length > 0,
          style: {},
          active: true,
          level: 0,
          firebaseEvent: "menu_champions_button",
          isNew: false,
        },
      ];
    },
    currentUserLevel: function () {
      return this.$store.getters["user/currentUser"].level;
    },
    generalConfig: function () {
      return this.$store.getters["getGeneralConfig"];
    },

    entryLevelCards: function () {
      return 1;
    },
    entryLevelKingspath: function () {
      if (!this.generalConfig) {
        return 1;
      }

      const entryLvl = this.generalConfig.settings["kingspath.entry.level"];
      if (!entryLvl) {
        return 1;
      }

      return entryLvl;
    },
    entryLevelTeams: function () {
      if (!this.generalConfig) {
        return 1;
      }

      const entryLvl = this.generalConfig.settings["team.join.level"];
      if (!entryLvl) {
        return 1;
      }

      return entryLvl;
    },
    entryLevelLeague: function () {
      return 1;
    },
    entryLevelTourney: function () {
      if (!this.generalConfig) {
        return 1;
      }

      const entryLvl = this.generalConfig.settings["tournament.entry.level"];
      if (!entryLvl) {
        return 1;
      }

      return entryLvl;
    },
    entryLevelAchieve: function () {
      return 1;
    },
    entryLevelLoyal: function () {
      return 1;
    },
    currentPath() {
      return this.$route.path;
    },

    pulsActiveChampions() {
      return this.$store.getters["champions/getPulsatingMenuItem"];
    },
  },
  watch: {
    // display green notification badge on menu item
    // if user hasn't checked out their new friend yet
    currentPath() {
      if (
        this.$store.state.user.showGreenBadgeOnTab &&
        !this.$route.path.startsWith("/friends")
      )
        this.$store.commit("user/setGreenNotificationBadge", {
          location: "friendsMenu",
          display: true,
        });
    },
  },

  methods: {
    openModal(modalId) {
      this.$bvModal.show(modalId);
    },

    pageLocked(item) {
      return (
        this.currentUserLevel < item.level &&
        (item.path === "/teams" ||
          item.path === "/kingspath" ||
          item.path === "/tournament")
      );
    },
  },
};
</script>

<style scoped lang="scss">
.page-item-container {
  z-index: 100;
  left: 0.638rem;
  top: 90px;

  .page-item {
    position: relative;
    display: flex;
    height: 4.25rem;
    width: 3.75rem;

    .item-content {
      background-color: #050d1c;
      border-radius: 0.75rem;
      border: 0.125rem solid rgba(77, 229, 255, 0.9);
      box-shadow: inset 0 0 0.25rem $light-blue;
      width: 3.75rem;
      height: 3.75rem;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;

      .icon {
        height: 2.813rem;
      }
    }

    .banner-text {
      display: none;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 1.25rem;
      left: 5.063rem;
      min-width: 11rem;
      width: 100%;
      height: 2rem;
      padding-left: 1rem;
      padding-right: 1rem;
      background-color: rgba(5, 13, 28, 0.8);
      border: 0.063rem solid rgba(77, 229, 255, 0.5);
      border-radius: 0.25rem;
      box-shadow: inset 0 0 0.125rem $light-blue;
      color: white;
      font-size: 0.875rem;
      font-family: Ubuntu-Medium;
      text-transform: uppercase;
      z-index: 999;
      line-height: 2rem;
    }

    .special-container {
      .item-content {
        width: 19.25rem;
      }
    }

    .page-item-lock {
      cursor: default;

      .grey-overlay {
        width: 100%;
        height: 89%;
        position: absolute;
        left: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 0.5rem;
      }

      .icon-unlock-level {
        left: -1.9rem;
        top: -1.9rem;
        width: 7.5rem;
        height: 7.5rem;
        background: url(~@/assets/img/lobby/icn-locked-slot.png) center/100%
          100% no-repeat;
        color: white;
        font-size: 0.7rem;
        padding-top: 4.2rem;
        font-family: Ubuntu-Medium;
        text-align: center;
        z-index: 10;
        text-transform: uppercase;
      }

      .page-item-lock-text {
        color: white;
        font-size: 0.8rem;
        top: 0.5rem;
        padding: 0 0.4rem;
        line-height: 1.1;
      }
    }

    .new-flag {
      position: absolute;
      top: 0;
      right: 0;
      width: 2.813rem;
      height: 3.75rem;
      z-index: 1;
      pointer-events: none;

      &.de {
        background: url(~@/assets/img/lobby/icn-flag-new-de.png) center/100%
          100% no-repeat;
      }
      &.en {
        background: url(~@/assets/img/lobby/icn-flag-new-en.png) center/100%
          100% no-repeat;
      }
    }
  }
}
</style>

<style lang="scss">
.page-item-container {
  .page-item {
    &:hover {
      .banner-text,
      .expanded-items-container {
        display: flex !important;
      }

      .expandable {
        width: 18rem;
      }
    }
  }
  .pulsating {
    animation-name: pulse;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-play-state: running;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }
  @keyframes pulse {
    0% {
      transform: scale(0.9);
    }

    100% {
      transform: scale(1.1);
    }
  }
}
</style>
