<template>
  <div>
    <b-modal
      :id="modalId"
      ref="modal-bundesliga-event"
      :hide-footer="true"
      modal-class="modal-bundesliga-event"
      @shown="setUpContent"
      @hidden="handleClose"
    >
      <template v-if="!loading" slot="modal-header" slot-scope="{ close }">
        <a href="#" class="close-button" @click="close()"> </a>
      </template>

      <template v-if="!loading" slot="default">
        <!-- BACKGROUNDS AND ANIMATIONS -->

        <AnimationEventBundesligaLogo
          :logo-class="contentComponent.toLowerCase()"
        />
        <div class="bg stadium left"></div>
        <div class="bg stadium right"></div>
        <AnimationEventBundesligaball />
        <div class="bg grass left"></div>
        <div class="bg grass right"></div>
        <div class="bg border-repeat left"></div>
        <div class="bg border-repeat right"></div>
        <div class="bg border-side left"></div>
        <div class="bg border-side right"></div>
        <component
          :is="contentComponent"
          :package-gold="packageGold"
          :package-crowns="packageCrowns"
          :package-loyalty="packageLoyalty"
          :gold-reward="goldReward"
          :offer-packages="offerPackages"
          :user-event="userEvent"
          :event-data="eventData"
          :package-price="packagePrice"
          :old-prices="oldPrices"
          :more-content="moreContent"
          :selected-option="selectedOption"
          :betting-options="bettingOptions"
          :bet-locked="betLocked"
          :purchase-possible="purchasePossible"
          :package-purchased="packagePurchased"
          :bet-spinner="betSpinner"
          @buy-package="handleBuyPackage"
          @lock-bet="handleLockBet"
          @option-selected="selectOption"
          @hide-bundesliga-modal="$bvModal.hide(modalId)"
        ></component>
      </template>
      <Spinner v-if="loading" class="dead-center" />
    </b-modal>
  </div>
</template>

<script>
import { fetchPaymentOffersByOfferId } from "@/API/payment.js";
import { placeSportsBet } from "@/API/events.js";
import eventPopups from "@/mixins/eventPopups.js";
import getRewardsFromPackage from "@/mixins/paymentFunctions.js";
import AnimationFullRewardEarned from "@/components/animation/animation-full-reward-earned";
import Spinner from "@/components/animation/animation-spinner.vue";
import dateFunctions from "@/mixins/dateFunctions.js";
import AnimationEventBundesligaball from "@/components/animation/animation-spine-event-bundesliga-ball.vue";
import AnimationEventBundesligaLogo from "@/components/animation/animation-spine-event-bundesliga-logo.vue";
import ModalBundesligaBet from "@/components/events/bundesliga/modal-bundesliga-bet.vue";
import ModalBundesligaWin from "@/components/events/bundesliga/modal-bundesliga-win.vue";
import ModalBundesligaNoBet from "@/components/events/bundesliga/modal-bundesliga-no-bet.vue";
import ModalBundesligaLose from "@/components/events/bundesliga/modal-bundesliga-lose.vue";
//import BundesligaTeamBadge from "@/components/events/bundesliga/bundesliga-team-badge";

export default {
  name: "ModalBundesligaEvent",
  components: {
    // BundesligaTeamBadge,
    Spinner,
    AnimationEventBundesligaball,
    AnimationEventBundesligaLogo,
    ModalBundesligaBet,
    ModalBundesligaWin,
    ModalBundesligaNoBet,
    ModalBundesligaLose,
  },
  mixins: [
    eventPopups,
    getRewardsFromPackage,
    AnimationFullRewardEarned,
    dateFunctions,
  ],
  props: {
    modalId: {
      type: String,
      default: "modal-bundesliga-event",
    },
  },

  data() {
    return {
      animReady: false,
      offerPackages: [],
      loading: true,
      selectedOption: null,
      betLocked: false,
      betSpinner: false,
      bettingOptions: [
        { option: "A", label: this.$t("events.bundesliga.teams.home") },
        { option: "B", label: this.$t("events.bundesliga.teams.away") },
        { option: "C", label: this.$t("events.bundesliga.dropdown.draw") },
      ],
      oldPrices: [],
      moreContent: [],
    };
  },

  computed: {
    contentComponent() {
      if (
        this.$store.state.events.showFootballResultForEvent &&
        this.$store.state.events.showFootballResultForEvent ===
          this.eventData.id
      ) {
        if (this.$store.getters["events/getUserHasTipped"]) {
          if (this.$store.getters["events/getUserHasTippedCorrectly"]) {
            return "ModalBundesligaWin";
          } else {
            return "ModalBundesligaLose";
          }
        } else {
          return "ModalBundesligaNoBet";
        }
      } else {
        return "ModalBundesligaBet";
      }
    },

    packageItems() {
      if (!this.offerPackages || !this.offerPackages[0]) return null;
      return this.offerPackages[0].package.items;
    },
    packageGold() {
      if (!this.packageItems) return null;
      let goldObject = this.packageItems.find(
        (packageItem) => packageItem.item.type === "gold"
      );
      return this.formatNumber(goldObject.item.amount);
    },
    packageCrowns() {
      if (!this.packageItems) return null;
      let crownObject = this.packageItems.find(
        (packageItem) => packageItem.item.type === "crown"
      );
      return this.formatNumber(crownObject.item.amount);
    },
    packageLoyalty() {
      if (!this.packageItems) return null;
      let loyaltyObject = this.packageItems.find(
        (packageItem) => packageItem.item.type === "loyalty"
      );
      return loyaltyObject.item.amount;
    },
    packagePrice() {
      if (!this.offerPackages || !this.offerPackages[0]) return null;
      return this.formatPrice(this.offerPackages[0].package.price);
    },
    goldReward() {
      return this.formatNumber(
        this.$store.state.generalConfig.settings["raffle.sports.reward.gold"]
      );
    },
    packagePurchased() {
      return (
        this.userEvent &&
        this.userEvent.payload &&
        this.userEvent.payload.packages.length > 0
      );
    },
    purchasePossible() {
      return this.eventData.isActive;
    },
  },

  mounted() {
    if (this.$route.path.startsWith("/tip-king")) {
      this.$bvModal.show(this.modalId);
    }
  },
  created() {
    this.$root.$on("bv::modal::hidden", async (bvEvent, modalId) => {
      if (modalId === "modal-buy-complete") {
        // update UserIngameEvent after successful purchase
        this.$store
          .dispatch("events/updateUserEvent", this.eventData.id)
          .then((res) => (this.userEvent = res));
      }
    });
  },
  methods: {
    setUpContent() {
      Promise.all([this.fetchPackages(), this.onOpen()])
        .then((res) => {
          this.updateUserBet(res[1]);
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.$bvModal.hide("modal-bundesliga-event");
        });
    },
    fetchPackages() {
      return new Promise((resolve, reject) => {
        let packageIdArray = this.eventData.payload.packages;
        packageIdArray.forEach((id) => {
          fetchPaymentOffersByOfferId(id)
            .then((res) => {
              this.offerPackages.push(res.packages[0]);
              this.$store.commit("events/setActiveEventOffer", res);

              if (res.configuration) {
                const conf = JSON.parse(res.configuration);
                this.oldPrices = conf.old_prices;
                this.moreContent = conf.more_content;
              }
            })
            .catch((e) => reject(e));
        });
        resolve();
      });
    },

    handleBuyPackage() {
      if (!this.betLocked) {
        this.$bvModal.show("modal-bundesliga-bet-note");
      } else {
        if (!this.offerPackages || !this.offerPackages[0]) return;
        let packageObj = this.offerPackages[0];
        packageObj.rewards = this.getRewardsFromPackage(packageObj);
        this.$store.commit("shop/setShopChosenPackage", packageObj);
        this.$bvModal.show("modal-payment");
      }
    },
    handleLockBet() {
      this.betSpinner = true;
      let home;
      let away;
      switch (this.selectedOption.option) {
        case "A":
          home = 1;
          away = 0;
          break;
        case "B":
          home = 0;
          away = 1;
          break;
        case "C":
          home = 0;
          away = 0;
          break;
      }
      placeSportsBet(this.eventData.id, home, away)
        .then(() => {
          this.onOpen()
            .then((res) => {
              this.updateUserBet(res);
            })
            .catch((e) => {
              this.betLocked = false;
              console.log(e);
            });
        })
        .catch((e) => {
          this.betLocked = false;
          console.log(e);
        })
        .finally(() => (this.betSpinner = false));
    },

    updateUserBet(betData) {
      if (!betData || !betData.payload) {
        return;
      }
      this.betLocked = true;

      if (betData.payload.home === 1 && betData.payload.away === 0) {
        this.selectedOption = this.bettingOptions[0];
      } else if (betData.payload.away === 1 && betData.payload.home === 0) {
        this.selectedOption = this.bettingOptions[1];
      } else {
        this.selectedOption = this.bettingOptions[2];
      }
    },
    selectOption(option) {
      this.selectedOption = option;
    },

    handleClose() {
      this.onClose();
      if (
        this.contentComponent === "ModalBundesligaBet" &&
        !this.packagePurchased &&
        this.betLocked &&
        this.purchasePossible
      ) {
        this.$bvModal.show("modal-bundesliga-pack-note");
      }
      this.clearContent();
    },
    clearContent() {
      this.offerPackages = [];
      this.loading = true;
    },
  },
};
</script>
<style lang="scss">
$modal-width: 1121px;
$modal-height: 660px;
.modal-bundesliga-event {
  .modal-dialog {
    min-width: $modal-width;
    max-width: $modal-width;
    width: 100%;
    height: $modal-height;
    margin-top: 50px;
    padding-right: 0;

    @media (max-height: 850px), (max-width: 1250px) {
      transform: scale(0.8) !important;
      top: -40px;
      margin-bottom: -20%;
    }
    @media (max-height: 770px), (max-width: 1100px) {
      transform: scale(0.7) !important;
      top: -100px;
      margin-bottom: -30%;
    }
    @media (max-width: 1100px) {
      left: -5% !important;
    }
  }

  .modal-header {
    margin-top: 0;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0;
    width: 100%;
    border: none !important;

    .close-button {
      width: 35px;
      height: 35px;
      opacity: 0.5;
      right: 10px;
    }
  }

  .modal-body {
    margin-top: 0 !important;
    width: 100%;
    height: 100%;
    overflow: visible;
    padding: 0;
    .bg {
      width: 562px;
      position: absolute;
      left: 0;
      bottom: 0;
      right: auto;
      z-index: -1;
      $border-width: 48px;
      pointer-events: none;

      &.right {
        transform: scale3d(-1, 1, 1);
        right: 0;
        left: auto;
      }
      &.stadium {
        height: 630px;
        bottom: calc(#{$border-width} / 2);
        background: linear-gradient(
              to bottom,
              rgba(12, 30, 60, 0),
              rgba(5, 13, 28, 0.55) 37%,
              #050d1c
            )
            center/100% 100% no-repeat,
          url(~@/assets/img/events/bundesliga/bl-main-bg.png) center/100% 100%
            no-repeat;
      }
      &.grass {
        height: 233px;
        background: url(~@/assets/img/events/bundesliga/bl-grass-bg.png) bottom
          center/560px 100% no-repeat;
        z-index: 0;
      }

      &.border-repeat {
        height: 100%;
        left: 49px;
        width: calc(560px - #{$border-width});

        background: url(~@/assets/img/events/bundesliga/frame-repeat.png) 0/2px
          100%;
        z-index: 1;
        &.right {
          left: auto;
          right: $border-width;
        }
      }

      &.border-side {
        height: 100%;
        width: 50px;
        background: url(~@/assets/img/events/bundesliga/frame-side.png) 0/100%
          100% no-repeat;
        z-index: 1;
        &.right {
          width: $border-width;
          right: 1px;
        }
      }
    }
  }

  .modal-content {
    width: 100%;
    height: 100%;
    background: none;
    color: white;
    text-align: center;
    z-index: 0;
    border: none;
    border-radius: 0;
  }
}
</style>
