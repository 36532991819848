<template>
  <div
    id="canvas-container-animation-elixir"
    ref="canvascontainer"
    class="elixir-spine"
  ></div>
</template>

<script>
import AnimationSpineBaseFunctions from "@/components/animation/animation-spine-base-functions";
import { Spine } from "pixi-spine";

export default {
  name: "AnimationSpineElixir",
  mixins: [AnimationSpineBaseFunctions],
  props: {
    loop: {
      type: Boolean,
      default: true,
    },
    run: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      unsubscribeEvents: [],
      version: 1,
      restart: false,
      assetPath: "/assets/spines/bonus-slot/",
      destroyApp: false,
    };
  },

  watch: {
    run: function () {
      this.restart = true;
    },
  },
  beforeDestroy() {
    //destroy event listeners
    for (let i = 0; i < this.unsubscribeEvents.length; i++) {
      this.unsubscribeEvents[i]();
    }

    this.destroyApp = true;
  },
  mounted: function () {
    this.play();
  },

  methods: {
    play: function () {
      const canvasEl = document.getElementById("canvas-animation-elixir");

      const assets = [
        [
          "main",
          this.assetPath + "bonusslot-potion-ani.json?v=" + this.version,
        ],
      ];
      let options = {
        width: 195.5,
        height: 256.55,
        antialias: true,
        backgroundAlpha: 0,
        autoResize: true,
      };

      const app = this.createCanvas(
        options,
        assets,
        this.$refs.canvascontainer.id
      );
      app.view.id = "canvas-animation-elixir";

      app.ticker.add(() => {
        if (this.destroyApp) {
          this.destroyApp = false;
          this.destroyAll(app);
        }
      });

      const setup = (loader, res) => {
        let spineData = this.getSpineData(res);

        spineData.forEach((data) => {
          const spine = new Spine(data);

          // set the position
          spine.x = app.screen.width + data.x;
          spine.y = app.screen.height + data.y;

          //add spine to stage
          app.stage.addChild(spine);

          //set audio
          this.setupSpineAudio(spine);

          //run animation
          let animation;
          let setupAnimation = () => {
            //choose animation
            if (this.run) {
              animation = this.getAnimationByName(data, "potion-main");
            } else {
              animation = this.getAnimationByName(data, "potion-idle");
            }
            spine.state.setAnimation(0, animation.name);

            //set animation end
            spine.state.getCurrent(0).animationEnd = animation.duration;
          };
          setupAnimation();

          // Detach event listeners
          this.unsubscribeEvents.push(() => {
            spine.state.getCurrent(0).onEvent = null;
          });

          //add loop
          if (this.loop) {
            spine.state.getCurrent(0).loop = true;
          }

          //ticker
          app.ticker.add(() => {
            if (this.restart) {
              this.restart = false;
              setupAnimation();
            }
          });
        });
      };

      app.loader.load(setup);
      app.loader.onComplete.add(() => {
        this.$emit("done");
      });

      //remove old chest when new loaded
      app.loader.onComplete.add(() => {
        if (canvasEl) {
          this.removePrevCanvas(this.$refs.canvascontainer.id);
        }
      });
    },
  },
};
</script>

<style lang="scss">
#canvas-container-animation-elixir {
  pointer-events: none;
  position: relative;
  z-index: 1;
}
</style>
