<template>
  <div
    id="canvas-container-animation-event-bundesliga-icon"
    ref="canvascontainer"
    class="bundesliga-icon"
    :class="position"
  ></div>
</template>

<script>
import AnimationSpineBaseFunctions from "@/components/animation/animation-spine-base-functions";
import {Spine} from "pixi-spine";

export default {
  name: "AnimationEventBundesligaIcon",
  mixins: [AnimationSpineBaseFunctions],
  props: {
    loop: {
      type: Boolean,
      default: true,
    },
    run: {
      type: Boolean,
      default: true,
    },
    position: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      unsubscribeEvents: [],
      version: 1,
      destroyApp: false,
    };
  },

  watch: {
    run: function (newVal) {
      if (newVal) {
        this.destroyApp = true;
        this.play();
      }
    },
  },
  beforeDestroy() {
    //destroy event listeners
    for (let i = 0; i < this.unsubscribeEvents.length; i++) {
      this.unsubscribeEvents[i]();
    }

    this.destroyApp = true;
  },
  mounted: function () {
    this.play();
  },

  methods: {
    play: function () {
      const canvasEl = document.getElementById(
        "canvas-animation-event-bundesliga-icon"
      );

      const assets = [
        [
          "main",
          "/assets/spines/events/bundesliga/tipking-icn-goldball.json?v=" +
            this.version,
        ],
      ];
      const options = {
        width: 112,
        height: 112,
        antialias: true,
        backgroundAlpha: 0,
        autoResize: true,
      };

      const app = this.createCanvas(
        options,
        assets,
        this.$refs.canvascontainer.id
      );
      app.view.id = "canvas-animation-event-bundesliga-icon";

      app.ticker.add(() => {
        if (this.destroyApp) {
          this.destroyApp = false;
          this.destroyAll(app);
        }
      });

      const setup = (loader, res) => {
        let spineData = this.getSpineData(res);

        spineData.forEach((data) => {
          const spine = new Spine(data);

          // set the position
          spine.x = app.screen.width + data.x;
          spine.y = app.screen.height + data.y;

          //set scale
          //  spine.scale.set(0.84);

          //add spine to stage
          app.stage.addChild(spine);

          //run animation
          let animation = this.getAnimationByName(data, "main-web");
          spine.state.setAnimation(0, animation.name);

          //show first frame when not running
          if (!this.run) {
            spine.state.getCurrent(0).animationEnd = 0;
            return;
          }

          //set animation end
          spine.state.getCurrent(0).animationEnd = animation.duration;

          // Detach event listeners
          this.unsubscribeEvents.push(() => {
            spine.state.getCurrent(0).onEvent = null;
          });

          //add loop
          if (this.loop) {
            spine.state.getCurrent(0).loop = true;
          }
        });
      };

      app.loader.load(setup);
      app.loader.onComplete.add(() => {
        this.$emit("done");
      });

      //remove old chest when new loaded
      app.loader.onComplete.add(() => {
        if (canvasEl) {
          this.removePrevCanvas(this.$refs.canvascontainer.id);
        }
      });
    },
  },
};
</script>

<style lang="scss">
#canvas-container-animation-event-bundesliga-icon {
  pointer-events: none;
  position: absolute;
  left: -35px;
  top: -15px;
  z-index: 1;
  &.right {
    left: auto;
    right: -35px;
  }
  &.draw-box {
    position: relative;
    margin-top: 35px;
  }

  #canvas-animation-event-bundesliga-icon {
  }
}
</style>
