var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"special-container position-relative clickable",attrs:{"id":"menu-item-mission"}},[_c('div',{staticClass:"item-content expandable"},[_c('div',{staticClass:"icon-container col clickable",on:{"click":function () {
          _vm.openMissionModal();
          _vm.firebaseClickItemFooter('footer_mission_button');
        }}},[_c('img',{staticClass:"icon",attrs:{"draggable":"false","src":require("@/assets/img/nav-menu/nav-icon-mission.png")}})]),_c('div',{staticClass:"expanded-items-container row"},[_vm._l((_vm.chests),function(chest,i){return _c('div',{key:chest.id,staticClass:"chest-container col clickable"},[(i === 0 && !_vm.expired[i])?[_c('div',{key:'active-chest-' + (i + 1) + '-container',staticClass:"chest-item",attrs:{"id":'active-chest-' + (i + 1) + '-container'},on:{"click":function () {
                _vm.openChestModal(i);
                _vm.firebaseClickItemFooter('footer_chest' + (i + 1) + '_button');
              }}},[_c('img',{staticClass:"chest-icon active",attrs:{"id":'active-chest-' + (i + 1) + '-icon',"draggable":"false","src":require('@/assets/img/chests/chest-' +
                  chest.chest.level +
                  '.png')}}),_c('img',{staticClass:"chest-lock position-absolute",attrs:{"draggable":"false","src":require("@/assets/img/common/lock-gold.png")}}),_c('div',{staticClass:"chest-countdown to-center-abs"},[_c('span',{staticClass:"countdown-numbers bold"},[_vm._v(" "+_vm._s(_vm.timer[i])+" ")])])])]:[_c('div',{key:'closed-chest-' + (i + 1) + '-container',staticClass:"chest-item",on:{"click":function () {
                _vm.openChestModal(i);
                _vm.firebaseClickItemFooter('footer_chest' + (i + 1) + '_button');
              }}},[(_vm.expired[i])?[_c('img',{staticClass:"chest-icon closed",attrs:{"id":'closed-chest-' + (3 - i) + '-icon',"draggable":"false","src":require('@/assets/img/chests/chest-' +
                    chest.chest.level +
                    '-open.png')}}),_c('span',{staticClass:"open-button-text to-center-abs"},[_vm._v(" "+_vm._s(_vm.$t("footer.chest_open"))+" ")])]:[_c('img',{staticClass:"chest-icon closed",attrs:{"id":'closed-chest-' + (3 - i) + '-icon',"draggable":"false","src":require('@/assets/img/chests/chest-' +
                    chest.chest.level +
                    '.png')}}),_c('img',{staticClass:"chest-lock position-absolute",attrs:{"draggable":"false","src":require("@/assets/img/common/lock-gold.png")}})]],2)]],2)}),_vm._l((_vm.freeSpaces),function(n,i){return _c('div',{key:i,staticClass:"chest-container col clickable"},[(_vm.loaded)?_c('div',{key:'empty-chest-' + (3 - _vm.freeSpaces + i) + '-container',staticClass:"chest-item",on:{"click":function () {
              _vm.openMissionModal(i);
              _vm.firebaseClickItemFooter(
                'footer_chest' + (_vm.chests.length + i + 1) + '_button'
              );
            }}},[_c('img',{staticClass:"chest-icon empty",attrs:{"id":'empty-chest-' + (3 - _vm.freeSpaces + i) + '-icon',"draggable":"false","src":require('@/assets/img/chests/chest-empty.png')}}),(i === 0 && _vm.missionInfos)?_c('div',{staticClass:"progress-dots d-inline-flex align-items-center w-100 to-center-abs"},_vm._l((3),function(dot,index){return _c('div',{key:("dot-" + index),staticClass:"progress-dot"},[(
                  (_vm.completedTasks >= 1 && index === 0) ||
                  (_vm.completedTasks >= 2 && index === 1) ||
                  (_vm.completedTasks >= 3 && index === 2)
                )?_c('div',{staticClass:"progress-dot fill"}):_vm._e()])}),0):_vm._e()]):_vm._e()])})],2),(_vm.showBadge)?_c('div',{staticClass:"new-icon"},[_vm._v("!")]):_vm._e(),_c('div',{staticClass:"banner-text"},[_vm._v(_vm._s(_vm.$t("footer.mission_text")))]),_c('ModalVanillaMission',{attrs:{"data":_vm.missionData,"current-chest":_vm.chests[_vm.currentChestIndex],"timer":_vm.timer[_vm.currentChestIndex]},on:{"test-finish-mission":_vm.testFinishMission,"test-transform-chest":_vm.testTransformChest,"handle-fetch-mission-data":_vm.fetchMissionData,"handle-change-mission":_vm.handleChangeMissions,"fetch-chest-data-footer":_vm.fetchChestData}}),_c('modal-chest-storage-full',{on:{"handle-open-modal-chest":function($event){return _vm.openChestModal(0)}}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }