<template>
  <div class="reward-item d-inline-flex align-items-center mr-1">
    <img draggable="false" :src="rewardIcon" class="reward-icon mr-1" />
    <span class="reward-amount blue">{{ rewardAmount }}</span>
  </div>
</template>

<script>
export default {
  name: "RewardItem",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    rewardType() {
      if (this.item.asset.endsWith("Card")) {
        return "card";
      } else if (this.item.asset.endsWith("Timer")) {
        return "timer";
      } else if (this.item.asset.endsWith("Currency")) {
        // will return either "gold" or "crown"
        return this.item.type;
      } else {
        return null;
      }
    },
    rewardAmount() {
      if (this.rewardType === "timer") {
        let boosterMinutes = this.item.amount / 60;
        if (boosterMinutes >= 10) {
          return boosterMinutes + ":00 Min.";
        } else {
          return "0" + boosterMinutes + ":00 Min.";
        }
      } else {
        return this.formatNumber(this.item.amount);
      }
    },
    rewardIcon() {
      if (this.rewardType) {
        return require(`@/assets/img/modal/reward_icon_small-${this.rewardType}.png`);
      } else {
        return require("@/assets/img/common/check-green.png");
      }
    },
  },
};
</script>
<style lang="scss">
.reward-item {
  .reward-icon {
    width: 34px;
    height: 34px;
  }
  .reward-amount {
    font-size: 18px;
  }
}
</style>
