<template>
  <div
    id="canvas-container-animation-spine-levelup-marker"
    ref="canvascontainer"
    class="canvas-container-animation-spine-levelup-marker"
  ></div>
</template>

<script>
import AnimationSpineBaseFunctions from "@/components/animation/animation-spine-base-functions";
import { Spine } from "pixi-spine";

export default {
  name: "AnimationLevelupMarker",
  mixins: [AnimationSpineBaseFunctions],
  props: {
    loop: {
      type: Boolean,
      default: true,
    },
    run: {
      type: Boolean,
      default: true,
    },
    userProgress: {
      type: Number,
      default: 0,
    },
    amountMarker: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      intervalId: null,
      destroyApp: false,
      unsubscribeEvents: [],
      positions: [
        { x: 321, y: 264 },
        { x: 483, y: 200 },
        { x: 413, y: 300 },
        { x: 213, y: 374 },
        { x: 290, y: 466 },
        { x: 453, y: 395 },
        { x: 611, y: 321 },
      ],
    };
  },

  watch: {
    run: function () {
      this.play();
    },
  },
  mounted() {
    this.play();
  },
  beforeDestroy() {
    clearInterval(this.intervalId);

    //destroy event listeners
    for (let i = 0; i < this.unsubscribeEvents.length; i++) {
      this.unsubscribeEvents[i]();
    }

    this.destroyApp = true;
  },

  methods: {
    play: function () {
      const canvasEl = document.getElementById(
        "canvas-animation-spine-levelup-marker"
      );

      const assets = [
        ["levelup-marker", "/assets/spines/events/levelup/icn-ani-done.json"],
      ];
      const options = {
        width: 1200,
        height: 500,
        antialias: true,
        backgroundAlpha: 0,
        autoResize: true,
      };

      const app = this.createCanvas(
        options,
        assets,
        this.$refs.canvascontainer.id
      );
      app.view.id = "canvas-animation-spine-levelup-marker";

      app.ticker.add(() => {
        if (this.destroyApp) {
          this.destroyApp = false;
          this.destroyAll(app);
        }
      });

      app.ticker.add(() => {
        if (this.destroyApp) {
          this.destroyAll(app);
        }
      });

      const setup = (loader, res) => {
        let spineData = this.getSpineData(res);

        for (let i = 0; i < this.amountMarker - 1; i++) {
          spineData.push(spineData[0]);
        }

        spineData.forEach((data, index) => {
          const spine = new Spine(data);

          //skeleton and animation configs
          let animationIndex = 0;
          if (this.userProgress <= index) {
            return;
          }

          // set the position
          spine.x = this.positions[index].x + 45;
          spine.y = this.positions[index].y - 55;

          // set scale
          spine.scale.set(0.8);

          //add spine to stage
          app.stage.addChild(spine);

          let animation = data.animations[animationIndex];

          //run animation
          spine.state.setAnimation(0, animation.name);

          //show first frame when not running
          if (!this.run) {
            spine.state.getCurrent(0).animationEnd = 0;
            return;
          }

          //set animation end
          spine.state.getCurrent(0).animationEnd = animation.duration;

          // Detach event listeners
          this.unsubscribeEvents.push(() => {
            spine.state.getCurrent(0).onEvent = null;
          });

          //add loop
          if (this.loop) {
            spine.state.getCurrent(0).loop = true;
          }
        });
      };

      app.loader.load(setup);

      //remove old canvas when new loaded
      app.loader.onComplete.add(() => {
        if (canvasEl) {
          this.removePrevCanvas(this.$refs.canvascontainer.id);
        }
      });
    },
  },
};
</script>

<style lang="scss">
.canvas-container-animation-spine-levelup-marker {
  pointer-events: none;
}
</style>
