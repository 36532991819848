<template>
  <b-modal
    id="modal-treasury"
    :hide-footer="true"
    modal-class="custom-modal2"
    @shown="onOpen"
    @hidden="onClose"
  >
    <template slot="modal-header" slot-scope="{ close }">
      <Spinner
        v-if="loading"
        size="medium"
        context="Loading Treasury..."
        classes="dead-center pl-2"
      ></Spinner>
      <template v-if="!loading">
        <div
          v-if="$store.state.locale === 'de'"
          id="headline-container-de"
        ></div>
        <div v-else id="headline-container-en"></div>

        <a href="#" class="close-button" @click="close()"> </a>
      </template>
    </template>
    <template slot="default">
      <template v-if="!loading">
        <b-row class="content-row">
          <div
            id="vault-img"
            class="to-center-abs"
            :class="{
              'vault-large-locked': inactive,
              'vault-large-closed': !inactive,
              'vault-large-opened': hundredPercent,
            }"
          ></div>
          <transition name="fade">
            <div
              v-if="showGlow && treasury.percentage >= minPercentage"
              id="glow-row"
            ></div>
          </transition>
          <b-col id="treasury-col-left" cols="6">
            <div v-if="inactive"></div>
            <div
              v-else
              class="earning-field position-absolute d-flex flex-column align-items-center justify-content-center"
            >
              <div
                v-if="!hundredPercent"
                class="info-icon"
                @click="$bvModal.show('modal-treasury-info')"
              ></div>
              <div
                v-if="!hundredPercent"
                class="w-100 text-center text-uppercase fill-status-text"
              >
                {{ $t("treasury.open.info_title") }}
              </div>
              <div
                v-else
                class="w-100 text-center text-uppercase bold fill-status-text full"
              >
                {{ $t("treasury.open.info_title_full") }}
              </div>
              <div class="w-100 d-inline-flex">
                <EarningShield
                  v-for="reward in treasury.rewards"
                  :key="reward.asset.id"
                  :earning="reward.asset.wallet"
                  style="max-width: 120px"
                >
                  <template v-slot:earning-amount-or-text
                    >{{ formatNumber(reward.amount) }}
                  </template>
                </EarningShield>
              </div>
              <button
                class="ok-button-green round-new w-75"
                @click="openForFree"
              >
                {{ $t("treasury.open.button_collect") }}
              </button>
            </div>
          </b-col>
          <b-col id="treasury-col-right" cols="6">
            <div
              v-if="inactive"
              id="vault-info-field"
              class="position-absolute text-center row"
            >
              <div
                class="info-icon col-12"
                @click="$bvModal.show('modal-treasury-info')"
              ></div>
              <p class="text-uppercase bold col-12">
                {{ $t("treasury.closed.info_title") }}
              </p>
              <p class="col-12">
                {{ $t("treasury.closed.info_message", { 0: minPercentage }) }}
              </p>
            </div>
            <div v-else class="magic-key-field position-relative">
              <div
                class="w-100 d-inline-flex align-items-center justify-content-between"
              >
                <div
                  class="magic-key-subheading super-headline3-light text-left"
                >
                  {{ $t("treasury.key.title") }}
                </div>
                <div class="multiplier-badge text-white bold position-absolute">
                  x <br />{{ multiplier }}
                </div>
                <div class="key-badge"></div>
              </div>
              <div id="magic-gold" class="w-100 position-relative">
                <span
                  class="position-absolute magic-gold-amount text-white bold text-center w-75"
                  >{{ formatNumber(magicKeyOffer.rewards.gold) }}</span
                >
              </div>
              <div
                id="magic-boni"
                class="w-100 position-relative d-inline-flex justify-content-center align-items-center"
              >
                <div class="luxury-badge position-relative crown">
                  <div class="luxury-badge-icon to-center-abs"></div>
                  <span class="text-white bold to-center-abs">{{
                    formatNumber(magicKeyOffer.rewards.crown)
                  }}</span>
                </div>
                <div class="luxury-badge position-relative loyalty">
                  <div class="bonus-sticker to-center-abs">
                    <span class="text-white bold to-center-abs">BONUS</span>
                  </div>
                  <div class="luxury-badge-icon to-center-abs"></div>
                  <span class="text-white bold to-center-abs">{{
                    formatNumber(magicKeyOffer.rewards.loyalty)
                  }}</span>
                </div>
              </div>

              <button
                id="buy-magic-key-button"
                class="to-center-abs"
                @click="buyMagicKey"
              >
                <span
                  class="text-white bold text-uppercase text-left position-absolute"
                >
                  {{ $t("treasury.key.buy_button") }}</span
                ><span class="text-white bold text-right position-absolute"
                  >{{ magicKeyPrice }} €
                </span>
                <div
                  v-if="oldPrices && oldPrices[0]"
                  class="offer-old-price ml-3 right"
                >
                  {{ formatPrice(oldPrices[0]) }} €
                  <div class="offer-red-line to-center-abs"></div>
                </div>
                <div
                  v-if="moreContent && moreContent[0]"
                  class="big-badge-more-percent smaller"
                >
                  <span class="big">{{ moreContent[0] }}</span>
                  {{ $t("shop.offers.more_contents2") }}
                </div>
              </button>
            </div>
          </b-col>
        </b-row>

        <div class="progress-bar-section position-absolute">
          <div class="custom-progress-bar to-center-abs">
            <div
              class="progress-number text-white bold text-center to-center-abs"
            >
              {{ treasury.percentage + " %" }}
            </div>
            <div class="progress-bar-fill-container to-center-abs">
              <div
                class="progress-bar-fill"
                :style="{ width: treasury.percentage + '%' }"
              ></div>
            </div>
          </div>
        </div>
      </template>
    </template>
    <ModalTreasuryInfo />
  </b-modal>
</template>

<script>
import EarningShield from "../base/earning-shield.vue";
import * as treasuryAPI from "@/API/treasury.js";
import AnimationParsingFunctions from "@/components/animation/animation-parsing-functions";
import * as paymentAPI from "@/API/payment.js";
import Spinner from "@/components/animation/animation-spinner";
import getRewardsFromPackage from "@/mixins/paymentFunctions.js";
import ModalTreasuryInfo from "@/components/treasury/modal-treasury-info.vue";

export default {
  name: "ModalTreasury",
  components: { EarningShield, Spinner, ModalTreasuryInfo },
  mixins: [AnimationParsingFunctions, getRewardsFromPackage],
  props: {
    treasury: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      multiplier: 40,
      keyBonus: false,
      magicKeyOffer: null,
      showGlow: false,
      interval: null,
      oldPrices: [],
      moreConent: [],
    };
  },

  computed: {
    hundredPercent() {
      return this.treasury.percentage >= 100;
    },
    minPercentage: function () {
      if (!this.$store.state.generalConfig) {
        return 25;
      }
      return this.$store.state.generalConfig.settings[
        "treasury.exchange.watermark.low"
      ];
    },
    inactive() {
      if (!this.magicKeyOffer) {
        return true;
      }

      return this.treasury.percentage < this.minPercentage;
    },
    magicKeyPrice: function () {
      if (!this.magicKeyOffer) {
        return "";
      }
      return this.magicKeyOffer.package.price;
    },

    websocketUpdate: function () {
      return this.$store.getters["treasury/getTreasuryUpdateData"];
    },
  },

  watch: {
    websocketUpdate: {
      handler(data) {
        const modalElement = document.getElementById("modal-treasury");
        if (modalElement) {
          this.fetchAllData();
          return;
        }

        this.$emit("handle-update-treasury-percentage", data);
      },
    },
  },

  mounted() {
    this.interval = setInterval(() => {
      this.showGlow = !this.showGlow;
    }, 4500);

    if (this.$route.path.startsWith("/treasury")) {
      this.$bvModal.show("modal-treasury");
    }
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },

  methods: {
    onOpen() {
      this.$store.commit("treasury/setPulseMenuIcon", false);

      this.fetchAllData();
      this.playBackgroundTreasury();
    },
    onClose() {
      this.playBackgroundMusic();
      this.firebaseClickItemTreasury("treasury_close_button");
    },
    fetchAllData: function () {
      Promise.all([
        this.fetchMagicKeyPackage(),
        this.$emit("handle-fetch-treasury-data"),
      ])
        .then(() => {
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },

    openForFree: function () {
      treasuryAPI
        .openTreasury()
        .then(() => {
          this.firebaseClickItemTreasury("treasury_open_free_button");
          this.showAnimation();
        })
        .catch((e) => console.log(e));
    },
    buyMagicKey: function () {
      this.firebaseClickItemTreasury("treasury_open_buy_button");
      this.magicKeyOffer.callback = () => {
        this.$bvModal.show("modal-treasury");
        this.fetchAllData();
      };
      this.magicKeyOffer.category = "Treasury";
      this.magicKeyOffer.variant = "Magic Key";
      this.$store.commit("shop/setShopChosenPackage", this.magicKeyOffer);
      this.$bvModal.show("modal-payment");
    },

    showAnimation: function () {
      this.firebaseClickItemTreasury("treasury_collect_button");
      this.mapApiJsonTreasury(this.treasury.rewards);
      this.$bvModal.hide("modal-treasury");
      this.fetchAllData();
    },

    fetchMagicKeyPackage: async function () {
      await paymentAPI
        .fetchPaymentOffersByOfferType("TREASURY")
        .then((result) => {
          if (result) {
            this.magicKeyOffer = result.offer.packages[0];
            this.magicKeyOffer.rewards = this.getRewardsFromPackage(
              this.magicKeyOffer
            );
            this.magicKeyOffer.customName = this.$t("treasury.key.title");
            this.magicKeyOffer.customImg = "king-key";

            if (result.configuration) {
              const conf = JSON.parse(result.configuration);
              this.oldPrices = conf.old_prices;
              this.moreContent = conf.more_content;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style lang="scss">
#modal-treasury {
  $bg-main: url(~@/assets/img/treasury/treasury-bg-main.png) bottom left/50%
    100% no-repeat;
  $bg-main-right: url(~@/assets/img/treasury/treasury-bg-main-right.png) bottom
    right/50% 100% no-repeat;
  $bg-glow: url(~@/assets/img/treasury/treasury-glow-overlay.png) bottom
    left/50% 100% no-repeat;
  $bg-glow-right: url(~@/assets/img/treasury/treasury-glow-overlay-right.png)
    bottom right/50% 100% no-repeat;

  .modal-dialog {
    max-width: 1348px;
    height: 663px;
    margin-top: 80px;

    @media (max-height: 750px) {
      transform: scale(0.8);
      top: -80px;
      margin-bottom: -20%;
    }
  }

  .modal-content {
    background-color: transparent;
    background-image: none;

    .modal-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: -240px;
      z-index: 5;
      font-size: 16px;

      .close-button {
        position: absolute;
        right: 30px;
        top: 10px;
        width: 60px;
        height: 60px;
        z-index: 10;
        background: url(~@/assets/img/common/button-close-grey.png) center/100%
          100% no-repeat;
        cursor: pointer;
      }

      #headline-container-de {
        width: 902px;
        height: 145px;
        background: url(~@/assets/img/treasury/treasury-hl-de.png) center/100%
          100% no-repeat;
      }

      #headline-container-en {
        width: 575px;
        height: 186px;
        background: url(~@/assets/img/treasury/treasury-hl-en.png) center/100%
          100% no-repeat;
      }
    }

    .modal-body {
      z-index: 4;

      .info-icon {
        margin-top: -10px;
        width: 60px;
        height: 60px;
        cursor: pointer;

        &:hover {
          filter: $hover-shadow;
        }

        background: url(~@/assets/img/mission/missions-info-button.svg)
          center/100% no-repeat;
        background-size: 60px 60px;
        -webkit-transform: translate3d(0, 0, 0);
      }
    }

    .content-row {
      height: 680px;
      background: $bg-main, $bg-main-right;

      #treasury-col-left,
      #treasury-col-right {
        z-index: 10;
      }

      #vault-img {
        width: 480px;
        height: 480px;
        z-index: 5;
        bottom: 80px;

        &.vault-large-locked {
          background: url(~@/assets/img/treasury/treasury-door-stone.png) bottom
            center/100% 100% no-repeat;
        }

        &.vault-large-closed {
          background: url(~@/assets/img/treasury/treasury-door-gold.png) bottom
            center/100% 100% no-repeat;
        }

        &.vault-large-opened {
          width: 648px;
          height: 486px;
          background: url(~@/assets/img/treasury/treasury-door-open.png) bottom
            center/100% 100% no-repeat;
        }
      }

      .fade-enter-active,
      .fade-leave-active {
        animation: fade-in 2s;
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
        perspective: 1000px;
      }

      .fade-enter,
      .fade-leave-to {
        animation: fade-in 2s reverse;
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
        perspective: 1000px;
      }

      @keyframes fade-in {
        0% {
          opacity: 0;
        }
        10% {
          opacity: 0.3;
        }
        55% {
          opacity: 0.7;
        }
        100% {
          opacity: 1;
        }
      }

      #glow-row {
        height: 680px;
        position: absolute;
        width: 100%;
        background: $bg-glow, $bg-glow-right;
        opacity: 0;
      }
    }

    #vault-info-field {
      width: 336px;
      height: 192px;
      background: url(~@/assets/img/treasury/treasury-info-frame.png)
        center/100% 100% no-repeat;
      right: 0;
      top: auto;
      bottom: 209px;
      left: auto;
      padding-left: 20px;
      padding-right: 20px;

      p {
        line-height: 1;
        margin-top: -10px;

        &:first-of-type {
          color: #ff0000;
          font-size: 28px;
        }

        &:last-of-type {
          color: #fff8b7;
          font-size: 20px;
        }
      }
    }

    .earning-field {
      left: 0;
      bottom: 120px;
      width: 311px;
      font-size: 20px;

      .fill-status-text {
        font-size: 21px;
        line-height: 1.1;

        &.full {
          font-size: 36px;
        }
      }

      .plunder-button {
        height: 44px;
        border-radius: 30px;
        background-image: linear-gradient(#4de5ff -200%, #050d1c),
          linear-gradient(#f7ffff, #ffee51 58%, #8b5a00);
        border: 2px solid transparent;
        background-size: 100% 100%;
        background-origin: border-box;
        background-clip: padding-box, border-box;
        color: #fff8b7;
        font-size: 24px;
        text-transform: uppercase;
        filter: drop-shadow(0 0 4px $light-blue);
        box-shadow: inset 0 0 4px black, inset 0 0 4px black,
          inset 0 0 4px black, inset 0 0 4px black;
        cursor: pointer;

        &:hover {
          filter: drop-shadow(0 0 16px rgba(77, 229, 255, 0.6))
            drop-shadow(0 0 8px $light-blue) drop-shadow(0 0 4px $light-blue);
        }
      }
    }

    .magic-key-field {
      margin-left: auto;
      top: 201px;
      width: 389px;

      .magic-key-subheading {
        font-size: 42px;
        width: 58%;
        overflow: visible;
        line-height: 1.1;
        filter: drop-shadow(0 0 12px rgba(0, 0, 0, 0.7))
          drop-shadow(0 0 12px rgba(0, 0, 0, 0.7))
          drop-shadow(0 0 8px rgba(0, 0, 0, 0.69));
      }

      .multiplier-badge {
        width: 76px;
        height: 76px;
        border-radius: 100%;
        border: 1px solid $light-blue;
        background-image: linear-gradient(#23667a, #050d1c);
        right: 80px;
        top: -16px;
        font-size: 38px;
        text-shadow: 0 0 5px $light-blue;
        line-height: 0.8;
        transform: rotate(7deg);
      }

      .key-badge {
        width: 128px;
        height: 167px;
        position: absolute;
        right: 0;
        background: url(~@/assets/img/treasury/treasury-key.png) center/100%
          100% no-repeat;
      }

      #magic-gold {
        width: 100%;
        height: 155px;
        background: url(~@/assets/img/treasury/treasury-luxury-display-gold.png)
          center/100% 100% no-repeat;

        .magic-gold-amount {
          font-size: 36px;
          text-shadow: 0 0 7px $light-blue;
          left: 23%;
          bottom: 50px;
        }
      }

      #magic-boni {
        margin-top: -32px;

        .luxury-badge {
          width: 131px;
          height: 155px;
          background: url(~@/assets/img/treasury/treasury-luxury-display.png)
            center/100% 100% no-repeat;

          .luxury-badge-icon {
            top: 45px;
          }

          .bonus-sticker {
            width: 112px;
            height: 63px;
            background: url(~@/assets/img/treasury/treasury-bonus-bg.png)
              center/100% 100% no-repeat;

            span {
              font-size: 16px;
              bottom: 21px;
              filter: drop-shadow(0 0 11px #43ff00) drop-shadow(0 0 7px #43ff00)
                drop-shadow(0 0 4px #0a2700);
            }
          }

          &.crown {
            .luxury-badge-icon {
              width: 57px;
              height: 44px;
              background: url(~@/assets/img/common/crown6.png) center/100% 100%
                no-repeat;
            }
          }

          &.loyalty {
            .luxury-badge-icon {
              width: 48px;
              height: 48px;
              background: url(~@/assets/img/common/icon-lp.png) center/100% 100%
                no-repeat;
            }
          }

          span {
            font-size: 21px;
            text-shadow: 0 0 6px $light-blue;
            bottom: 25px;
          }
        }
      }

      #buy-magic-key-button {
        width: 400px;
        height: 201px;
        border: none;
        background: url(~@/assets/img/treasury/treasury-buy-button.png)
          center/100% 100% no-repeat;
        bottom: -100px;

        span {
          filter: drop-shadow(0 0 12px #258e00) drop-shadow(0 0 8px #258e00)
            drop-shadow(0 0 5px #258e00);

          &:first-of-type {
            font-size: 17px;
            left: 60px;
            bottom: 47px;
          }

          &:last-of-type {
            font-size: 38px;
            bottom: 34px;
            right: 73px;
          }
        }

        &:hover {
          background: url(~@/assets/img/treasury/treasury-buy-button-hover.png)
            center/100% 100% no-repeat;
        }

        .offer-old-price {
          top: 65px;
          right: -20px;
          filter: none;
        }
        .big-badge-more-percent {
          top: -229px;
          right: -40px;
        }
      }
    }
  }

  .progress-bar-section {
    height: 177px;
    width: 100%;
    bottom: 20px;
    z-index: 6;
    pointer-events: none;
    background: url(~@/assets/img/treasury/treasury-stone-left.png) 8% 100%/440px
        146px no-repeat,
      url(~@/assets/img/treasury/treasury-stone-right.png) 88% 100%/464px 177px
        no-repeat;

    .custom-progress-bar {
      width: 472px;
      height: 121px;
      bottom: 0;
      background: url(~@/assets/img/treasury/treasury-progress.png) center/100%
        100% no-repeat;

      .progress-number {
        font-size: 28px;
        filter: drop-shadow(0 0 7px $light-blue);
        top: 11px;
      }

      .progress-bar-fill-container {
        height: 22px;
        width: 264px;
        bottom: 28px;

        .progress-bar-fill {
          height: 100%;
          max-width: 100%;
          background-color: $light-blue;
          filter: drop-shadow(0 0 6px $light-blue);
        }
      }
    }
  }

  .spinner-container {
    left: 51px;
  }
}
</style>
