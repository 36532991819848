<template>
  <b-modal
    id="modal-bundesliga-help"
    ref="modal-bundesliga-help"
    :hide-footer="true"
    modal-class="custom-modal1"
    :header-class="`page${currentPage}`"
    @shown="onOpen"
  >
    <template slot="modal-header" slot-scope="{ close }">
      <template v-if="currentPage === 1">
        <div class="headline text-orange bold text-uppercase">
          {{ $t("events.bundesliga.tutorial.page_1.headline") }}
        </div></template
      ><template v-else-if="currentPage === 2"
        ><div class="w-100 text-center">
          <span class="text1 text-uppercase bold text-blue">
            {{ $t("events.bundesliga.tutorial.page_2.headline") }} </span
          ><br />

          <span class="text2 text-uppercase bold text-blue">
            {{ $t("events.bundesliga.bonus_package") }}
          </span>
        </div></template
      ><template v-else-if="currentPage === 4">
        <div class="w-100 text1 text-blue text-uppercase bold">
          {{ $t("events.bundesliga.place_bet") }}
        </div></template
      ><template v-else-if="currentPage === 5"
        ><div class="w-100 text1 text-blue bold text-uppercase">
          {{ $t("events.bundesliga.tutorial.page_5.headline") }}
        </div></template
      >
      <a href="#" class="close-button" @click="close()"> </a>
    </template>

    <template slot="default">
      <div class="preload"></div>
      <div class="w-100 h-100 bg"></div>
      <div class="bg border-repeat left"></div>
      <div class="bg border-repeat right"></div>
      <div class="bg border-side left"></div>
      <div class="bg border-side right"></div>
      <template>
        <!--page 1-->
        <div v-if="currentPage === 1" id="page1">
          <div class="logo-container"></div>

          <div class="text-container text-center">
            <div class="text2 text-blue bold">
              {{ $t("events.bundesliga.tutorial.page_1.par_1") }}
            </div>
            <div class="text3 text-blue bold">
              {{
                $t("events.bundesliga.tutorial.page_1.par_2", { 0: goldReward })
              }}
            </div>
            <div class="text4 text-blue bold">
              {{ $t("events.bundesliga.tutorial.page_1.par_3") }}<br />

              <span class="big bold"
                >{{ $t("events.bundesliga.bonus_package") }}!</span
              >
            </div>
          </div>
        </div>

        <!--page 2-->
        <div v-if="currentPage === 2" id="page2">
          <div class="badge-container">
            <BundesligaPackNoteBadge
              :offer-package="offerPackage"
              :buyable="false"
            ></BundesligaPackNoteBadge>
          </div>
        </div>

        <!--page 3-->
        <div v-if="currentPage === 3" id="page3">
          <div class="w-100 pt-5">
            <BundesligaTeamBadge :event-data="eventData"></BundesligaTeamBadge>
          </div>
          <div class="w-100 px-5">
            <p class="text1 my-5 text-blue bold text-uppercase">
              {{ $t("events.bundesliga.tutorial.page_3.headline") }}
            </p>
            <p class="text2 my-5 text-blue bold">
              {{ $t("events.bundesliga.tutorial.page_3.par_1") }}
            </p>
            <p class="text3 my-5 text-blue bold">
              {{ $t("events.bundesliga.tutorial.page_3.par_2") }}
            </p>
          </div>
        </div>

        <!--page 4-->
        <div v-if="currentPage === 4" id="page4">
          <div class="badge-container w-100">
            <div
              id="team-select"
              class="d-inline-flex align-items-center justify-content-between"
            >
              <span class="select-label text-uppercase bold">{{
                $t("events.bundesliga.dropdown.label")
              }}</span>
              <div id="dropdown-label" class="d-inline-flex align-items-center">
                <span class="text-uppercase bold">{{
                  $t("events.bundesliga.dropdown.select")
                }}</span>
                <div class="checkmark big white ml-1"></div>
              </div>

              <div id="team-select-dropdown">
                <div id="dropdown-contents">
                  <ul>
                    <li
                      v-for="(option, index) in bettingOptions"
                      :key="option.label"
                      class="position-relative"
                    >
                      <span class="text-uppercase bold">
                        {{ option.label }}</span
                      >
                      <div
                        v-if="index === 0"
                        class="checkmark small white position-absolute"
                        style="right: 20px; top: 17px"
                      ></div>
                    </li>

                    <li
                      class="text-uppercase bold pt-3 d-inline-flex align-items center"
                    >
                      <span>
                        {{ $t("events.bundesliga.dropdown.cancel") }}</span
                      >
                      <div class="ml-1 cancel-button-orange"></div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div id="lock-col" cols="3">
              <div class="w-100 h-100 d-inline-flex align-items-center">
                <div class="lock-button"></div>
              </div>
            </div>
          </div>
          <div class="w-50 pl-5">
            <p class="my-3 text2 text-blue bold">
              {{ $t("events.bundesliga.tutorial.page_4.par_1") }}
            </p>
            <p class="my-3 text3 text-blue bold">
              {{ $t("events.bundesliga.tutorial.page_4.par_2") }}
            </p>
          </div>
        </div>

        <!--page 5-->
        <div v-if="currentPage === 5" id="page5">
          <p class="text-orange text-uppercase bold">
            {{ $t("events.bundesliga.tutorial.page_5.par_1") }}
          </p>
          <p class="text-blue">
            {{
              $t("events.bundesliga.tutorial.page_5.par_2", {
                0: eventBeginDayOfWeek,
                1: eventBeginTime,
                2: eventEndDayOfWeek,
                3: eventEndTime,
              })
            }}
          </p>
          <p class="text-orange bold text-uppercase">
            {{ $t("events.bundesliga.tutorial.page_5.par_3") }}
          </p>
          <p class="text-blue">
            {{
              $t("events.bundesliga.tutorial.page_5.par_4", {
                0: resultBeginDayOfWeek,
                1: resultEndDayOfWeek,
              })
            }}
          </p>
          <p class="text-orange bold text-uppercase">
            {{ $t("events.bundesliga.tutorial.page_5.par_5") }}
          </p>
          <p class="text-blue">
            {{ dateStringBegin }}
            {{
              $t("events.bundesliga.tutorial.page_5.par_6", {
                0: "",
                1: "",
              })
            }}
            {{ dateStringEnd }}
          </p>
        </div>

        <div class="bottom-menu to-center-abs">
          <div
            v-if="currentPage >= 2"
            class="arrow left"
            @click="prevPage"
          ></div>
          <div class="arrow right" @click="nextPage"></div>
          <div class="row">
            <a
              v-for="step in menu"
              :key="step.title"
              class="col p-0"
              :class="{ 'text-orange': currentPage === step.page }"
              @click="currentPage = step.page"
              >{{ step.title }}</a
            >
          </div>
        </div>
      </template>
    </template>
  </b-modal>
</template>

<script>
import eventPopups from "@/mixins/eventPopups.js";
import getRewardsFromPackage from "@/mixins/paymentFunctions.js";
import AnimationFullRewardEarned from "@/components/animation/animation-full-reward-earned";
import dateFunctions from "@/mixins/dateFunctions.js";
import BundesligaPackNoteBadge from "@/components/events/bundesliga/bundesliga-pack-note-badge";
import BundesligaTeamBadge from "@/components/events/bundesliga/bundesliga-team-badge";

export default {
  name: "ModalBundesligaHelp",
  components: {
    BundesligaTeamBadge,
    BundesligaPackNoteBadge,
  },
  mixins: [
    eventPopups,
    getRewardsFromPackage,
    AnimationFullRewardEarned,
    dateFunctions,
  ],
  props: {
    goldReward: {
      type: String,
      default: "",
    },
    offerPackages: { type: Array, default: () => [] },
    eventData: { type: Object, default: () => {} },
  },

  data() {
    return {
      modalId: "modal-bundesliga-help",
      currentPage: 1,
      maxPages: 5,
      menu: [
        { title: this.$t("events.bundesliga.bonus_package"), page: 2 },
        {
          title: this.$t("events.bundesliga.tutorial.page_3.headline"),
          page: 3,
        },
        { title: this.$t("events.bundesliga.place_bet"), page: 4 },
        {
          title: this.$t("events.bundesliga.tutorial.page_5.headline"),
          page: 5,
        },
      ],
      bettingOptions: [
        { option: "A", label: this.$t("events.bundesliga.teams.home") },
        { option: "B", label: this.$t("events.bundesliga.teams.away") },
        { option: "C", label: this.$t("events.bundesliga.dropdown.draw") },
      ],
    };
  },

  computed: {
    offerPackage() {
      return this.offerPackages[0];
    },
    eventBeginDate() {
      return new Date(this.eventData.start_at);
    },
    eventEndDate() {
      return new Date(this.eventData.end_at);
    },
    eventBeginDayOfWeek() {
      return this.$t(`day_of_the_week_${this.eventBeginDate.getDay()}`);
    },
    eventEndDayOfWeek() {
      return this.$t(`day_of_the_week_${this.eventEndDate.getDay()}`);
    },
    eventBeginTime() {
      if (this.$store.state.locale === "de") {
        return this.eventBeginDate
          .toLocaleTimeString([], {
            hour: "2-digit",
            hour12: false,
          })
          .replace(/^0+/, "");
      }
      return this.eventBeginDate.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
    },
    eventEndTime() {
      if (this.$store.state.locale === "de") {
        return this.eventEndDate.toLocaleTimeString([], {
          hour: "2-digit",
          hour12: false,
        });
      }
      return this.eventEndDate.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
    },

    resultBeginDayOfWeek() {
      let date = new Date(this.eventEndDate.getTime());
      date.setDate(date.getDate() + 1);
      return this.$t(`day_of_the_week_${date.getDay()}`);
    },
    resultEndDayOfWeek() {
      let date = new Date(this.eventEndDate.getTime());
      date.setDate(date.getDate() + 2);
      return this.$t(`day_of_the_week_${date.getDay()}`);
    },

    dateStringBegin() {
      const date = new Date("2021-09-09T10:00:00.000000Z");
      return date.toLocaleDateString(this.$store.state.locale, {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      });
    },
    dateStringEnd() {
      const date = new Date("2022-05-14T10:00:00.000000Z");
      return date.toLocaleDateString(this.$store.state.locale, {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      });
    },
  },
  created() {},
  mounted() {
    // this.$bvModal.show("modal-bundesliga-help");
  },
  methods: {
    prevPage() {
      if (this.currentPage <= 0) {
        this.currentPage = 1;
        return;
      }
      this.currentPage--;
    },
    nextPage() {
      if (this.currentPage >= this.maxPages) {
        this.$bvModal.hide(this.modalId);
        return;
      }
      this.currentPage++;
    },
    onOpen() {
      this.currentPage = 1;
    },
  },
};
</script>
<style lang="scss">
$modal-width: 1130px;
$modal-height: 660px;

#modal-bundesliga-help {
  .preload {
    height: 0;
    width: 0;
    background-image: url(~@/assets/img/events/bundesliga/btn-collect-all-bg-hover.png);
  }

  .modal-dialog {
    min-width: $modal-width;
    max-width: $modal-width;
    width: 100%;
    height: $modal-height;
    margin-top: 165px;

    @media (max-height: 850px), (max-width: 1250px) {
      transform: scale(0.8);
      top: -40px;
      margin-bottom: -20%;
    }
    @media (max-height: 770px), (max-width: 1100px) {
      transform: scale(0.7);
      top: -100px;
      margin-bottom: -30%;
    }
    @media (max-width: 1100px) {
      left: -5%;
    }
  }
  .modal-header {
    height: 144px;
    text-align: center;
    padding-top: 50px;
    &.page1 {
      width: 64%;
      margin-left: 36%;
      font-size: 50px;
    }
    &.page2 {
      .text1 {
        font-size: 38px;
      }
      .text2 {
        font-size: 72px;
      }
    }
    &.page4,
    &.page5 {
      font-size: 50px;
    }

    .close-button {
      width: 40px;
      height: 40px;
      opacity: 0.5;
      top: -85px;
      right: 15px;
    }
  }

  .modal-body {
    margin-top: -144px !important;
    width: 100%;
    height: 100%;
    overflow: visible;
    padding: 0;
    .bg {
      width: 560px;
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      background: linear-gradient(to top, #000000, #212121) center/100% 110%
        no-repeat;
      filter: blur(6.7px);
      opacity: 0.8;

      &.right {
        transform: rotateY(180deg);
        right: 0;
        left: auto;
      }

      &.border-repeat {
        height: 100%;
        background: url(~@/assets/img/events/bundesliga/frame-repeat.png) 0/2px
          100%;
        filter: blur(0);
        opacity: 1;
      }

      &.border-side {
        height: 100%;
        background: url(~@/assets/img/events/bundesliga/frame-side.png) 0/48px
          100% no-repeat;
        filter: blur(0);
        opacity: 1;
      }
    }
  }

  .modal-content {
    width: 100%;
    height: 100%;
    background: none;
    line-height: 1.2;

    .text-orange {
      color: white !important;
      text-shadow: 0 0 18px #ffa900, 0 0 18px #ffa900;
    }
    .text-blue {
      color: white !important;
      text-shadow: 0 0 18px rgba(77, 229, 255, 0.71),
        0 0 18px rgba(77, 229, 255, 0.71);
    }

    .bottom-menu {
      bottom: 50px;
      width: 75%;

      a {
        font-family: Ubuntu-Bold;
        font-size: 22px;
        color: #8e8e8e;
        cursor: pointer;
      }

      .arrow {
        position: absolute;
        top: -35px;
        width: 100px;
        height: 100px;
        cursor: pointer;

        &.left {
          background: url(~@/assets/img/events/bundesliga/btn-prev-next.png)
            bottom center/100% 100% no-repeat;
          left: -120px;
        }

        &.right {
          background: url(~@/assets/img/events/bundesliga/btn-prev-next.png)
            bottom center/100% 100% no-repeat;
          right: -120px;
          transform: scaleX(-1);
        }
      }
    }

    #page1 {
      .logo-container {
        position: absolute;
        width: 398px;
        height: 365px;
        top: 140px;
        left: 40px;
        background: url(~@/assets/img/events/bundesliga/tipking-logo-temp.png)
          bottom center/100% 100% no-repeat;
      }

      .text-container {
        width: 64%;
        position: absolute;
        right: 0;
        top: 200px;

        .text2 {
          font-size: 23px;
          padding: 10px 50px;
        }

        .text3 {
          font-size: 40px;
          padding: 10px 70px;
        }
        .text4 {
          font-size: 26px;
          padding: 10px 0;

          .big {
            font-size: 40px;
          }
        }
      }
    }

    #page2 {
      .badge-container {
        top: 200px;
        position: absolute;
        width: 100%;
      }
    }

    #page3 {
      .text1 {
        font-size: 50px;
      }
      .text2,
      .text3 {
        font-size: 30px;
      }
    }

    #page4 {
      .badge-container {
        width: 100%;
        margin-top: 150px;
        position: relative;

        #team-select {
          width: 642px;
          height: 95px;
          background: url(~@/assets/img/events/bundesliga/bl-select-bg.svg)
            center/100% 100% no-repeat;
          position: relative;
          padding-left: 35px;

          .select-label {
            font-size: 21.8px;
          }

          #dropdown-label {
            font-size: 20.2px;
            z-index: 1;
            padding-left: 20px;
            width: 270px;
            height: 51px;
            background: url(~@/assets/img/events/bundesliga/dropwdown-background.png)
              center/100% 100% no-repeat;
            position: absolute;
            right: 25px;
          }
          #team-select-dropdown {
            width: 271px;
            height: 255px;
            background: url(~@/assets/img/events/bundesliga/bl-dropdown-bg.svg)
              center/100% 100% no-repeat;
            position: absolute;
            z-index: 10;
            right: 5px;
            top: 70px;
            #dropdown-contents {
              padding: 15px;
              ul {
                list-style: none;
                padding: 0;
                li {
                  font-size: 23.5px;
                  padding: 9px 0 9px 0;
                  .cancel-button-orange {
                    width: 30px;
                    height: 30px;
                    background: url(~@/assets/img/events/cancel-button-orange.svg)
                      center/100% 100% no-repeat;
                  }
                  &:last-of-type {
                    font-size: 20.2px;
                  }
                  &:not(:last-of-type) {
                    border-bottom: 1.7px solid white;
                    text-align: left;
                  }
                }
              }
            }
          }
          .checkmark {
            &.white {
              background: url(~@/assets/img/events/checkmark-white-small.svg)
                center/100% 100% no-repeat;

              &.big {
                width: 30px;
                height: 30px;
              }
              &.small {
                width: 21px;
                height: 21px;
              }
            }
          }
        }
        #lock-col {
          height: 97px;
          position: absolute;
          top: 0;
          right: 100px;

          span {
            text-shadow: 0 0 13px rgba(77, 229, 255, 0.71), 0 0 7px #4de5ff,
              0 0 3px #4de5ff;
            font-size: 17.6px;
          }
          .lock-button {
            width: 97px;
            height: 97px;
            background: url(~@/assets/img/events/lock-active.png) center/100%
              100% no-repeat;
            z-index: 2;
            &.locked {
              background: url(~@/assets/img/events/lock-inactive.png)
                center/62px 61px no-repeat;
              &.small {
                width: 42px;
                height: 41px;
                background-size: 100% 100%;
                position: absolute;
                left: 30px;
                &.right {
                  left: auto;
                  right: 30px;
                }
              }
            }
          }
        }
      }

      .text2,
      .text3 {
        font-size: 30px;
        text-align: right;
      }
    }

    #page5 {
      padding-top: 200px;
      p {
        &:nth-child(odd) {
          font-size: 40px;
        }
        &:nth-child(even) {
          font-size: 30px;
        }
      }
    }
  }
}
</style>
