<template>
  <div
    :id="`canvas-container-animation-spine-goforgold-medal-${index}`"
    ref="canvascontainer"
    class="canvas-container-animation-spine-goforgold-medal"
  ></div>
</template>

<script>
import AnimationSpineBaseFunctions from "@/components/animation/animation-spine-base-functions";
import { Spine } from "pixi-spine";

export default {
  name: "AnimationGoforgoldMedal",
  mixins: [AnimationSpineBaseFunctions],
  props: {
    loop: {
      type: Boolean,
      default: true,
    },
    run: {
      type: Boolean,
      default: true,
    },
    index: {
      type: Number,
      default: 0,
    },
    medal: {
      type: String,
      default: "gold",
    },
  },
  data() {
    return {
      destroyApp: false,
      unsubscribeEvents: [],
    };
  },
  mounted() {
    this.play();
  },
  beforeDestroy() {
    //destroy event listeners
    for (let i = 0; i < this.unsubscribeEvents.length; i++) {
      this.unsubscribeEvents[i]();
    }

    this.destroyApp = true;
  },

  methods: {
    play: function () {
      const assets = [
        [
          "goforgold-medal",
          "/assets/spines/events/goforgold/goforgold-medals-animation.json",
        ],
      ];
      const options = {
        width: 92,
        height: 92,
        antialias: true,
        backgroundAlpha: 0,
        autoResize: true,
      };

      const app = this.createCanvas(
        options,
        assets,
        this.$refs.canvascontainer.id
      );
      app.view.id = `canvas-animation-spine-goforgold-medal-${this.index}`;

      app.ticker.add(() => {
        if (this.destroyApp) {
          this.destroyAll(app);
        }
      });

      const setup = (loader, res) => {
        let spineData = this.getSpineData(res);

        spineData.forEach((data) => {
          const spine = new Spine(data);

          //skeleton and animation configs
          let animationIndex;
          switch (this.medal) {
            case "gold":
              animationIndex = 1;
              break;
            case "silver":
              animationIndex = 2;
              break;
            case "bronze":
              animationIndex = 0;
              break;
            default:
              animationIndex = 0;
          }

          // set the position
          spine.x = app.screen.width / 2;
          spine.y = app.screen.height - 40;

          // set scale
          spine.scale.set(1.01);

          //add spine to stage
          app.stage.addChild(spine);

          let animation = data.animations[animationIndex];
          //run animation
          spine.state.setAnimation(0, animation.name);

          //show first frame when not running
          if (!this.run) {
            spine.state.getCurrent(0).animationEnd = 0;
            return;
          }

          //set animation end
          spine.state.getCurrent(0).animationEnd = animation.duration;

          // Detach event listeners
          this.unsubscribeEvents.push(() => {
            spine.state.getCurrent(0).onEvent = null;
          });

          //add loop
          if (this.loop) {
            spine.state.getCurrent(0).loop = true;
          }
        });
      };

      app.loader.load(setup);
    },
  },
};
</script>

<style lang="scss">
.canvas-container-animation-spine-goforgold-medal {
  pointer-events: none;
}
</style>
