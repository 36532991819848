<template>
  <transition name="pan">
    <div v-if="showAlertBanner" class="alert-wrapper" @click="handleClick">
      <b-alert
        id="krone-alert"
        class="w-100 h-100"
        :dismissible="alertBanner.dismissible"
        :variant="alertBanner.variant"
        :show="configureVisibility"
        :class="isClickable"
        @dismissed="handleDismiss"
      >
        <div class="banner-icon position-relative">
          <img
            draggable="false"
            :src="iconSrc"
            class="alert-icon dead-center"
            :class="alertType"
          />
        </div>
        <div
          class="banner-text d-flex flex-column justify-content-center"
          :class="getTextSize"
        >
          <span class="banner-heading text-white text-uppercase bold">
            {{ alertHeading }}
          </span>
          <span v-if="alertText" class="banner-subline text-white">{{
            alertText
          }}</span>
          <div
            v-if="otherAlertContent"
            class="rewards-row d-inline-flex align-items-center justify-content-center px-1"
          >
            <RewardItem
              v-for="item in otherAlertContent"
              :key="item.id"
              :item="item"
            />
          </div>
        </div>
      </b-alert></div
  ></transition>
</template>

<script>
import RewardItem from "@/components/base/reward-item.vue";
export default {
  name: "AlertBanner",
  components: { RewardItem },
  computed: {
    alertBanner() {
      if (this.$store.state.popups.alertBannerQueue.length > 0) {
        return this.$store.state.popups.alertBannerQueue[0];
      } else {
        return null;
      }
    },
    showAlertBanner() {
      if (this.alertBanner && this.alertBanner.show) {
        return true;
      } else {
        return false;
      }
    },
    alertText() {
      return this.alertBanner.alertText;
    },
    alertHeading() {
      return this.alertBanner.alertHeading;
    },
    alertType() {
      return this.alertBanner.type;
    },
    dismissMessage() {
      return this.alertBanner.dismissMessage;
    },
    otherAlertContent() {
      return this.alertBanner.otherAlertContent;
    },
    iconSrc() {
      switch (this.alertType) {
        case "info":
          return require("@/assets/img/modal/alert-icon-info.svg");
        // case "battle":
        //   return require("@/assets/img/modal/alert-icon-battle.png");
        case "booster":
          return require("@/assets/img/modal/alert-icon-booster.png");
        case "check":
          return require("@/assets/img/modal/alert-icon-check.svg");
        case "danger":
          return require("@/assets/img/modal/alert-icon-danger.svg");
        case "fellowship":
          return require("@/assets/img/modal/alert-icon-fellowship.png");
        case "friend":
          return require("@/assets/img/modal/alert-icon-friend.png");
        case "kingspath":
          return require("@/assets/img/modal/alert-icon-kingspath.png");
        case "newsletter":
          return require("@/assets/img/modal/alert-icon-newsletter.png");
        case "tourney":
          return require("@/assets/img/modal/alert-icon-tourney.png");
        case "offer":
          return require("@/assets/img/modal/alert-icon-offer.png");
        case "treasury-active":
          return require("@/assets/img/footer/vault_state_02.png");
        case "treasury-full":
          return require("@/assets/img/footer/vault_state_03.png");
        default:
          return require("@/assets/img/modal/alert-icon-info.svg");
      }
    },

    getTextSize() {
      if (!this.alertText) return "";
      if (this.alertText.length <= 40 && this.otherAlertContent) {
        return "small";
      } else if (this.alertText.length > 40 && this.alertText.length < 50) {
        return "small";
      } else if (this.alertText.length > 50 || this.alertHeading.length >= 30) {
        return "smaller";
      } else {
        return "";
      }
    },

    isClickable() {
      if (
        this.alertType === "fellowship" ||
        this.alertType === "friend" ||
        this.alertType === "kingspath" ||
        this.alertType === "tourney" ||
        this.alertType === "offer" ||
        this.alertType === "treasury-active" ||
        this.alertType === "treasury-full"
      ) {
        return "clickable";
      } else {
        return "";
      }
    },
    configureVisibility() {
      if (!this.alertBanner.countdown) {
        return this.alertBanner.show;
      } else {
        return this.alertBanner.show && this.alertBanner.countdown;
      }
    },
  },
  methods: {
    handleDismiss() {
      if (this.dismissMessage) {
        this.$root.$emit(this.dismissMessage);
      }
      this.$store.commit("popups/hideAlertBanner");
    },
    chooseClickAction() {
      switch (this.alertType) {
        case "info":
          return null;

        case "booster":
          return null;
        case "check":
          return null;
        case "danger":
          return null;
        case "fellowship":
          this.$router.push({ name: "friends", query: "overrideGuard" });
          break;
        case "friend":
          this.$router.push({ name: "friends", query: "overrideGuard" });
          break;
        case "kingspath":
          this.$router.push("/kingspath");
          break;
        case "newsletter":
          return null;
        case "tourney":
          this.$router.push("/tournament");
          break;
        case "offer":
          this.$root.$emit("show-offer");
          break;
        case "treasury-active":
          this.$root.$emit("show-treasury");
          break;
        case "treasury-full":
          this.$root.$emit("show-treasury");
          break;

        default:
          return null;
      }
    },
    handleClick() {
      this.chooseClickAction();
      this.handleDismiss();
    },
  },
};
</script>
<style lang="scss">
.pan-enter-active,
.pan-leave-active {
  transition: all 0.8s ease-in-out;
}
.pan-enter,
.pan-leave-to {
  transform: translateY(-80px);
}
.pan-leave {
  transform: translateY(64px);
}
.alert-wrapper {
  width: 736px;
  height: 99px;
  position: fixed;
  z-index: 1500;
  left: 0;
  right: 0;
  top: 0;
  margin: auto;

  #krone-alert {
    padding: 0 85px 0 85px;

    &.alert {
      border: 0;

      display: flex;
      &.clickable {
        &:hover {
          filter: $hover-shadow;
          cursor: pointer;
        }
      }
    }
    &.alert-dismissible .close {
      right: 30px;
      top: -30px;
    }

    &.alert-danger {
      background: url(~@/assets/img/modal/alert-danger.svg) center/100% 100%
        no-repeat;
    }
    &.alert-success {
      background: url(~@/assets/img/modal/alert-success.svg) center/100% 100%
        no-repeat;
    }
    &.alert-primary {
      background: url(~@/assets/img/modal/alert-primary.svg) center/100% 100%
        no-repeat;
    }
    .banner-icon {
      width: 19%;
      height: 80px;
      .alert-icon {
        width: 86px;
        height: 82px;
        &.treasury-active,
        &.treasury-full {
          height: 65px;
        }
      }
    }
    .banner-text {
      height: 80px;
      line-height: 1.2;
      &.small {
        .banner-heading {
          font-size: 24px;
        }
        .banner-subline {
          font-size: 20px;
        }
      }
      &.smaller {
        .banner-subline {
          font-size: 14px;
        }
        .banner-heading {
          font-size: 18px;
        }
      }

      .banner-heading {
        font-size: 26px;
      }
      .banner-subline {
        font-size: 22px;
      }
    }
    .rewards-row {
      height: 26px;
    }
  }
}
</style>
