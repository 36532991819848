<template>
  <div
    :id="'canvas-container-animation-bonus-panel' + index"
    ref="canvascontainer"
    class="bonus-panel-spine"
  ></div>
</template>

<script>
import AnimationSpineBaseFunctions from "@/components/animation/animation-spine-base-functions";
import { Spine } from "pixi-spine";

export default {
  name: "AnimationSpineBonusPanel",
  mixins: [AnimationSpineBaseFunctions],
  props: {
    loop: {
      type: Boolean,
      default: true,
    },
    run: {
      type: Boolean,
      default: true,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      unsubscribeEvents: [],
      version: 1,
      restart: false,
      assetPath: "/assets/spines/bonus-slot/",
      destroyApp: false,
    };
  },

  watch: {
    run: function () {
      this.restart = true;
    },
  },
  beforeDestroy() {
    //destroy event listeners
    for (let i = 0; i < this.unsubscribeEvents.length; i++) {
      this.unsubscribeEvents[i]();
    }

    this.destroyApp = true;
  },
  mounted: function () {
    this.play();
  },

  methods: {
    play: function () {
      const assets = [
        [
          "main",
          this.assetPath + "win-amount-display-ani.json?v=" + this.version,
        ],
      ];
      let options = {
        width: 327.05,
        height: 94.21,
        antialias: true,
        backgroundAlpha: 0,
        autoResize: true,
      };

      const app = this.createCanvas(
        options,
        assets,
        this.$refs.canvascontainer.id
      );
      app.view.id = "canvas-animation-bonus-panel";

      app.ticker.add(() => {
        if (this.destroyApp) {
          this.destroyApp = false;
          this.destroyAll(app);
        }
      });

      const setup = (loader, res) => {
        let spineData = this.getSpineData(res);

        spineData.forEach((data) => {
          const spine = new Spine(data);

          // set the position
          spine.x = app.screen.width + data.x;
          spine.y = app.screen.height + data.y;

          //add spine to stage
          app.stage.addChild(spine);

          //set audio
          this.setupSpineAudio(spine);

          //run animation
          let animation;
          let setupAnimation = () => {
            //choose animation
            if (this.run) {
              animation = this.getAnimationByName(data, "main");
            } else {
              animation = this.getAnimationByName(data, "idle");
            }
            spine.state.setAnimation(0, animation.name);

            //set animation end
            spine.state.getCurrent(0).animationEnd = animation.duration;

            //add loop
            if (this.loop) {
              spine.state.getCurrent(0).loop = true;
            }
          };
          setupAnimation();

          // Detach event listeners
          this.unsubscribeEvents.push(() => {
            spine.state.getCurrent(0).onEvent = null;
          });

          //ticker
          app.ticker.add(() => {
            if (this.restart) {
              this.restart = false;
              setupAnimation();
            }

            if (this.destroyApp) {
              this.destroyApp = false;
              this.destroyAll(app);
            }
          });
        });
      };

      app.loader.load(setup);
      app.loader.onComplete.add(() => {
        this.$emit("done");
      });
    },
  },
};
</script>

<style lang="scss">
#canvas-container-animation-bonus-panel {
  pointer-events: none;
  position: relative;
  z-index: 1;
}
</style>
