<template>
  <div id="champions-hero-box">
    <div class="preload"></div>

    <!--    TODO: Info text-->
    <InfoBadge
      id="info-champions"
      :info-text="$t('info_badge.champions.hero_section')"
      size="lg"
    />
    <div
      v-if="!isBattleExpired && currentBattle.started_at"
      class="countdown-container match"
    >
      <div class="countdown-text">
        {{ $t("champions.hero_box.countdown_match") }}
      </div>
      <div class="countdown">
        <CountdownDigital
          v-if="matchEndTime"
          :time="matchEndTime"
          prefix="- "
          :with-time-diff="true"
        />
      </div>
    </div>
    <div class="button-container to-center-abs">
      <div
        class="button-start to-center-abs btn ok-button-green round-new center clickable"
        @click="startMatch"
      >
        <template v-if="isBattleActive && !isBattleExpired">
          {{ $t("champions.hero_box.resume_match") }}</template
        >
        <template v-else> {{ $t("champions.hero_box.start_match") }}</template>
      </div>
    </div>
    <div class="countdown-container">
      <div class="countdown-text">{{ $t("champions.hero_box.countdown") }}</div>
      <div class="countdown">
        <CountdownDigital
          v-if="matchdayEndTime"
          :time="matchdayEndTime"
          prefix="- "
          :with-time-diff="true"
          @over.once="$root.$emit('update-matchday')"
        />
      </div>
    </div>
  </div>
</template>
<script>
import CountdownDigital from "@/components/base/countdown-digital";
import * as ChampionsAPI from "@/API/champions";
import InfoBadge from "@/components/base/info-badge";
import dateFunctions from "@/mixins/dateFunctions.js";
import { getSettings } from "@/API/static-config";

export default {
  name: "ChampionsHeroBox",
  components: { InfoBadge, CountdownDigital },
  mixins: [dateFunctions],
  data() {
    return {
      battleDuration: 300,
      settingsData: null,
    };
  },
  computed: {
    settings() {
      if (this.settingsData) {
        return this.settingsData.data;
      }
      return null;
      // return this.$store.state.generalConfig.settings;
    },

    user() {
      return this.$store.getters["user/currentUser"];
    },

    isBattleExpired() {
      if (!this.currentBattle) {
        return true;
      }

      if (!this.currentBattle.started_at) {
        return false;
      }

      if (this.currentBattle.ended_at) {
        return true;
      }

      if (!this.settings) {
        return;
      }

      let interval = this.battleDuration;
      if (this.settings["champions.user.battle.interval"]) {
        interval = this.settings["champions.user.battle.interval"];
      }
      let endDate = new Date(this.currentBattle.started_at);
      endDate.setSeconds(endDate.getSeconds() + interval - 1);

      const today = this.getCurrentDate();
      return this.isDateInPast(endDate, today);
    },

    currentBattle() {
      return this.$store.getters["champions/getCurrentBattle"];
    },

    currentMatchday() {
      return this.$store.getters["champions/getCurrentMatchday"];
    },

    matchdayEndTime() {
      if (!this.currentMatchday) {
        return;
      }

      return new Date(this.currentMatchday.ended_at).getTime();
    },
    matchEndTime() {
      if (!this.isBattleActive) {
        return null;
      }

      if (!this.settings) {
        return;
      }

      let interval = this.battleDuration;
      if (this.settings["champions.user.battle.interval"]) {
        interval = this.settings["champions.user.battle.interval"];
      }
      let date = new Date(this.currentBattle.started_at);
      date.setSeconds(date.getSeconds() + interval + 1);

      return date.getTime();
    },

    isBattleActive() {
      if (!this.currentBattle) {
        return false;
      }

      return !this.currentBattle.battle.ended_at;
    },
  },

  created() {
    this.fetchSettings();
  },
  mounted() {
    if (this.$route.query.rematch) {
      this.startMatch();
    }
  },
  methods: {
    startMatch() {
      this.$store.commit("champions/setCurrentOpponentId", null);
      this.$store.commit("champions/setCurrentBattle", null);

      ChampionsAPI.createBattle()
        .then((res) => {
          this.$store.commit("champions/setCurrentBattle", res.battle);
          const gameId = res.battle.battle.game_id;
          this.$router.push("/champions/game/" + gameId);
        })
        .catch((e) => {
          console.log("ERROR", e);
          console.log("error exact", e.message);
        });
    },

    fetchSettings() {
      getSettings().then((res) => {
        this.settingsData = res;
      });
    },
  },
};
</script>
<style scoped lang="scss">
#champions-hero-box {
  width: 100%;
  height: 100%;
  position: relative;

  .preload {
    height: 0;
    width: 0;
    background-image: url(~@/assets/img/cards/btn-wish-card-hover.png),
      url(~@/assets/img/cards/btn-wish-card-hover.png);
  }

  .button-container {
    bottom: 5.5rem;

    .button-start {
      width: 16rem;
      height: 3.5rem;
      font-size: 1.313rem;
      color: white;
      font-family: Ubuntu-Bold;
      border-radius: 1.75rem;
      line-height: 3.2rem;
    }
  }

  .countdown-container {
    position: absolute;
    bottom: 2.125rem;
    right: 23%;
    background-color: #050d1c;
    border-radius: 0.5rem;
    width: 16rem;
    height: 3.5rem;
    text-align: center;
    padding: 0.313rem 0.625rem 0.313rem 0.625rem;
    &.match {
      left: 23%;
    }

    .countdown-text {
      font-family: Ubuntu-Medium;
      font-size: 0.75rem;
      color: #8e8e8e;
      text-transform: uppercase;
    }

    .countdown {
      text-shadow: 0 0 1rem rgba(77, 229, 255, 0.6),
        0 0 0.5rem rgba(77, 229, 255, 0.6), 0 0 0.5rem rgba(77, 229, 255, 0.6),
        0 0 0.25rem rgba(0, 94, 110, 0.8), 0 0 0.25rem rgba(0, 94, 110, 0.8);
      text-transform: uppercase;
      font-size: 1.5rem;
      font-family: Ubuntu-Bold;
      color: white;
      line-height: 1.2;
    }
  }
}
</style>

<style lang="scss">
#champions-hero-box {
  .info-badge-orange {
    right: 30%;
  }
}
</style>
