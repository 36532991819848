<template>
  <b-col id="offers-col" class="d-flex"
    ><div v-if="megaPackage && normalPackage" id="day-one" class="day-column">
      <div class="date-field">
        {{ dayOneDate }}
      </div>
      <div class="heading-field">
        <span class="inset-text">
          {{ $t("events.olympics.chance_bundles") }}</span
        >
      </div>
      <div v-if="megaPackageDenied" class="icon-denied mega"></div>
      <div v-if="normalPackageDenied" class="icon-denied normal"></div>

      <div id="mega-deal" :class="[megaPackageDisabled ? 'disabled' : '']">
        <div id="mega-deal-badge" class="to-center-abs">MEGA-DEAL</div>
        <div v-if="megaPackageGold" class="deal-item mega w-100">
          <span> {{ megaPackageGold }}</span>
          <div class="deal-icon gold"></div>
        </div>
        <div v-if="megaPackageCrowns" class="deal-item mega w-100">
          <span> {{ megaPackageCrowns }}</span>
          <div class="deal-icon crown"></div>
        </div>
        <div v-if="megaPackageTurbo" class="deal-item mega extra w-50">
          <span>{{ megaPackageTurbo }} Min.</span>
          <div class="deal-icon turbo"></div>
        </div>
        <div v-if="megaPackageLoyalty" class="deal-item mega extra w-50">
          <span> {{ megaPackageLoyalty }}</span>
          <div class="deal-icon loyalty"></div>
        </div>
        <div v-if="megaPackagePurchased" class="checkmark-green mega"></div>
        <button
          class="buy-button mega clickable"
          :disabled="!purchasePossible || megaPackagePurchased"
          @click="$emit('buy-package', megaPackage)"
        >
          <span>{{ $t("events.olympics.buy_button") }}</span>
          <span class="price">{{ megaPackagePrice }} €</span>
        </button>
      </div>
      <div id="normal-deal" :class="[normalPackageDisabled ? 'disabled' : '']">
        <div v-if="normalPackageGold" class="deal-item w-100">
          <span> {{ normalPackageGold }}</span>
          <div class="deal-icon gold"></div>
        </div>
        <div v-if="normalPackageCrowns" class="deal-item w-50">
          <span> {{ normalPackageCrowns }}</span>
          <div class="deal-icon crown"></div>
        </div>
        <div v-if="normalPackageLoyalty" class="deal-item w-50">
          <span> {{ normalPackageLoyalty }}</span>
          <div class="deal-icon loyalty"></div>
        </div>
        <div v-if="normalPackageTurbo" class="deal-item w-50">
          <span>{{ normalPackageTurbo }}</span>
          <div class="deal-icon loyalty"></div>
        </div>
        <div v-if="normalPackagePurchased" class="checkmark-green normal"></div>

        <button
          class="buy-button clickable"
          :disabled="!purchasePossible || normalPackagePurchased"
          @click="$emit('buy-package', normalPackage)"
        >
          <span>{{ $t("events.olympics.buy_button") }}</span>
          <span class="price">{{ normalPackagePrice }} €</span>
        </button>
      </div>
    </div>
    <div id="day-two" class="day-column">
      <div class="date-field">
        {{ dayTwoDate }}
      </div>
      <div class="heading-field">
        <div id="ger-icon"></div>
        <span class="inset-text">{{ $t("events.olympics.medals>other") }}</span>
      </div>
      <div id="medals-col">
        <div class="medals-row">
          <span v-if="medalsDecided">{{ goldMedals }}x</span>
          <div v-else class="points-small"></div>
          <AnimationGoforgoldMedal :index="0" medal="gold" />
        </div>
        <div class="medals-row">
          <span v-if="medalsDecided">{{ silverMedals }}x</span>
          <div v-else class="points-small"></div>
          <AnimationGoforgoldMedal :index="1" medal="silver" />
        </div>
        <div class="medals-row">
          <span v-if="medalsDecided">{{ bronzeMedals }}x</span>
          <div v-else class="points-small"></div>
          <AnimationGoforgoldMedal :index="2" medal="bronze" />
        </div>
        <div class="medals-row">
          <button
            id="medal-info-button"
            class="clickable"
            @click="$bvModal.show('modal-olympia-medal-bonus')"
          >
            {{ $t("events.olympics.info_button_text") }}
          </button>
        </div>
      </div>
    </div>
    <div id="day-three" class="day-column">
      <div class="date-field">
        {{ dayThreeDate }}
      </div>
      <div class="heading-field">
        <span class="bonus-text"> Bonus</span>
      </div>
      <div
        v-if="bonusAvailable"
        id="bonus-col"
        class="d-flex flex-column justify-content-around"
      >
        <div class="bonus-row">
          <div class="bonus-item gold"></div>
          <div class="bonus-amount">
            {{ raffleRewardGold }}
          </div>
        </div>
        <div
          v-if="raffleRewardCrowns && raffleRewardCrowns > 0"
          class="bonus-row"
        >
          <div class="bonus-item crown"></div>
          <div class="bonus-amount">
            {{ raffleRewardCrowns }}
          </div>
        </div>
        <div
          v-if="bonusCollected"
          class="w-100 d-inline-flex align-items-center justify-content-center"
        >
          <div class="checkmark-green"></div>
        </div>
        <div
          v-else
          id="collect-button"
          class="clickable"
          @click="$emit('bonus-collected', matchdayUserRaffle.raffle_id)"
        >
          {{ $t("shop.complete.button") }}
        </div>
      </div>
      <div
        v-else
        id="bonus-col"
        class="d-flex flex-column align-items-center justify-content-between py-5"
      >
        <div>
          <span
            v-if="isToday || isYesterday"
            style="font-size: 21px"
            class="text-white text-uppercase medium"
            >{{ $t("events.olympics.bonus_available_from") }}</span
          >
        </div>
        <div class="points-big"></div>
      </div>
    </div>
    <ModalOlympiaMedalBonus />
  </b-col>
</template>
<script>
import ModalOlympiaMedalBonus from "@/components/events/olympia/modal-olympia-medal-bonus.vue";
import AnimationGoforgoldMedal from "@/components/animation/animation-spine-goforgold-medal.vue";
export default {
  name: "ModalOlympiaDayCols",
  components: { ModalOlympiaMedalBonus, AnimationGoforgoldMedal },
  props: {
    matchday: {
      type: Object,
      default: () => {},
    },
    isToday: {
      type: Boolean,
      default: false,
    },
    isYesterday: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    megaPackage: {
      type: Object,
      default: () => {},
    },
    normalPackage: {
      type: Object,
      default: () => {},
    },
    userIngameEvent: {
      type: Object,
      default: () => {},
    },
    matchdayIngameEvent: {
      type: Object,
      default: () => {},
    },
    dayOneDate: {
      type: String,
      default: "",
    },
    dayTwoDate: {
      type: String,
      default: "",
    },
    dayThreeDate: {
      type: String,
      default: "",
    },
  },
  computed: {
    allRaffles() {
      return this.$store.state.events.paymentEventRaffles;
    },
    userRaffles() {
      return this.$store.state.events.paymentEventUserRaffles;
    },

    matchdayRaffle() {
      if (!this.allRaffles) return null;
      return this.allRaffles.find(
        (raffle) => raffle.raffleable_id === this.matchday.id
      );
    },
    matchdayUserRaffle() {
      if (!this.userRaffles) return null;
      return this.userRaffles.find(
        (userRaffle) => userRaffle.raffle.raffleable_id === this.matchday.id
      );
    },

    megaPackageDisabled() {
      if (
        (!this.packagePurchased && this.purchasePossible) ||
        this.megaPackagePurchased
      ) {
        return false;
      }

      return true;
    },
    normalPackageDisabled() {
      if (
        (!this.packagePurchased && this.purchasePossible) ||
        this.normalPackagePurchased
      ) {
        return false;
      }
      return true;
    },
    megaPackageDenied() {
      if (!this.packagePurchased) return false;
      return !this.megaPackagePurchased && this.normalPackagePurchased;
    },
    normalPackageDenied() {
      if (!this.packagePurchased) return false;
      return this.megaPackagePurchased && !this.normalPackagePurchased;
    },
    purchasePossible() {
      return this.isToday && !this.packagePurchased && this.isActive;
    },
    packagePurchased() {
      return this.megaPackagePurchased || this.normalPackagePurchased;
    },
    medalsDecided() {
      if (this.matchdayRaffle) {
        return true;
      }
      return false;
    },
    bonusAvailable() {
      if (this.matchdayUserRaffle || this.bonusCollected) {
        return true;
      } else {
        return false;
      }
    },
    bonusCollected() {
      if (!this.matchdayUserRaffle) {
        return false;
      } else {
        if (this.matchdayUserRaffle.status === "COLLECTED") {
          return true;
        }
        return false;
      }
    },
    megaPackagePurchased() {
      if (!this.matchdayIngameEvent) return false;
      if (
        this.matchdayIngameEvent.payload.packages &&
        this.matchdayIngameEvent.payload.packages.includes(
          this.megaPackage.payment_offer_id
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    normalPackagePurchased() {
      if (!this.matchdayIngameEvent) return false;
      if (
        this.matchdayIngameEvent.payload.packages &&
        this.matchdayIngameEvent.payload.packages.includes(
          this.normalPackage.payment_offer_id
        )
      ) {
        return true;
      }

      return false;
    },

    goldMedals() {
      if (!this.matchdayRaffle) return;
      return this.matchdayRaffle.gold;
    },
    silverMedals() {
      if (!this.matchdayRaffle) return;
      return this.matchdayRaffle.silver;
    },
    bronzeMedals() {
      if (!this.matchdayRaffle) return;
      return this.matchdayRaffle.bronze;
    },
    raffleRewardGold() {
      if (!this.matchdayUserRaffle) return null;
      return this.formatNumber(this.matchdayUserRaffle.gold);
    },
    raffleRewardCrowns() {
      if (!this.matchdayUserRaffle) return null;
      return this.formatNumber(this.matchdayUserRaffle.crowns);
    },
    megaPackageGold() {
      let megaGoldObject = this.megaPackage.package.items.find(
        (item) => item.item.type === "gold"
      );
      if (megaGoldObject) {
        return this.formatNumber(megaGoldObject.item.amount);
      } else {
        return null;
      }
    },
    normalPackageGold() {
      let normalGoldObject = this.normalPackage.package.items.find(
        (item) => item.item.type === "gold"
      );
      if (normalGoldObject) {
        return this.formatNumber(normalGoldObject.item.amount);
      } else {
        return null;
      }
    },
    megaPackageCrowns() {
      let megaCrownObject = this.megaPackage.package.items.find(
        (item) => item.item.type === "crown"
      );
      if (megaCrownObject) {
        return this.formatNumber(megaCrownObject.item.amount);
      } else {
        return null;
      }
    },
    normalPackageCrowns() {
      let normalCrownObject = this.normalPackage.package.items.find(
        (item) => item.item.type === "crown"
      );
      if (normalCrownObject) {
        return this.formatNumber(normalCrownObject.item.amount);
      } else {
        return null;
      }
    },
    megaPackageTurbo() {
      let megaTurboObject = this.megaPackage.package.items.find((item) =>
        item.item.asset.endsWith("Timer")
      );
      if (megaTurboObject) {
        return megaTurboObject.item.amount / 60;
      } else {
        return null;
      }
    },
    normalPackageTurbo() {
      let normalTurboObject = this.normalPackage.package.items.find((item) =>
        item.item.asset.endsWith("Timer")
      );
      if (normalTurboObject) {
        return normalTurboObject.item.amount / 60;
      } else {
        return null;
      }
    },
    megaPackageLoyalty() {
      let megaLoyaltyObject = this.megaPackage.package.items.find(
        (item) => (item) => item.item.type === "loyalty"
      );
      if (megaLoyaltyObject) {
        return megaLoyaltyObject.item.amount;
      } else {
        return null;
      }
    },
    normalPackageLoyalty() {
      let normalLoyaltyObject = this.normalPackage.package.items.find(
        (item) => (item) => item.item.type === "loyalty"
      );
      if (normalLoyaltyObject) {
        return normalLoyaltyObject.item.amount;
      } else {
        return null;
      }
    },
    megaPackagePrice() {
      return this.formatPrice(this.megaPackage.package.price);
    },
    normalPackagePrice() {
      return this.formatPrice(this.normalPackage.package.price);
    },
  },
};
</script>
<style lang="scss">
#offers-col {
  width: 914px;
  height: 582px;
  background: url(~@/assets/img/events/gfg-main-display.png) center/100% 100%
    no-repeat;
  padding: 10px 50px 17px 50px;
  .checkmark-green {
    width: 66px;
    height: 66px;
    background: url(~@/assets/img/events/icn-checkmark-green.svg) center/100%
      100% no-repeat;
    &.mega {
      position: absolute;
      bottom: 9%;
    }
    &.normal {
      position: absolute;
      bottom: 30px;
    }
  }
  .disabled {
    filter: grayscale(100) brightness(50%);
    pointer-events: none;
  }
  .icon-denied {
    width: 112px;
    height: 112px;
    background: url(~@/assets/img/events/icn-denied-red.svg) center/100% 100%
      no-repeat;
    z-index: 5;
    position: absolute;
    left: 120px;
    &.mega {
      top: 188px;
    }
    &.normal {
      top: 425px;
    }
  }

  .day-column {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 16px;

    .date-field {
      width: 100%;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: Ubuntu-medium;
      text-transform: uppercase;
      font-size: 26px;
      color: #c8c8c8;
    }
    .heading-field {
      width: 100%;
      height: 74px;
      text-transform: uppercase;
      display: inline-flex;
      align-items: center;
      justify-content: space-around;
      line-height: 1;
      font-size: 16px;
      #ger-icon {
        width: 39px;
        height: 39px;
        background: url(~@/assets/img/events/gfg-icn-germany.png) center/100%
          100% no-repeat;
      }
      .inset-text {
        font-size: 28px;
        font-family: Ubuntu-medium;
        color: black;
        text-shadow: 0 1px 0 white;
      }
      .bonus-text {
        font-family: Ubuntu-bold;
        font-size: 40px;
        color: white;
        text-shadow: 0 0 16px #ffe600, 0 0 8px #8b5a00;
      }
    }
    .deal-item {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;

      span {
        font-family: Ubuntu-medium;
        font-size: 32px;
        color: white;
        text-shadow: 0 0 12px rgba(0, 0, 0, 0.5), 0 0 6px rgba(0, 0, 0, 0.8);
      }
      .deal-icon {
        background-position: center;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin-left: 5px;
        &.gold {
          width: 32px;
          height: 32px;
          background-image: url(~@/assets/img/common/coin1.png);
        }
        &.crown {
          width: 43px;
          height: 32px;
          background-image: url(~@/assets/img/common/crown6.png);
        }
        &.turbo {
          width: 36px;
          height: 31px;
          background-image: url(~@/assets/img/common/booster.png);
        }
        &.loyalty {
          width: 40px;
          height: 40px;
          background-image: url(~@/assets/img/common/loyalty-bonus.png);
        }
      }
      &.mega {
        span {
          font-family: Ubuntu-bold;
          font-size: 36px;
          background-clip: text;
          -webkit-background-clip: text;
          color: transparent;
          //-webkit-text-fill-color: #ffee51;
          background-image: linear-gradient(to bottom, #f7ffff, #ffee51);
          text-shadow: none;
          filter: drop-shadow(0 0 8px #0a2700);
        }
      }
      &.extra {
        font-size: 26px;
      }
    }
    .buy-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 230px;
      height: 44px;
      background: linear-gradient(to bottom, #9bf811, #258c00 55%, #1e7000)
        center/100% 100% no-repeat;
      font-size: 16px;
      border-radius: 8px;
      margin-left: auto;
      margin-right: auto;
      border: none;
      span {
        font-family: Ubuntu-medium;
        font-size: 20px;
        color: white;
      }
      &:disabled {
        pointer-events: none;
      }

      .price {
        font-size: 30px;
        margin-left: 5px;
      }

      &.mega {
        width: 260px;
        height: 86px;
        background: url(~@/assets/img/events/gfg-btn-buy-mega.svg) center/100%
          100% no-repeat;

        span {
          font-family: Ubuntu-bold;
          font-size: 21px;
          color: #43ff00;

          &.price {
            font-size: 32px;
            text-shadow: 0 0 16px #43ff00, 0 0 8px #0a2700;
            margin-left: 10px;
          }
        }
      }
    }
  }

  #day-one {
    width: 36%;
    .date-field {
      color: $light-blue;
    }
    #mega-deal {
      width: 100%;
      height: 265px;
      position: relative;
      padding-top: 20px;
      &.disabled {
        background-color: black;
      }
      #mega-deal-badge {
        width: 199px;
        height: 56px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: url(~@/assets/img/events/gfg-badge-bg-active.svg)
          center/100% 100% no-repeat;
        color: #0a2700;
        font-family: Ubuntu-bold;
        font-size: 21.8px;
        top: -20px;
      }
    }
    #normal-deal {
      width: 100%;
      height: 171px;
      padding-top: 5px;
    }
  }
  #day-two {
    width: 26%;
    #medals-col {
      width: 100%;
      height: 80%;
      padding: 16px;
      .medals-row {
        height: 25%;
        width: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-family: Ubuntu-bold;
        font-size: 36px;
        .points-small {
          width: 51px;
          height: 12px;
          background: url(~@/assets/img/events/icn-dots-small.svg) center/100%
            100% no-repeat;
        }

        #medal-info-button {
          background-color: transparent;
          border: 2px solid #5499a4;
          border-radius: 8px;
          text-transform: uppercase;
          color: $light-blue;
          font-size: 18px;
          font-family: Ubuntu-bold;
          line-height: 1.2;
        }
      }
    }
  }
  #day-three {
    width: 38%;
    #bonus-col {
      width: 100%;
      height: 80%;
      position: relative;
      .points-big {
        width: 68px;
        height: 16px;
        background: url(~@/assets/img/events/icn-dots-big.svg) center/100% 100%
          no-repeat;
      }
      .bonus-row {
        height: 40%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        font-size: 16px;
        .bonus-amount {
          font-family: Ubuntu-bold;
          font-size: 40px;
          background-clip: text;
          -webkit-background-clip: text;
          color: transparent;
          background-image: linear-gradient(
            to bottom,
            #f7ffff,
            #ffee51 58%,
            #8b5a00
          );
          filter: drop-shadow(0 0 12px rgba(0, 0, 0, 0.5))
            drop-shadow(0 0 6px rgba(0, 0, 0, 0.8));
          line-height: 1;
        }
        .bonus-item {
          background-size: 100% 100%;
          background-position: center;
          background-repeat: no-repeat;
          &.gold {
            width: 196px;
            height: 122px;
            background-image: url(~@/assets/img/common/icn-coins-big.png);
          }
          &.crown {
            width: 120px;
            height: 98px;
            background-image: url(~@/assets/img/common/crown6.png);
          }
        }
      }
      #collect-button {
        width: 100%;
        height: 109px;
        background: url(~@/assets/img/events/btn-collect.png) center/100% 100%
          no-repeat;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 32px;
        font-family: Ubuntu-bold;
        text-shadow: 0 0 24px #43ff00, 0 0 16px #0a2700, 0 0 8px #0a2700;
        span {
          font-size: 36px;
          text-shadow: 0 0 16px rgba(67, 255, 0, 0.7),
            0 0 8px rgba(67, 255, 0, 0.7);
        }
      }
    }
  }
}
@media (max-width: 1200px) {
  #offers-col {
    .day-column {
      .deal-item {
        &.mega {
          span {
            font-size: 30px;
          }
        }
        &.extra {
          span {
            font-size: 20px;
          }
        }
      }
      .buy-button {
        width: 200px;
        &.mega {
          width: 212px;
          height: 65px;
          span {
            font-size: 18px;
          }
        }
      }
    }
    #day-one {
      #mega-deal {
        padding-top: 45px;
      }
    }
    #day-three {
      #bonus-col {
        .bonus-row {
          .bonus-amount {
            font-size: 34px;
          }
        }

        #collect-button {
          font-size: 28px;
        }
      }
    }
  }
}
</style>
