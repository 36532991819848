<template>
  <div>
    <b-modal
      id="modal-chest-storage-full"
      class="custom-modal3"
      :hide-footer="true"
      modal-class="custom-modal3"
      content-class="rectangular-dark-blue-modal"
    >
      <template slot="modal-header" slot-scope="{ close }">
        <a href="#" class="close-button" @click="close()"> </a>
      </template>

      <template slot="default">
        <DecoCorners color-variant="blue" />

        <div class="modal-icon to-center-abs">
          <img src="@/assets/img/mission/icon-exmark-red.png" class="w-100" />
        </div>

        <div class="headline1">
          {{ $t("mission.chest_full.title") }}
        </div>

        <div class="text">
          {{ $t("mission.chest_full.message") }}
        </div>

        <div id="button-container" class="button-container to-center-abs">
          <DecoButton color-variant="green" @click="openChestModal">
            {{ $t("mission.chest_full.message.button") }}
          </DecoButton>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import DecoCorners from "@/components/base/deco-corners.vue";
import DecoButton from "@/components/base/deco-button.vue";

export default {
  name: "ModalChestStorageFull",
  components: { DecoCorners, DecoButton },
  mounted: function () {
    // this.$bvModal.show("modal-chest-storage-full");
  },
  methods: {
    openChestModal: function () {
      this.$bvModal.hide("modal-chest-storage-full");
      this.$emit("handle-open-modal-chest");
    },
  },
};
</script>
<style lang="scss">
$modal-width: 654px;
$modal-height: 259px;

#modal-chest-storage-full {
  .modal-body {
    margin-top: -15px;
  }

  .modal-dialog {
    top: 5%;
    max-width: $modal-width;
    height: $modal-height;
  }

  .deco-corner {
    &.top-left {
      top: -20px;
      left: -5px;
    }
    &.top-right {
      top: -20px;
      right: -5px;
    }
    &.bottom-right {
      bottom: -5px;
      right: -5px;
    }

    &.bottom-left {
      bottom: -5px;
      left: -5px;
    }
  }

  #button-container {
    position: absolute;
    bottom: 20px;

    .deco-button {
      font-family: Ubuntu;
      font-size: 24px;
      width: 400px;
      height: 50px;

      .deco-gem {
        width: 6%;
        height: 125%;

        &.left {
          left: -3%;
          top: -6px;
        }
        &.right {
          right: -3%;
          top: -6px;
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
#modal-chest-storage-full {
  .modal-icon {
    width: 72px;
    top: -50px;
  }

  .headline1 {
    font-family: Ubuntu-Bold;
    color: white;
    font-size: 32px;
  }

  .text {
    font-family: Ubuntu;
    font-size: 26px;
    color: #c8c8c8;
    line-height: 33px;
    padding: 0 10px;
  }
}
</style>
