<template>
  <a
    class="vanilla-mission-task-box"
    :class="taskDisplayText.class"
    :href="taskDisplayText.href ? taskDisplayText.href : false"
  >
    <div class="task-container">
      <div class="number to-center-abs">
        {{ index }}
      </div>

      <div class="task-icon to-center-abs">
        <img
          :src="taskDisplayText.bg"
          class="h-100 task-icon-img1 to-center-abs"
        />
        <img
          :src="taskDisplayText.icon"
          class="h-100 task-icon-img2 to-center-abs"
        />
      </div>

      <div class="task-title to-center-abs">
        {{ shortenString(taskDisplayText.title, 24) }}
      </div>

      <div class="task-text to-center-abs" v-html="taskDisplayText.task"></div>

      <div class="progress-bar-container to-center-abs">
        <div class="progress-bar"></div>

        <div class="progress-fill-bg"></div>

        <div class="progress-fill-container">
          <div
            class="progress-fill"
            :style="{ width: taskDisplayText.missionProgress + '%' }"
          ></div>
        </div>

        <div class="progress-number to-center-abs">
          {{ taskDisplayText.missionProgress + "%" }}
        </div>

        <div
          v-if="taskDisplayText.taskComplete"
          class="progress-finish-icon to-center-abs"
        ></div>
      </div>
    </div>
  </a>
</template>

<script>
export default {
  name: "VanillaMissionTaskBox",
  props: {
    task: { type: Object, default: null },
    index: { type: Number, default: 0 },
  },
  data() {
    return {
      missionText: {},
    };
  },
  computed: {
    generalConfig: function () {
      return this.$store.getters["getGeneralConfig"];
    },
    user: function () {
      return this.$store.getters["user/currentUser"];
    },

    taskDisplayText: function () {
      let result = {};
      if (!this.task) {
        return;
      }

      const amount = this.formatNumber(this.task.target);
      const type = this.task.task.type.replace(
        "App\\Models\\Mission\\Task\\",
        ""
      );
      switch (type) {
        case "LevelUpTaskType":
          result.task = this.$t("mission.box.task." + type.toLowerCase(), {
            0: amount,
          });
          result.icon = require("@/assets/img/mission/icon-levelup.png");
          result.class = "task-levelup";
          result.title = this.$t("mission.box.title." + type.toLowerCase());
          break;
        case "TotalSlotWinTaskType":
          result.task = this.$t("mission.box.task." + type.toLowerCase(), {
            0: amount,
          });
          if (this.task.payload.game.images[0]) {
            result.icon = this.task.payload.game.images[0].url;
          }
          result.class = "task-game";
          result.href = "/game/" + this.task.payload.game.id;
          result.title = this.task.payload.game.title;
          break;
        case "InstantSlotWinTaskType":
          result.task = this.$t("mission.box.task." + type.toLowerCase(), {
            0: amount,
          });
          if (this.task.payload.game.images[0]) {
            result.icon = this.task.payload.game.images[0].url;
          }
          result.class = "task-game";
          result.href = "/game/" + this.task.payload.game.id;
          result.title = this.task.payload.game.title;
          break;
        case "SlotSpinTaskType":
          result.task = this.$t("mission.box.task." + type.toLowerCase(), {
            0: amount,
          });
          if (this.task.payload.game.images[0]) {
            result.icon = this.task.payload.game.images[0].url;
          }
          result.class = "task-game";
          result.href = "/game/" + this.task.payload.game.id;
          result.title = this.task.payload.game.title;
          break;
        case "CollectCrownsTaskType":
          result.task = this.$t("mission.box.task." + type.toLowerCase(), {
            0: amount,
          });
          result.icon = require("@/assets/img/common/crown6.png");
          result.class = "task-crown";
          result.title = this.$t("mission.box.title." + type.toLowerCase());
          break;
        case "FindCardsTaskType":
          result.title = this.$t("mission.box.title." + type.toLowerCase());
          result.class = "task-cards";
          result.icon = require("@/assets/img/mission/icon-cards.png");
          result.task = this.$t("mission.box.task." + type.toLowerCase(), {
            0: amount,
          });
          break;
        case "KingspathLevelTaskType":
          let substring = ">one";
          if (amount > 1) {
            substring = ">other";
          }
          result.task = this.$t(
            "mission.box.task." + type.toLowerCase() + substring,
            {
              0: amount,
            }
          );
          result.icon = require("@/assets/img/mission/icon-kingspath.png");
          result.class = "task-koenigsweg";
          result.title = this.$t("mission.box.title." + type.toLowerCase());

          if (
            this.generalConfig.settings["kingspath.entry.level"] <=
            this.user.level
          ) {
            result.href = "/kingspath";
          }
          break;
        case "AchievementTaskType":
          result.task = this.$t(
            "mission.box.task." + type.toLowerCase() + ">other",
            { 0: amount }
          );
          result.icon = require("@/assets/img/mission/icon-achievement.png");
          result.class = "task-achievement";
          result.href = "/profile/achievements";
          result.title = this.$t("mission.box.title." + type.toLowerCase());
          break;
        case "TournamentRankTaskType":
          result.task = this.$t("mission.box.task." + type.toLowerCase(), {
            0: amount,
          });
          result.icon = require("@/assets/img/mission/icon-tourny.png");
          result.class = "task-tournament";
          result.title = this.$t("mission.box.title." + type.toLowerCase());

          if (
            this.generalConfig.settings["tournament.entry.level"] <=
            this.user.level
          ) {
            result.href = "/tournament";
          }
          break;
      }

      //current task progress
      if (this.task.progress_percentage !== undefined) {
        result.missionProgress = Math.round(this.task.progress_percentage);
        if (this.task.progress_percentage >= 100) {
          result.taskComplete = true;
        }
      }

      return result;
    },
  },
};
</script>
<style lang="scss" scoped>
.vanilla-mission-task-box {
  display: block;
  width: 252px;
  height: 459px;
  background: url(~@/assets/img/mission/mission-task-panel-back.png) no-repeat;
  background-size: 100% 100%;
  text-decoration: none;

  .task-container {
    width: 258px;
    margin-left: -5px;
    height: 100%;
    position: relative;

    .number {
      font-family: Ubuntu;
      color: #fad5a8;
      font-size: 24px;
      top: 0;
      margin-left: -3px;
    }

    .task-icon {
      top: 70px;
      height: 107px;

      .task-icon-img1 {
        z-index: 5;
      }
      .task-icon-img2 {
        z-index: 10;
      }
    }

    .task-title {
      font-family: Ubuntu-Bold;
      font-size: 23px;
      color: #4de5ff;
      text-transform: uppercase;
      top: 200px;
      padding: 0 22px;
      line-height: 1.2;
    }

    .task-text {
      font-family: Ubuntu-Bold;
      font-size: 24px;
      color: white;
      top: 255px;
      line-height: 1.2;
      padding: 0 30px;
    }

    .progress-bar-container {
      top: 370px;
      left: 7px;

      .progress-bar {
        position: absolute;
        top: 0px;
        width: 248px;
        height: 45px;
        background: url(~@/assets/img/mission/mission-task-progress-frame.png)
          no-repeat;
        background-size: 100% 100%;
        z-index: 5;
      }

      .progress-fill-bg {
        position: absolute;
        width: 95%;
        left: 2%;
        background-image: linear-gradient(to bottom, #000000, #343538, #8497a5);
        position: absolute;
        top: 0;
        height: 45px;
        mask-image: url(~@/assets/img/mission/mission-task-progress-mask.png);
        mask-repeat: no-repeat;
        -webkit-transform: translate3d(0, 0, 0);
        z-index: 2;
      }

      .progress-fill-container {
        position: absolute;
        width: 95%;
        left: 2%;

        .progress-fill {
          max-width: 100%;
          background-image: linear-gradient(
            to bottom,
            #fff7d3,
            #d79969,
            #56413e,
            #56413e
          );
          position: absolute;
          top: 0;
          height: 45px;
          mask-image: url(~@/assets/img/mission/mission-task-progress-mask.png);
          mask-repeat: no-repeat;
          -webkit-transform: translate3d(0, 0, 0);
          z-index: 3;
        }
      }

      .progress-number {
        top: 40px;
        font-family: Ubuntu-Bold;
        font-size: 21px;
        color: #fff8b7;
      }

      .progress-finish-icon {
        top: -15px;
        width: 64px;
        height: 64px;
        background: url(~@/assets/img/mission/miaaion-checkmark-task-done.png)
          no-repeat;
        background-size: 100% 100%;
        z-index: 20;
      }
    }
  }
}
</style>
