<script>
import AnimationSpineBaseFunctions from "@/components/animation/animation-spine-base-functions";
import { Spine } from "pixi-spine";

export default {
  name: "AnimationSpineRewardFlyHeader",
  mixins: [AnimationSpineBaseFunctions],
  props: {
    loop: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      intervalId: null,
      destroyApp: false,
    };
  },

  computed: {
    rewardType: function () {
      const result = this.$store.getters["animations/getDoRewardFlyHeader"];

      switch (result) {
        case "gold":
          return "gold";
        case "coin":
          return "gold";
        case "coins":
          return "gold";
        case "crown":
          return "crown";
        case "crowns":
          return "crown";
        case "card":
          return "card";
        case "cards":
          return "card";
        default:
          return null;
      }
    },
  },

  watch: {
    rewardType: function (newVal) {
      if (newVal) {
        this.play();
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.intervalId);

    this.destroyApp = true;
  },

  methods: {
    play: function () {
      let targetElement;
      let targetMarginY = 0;

      //load needed assets only
      const assets = [];
      switch (this.rewardType) {
        case "gold":
          assets.push([
            "gold",
            "/assets/spines/reward-fly/skeleton-coinfly-main.json",
          ]);
          targetElement = document.getElementById("header-gold-container");
          break;
        case "card":
          assets.push([
            "card",
            "/assets/spines/reward-fly/skeleton-cardfly-main.json",
          ]);
          targetElement = document.getElementsByName(
            "page-item-lobby.navigation.trading_cards"
          )[0];
          break;
        case "crown":
          assets.push([
            "crown",
            "/assets/spines/reward-fly/skeleton-crownfly-main.json",
          ]);
          targetElement = document.getElementById("header-crown-container");
          break;
        default:
          assets.push([
            "gold",
            "/assets/spines/reward-fly/skeleton-coinfly-main.json",
          ]);
          targetElement = document.getElementById("header-gold-container");
          break;
      }

      if (!targetElement) {
        return;
      }

      //reset store value
      this.$store.commit("animations/setDoRewardFlyHeader", null);

      const options = {
        width: window.innerWidth,
        height: window.innerHeight,
        antialias: true,
        backgroundAlpha: 0,
        autoResize: true,
      };
      const app = this.createCanvas(options, assets);
      app.view.id = "canvas-fly-header-" + +Math.floor(Math.random() * 100);
      app.view.classList.add("canvas-full-width");

      app.ticker.add(() => {
        if (this.destroyApp) {
          this.destroyApp = false;
          this.destroyAll(app);
        }
      });

      const setup = (loader, res) => {
        let spineData = this.getSpineData(res);

        spineData.forEach((data) => {
          const spine = new Spine(data);
          const skeleton = spine.skeleton;

          //start point
          const boneBegin = skeleton.findBone("ctrl-start");
          boneBegin.x = app.screen.width;
          boneBegin.y = -app.screen.height;

          //end point
          const boneEnd = skeleton.findBone("ctrl-end");
          boneEnd.x =
            targetElement.getBoundingClientRect().left * 2 +
            targetElement.offsetWidth;
          boneEnd.y =
            -targetElement.getBoundingClientRect().top * 2 -
            targetElement.offsetHeight +
            targetMarginY;

          //set scale
          spine.scale.set(0.5);

          //add spine to stage
          app.stage.addChild(spine);

          //get correct animation
          let animation = this.getAnimationByName(data, "ani-fly");

          //set animation end
          const duration = animation.duration * 1000;

          //run animation
          spine.state.setAnimation(0, animation.name, false);

          //add loop
          if (this.loop) {
            this.intervalId = setInterval(() => {
              spine.state.setAnimation(0, animation.name, false);
            }, duration);
          } else {
            setTimeout(() => {
              this.destroyAll(app);
            }, duration);
          }
        });
      };

      app.loader.load(setup);
    },
  },
};
</script>

<style lang="scss"></style>
