import { optionsFunction } from "./_config";
const axios = require("axios");

export const fetchChests = () =>
  axios
    .get(`/api/chests/list`, optionsFunction())
    .then((res) => res.data.data)
    .catch((e) => console.log(e));

export const openChest = (user_chest_id) =>
  axios
    .post(
      `/api/chests/open`,
      {
        user_chest_id: user_chest_id,
      },
      optionsFunction()
    )
    .then((res) => res.data.data)
    .catch((e) => e.data);

export const forceOpenChest = (user_chest_id) =>
  axios
    .post(
      `/api/chests/forceopen`,
      {
        user_chest_id: user_chest_id,
      },
      optionsFunction()
    )
    .then((res) => res.data.data)
    .catch((e) => e.data);

export const debugChest = () =>
  axios
    .post(`/api/chests/debug`, {}, optionsFunction())
    .then((res) => res.data.data)
    .catch((e) => console.log(e));

export const fetchChestRewards = () =>
  axios
    .get(`/api/chests/rewards`, optionsFunction())
    .then((res) => res.data.data)
    .catch((e) => console.log(e));
