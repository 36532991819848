<template>
  <div
    id="canvas-container-animation-spine-chest"
    ref="canvascontainer"
    class="canvas-container-animation-spine-chest"
  ></div>
</template>

<script>
import AnimationSpineBaseFunctions from "@/components/animation/animation-spine-base-functions";
import {Spine} from "pixi-spine";

export default {
  name: "AnimationSpineChest",
  mixins: [AnimationSpineBaseFunctions],
  props: {
    chestLevel: {
      type: Number,
      required: true,
    },
    loop: {
      type: Boolean,
      default: false,
    },
    run: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      intervalId: null,
      unsubscribeEvents: [],
      destroyApp: false,
    };
  },

  watch: {
    run: function (newVal) {
      if (newVal) {
        this.play();
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.intervalId);

    //destroy event listeners
    for (let i = 0; i < this.unsubscribeEvents.length; i++) {
      this.unsubscribeEvents[i]();
    }

    this.destroyApp = true;
  },
  mounted: function () {
    this.play();
  },

  methods: {
    play: function () {
      const canvasEl = document.getElementById("canvas-animation-spine-chest");

      const assets = [
        ["chest", "/assets/spines/chest/missions-ani-chest-all-chests.json"],
        ["coin1", "/assets/spines/chest/missions-ani-chest-coinfireworks.json"],
        ["coin2", "/assets/spines/chest/missions-ani-chest-coinfireworks.json"],
        ["coin3", "/assets/spines/chest/missions-ani-chest-coinfireworks.json"],
        ["coin4", "/assets/spines/chest/missions-ani-chest-coinfireworks.json"],
        ["coin5", "/assets/spines/chest/missions-ani-chest-coinfireworks.json"],
        ["particles", "/assets/spines/chest/missions-ani-chest-particles.json"],
      ];
      const options = {
        width: 1200,
        height: 1000,
        antialias: true,
        backgroundAlpha: 0,
        autoResize: true,
      };

      const app = this.createCanvas(
        options,
        assets,
        this.$refs.canvascontainer.id
      );
      app.view.id = "canvas-animation-spine-chest";

      app.ticker.add(() => {
        if (this.destroyApp) {
          this.destroyApp = false;
          this.destroyAll(app);
        }
      });

      const setup = (loader, res) => {
        let spineData = this.getSpineData(res);

        spineData.forEach((data) => {
          const spine = new Spine(data);

          //skeleton and animation configs
          let animationIndex = 0;

          //chest data
          if (data.name === "chest") {
            this.setSkinByName(spine.skeleton, this.chestLevel + "-chest");
            if (this.chestLevel === 5) {
              animationIndex = 1;
            }
          }

          // set the position
          spine.x = app.screen.width / 2;
          spine.y = app.screen.height - 150;

          //set scale
          spine.scale.set(0.9);

          //add spine to stage
          app.stage.addChild(spine);

          let animation = data.animations[animationIndex];
          //run animation
          spine.state.setAnimation(0, animation.name);

          //show first frame when not running
          if (!this.run) {
            spine.state.getCurrent(0).animationEnd = 0;
            return;
          }

          //set animation end
          spine.state.getCurrent(0).animationEnd = animation.duration;

          //coin firework data
          if (data.name.indexOf("coin") !== -1) {
            const currentCoinIndex = parseInt(data.name.substring(4, 5));
            if (currentCoinIndex < this.chestLevel) {
              spine.state.getCurrent(0).animationStart +=
                0.1 * currentCoinIndex;
              spine.state.getCurrent(0).animationEnd += 0.1 * currentCoinIndex;
            }
          }

          // Detach event listeners
          this.unsubscribeEvents.push(() => {
            spine.state.getCurrent(0).onEvent = null;
          });

          //play audio event
          spine.state.getCurrent(0).onEvent = () => {
            this.playSoundEffect(
              require("@/assets/sound/vanilla-mission/kleinekrone-ani-snd-main-441.mp3")
            );
          };

          //add loop
          if (this.loop) {
            spine.state.getCurrent(0).loop = true;
          }
        });
      };

      app.loader.load(setup);

      //remove old chest when new loaded
      app.loader.onComplete.add(() => {
        if (canvasEl) {
          this.removePrevCanvas(this.$refs.canvascontainer.id);
        }
      });
    },
  },
};
</script>

<style lang="scss">
.canvas-container-animation-spine-chest {
  pointer-events: none;

  canvas {
    position: absolute;
    top: -500px;
    left: -330px;
  }
}
</style>
