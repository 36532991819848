<template>
  <div>
    <div
      v-if="eventData"
      :id="'event-' + eventData.id"
      class="event-badge position-relative"
      @click="showEventModal"
    >
      <div class="menu-event-icon" :class="eventType"></div>
      <div v-if="isBadgeLocked" class="lock-icon dead-center"></div>
      <component
        :is="eventComponent"
        :event-data="eventData"
        :modal-id="eventModalId"
      ></component>
    </div>

    <div
      v-else-if="winnerData"
      class="event-badge position-relative clickable"
      @click="showWinnerModal"
    ></div>
  </div>
</template>
<script>
import ModalSpinwinEvent from "@/components/events/modal-spinwin-event.vue";
import ModalLevelupEvent from "@/components/events/modal-levelup-event.vue";
import ModalOlympiaEvent from "@/components/events/olympia/modal-olympia-event.vue";
import ModalBundesligaEvent from "@/components/events/bundesliga/modal-bundesliga-event.vue";
import ModalWinterBonusJagd from "@/components/events/winter-bonus-jagd/modal-winter-bonus-jagd";
import ModalSummerBonusJagd from "@/components/events/summer-bonus-jagd/modal-summer-bonus-jagd";
import eventPopups from "@/mixins/eventPopups.js";
export default {
  name: "EventBadgeLight",
  components: {
    ModalSpinwinEvent,
    ModalLevelupEvent,
    ModalOlympiaEvent,
    ModalBundesligaEvent,
    ModalWinterBonusJagd,
    ModalSummerBonusJagd,
  },
  props: {
    eventData: {
      type: Object,
      default: null,
      required: false,
    },
    winnerData: {
      type: Array,
      default: null,
      required: false,
    },
    isCustomBadge: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    eventType() {
      let eventType;
      if (this.eventData && this.eventData.type) {
        eventType = eventPopups.methods.getEventTypeName(this.eventData.type);
      } else if (this.eventData && this.eventData.title) {
        eventType = eventPopups.methods.getPassTypeName(this.eventData.title);
      } else {
        eventType = "";
      }

      if (eventType === "payment") {
        if (this.eventData && this.eventData.name) {
          eventType = eventPopups.methods.getPassTypeName(this.eventData.name);
        }
      }

      return eventType;
    },
    eventComponent() {
      if (this.isCustomBadge) return "";
      if (this.eventData.type) {
        return (
          "Modal" +
          this.eventType.charAt(0).toUpperCase() +
          this.eventType.slice(1) +
          "Event"
        );
      } else if (this.eventType === "winterbonusjagd") {
        return "ModalWinterBonusJagd";
      } else if (this.eventType === "summerbonusjagd") {
        return "ModalSummerBonusJagd";
      } else {
        return "";
      }
    },
    eventModalId() {
      if (this.isCustomBadge || this.winnerData)
        return "modal-winter-bonus-jagd-winner";
      return `modal-${this.eventType.toLowerCase()}-event-${this.eventData.id}`;
    },

    isBadgeLocked() {
      if (this.isCustomBadge) return false;
      // return false;
      return (
        this.eventData.levels &&
        this.$store.getters["events/getIsCurrentPassLevelLocked"]
      );
    },
  },
  created() {
    this.$root.$on("open-event-modal", (payload) => {
      if (payload[0] === this.eventData.id) {
        this.showEventModal();
      } else if (
        this.eventData.title &&
        this.eventData.title.includes(payload[0])
      ) {
        this.showEventModal();
      }
    });
  },
  methods: {
    showEventModal() {
      this.playSoundButtonCommon();
      this.$bvModal.show(this.eventModalId);
    },
    showWinnerModal() {
      this.playSoundButtonCommon();
      this.$bvModal.show("modal-winter-bonus-jagd-winner");
    },
  },
};
</script>
<style lang="scss">
.event-badge {
  width: 100px;
  height: 100px;
  pointer-events: all;
  .lock-icon {
    position: absolute;
    width: 2.188rem;
    height: 2.938rem;
    background: url(~@/assets/img/events/winter-bonus-jagd/gold-lock.png)
      right/100% 100% no-repeat;
  }
  .menu-event-icon {
    width: 100%;
    height: 100%;

    &.spinwin {
      background: url(~@/assets/img/events/menu/icn-spinwin.png) center/4.375rem
        4.375rem no-repeat;
    }

    &.levelup {
      background: url(~@/assets/img/events/menu/icn-levelup.png) center/4.375rem
        4.375rem no-repeat;
    }

    &.goforgold {
      background: url(~@/assets/img/events/menu/icn-goforgold.png)
        center/4.375rem 4.375rem no-repeat;
    }

    &.tipking {
      background: url(~@/assets/img/events/menu/icn-tipking.png) center/4.375rem
        4.375rem no-repeat;
    }

    &.olympia {
      background: url(~@/assets/img/events/menu/icn-goforgold.png)
        center/4.375rem 4.375rem no-repeat;
    }

    &.bundesliga {
      background: url(~@/assets/img/events/menu/icn-tipking.png) center/4.375rem
        4.375rem no-repeat;
    }
    &.winter {
      background: url(~@/assets/img/events/menu/icn-winterbonusjagd.png)
        center/4.375rem 4.375rem no-repeat;
    }
    &.winterbonusjagd {
      background: url(~@/assets/img/events/menu/icn-winterbonusjagd.png)
        center/4.375rem 4.375rem no-repeat;
    }
  }
}
</style>
