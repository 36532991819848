<template v-if="config">
  <div>
    <b-modal
      id="modal-vanilla-mission"
      :hide-footer="true"
      modal-class="custom-modal1"
      @shown="onOpen"
      @hidden="onClose"
    >
      <template slot="modal-header" slot-scope="{ close }">
        <div class="preload"></div>
        <template v-if="showTestButton">
          <button
            v-if="!currentChest"
            style="
              position: absolute;
              left: -100px;
              top: 68%;
              width: 100px;
              z-index: 999;
              background-color: red;
              color: white;
            "
            @click="$emit('test-finish-mission')"
          >
            complete
          </button>
          <button
            v-if="currentChest"
            style="
              position: absolute;
              left: -100px;
              top: 100px;
              width: 100px;
              z-index: 999;
              background-color: red;
              color: white;
            "
            @click="$emit('test-transform-chest', 3)"
          >
            to silver chest
          </button>
          <button
            v-if="currentChest"
            style="
              position: absolute;
              left: -100px;
              top: 200px;
              width: 100px;
              z-index: 999;
              background-color: red;
              color: white;
            "
            @click="$emit('test-transform-chest', 4)"
          >
            to gold chest
          </button>
          <button
            v-if="currentChest"
            style="
              position: absolute;
              left: -100px;
              top: 300px;
              width: 100px;
              z-index: 999;
              background-color: red;
              color: white;
            "
            @click="$emit('test-transform-chest', 5)"
          >
            to king chest
          </button>
        </template>
        <a href="#" class="close-button" @click="close()"> </a>
        <div class="headline to-center-abs">
          <img
            :src="
              require(`@/assets/img/mission/mission-headline-${$store.state.locale}.png`)
            "
            class="w-100"
          />
        </div>
      </template>

      <template slot="default" slot-scope="{ close }">
        <div class="content-container">
          <div class="left-side">
            <Spinner
              v-if="!tasks"
              size="medium"
              context="Loading Bonus Slot..."
              classes="dead-center"
            ></Spinner>
            <template v-if="tasks">
              <div class="task-container to-center-abs">
                <div class="container">
                  <div v-if="tasks" class="row">
                    <div class="col-4 task-col">
                      <vanilla-mission-task-box
                        :task="tasks[0]"
                        :index="1"
                      ></vanilla-mission-task-box>
                    </div>
                    <div class="col-4 task-col">
                      <vanilla-mission-task-box
                        :task="tasks[1]"
                        :index="2"
                      ></vanilla-mission-task-box>
                    </div>
                    <div class="col-4 task-col">
                      <vanilla-mission-task-box
                        :task="tasks[2]"
                        :index="3"
                      ></vanilla-mission-task-box>
                    </div>
                  </div>
                </div>
              </div>

              <div class="reward-gold-container">
                <div class="reward-gold">
                  {{ formatNumber(amountGold) }}
                </div>
              </div>
              <div class="reward-card-container">
                <div class="reward-card">
                  {{ formatNumber(amountCard) }}
                </div>
              </div>
            </template>
          </div>

          <div class="right-side">
            <template v-if="currentChest">
              <div class="won-chest-layer">
                <div class="chest-text-container to-center-abs">
                  <div class="d-flex align-items-center justify-content-center">
                    <div class="text">
                      {{
                        $t("mission.your_chest.won_text", {
                          0: "xxx",
                        }).split("xxx")[0]
                      }}
                      <br />
                      <span>{{ getChestType }}</span>
                      {{
                        $t("mission.your_chest.won_text", {
                          0: "xxx",
                        }).split("xxx")[1]
                      }}
                    </div>
                  </div>
                </div>
                <div
                  class="chest to-center-abs"
                  :class="'chest-' + currentChest.chest.level"
                >
                  <animation-spine-chest
                    :chest-level="currentChest.chest.level"
                    :loop="false"
                    :run="runChestAnimation"
                  ></animation-spine-chest>
                </div>

                <div v-if="timer" class="timer-container to-center-abs">
                  <div class="timer">-{{ timer }}</div>
                  <div class="progress-bar-container">
                    <div class="progress-bar-img to-center-abs"></div>
                    <div class="progress-bg to-center-abs"></div>
                    <div class="custom-progress-bar">
                      <div
                        class="progress-fill"
                        :style="{ width: chestPercent + '%' }"
                      ></div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="!timer && currentChest.open_costs > 0"
                  class="reverse-panel to-center-abs"
                >
                  <div class="d-flex align-items-center justify-content-center">
                    <div class="panel-text">
                      <div class="sub">
                        {{ $t("mission.pending_info_panel.message") }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>

            <template v-else>
              <div class="unknown-chest-layer">
                <div class="panel">
                  <div class="d-flex align-items-center justify-content-center">
                    <div class="panel-text">
                      <div class="panel-info-icon" @click="openChestInfo"></div>
                      <div class="panel-head">
                        {{ $t("mission.info_panel.title") }}
                      </div>
                      <div class="sub">
                        {{ $t("mission.infobox") }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="chest-covered"></div>
              </div>
            </template>
          </div>
          <div class="bottom-side">
            <div
              v-if="!currentChest"
              class="btn-base button-change-mission"
              @click="showChangeMissionModal()"
            >
              <div class="row">
                <div class="col-8 text1">
                  {{ $t("mission.change_button") }}
                </div>
                <div class="col-4 pl-0">
                  <div class="text2">
                    {{ config["mission.exchange.price"] }}
                  </div>
                  <div class="crown-img">
                    <img
                      draggable="false"
                      src="@/assets/img/common/crown5.png"
                      class="w-100"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              class="btn-base button-active to-center-abs"
              :class="rewards ? 'pointer' : ''"
              @click="rewards ? close() : null"
            >
              <div class="button-active-text green-grad to-center-abs">
                <template v-if="currentChest">{{
                  $t("mission.complete")
                }}</template>
                <template v-else>{{ $t("mission.active") }}</template>
              </div>
              <div class="button-active-text collect to-center-abs">
                {{ $t("missions.chest.collect") }}
              </div>
            </div>

            <template v-if="currentChest">
              <template v-if="currentChest.open_costs > 0">
                <div class="btn-base button-open" @click="showOpenChestModal">
                  <div class="row">
                    <div class="col-8 text1">
                      {{ $t("mission.chest.open_now") }}
                    </div>
                    <div class="col-3 pl-0">
                      <div class="text2">
                        {{ currentChest.open_costs }}
                        <div class="crown-img">
                          <img
                            draggable="false"
                            src="@/assets/img/common/crown5.png"
                            class="w-100"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <div
                  class="btn-base button-open"
                  @click="handleOpenChest(currentChest.id)"
                >
                  <div class="row">
                    <div class="col-12 text1">
                      {{ $t("mission.chest.open") }}
                    </div>
                  </div>
                </div>
              </template>
            </template>
          </div>
        </div>
      </template>
    </b-modal>
    <modal-change-vanilla-mission
      :config="config"
      @handle-change-mission="$emit('handle-change-mission')"
    ></modal-change-vanilla-mission>
    <modal-open-chest
      v-if="currentChest"
      :costs="currentChest.open_costs"
      @handle-force-open-chest="handleForceOpenChest(currentChest.id)"
    ></modal-open-chest>
    <modal-chest-info :data="chestRewards"></modal-chest-info>
  </div>
</template>

<script>
import VanillaMissionTaskBox from "./vanilla-mission-task-box";
import ModalChangeVanillaMission from "./modal-change-vanilla-mission";
import ModalOpenChest from "./modal-open-chest";
import * as chestAPI from "@/API/chests.js";
import AnimationParsingFunctions from "@/components/animation/animation-parsing-functions";
import ModalChestInfo from "@/components/mission/modal-chest-info";
import AnimationSpineChest from "@/components/animation/animation-spine-chest";
import Spinner from "@/components/animation/animation-spinner";

export default {
  name: "ModalVanillaMission",
  components: {
    ModalChestInfo,
    ModalOpenChest,
    ModalChangeVanillaMission,
    VanillaMissionTaskBox,
    AnimationSpineChest,
    Spinner,
  },
  mixins: [AnimationParsingFunctions],
  props: {
    data: {
      type: [Array, Object],
      default: null,
    },
    currentChest: { type: Object, required: false, default: null },
    timer: { type: String, required: false, default: null },
  },
  data() {
    return {
      doAnimation: false,
      openedChest: null,
      doOpenChest: false,
      runChestAnimation: false,
    };
  },

  computed: {
    tasks: function () {
      if (this.currentChest && this.currentChest.usermission) {
        return this.currentChest.usermission.tasks;
      }

      //when test case with 3 test chests
      if (!this.data) {
        return "";
      }

      return this.data.tasks;
    },

    getMissionStatus: function () {
      return this.$store.getters.getMissionStatus;
    },

    getChestType: function () {
      return this.$t(
        "mission.your_chest.chest_type_" + this.currentChest.chest.level
      );
    },

    chestPercent: function () {
      if (!this.currentChest || !this.currentChest.unlocked_in) {
        return 0;
      }

      const secDay = 86400;
      const secCurr = this.currentChest.unlocked_in;
      return 100 - (100 / secDay) * secCurr;
    },
    config: function () {
      const config = this.$store.getters["getGeneralConfig"];
      if (!config) {
        return;
      }
      return config.settings;
    },

    chestRewards: function () {
      const config = this.$store.getters["getGeneralConfig"];
      if (!config) {
        return;
      }
      return this.$store.getters["getGeneralConfig"].chestRewards;
    },

    earnings: function () {
      if (this.currentChest === null || this.currentChest === undefined) {
        return;
      }
      return this.currentChest.chest.user_chest.content_object;
    },

    rewards: function () {
      if (!this.openedChest) {
        return;
      }

      return this.openedChest.chest.user_chest.content_object;
    },

    amountGold: function () {
      if (!this.rewards) {
        return 0;
      }

      return this.rewards.gold;
    },

    amountCard: function () {
      if (!this.rewards) {
        return 0;
      }

      const cards = this.rewards.cards;

      let amount = 0;
      for (let i = 0; i < cards.length; i++) {
        amount += cards[i].amount;
      }

      return amount;
    },
    showTestButton: function () {
      return process.env.VUE_APP_TESTBUTTONS === "true";
    },
  },

  mounted: function () {
    if (this.$route.path.startsWith("/missions")) {
      this.$bvModal.show("modal-vanilla-mission");
    }
  },

  methods: {
    onOpen() {
      this.playBackgroundMissions();
      this.$emit("handle-fetch-mission-data");
    },
    onClose() {
      this.playBackgroundMusic();
      this.doAnimation = false;

      if (this.openedChest) {
        this.mapApiJsonChest(this.rewards);
        this.$emit("fetch-chest-data-footer");
        this.openedChest = null;
        this.runChestAnimation = false;
      }
    },
    animationOpenChest: function () {
      if (this.openedChest.status === "error") {
        this.errorMessage = this.$t(this.openedChest.message);
        this.$store.commit("popups/setAlertBannerContent", {
          alertText: this.errorMessage,
          alertHeading: this.$t("alerts.title.error"),
          type: "danger",
          variant: "danger",
        });
        return;
      }

      const modal = document.getElementById("modal-vanilla-mission");

      const tasks = modal.getElementsByClassName("task-col");
      const buttonOpen = modal.getElementsByClassName("button-open")[0];
      const buttonState = modal.getElementsByClassName("button-active-text")[0];
      const textStateOpen =
        modal.getElementsByClassName("button-active-text")[1];
      const timer = modal.getElementsByClassName("timer-container")[0];
      const reversePanel = modal.getElementsByClassName("reverse-panel")[0];
      const chest = modal.getElementsByClassName("chest")[0];
      const rewardGold = modal.getElementsByClassName(
        "reward-gold-container"
      )[0];
      const rewardCard = modal.getElementsByClassName(
        "reward-card-container"
      )[0];

      this.gsap.set(tasks, {
        webkitFilter: "blur(0px)",
        filter: "blur(0px)",
      });
      this.gsap.set([rewardGold, rewardCard], {
        opacity: 1,
      });

      //begin animation
      const timeline = this.gsap.timeline();
      timeline
        .to(
          buttonOpen,
          0.01,
          {
            opacity: 0,
            onStart: () => {
              if (timer) {
                timeline.to(
                  timer,
                  1,
                  {
                    opacity: 0,
                  },
                  0
                );
              }
              if (reversePanel) {
                timeline.to(
                  reversePanel,
                  1,
                  {
                    opacity: 0,
                  },
                  0
                );
              }
            },
          },
          0
        )
        .to(
          chest,
          0.01,
          {
            onStart: () => {
              this.runChestAnimation = true;
            },
          },
          0
        );
      for (let i = 0; i < 3; i++) {
        timeline.to(
          tasks[i],
          0.4,
          {
            y: -600,
            webkitFilter: "blur(3px)",
            filter: "blur(3px)",
            opacity: 0.8,
          },
          1 + i * 0.3
        );
      }

      timeline
        .to(
          buttonState,
          1,
          {
            opacity: 0,
          },
          1
        )
        .to(
          textStateOpen,
          1,
          {
            opacity: 1,
          },
          1
        )
        .to(
          rewardGold,
          1,
          {
            ease: "elastic",
            scale: 1,
          },
          2
        )
        .to(
          rewardCard,
          1,
          {
            ease: "elastic",
            scale: 1,
          },
          2.5
        );

      timeline.to(buttonState, 0.01, {
        onStart: () => {
          this.doAnimation = false;
        },
      });

      this.doAnimation = true;
      this.$bvModal.hide("modal-open-chest");
      this.playSoundNotification();
    },
    handleOpenChest: async function (user_chest_id) {
      if (this.doOpenChest === true) {
        return;
      }
      this.doOpenChest = true;

      this.playSoundButtonCommon();
      await chestAPI
        .openChest(user_chest_id)
        .then((result) => {
          this.doOpenChest = false;
          this.openedChest = result;
          this.animationOpenChest();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleForceOpenChest: async function (user_chest_id) {
      if (this.doOpenChest === true) {
        return;
      }
      this.doOpenChest = true;

      this.playSoundButtonCommon();
      await chestAPI
        .forceOpenChest(user_chest_id)
        .then((result) => {
          this.doOpenChest = false;

          if (result.status === "error") {
            this.errorMessage = this.$t(result.message);
            this.$store.commit("popups/setAlertBannerContent", {
              alertText: this.errorMessage,
              alertHeading: this.$t("alerts.title.error"),
              type: "danger",
              variant: "danger",
            });
            return;
          }

          this.firebaseVirtualCurrencyDrain(
            "chest_force_open",
            "CROWN",
            this.currentChest.open_costs,
            "MISSION"
          );
          this.openedChest = result;

          this.animationOpenChest();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    showChangeMissionModal: function () {
      this.playSoundTab();
      this.$bvModal.show("modal-change-vanilla-mission");
    },

    showOpenChestModal: function () {
      this.playSoundTab();
      this.$bvModal.show("modal-open-chest");
    },
    openChestInfo: function () {
      this.$bvModal.show("modal-chest-info");
    },
  },
};
</script>
<style lang="scss">
$modal-width: 1266px;
$modal-height: 686px;

#modal-vanilla-mission {
  .modal-dialog {
    max-width: $modal-width;
    width: 100%;
    height: $modal-height;

    @media (max-height: 700px) {
      transform: scale(0.8);
      top: -80px;
      margin-bottom: -20%;
    }
  }

  .modal-content {
    width: 100%;
    height: 100%;
    background: url(~@/assets/img/mission/mission-main-panel-back-sm.png)
      no-repeat;
    background-size: 100% 100%;
  }

  .close-button {
    position: absolute;
    right: 9px;
    top: 23px;
    width: 54px;
    height: 54px;
    max-width: none;
    background: url(~@/assets/img/mission/mission-close-button.png) no-repeat;
    background-size: 100% 100%;

    &:hover {
      background-image: url(~@/assets/img/mission/mission-close-button-hover.png);
    }
  }
}
</style>

<style lang="scss" scoped>
#modal-vanilla-mission {
  .preload {
    height: 0;
    width: 0;
    background-image: url(~@/assets/img/mission/neue-aufgaben-button-hover-kopie.png),
      url(~@/assets/img/mission/mission-zeitschloss-button-hover-kopie.png),
      url(~@/assets/img/mission/mission-close-button-hover.png);
  }
  .btn-base {
    border: none;
    color: white;
    font-family: Ubuntu-bold;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent;
    display: inline-block;
  }

  .content-container {
    width: 98%;
    height: 100%;
    position: relative;

    .left-side {
      position: relative;
      width: 66.25%;
      height: 100%;
      display: inline-block;

      .task-container {
        padding-top: 90px;
        z-index: 20;
        overflow: hidden;
      }

      .reward-gold-container {
        position: absolute;
        top: 90px;
        left: 20px;
        transform: scale(0);
        width: 818px;
        height: 287px;
        background: url(~@/assets/img/mission/mission-gold-display.png)
          no-repeat;
        background-size: 100% 100%;
        opacity: 0;

        .reward-gold {
          font-family: Ubuntu-Bold;
          font-size: 80px;
          color: white;
          text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.5);
          line-height: 270px;
          margin-left: 180px;
        }
      }

      .reward-card-container {
        position: absolute;
        top: 300px;
        left: 250px;
        transform: scale(0);
        width: 466px;
        height: 283px;
        background: url(~@/assets/img/mission/missionCardDisplay.png) no-repeat;
        background-size: 100% 100%;
        opacity: 0;

        .reward-card {
          font-family: Ubuntu-Bold;
          font-size: 80px;
          color: white;
          text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.5);
          line-height: 265px;
          margin-left: 180px;
        }
      }
    }

    .right-side {
      position: relative;
      width: 33.75%;
      height: 100%;
      display: inline-block;

      .unknown-chest-layer {
        .panel {
          position: absolute;
          top: -7px;
          right: 37px;
          z-index: 5;
          width: 328px;
          height: 295px;
          background: url(~@/assets/img/mission/info-panel-backgrpund.png)
            no-repeat;
          background-size: 100% 100%;

          .panel-text {
            width: 320px;
            height: 160px;
            padding: 5% 10%;
            position: absolute;
            top: 119px;
            left: 0;
            line-height: 26px;

            .panel-info-icon {
              cursor: pointer;
              position: absolute;
              top: -24px;
              right: -14px;
              width: 60px;
              height: 60px;
              background: url(~@/assets/img/mission/missions-info-button.svg)
                no-repeat;
              background-size: 100% 100%;
              &:hover {
                filter: $hover-shadow;
              }
            }

            .panel-head {
              font-family: Ubuntu;
              font-size: 22px;
              color: #43ff00;
            }

            .sub {
              font-family: Ubuntu;
              font-size: 22px;
              color: #fad5a8;
            }
          }
        }

        .chest-covered {
          position: absolute;
          top: 175px;
          right: -32px;
          z-index: 3;
          width: 427px;
          height: 388px;
          background: url(~@/assets/img/mission/chest-covered.png) no-repeat;
          background-size: 100% 100%;
        }
      }

      .won-chest-layer {
        .chest-text-container {
          top: 60px;

          .text {
            font-family: Ubuntu;
            color: #fad5a8;
            font-size: 24px;
            text-transform: uppercase;

            span {
              font-family: Cinzel-Bold;
              color: #fad5a8;
              font-size: 44px;
              text-transform: uppercase;
              line-height: 1;
            }
          }
        }

        .chest {
          top: 90px;
          left: -80px;
          background-repeat: no-repeat;
          pointer-events: none;
          width: 467px;
          height: 456px;
        }

        .timer-container {
          top: 516px;
          left: 16px;
          .timer {
            font-family: Ubuntu-Bold;
            font-size: 28px;
          }
          .progress-bar-container {
          }
          .progress-bar-img {
            top: 4px;
            width: 281px;
            height: 91px;
            background: url(~@/assets/img/mission/mission-time-progress.png)
              no-repeat;
            background-size: 100% 100%;
            z-index: 5;
          }
          .progress-bg {
            width: 186px;
            height: 12px;
            margin-left: auto;
            margin-right: auto;
            position: absolute;
            z-index: 3;
            margin-top: 2px;
            background-image: linear-gradient(
              to bottom,
              #000000,
              #313233,
              #8294a1
            );
            z-index: 3;
          }

          .custom-progress-bar {
            width: 188px;
            height: 12px;
            margin-left: 98px;
            position: relative;
            z-index: 3;
            margin-top: 2px;

            .progress-fill {
              max-width: 100%;
              background-image: linear-gradient(
                to bottom,
                #929292,
                #ffffff,
                #888888,
                #ffffff
              );
              background-size: 100% 100%;
              position: absolute;
              height: 100%;
              width: 100%;
              left: 0px;
            }
          }
        }

        .reverse-panel {
          position: absolute;
          top: 400px;
          right: -15px;
          z-index: 5;
          width: 328px;
          height: 200px;
          background: url(~@/assets/img/mission/info-panel-backgrpund-reverse.png)
            no-repeat;
          background-size: 100% 100%;

          .panel-text {
            width: 320px;
            height: 160px;
            padding: 5% 6%;
            position: absolute;
            top: 16px;
            left: 0;
            line-height: 28px;

            .sub {
              font-family: Ubuntu;
              font-size: 22px;
              color: #fad5a8;
            }
          }
        }
      }
    }

    .bottom-side {
      position: absolute;
      bottom: 27px;
      width: 100%;
      height: 1px;

      .button-change-mission {
        position: absolute;
        top: 0px;
        left: 25px;
        background-image: url(~@/assets/img/mission/neue-aufgaben-button-kopie.png);
        height: 58px;
        width: 363px;
        line-height: 58px;
        padding: 0 4%;
        cursor: pointer;

        &:hover {
          filter: drop-shadow(0 0 8px #52cbee);
          outline: 10px solid transparent;
          background-image: url(~@/assets/img/mission/neue-aufgaben-button-hover-kopie.png);
        }

        .text1 {
          font-size: 20px;
          text-transform: uppercase;
          font-family: Ubuntu-Bold;
        }
        .text2 {
          font-size: 28px;
          text-transform: uppercase;
          font-family: Ubuntu-Bold;
          display: inline-flex;
          margin-right: 42px;
          float: right;
        }

        .crown-img {
          width: 32px;
          position: absolute;
          top: -2px;
          right: 20px;
        }
      }
      .button-active {
        position: absolute;
        top: -15px;
        left: 13px;
        background-image: url(~@/assets/img/mission/mission-green-button-kopie.png);
        height: 86px;
        width: 37.9%;
        line-height: 86px;
        cursor: default;

        &.pointer {
          cursor: pointer !important;
        }

        .button-active-text {
          font-size: 35px;
          text-transform: uppercase;
          font-family: Ubuntu-Bold;
          top: 0px;

          & .green-grad {
            background-image: linear-gradient(to bottom, #eeeeee, #43ff00);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          &.collect {
            opacity: 0;
          }
        }
      }

      .button-open {
        position: absolute;
        top: -45px;
        right: 10px;
        background-image: url(~@/assets/img/mission/mission-zeitschloss-button-kopie.png);
        height: 114px;
        width: 368px;
        line-height: 146px;
        padding: 0 4%;
        cursor: pointer;
        text-shadow: 1px 1px 4px rgba(10, 39, 0, 0.78);
        z-index: 10;

        &:hover {
          filter: drop-shadow(0 0 8px #52cbee);
          outline: 10px solid transparent;
          background-image: url(~@/assets/img/mission/mission-zeitschloss-button-hover-kopie.png);
        }

        .text1 {
          font-size: 20px;
          text-transform: uppercase;
          font-family: Ubuntu-Bold;
          text-shadow: 1px 1px 4px rgba(10, 39, 0, 0.78);
        }
        .text2 {
          font-size: 28px;
          text-transform: uppercase;
          font-family: Ubuntu-Bold;
          text-align: left;
          position: relative;
          text-shadow: 1px 1px 4px rgba(10, 39, 0, 0.78);
          display: inline-flex;
          margin-left: -35px;

          .crown-img {
            width: 32px;
            position: absolute;
            top: -2px;
            right: -35px;
          }
        }
      }
    }
  }

  .headline {
    top: 0px;
    left: -20px;
    width: 290px;
  }

  @media (max-width: 1279px) {
    .task-col {
      transform: scale(0.75);
    }
    .chest {
      transform: scale(0.75);
    }
    .chest-covered {
      right: -80px !important;
      transform: scale(0.75);
    }
    .panel {
      right: -15px !important;
    }

    .button-active-text {
      font-size: 26px !important;
    }

    .button-open {
      width: 280px !important;

      .text1 {
        font-size: 16px !important;
      }
    }
    .button-change-mission {
      width: 280px !important;

      .text1 {
        font-size: 14px !important;
        padding: 0 !important;
      }
      .text2 {
        font-size: 18px !important;
        margin-left: -45px !important;
      }
    }
  }
}
</style>
