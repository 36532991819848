var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{staticClass:"custom-modal2",attrs:{"id":"modal-shop","hide-footer":true,"modal-class":"custom-modal2","content-class":"rectangular-dark-blue-modal-no-shadow"},on:{"shown":_vm.onOpen,"hidden":_vm.onClose},scopedSlots:_vm._u([{key:"modal-header",fn:function(ref){
var close = ref.close;
return [_c('a',{staticClass:"close-button",attrs:{"href":"#"},on:{"click":function($event){return close()}}}),_c('div',{staticClass:"head-icon to-center-abs"})]}}])},[_c('template',{slot:"default"},[(_vm.loading)?_c('Spinner',{attrs:{"size":"medium","context":"Loading Shop...","classes":"dead-center"}}):_vm._e(),_c('div',{attrs:{"id":"shop-container"}},[_c('b-row',[_c('b-col',{attrs:{"id":"nav-container","cols":"12"}},[_c('b-nav',{staticClass:"tabbed-nav align-items-center justify-content-center"},_vm._l((_vm.contentTabs),function(tab,index){return _c('NavTabCustomAction',{key:tab.title,attrs:{"click-event":true,"label":_vm.$t(tab.title),"active":_vm.currentTab === index},on:{"click-event":function($event){return _vm.changeCurrentTab(index)}},nativeOn:{"click":function($event){return _vm.playSoundTab.apply(null, arguments)}}})}),1)],1),_c('b-col',{staticClass:"table-col",attrs:{"cols":"12"}},[(_vm.items.length > 0)?_c('b-table',{attrs:{"id":"shop-table","items":_vm.currentItems,"fields":_vm.fields,"thead-class":"head-row-text"},on:{"row-clicked":_vm.chooseItem},scopedSlots:_vm._u([{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"shop-item-name col-8 d-flex align-items-center"},[_vm._v(" "+_vm._s(_vm.$t(("shop.items." + (item.package.slug))))+" ")])]}},{key:"cell(icon)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"shop-item-icon"},[_c('img',{staticClass:"h-75",attrs:{"draggable":"false","src":_vm.getImageSrc(item.package.slug)}}),(item.rewards.expbooster)?_c('InfoBadge',{attrs:{"id":"info-expbooster","tooltip-only":true,"info-text":_vm.$t('info_badge.shop.expbooster_info', { 0: 50 })}}):_vm._e(),(_vm.getMoreContentLabel(item.package))?_c('div',{staticClass:"shop-more-percent"},[_vm._v(" "+_vm._s(_vm.getMoreContentLabel(item.package).value)+" ")]):_vm._e()],1)]}},{key:"cell(content)",fn:function(ref){
var item = ref.item;
return [(item.rewards.gold > 0)?_c('div',{staticClass:"row h-100 text-right"},[_c('div',{staticClass:"col-10"},[_c('div',{staticClass:"shop-item-amount glow"},[_vm._v(" "+_vm._s(_vm.formatNumber(item.rewards.gold))+" ")]),_c('div',{staticClass:"old-price glow"},[_vm._v(" "+_vm._s(_vm.getOldPrice(item.package).value)+" ")])]),_c('div',{staticClass:"col-2"},[_c('div',{staticClass:"main-currency-icon gold"},[_c('img',{staticClass:"h-100",attrs:{"src":require("@/assets/img/common/coin1.png")}})])])]):_vm._e(),(item.rewards.crown > 0)?_c('div',{staticClass:"row h-100 text-right"},[_c('div',{staticClass:"col-10"},[_c('div',{staticClass:"shop-item-amount glow"},[_vm._v(" "+_vm._s(_vm.formatNumber(item.rewards.crown))+"x ")]),_c('div',{staticClass:"old-price glow"},[_vm._v(" "+_vm._s(_vm.getOldPrice(item.package).value)+" ")])]),_c('div',{staticClass:"col-2"},[_c('div',{staticClass:"main-currency-icon crown"},[_c('img',{staticClass:"h-100",attrs:{"src":require("@/assets/img/common/crown4.png")}})])])]):_vm._e(),(item.rewards.expbooster > 0)?_c('div',{staticClass:"row h-100 text-right"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"shop-item-amount-expbooster glow"},[_vm._v(" Turbo +50% ")]),_c('div',{staticClass:"old-price-expbooster glow"},[_vm._v(" "+_vm._s(_vm.$t("shop.expboost.valid", { 0: Math.round(item.rewards.expbooster / 60), }))+" ")])])]):_vm._e()]}},{key:"cell(bonus)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"row item-row"},[_c('div',{staticClass:"shop-item-bonus text-right col-3"},[(item.rewards.card)?_c('div',{staticClass:"reward-text glow"},[_vm._v(" "+_vm._s(item.rewards.card)+" x ")]):_vm._e()]),_c('div',{staticClass:"col-1"},[(item.rewards.card)?_c('div',{staticClass:"reward-icon reward-icon-card"},[_c('img',{staticClass:"h-100",attrs:{"src":require("@/assets/img/common/cardstack-3.png")}}),_c('InfoBadge',{attrs:{"id":("info-trading-cards" + (item.id)),"info-text":_vm.$t('info_badge.shop.trading_cards'),"tooltip-only":true}})],1):_vm._e()]),_c('div',{staticClass:"shop-item-bonus text-right col-3"},[(item.rewards.loyalty)?_c('div',{staticClass:"reward-text glow"},[_vm._v(" "+_vm._s(item.rewards.loyalty)+" x ")]):_vm._e()]),_c('div',{staticClass:"col-1"},[(item.rewards.loyalty)?_c('div',{staticClass:"reward-icon reward-icon-loyalty"},[_c('img',{staticClass:"h-100",attrs:{"src":require("@/assets/img/nav-menu/nav-icon-loyalty.png")}}),_c('InfoBadge',{attrs:{"id":("info-royalty" + (item.id)),"info-text":_vm.$t('info_badge.shop.loyalty'),"tooltip-only":true}})],1):_vm._e()]),_c('div',{staticClass:"shop-item-bonus text-right col-3"},[(item.rewards.elixir)?_c('div',{staticClass:"reward-text glow"},[_vm._v(" "+_vm._s(item.rewards.elixir)+" x ")]):_vm._e()]),_c('div',{staticClass:"col-1"},[(item.rewards.elixir)?_c('div',{staticClass:"reward-icon reward-icon-elixir"},[_c('img',{staticClass:"h-100",attrs:{"src":require("@/assets/img/common/elixir.png")}}),_c('InfoBadge',{attrs:{"id":("info-elixir" + (item.id)),"info-text":_vm.$t('info_badge.shop.elixir'),"tooltip-only":true}})],1):_vm._e()])])]}},{key:"cell(price)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"btn ok-button-green round-new",on:{"click":function($event){return _vm.chooseItem(item)}}},[_vm._v(" € "+_vm._s(_vm.formatPrice(item.package.price))+" ")])]}}],null,false,3917051292)}):_vm._e()],1)],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }