<template>
  <b-modal
    v-if="eventData"
    :id="modalId"
    ref="modal-levelup-event"
    :hide-footer="true"
    modal-class="modal-levelup-event"
    @shown="handleShow"
    @hidden="handleClose"
  >
    <AnimationEventLevelup
      :animation-step="eventIsFinished ? 'done' : 'main'"
      :loop="true"
      :run="true"
      @done="animReady = true"
    ></AnimationEventLevelup>
    <Spinner
      v-if="!animReady"
      size="medium"
      context="Loading Event..."
      classes="dead-center"
    ></Spinner>
    <template slot="modal-header" slot-scope="{ close }">
      <div v-if="!eventIsFinished && animReady">
        <p>
          <span class="golden-gradient-text text-uppercase">
            {{ $t("events.levelup.headline_1a") }}
          </span>
          <span class="golden-gradient-text text-uppercase big">{{
            $t("events.levelup.headline_1b", {
              0: eventData.payload.levelUps,
              1: eventDurationDays,
            })
          }}</span>
        </p>
        <div>
          <span class="golden-gradient-text text-uppercase"
            >{{ $t("events.levelup.headline_2") }}
          </span>

          <div
            v-for="reward in eventRewards"
            :key="reward.amount"
            class="d-inline-flex align-items-center"
          >
            <span class="golden-gradient-text big">{{
              formatNumber(reward.amount)
            }}</span>
            <div
              class="reward-icon ml-3"
              :style="{
                'background-image': `url('${getRewardIcon(reward)}')`,
              }"
            ></div>
          </div>
        </div>
      </div>
      <a href="#" class="close-button" @click="close()"> </a>
    </template>

    <template v-if="animReady" slot="default">
      <div v-if="!eventIsFinished" class="w-100 h-100">
        <div v-if="voucherVal" id="raffle-box">
          <p class="grey-gradient-text pb-3">
            {{ $t("events.raffle.subline") }}
          </p>
          <p class="grey-gradient-text big">
            {{ numberOfWinners }} x {{ voucherVal }} €
          </p>
          <p class="grey-gradient-text ml-3 medium w-75">
            <template v-if="numberOfWinners > 1">{{
              $t("events.raffle.amazon.voucher.name>other")
            }}</template>
            <template v-else>{{
              $t("events.raffle.amazon.voucher.name>one")
            }}</template>
          </p>
        </div>
        <div id="countdonw-box">
          <div id="countdown-text-box" class="text-center text-uppercase">
            <div class="w-100 yellow bolditalic">
              {{ $t("shop.offers.countdown.time_remaining") }}
            </div>
            <div
              class="w-100 yellow medium d-inline-flex align-items-center justify-content-around px-5"
            >
              <span>T</span><span>S</span><span>M</span>
            </div>
            <CountdownDigital
              class="to-center-abs"
              :days-to-minutes="true"
              :time="endTime"
              :with-time-diff="true"
              @over="handleEventCountdownEnd"
            />
          </div>
        </div>
        <div
          id="play-now-button"
          class="text-uppercase text-white bold"
          @click="handlePlayNowClick"
        >
          {{ $t("login.web.register.play_now_button") }}
        </div>
        <div
          v-for="(n, index) in eventData.payload.levelUps"
          :key="index"
          class="level-marker"
          :class="[
            'level-marker-' + (index + 1),
            { completed: userProgress >= index + 1 },
          ]"
        >
          <span v-if="userProgress < index + 1"> {{ index + 1 }}</span>
        </div>

        <div class="level-marker-canvas to-center-abs">
          <AnimationLevelupMarker
            :run="true"
            :user-progress="userProgress"
            :amount-marker="eventData.payload.levelUps"
          />
        </div>

        <b-row
          v-if="voucherVal"
          id="event-disclaimer-row"
          no-gutters
          align-h="between"
          align-v="center"
          class="text-left"
        >
          <b-col
            ><p class="grey">
              {{ $t("events.raffle.disclaimer") }}
            </p></b-col
          >
          <b-col v-if="!emailConfirmed"
            ><p class="blue medium">
              {{ $t("events.raffle.email_confirm_info") }}
            </p></b-col
          >
          <b-col v-if="!emailConfirmed" cols="3">
            <button
              class="button-blue-outline"
              @click="handleEmailConfirmClick"
            >
              {{ $t("profile.email_confirm") }}
            </button>
          </b-col>
        </b-row>
      </div>
      <div v-else class="w-100 h-100">
        <div
          id="amount-display"
          class="to-center-abs d-inline-flex align-items-center justify-content-between"
        >
          <span class="golden-gradient-text"
            >+ {{ formatNumber(eventRewards[0].amount) }}
            <div
              class="reward-icon"
              :style="{
                'background-image': `url('${getRewardIcon(eventRewards[0])}')`,
              }"
            ></div>
          </span>
        </div>
        <div id="raffle-box2">
          <p class="grey-gradient-text small">
            {{ $t("events.battle.win_note") }}
          </p>
          <div class="raffle-img"></div>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import CountdownDigital from "@/components/base/countdown-digital.vue";
import AnimationEventLevelup from "@/components/animation/animation-spine-event-levelup.vue";
import AnimationLevelupMarker from "@/components/animation/animation-spine-levelup-marker.vue";
import Spinner from "@/components/animation/animation-spinner.vue";
import eventPopups from "@/mixins/eventPopups.js";

export default {
  name: "ModalLevelupEvent",
  components: {
    CountdownDigital,
    AnimationEventLevelup,
    AnimationLevelupMarker,
    Spinner,
  },
  mixins: [eventPopups],
  props: {
    modalId: {
      type: String,
      default: "modal-levelup-event",
    },
  },

  data() {
    return {
      animReady: false,
    };
  },

  computed: {
    userProgress() {
      if (!this.userEvent) {
        return 0;
      } else {
        let percentageStep = 100 / this.eventData.payload.levelUps;
        return Math.round(this.userEvent.progress / percentageStep);
      }
    },

    eventIsFinished() {
      if (this.eventData.isActive) {
        return (
          this.userProgress >= this.eventData.payload.levelUps &&
          this.userEvent &&
          this.userEvent.status === "DONE"
        );
      } else if (!this.eventData.isActive) {
        return this.userEvent && this.userEvent.status === "DONE";
      } else return false;
    },

    voucherVal() {
      return this.getAmazonVoucherValueByEvent(this.eventData);
    },
  },

  mounted() {
    if (this.$route.path.startsWith("/levelup")) {
      this.$bvModal.show(this.modalId);
    }
  },

  methods: {
    handleClose() {
      this.onClose();
      this.animReady = false;
    },
    handleShow() {
      this.onOpen();
      this.animReady = true;
    },
  },
};
</script>
<style lang="scss">
$modal-width: 1200px;
$modal-height: 715px;

.modal-levelup-event {
  .modal-dialog {
    max-width: $modal-width;
    width: 100%;
    height: $modal-height;
    padding-top: 50px;

    @media (max-height: 770px), (max-width: 1250px) {
      transform: scale(0.9) !important;
      top: -40px;
    }
    @media (max-height: 690px), (max-width: 1100px) {
      transform: scale(0.8) !important;
      top: -120px;
    }

    p {
      margin: 0;
    }
  }

  .modal-header {
    margin-top: 0;
    flex-direction: column;
    align-items: flex-end;
    position: absolute;
    top: 0;
    right: 0;
    border: none !important;

    .close-button {
      right: -50px;
      top: 0px;
    }
  }

  .modal-body {
    margin-top: 0 !important;
    width: $modal-width;
    height: $modal-height;
  }

  .modal-content {
    width: 100%;
    height: 100%;
    background: url(~@/assets/img/events/bg-w-coins.png) center/996px 639px
      no-repeat;
    color: white;
    text-align: center;
    z-index: 0;
    border: none;
    border-radius: 0;
  }

  .golden-gradient-text {
    font-family: Ubuntu-Bold;
    font-size: 30px;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      #f7ffff,
      #ffee51 58%,
      #8b5a00
    );
    background-image: linear-gradient(to bottom, #f7ffff, #ffee51 58%, #8b5a00);
    filter: drop-shadow(0 0 15px rgba(0, 0, 0, 0.7)) drop-shadow(0 0 8px black);
    line-height: 1;

    &.big {
      font-size: 56px;
    }
  }

  .grey-gradient-text {
    font-family: Ubuntu-Bold;
    font-size: 21px;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      #ffffff,
      #979797
    );
    background-image: linear-gradient(to bottom, #ffffff, #979797);
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.5));

    &.big {
      font-size: 60px;
    }

    &.medium {
      font-size: 36px;
    }
    &.small {
      font-size: 32px;
    }
  }

  .reward-icon {
    width: 56px;
    height: 56px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  #raffle-box {
    width: 450px;
    height: 200px;
    background-position: bottom right;
    background-size: 226px 217px;
    background-repeat: no-repeat;
    position: absolute;
    right: 0;
    top: 210px;
    text-transform: uppercase;
    text-align: left;
    line-height: 1;
  }

  #countdonw-box {
    width: 324px;
    height: 132px;
    position: absolute;
    bottom: 119px;
    right: 190px;
    z-index: -1;
    background: url(~@/assets/img/events/counter-display.png) top center/253px
      149px no-repeat;

    #countdown-text-box {
      padding-top: 5px;
      height: 100px;

      .yellow {
        color: #ffe600;
      }

      .bolditalic {
        font-family: Ubuntu-BoldItalic;
        font-size: 18px;
      }

      .countdown-digital {
        font-family: ds-digital-bold, monospace;
        font-size: 56px;
        position: absolute;
        filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.5));
      }
    }
  }

  #play-now-button {
    position: absolute;
    font-size: 28px;
    text-shadow: 0 0 6px #ff0000;
    right: 280px;
    top: 530px;
    cursor: pointer;
  }

  #event-disclaimer-row {
    position: absolute;
    bottom: -15px;
    font-size: 18px;
    line-height: 1;

    .grey {
      color: #8e8e8e;
      font-size: 18px;
    }
    .blue {
      font-size: 18px;
    }
  }

  .level-marker {
    width: 93px;
    height: 93px;
    background: url(~@/assets/img/events/level-marker-red.png) center/100% 100%
      no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    font-size: 16px;

    span {
      font-size: 36px;
      font-family: Ubuntu-Bold;
      filter: drop-shadow(0 2px 16px #330000) drop-shadow(0 2px 4px #330000)
        drop-shadow(0 2px 4px #330000);
    }

    &.completed {
      background: none;
    }

    &.level-marker-1 {
      top: 264px;
      left: 321px;
    }

    &.level-marker-2 {
      top: 200px;
      left: 483px;
    }

    &.level-marker-3 {
      top: 300px;
      left: 413px;
    }

    &.level-marker-4 {
      top: 374px;
      left: 213px;
    }

    &.level-marker-5 {
      top: 466px;
      left: 290px;
    }

    &.level-marker-6 {
      top: 395px;
      left: 453px;
    }

    &.level-marker-7 {
      top: 321px;
      left: 611px;
    }
  }

  .level-marker-canvas {
    top: 100px;
    pointer-events: none;
  }
  #amount-display {
    width: 596px;
    height: 106px;
    background: url(~@/assets/img/events/amount-display.svg) center/100% 100%
      no-repeat;
    bottom: 70px;
    z-index: 1;
    span {
      font-size: 72px;
      filter: drop-shadow(0 0 15px rgba(0, 0, 0, 0.7))
        drop-shadow(0 0 8px black);
      margin-left: auto;
      margin-right: auto;
      padding-right: 75px;
    }

    .reward-text {
      font-size: 38px;
      top: 10px;
    }

    .reward-icon {
      position: absolute;
      top: 10px;
      right: 0;
    }

    .headline-icon {
      width: 245px;
      height: 201px;
      background: url(~@/assets/img/events/slotbattle-logo-with-fire-big.png)
        center/100% 100% no-repeat;
      top: -181px;
    }
  }

  #raffle-box2 {
    width: 100%;
    height: 50px;
    position: absolute;
    right: 0;
    top: 635px;
    text-transform: uppercase;
    text-align: center;
    line-height: 1.2;
    padding: 0 10%;

    .raffle-img {
      width: 242px;
      height: 232px;
      background-position: bottom right;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      position: absolute;
      right: 0;
      top: -95px;
    }
  }

  #collect-button {
    width: 329px;
    height: 64px;
    background: url(~@/assets/img/events/btn-collect.svg) center/100% 100%
      no-repeat;
    cursor: pointer;
    bottom: 0;

    span {
      font-size: 36px;
      text-shadow: 0 0 16px rgba(67, 255, 0, 0.7), 0 0 8px rgba(67, 255, 0, 0.7);
    }

    &:hover {
      background: url(~@/assets/img/events/btn-collect-hover.svg) center/100%
        100% no-repeat;
    }
  }
}
</style>

<style scoped lang="scss">
$modal-width: 1200px;
$modal-height: 688px;
#modal-levelup-event {
  .content {
    height: $modal-height;
    position: relative;
    margin-left: auto;
    margin-right: auto;

    &.modal-content {
      width: $modal-width;
    }
  }
}
</style>
