<template>
  <b-modal
    id="modal-olympia-tutorial"
    ref="modal-olympia-tutorial"
    :hide-footer="true"
    modal-class="custom-modal1"
  >
    <template slot="modal-header">
      <a href="#" class="close-button" @click="handleClose"> </a>
    </template>

    <template slot="default">
      <div id="tutorial-content" class="container-fluid">
        <component :is="pageComponent" />

        <div
          v-if="currentPage > 0"
          class="pgn-arrow prev clickable"
          @click="goBack"
        ></div>
        <div
          v-if="currentPage < pages.length - 1"
          class="pgn-arrow next clickable"
          @click="goFurther"
        ></div>
        <div
          v-else-if="currentPage === pages.length - 1"
          class="pgn-arrow done clickable"
          @click="handleClose"
        ></div>
      </div>

      <div class="blurred-bg" :style="getWindowStyle"></div>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "ModalOlympiaTutorial",
  components: {},

  data() {
    return {
      currentPage: 0,
      pages: [
        {
          page: 1,
          windowPosition: {
            "border-width": "359px 592px",
            "border-color": "black",
          },
          windowSmall: {
            "border-width": "359px 592px",
            "border-color": "black",
          },
        },
        {
          page: 2,
          windowPosition: {
            "border-width": "236px 544px 98px 345px",
          },
          windowSmall: {
            "border-width": "236px 477px 98px 326px",
          },
        },
        {
          page: 3,
          windowPosition: {
            "border-width": "231px 361px 98px 628px",
          },
          windowSmall: {
            "border-width": "231px 314px 98px 551px",
          },
        },
        {
          page: 4,
          windowPosition: {
            "border-width": "359px 592px",
            "border-color": "black",
          },
          windowSmall: {
            "border-width": "359px 592px",
            "border-color": "black",
          },
        },
        {
          page: 5,
          windowPosition: {
            "border-width": "236px 107px 98px 796px",
          },
          windowSmall: {
            "border-width": "236px 107px 98px 713px",
          },
        },
        {
          page: 6,
          windowPosition: {
            "border-width": "136px 107px 598px 396px",
          },
          windowSmall: {
            "border-width": "153px 107px 593px 396px",
          },
        },

        {
          page: 7,
          windowPosition: {
            "border-width": "254px 855px 121px 27px",
          },
          windowSmall: {
            "border-width": "290px 747px 121px 10px",
          },
        },
        {
          page: 8,
          windowPosition: {
            "border-width": "0px 15px 681px 650px",
          },
          windowSmall: {
            "border-width": "0px 15px 681px 532px",
          },
        },
      ],
    };
  },

  computed: {
    pageData() {
      return this.pages[this.currentPage];
    },
    getWindowStyle() {
      if (window.innerWidth > 1100) {
        return this.pageData.windowPosition;
      } else {
        return this.pageData.windowSmall;
      }
    },
    pageComponent() {
      let pageNumber = this.pageData.page;
      return () =>
        import(
          `@/components/events/olympia/modal-olympia-tutorial-pages/page-${pageNumber}.vue`
        );
    },
  },
  methods: {
    goBack() {
      this.currentPage -= 1;
    },
    goFurther() {
      this.currentPage += 1;
    },
    handleClose() {
      this.$bvModal.hide("modal-olympia-tutorial");
      this.currentPage = 0;
    },
  },
};
</script>
<style lang="scss">
#modal-olympia-tutorial___BV_modal_backdrop_ {
  &.modal-backdrop {
    display: none !important;
  }
}
#modal-olympia-tutorial {
  .modal-dialog {
    max-width: 1200px;
    width: 100%;
    height: 837px;
    padding-top: 50px;

    @media (max-height: 900px), (max-width: 1250px) {
      transform: scale(0.9);
      top: -40px;
      margin-bottom: -10%;
    }
    @media (max-height: 820px), (max-width: 1100px) {
      transform: scale(0.8);
      top: -120px;
      margin-bottom: -20%;
    }
  }

  .modal-header {
    margin-top: 0;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-bottom: 0;

    .close-button {
      right: 10px;
      top: 0;
    }
  }

  .modal-body {
    margin-top: 0 !important;
    width: 100%;
    height: 100%;
    padding-top: 0;
    #tutorial-content {
      z-index: 5;
      position: relative;
      height: 837px;
      max-width: 1200px;
      padding: 16px;
      .gfg-icon {
        background: url(~@/assets/img/events/gfg-logo.png) center/100% 100%
          no-repeat;
        width: 521px;
        height: 300px;
        position: absolute;
        left: -31px;
        top: -31px;
        @media (max-width: 1200px) {
          width: 400px;
          height: 230px;
        }
      }
      .text-golden {
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
        background-image: linear-gradient(
          to bottom,
          #f7ffff,
          #ffee51 58%,
          #8b5a00
        );
        filter: drop-shadow(0 0 12px rgba(0, 0, 0, 0.5))
          drop-shadow(0 0 6px rgba(0, 0, 0, 0.8));
      }
      .text-shadow {
        text-shadow: 0 0 12px rgba(0, 0, 0, 0.5), 0 0 6px rgba(0, 0, 0, 0.8);
      }
      .green {
        color: #43ff00;
      }
      .circled-number {
        border-radius: 100%;
        border: 4px solid #43ff00;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 60px;
        font-family: Ubuntu-bold;
        color: #43ff00;
        height: 74px;
        width: 74px;
      }
      .huge {
        font-size: 56px;
      }
      .large {
        font-size: 32px;
      }
      .larger {
        font-size: 40px;
      }
      .normalsize {
        font-size: 24px;
      }
      .grey-subheading {
        font-size: 36px;
        color: #524749;
      }
      p,
      span {
        line-height: 1.2;
      }
      .pgn-arrow {
        width: 88px;
        height: 88px;
        position: absolute;
        background-size: 100% 100%;
        background-position: center;
        background-repeat: no-repeat;
        bottom: 29px;
        &.prev {
          background-image: url(~@/assets/img/events/btn-previous.svg);
          left: 35px;
        }
        &.next {
          background-image: url(~@/assets/img/events/btn-next.svg);
          right: 35px;
        }
        &.done {
          background-image: url(~@/assets/img/events/btn-checkmark-grey.svg);
          right: 35px;
        }
      }
    }
  }

  .modal-content {
    width: 100%;
    height: 100%;
    background: none;
  }
  .blurred-bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 1200px;
    height: 837px;

    /* window colour */
    background-color: rgba(0, 0, 0, 0);

    /* frame colour */
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.9);
    filter: blur(16px);
    box-shadow: 0 0 200px black;
  }
}
</style>
