<template>
  <div class="hero-section" :class="{ big: inLobby }">
    <InfoBadge
      v-if="hasInfoBadge"
      :id="`info-${routePath}`"
      :info-text="$t(`info_badge.${routePath}`)"
      :faq-link="routePath"
      size="lg"
      :class="routePath"
    />
    <template
      ><div
        class="hero-bg-img h-100 d-flex align-items-end justify-content-center"
        :class="[heroBgClass, this.$store.state.locale]"
      >
        <template v-if="heroContentComponent"
          ><component :is="heroContentComponent" /></template
        ><template v-if="hasHeroNav"
          ><HeroNav :content-type="heroBgClass"
        /></template>
      </div>
    </template>
  </div>
</template>
<script>
import BannerCarousel from "@/components/lobby/banner-carousel.vue";
import HeroNav from "@/components/base/hero-nav.vue";
import ProfileHeroBox from "@/components/profile/profile-hero-box.vue";
import CardsHeroBox from "@/components/cards/cards-hero-box.vue";
import InfoBadge from "@/components/base/info-badge.vue";
import ChampionsHeroBox from "@/components/champions/champions-hero-box";
export default {
  name: "HeroComponent",
  components: {
    ChampionsHeroBox,
    BannerCarousel,
    HeroNav,
    ProfileHeroBox,
    CardsHeroBox,
    InfoBadge,
  },
  computed: {
    heroBgClass() {
      return this.$route.path.split("/")[1];
    },
    hasHeroNav() {
      return (
        this.$route.path.startsWith("/cards") ||
        this.$route.path.startsWith("/tournament") ||
        this.$route.path.startsWith("/profile") ||
        this.$route.path.startsWith("/league") ||
        this.$route.path.startsWith("/champions") ||
        this.$route.path.startsWith("/teams")
      );
    },
    hasInfoBadge() {
      return (
        this.$route.path.startsWith("/tournament") ||
        this.$route.path.startsWith("/league")
      );
    },
    inLobby() {
      return (
        this.$route.name === "home" ||
        this.$route.name === "home2" ||
        this.$route.name === "GameGrid" ||
        this.$route.name === "ProviderSlots"
      );
    },
    heroContentComponent() {
      if (
        this.$route.name === "home" ||
        this.$route.name === "home2" ||
        this.$route.name === "GameGrid" ||
        this.$route.name === "ProviderSlots"
      ) {
        return "BannerCarousel";
      } else if (this.$route.path.startsWith("/cards")) {
        return "CardsHeroBox";
      } else if (this.$route.path.startsWith("/profile")) {
        return "ProfileHeroBox";
      } else if (this.$route.path.startsWith("/champions")) {
        return "ChampionsHeroBox";
      } else return "";
    },
    routePath() {
      return this.$route.path.substring(1).split("/")[0];
    },
  },
};
</script>
<style lang="scss">
.hero-section {
  background-color: #00040f;
  margin-top: -40px;
  height: 21rem;
  .info-badge-orange {
    top: 20%;
    right: 28%;
  }

  &.big {
    height: 27.5rem;
  }

  .hero-bg-img {
    &.tournament.en {
      background: url("~@/assets/img/hero-images/hero-logo-tourney-en.png") 50%
          33%/38.875rem 13.438rem no-repeat,
        url("~@/assets/img/hero-images/hero-pic-tourney.png") center/cover
          no-repeat;
    }
    &.tournament.de {
      background: url("~@/assets/img/hero-images/hero-logo-tourney-de.png") 50%
          33%/38.875rem 13.438rem no-repeat,
        url("~@/assets/img/hero-images/hero-pic-tourney.png") center/cover
          no-repeat;
    }

    &.league.en {
      background: url("~@/assets/img/hero-images/royalleague-hero-logo-en.png")
          50% 60%/59.688rem 11.438rem no-repeat,
        url("~@/assets/img/hero-images/hero-pic-feature-royalleague-default.png")
          center/cover no-repeat;
    }
    &.league.de {
      background: url("~@/assets/img/hero-images/royalleague-hero-logo-de.png")
          50% 60%/59.688rem 11.438rem no-repeat,
        url("~@/assets/img/hero-images/hero-pic-feature-royalleague-default.png")
          center/cover no-repeat;
    }

    &.teams {
      background: url("~@/assets/img/hero-images/teams-heropic-logo-copy.png")
          50% 60%/39.5rem 18.813rem no-repeat,
        url("~@/assets/img/hero-images/hero-pic-feature-teams-default.png")
          center/cover no-repeat;
    }
    &.cards {
      background: url("~@/assets/img/lobby/carousel/hero-pic-feature-cards-default.png")
        center/cover no-repeat;
    }
    &.profile {
      background: url("~@/assets/img/hero-images/hero-pic-profile.png")
        center/cover no-repeat;
    }
    &.champions {
      background: url("~@/assets/img/hero-images/hero-pic-feature-champions-default.png")
        center/cover no-repeat;
    }
  }
}
</style>
