<template>
  <div>
    <b-modal
      id="modal-open-chest"
      class="custom-modal3"
      :hide-footer="true"
      modal-class="custom-modal3"
      content-class="rectangular-dark-blue-modal"
    >
      <template slot="modal-header" slot-scope="{ close }">
        <a href="#" class="close-button" @click="close()"> </a>
      </template>

      <template slot="default" slot-scope="{ hide, close }">
        <DecoCorners color-variant="gold" />

        <div class="headline1">
          {{ $t("mission.chest.force_open.message", { 0: "" }) }}
        </div>
        <div class="headline2 col-12">
          <div class="subheadline">
            {{ costs }}
            <div class="icon-crown">
              <img
                draggable="false"
                src="@/assets/img/common/crown4.png"
                class="w-100"
              />
            </div>
          </div>
        </div>

        <div id="button-container-left" class="button-container">
          <button class="cancel-button-red" @click="close()">
            {{ $t("mission.chest.force_open.no") }}
          </button>
        </div>
        <div id="button-container-right" class="button-container">
          <button
            class="ok-button-green"
            @click="$emit('handle-force-open-chest')"
          >
            {{ $t("mission.chest.force_open.yes") }}
          </button>
        </div>
        <div id="deco-bottom" class="to-center-abs"></div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import DecoCorners from "@/components/base/deco-corners.vue";

export default {
  name: "ModalOpenChest",
  components: { DecoCorners },
  props: { costs: { type: Number, default: 0 } },
};
</script>
<style lang="scss">
$modal-width: 640px;
$modal-height: 271px;

#modal-open-chest {
  .modal-body {
    margin-top: -15px;
  }

  .modal-dialog {
    top: 5%;
    max-width: $modal-width;
    height: $modal-height;
  }

  .deco-corner {
    &.top-left {
      top: -20px;
      left: -5px;
    }
    &.top-right {
      top: -20px;
      right: -5px;
    }
    &.bottom-right {
      bottom: -5px;
      right: -5px;
    }

    &.bottom-left {
      bottom: -5px;
      left: -5px;
    }
  }

  #button-container-left {
    position: absolute;
    left: 15px;
    bottom: 40px;

    button {
      font-family: Ubuntu;
      font-size: 24px;
      width: 288px;
      height: 44px;
    }
  }

  #button-container-right {
    position: absolute;
    right: 15px;
    bottom: 40px;

    button {
      font-family: Ubuntu;
      font-size: 24px;
      width: 288px;
      height: 44px;
    }
  }
}
</style>

<style lang="scss" scoped>
#modal-open-chest {
  .headline1 {
    font-family: Ubuntu-Bold;
    color: white;
    font-size: 32px;
    text-transform: uppercase;
  }

  .headline2 {
    font-family: Ubuntu-Bold;
    color: white;
    font-size: 48px;
    text-transform: uppercase;
    width: 100px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    padding-right: 60px;
    text-align: center;

    .subheadline {
      font-size: 48px;
      font-family: Ubuntu-Bold;
      position: relative;
      display: inline-flex;
      text-align: center;
      margin-left: -46px;
    }

    .icon-crown {
      width: 66px;
      position: absolute;
      right: -80px;
      top: -5px;
    }
  }

  .text {
    font-family: Ubuntu;
    font-size: 26px;
    color: #c8c8c8;
    line-height: 33px;
    padding: 0 20px;
  }
}
</style>
