<template>
  <div
    id="header"
    name="header"
    :class="$route.name && $route.name.endsWith('game') ? 'resize-header' : ''"
  >
    <b-navbar id="header-content" class="to-center-abs">
      <!--      left area-->
      <b-navbar-nav id="nav-area-left">
        <!--        home button-->
        <b-nav-item class="nav-item-home mr-0">
          <router-link
            id="header-home-container"
            to="/"
            @click.native="handleHomeButtonClick"
          >
          </router-link>
        </b-nav-item>

        <!--        logo-->
        <b-nav-item class="nav-item-logo ml-0">
          <router-link
            id="header-logo-container"
            to="/"
            @click.native="handleHomeButtonClick"
          >
          </router-link>
        </b-nav-item>
      </b-navbar-nav>

      <b-navbar-nav id="nav-area-middle">
        <!--        league badge-->
        <b-nav-item
          v-if="headerDetailsUnlocked"
          class="nav-item-league ml-0"
          @click="$router.push('/league')"
        >
          <div
            id="header-league-container"
            name="header-item-league"
            class="d-flex align-items-center clickable"
            @click="
              () => {
                playSoundTab();
                firebaseClickItemHeader('header_user_league_button');
              }
            "
          >
            <img
              id="header-league-icon"
              draggable="false"
              class="header-item-icon"
              src="@/assets/img/header/header-league-icon.png"
            />
            <img
              id="header-league-movement"
              :src="
                require(`@/assets/img/header/arrow-league-${userLeagueMovement}.png`)
              "
            />

            <div v-if="userLeaguePosition" id="header-league-text-field">
              {{ userLeaguePosition }}
            </div>
          </div>
        </b-nav-item>

        <b-nav-item class="nav-item-box ml-0">
          <!--        yellow box-->
          <div
            id="header-center-container"
            class="to-center-abs cursor-default"
          >
            <!--          gold-->
            <div
              id="header-gold-container"
              class="clickable"
              @click="
                () => {
                  showShop(0);
                  firebaseClickItemHeader('header_gold_display');
                }
              "
            >
              <div class="header-number-bar">
                <div
                  v-if="user.gold"
                  class="header-number-text big"
                  name="header-item-gold"
                >
                  {{ formatNumber(user.gold.amount) }}
                </div>
              </div>
            </div>

            <!--          crowns-->
            <div
              id="header-crown-container"
              class="clickable"
              @click="
                () => {
                  showShop(1);
                  firebaseClickItemHeader('header_crown_display');
                }
              "
            >
              <div class="header-number-bar">
                <div
                  v-if="user.crowns"
                  class="header-number-text big"
                  name="header-item-crown"
                >
                  {{ formatNumber(user.crowns.amount) }}
                </div>
              </div>
            </div>

            <!--          elixir-->
            <div
              id="header-elixir-container"
              class="clickable"
              @click="showBonusSlotElixirPage"
            >
              <div class="header-number-bar">
                <div id="header-elixir-icon"></div>
                <div class="header-number-text small" name="header-item-elixir">
                  {{ formatNumber(amountElixir) }}
                </div>
              </div>
            </div>

            <!--          avatar -->
            <div
              id="header-avatar-container"
              class="clickable"
              name="header-item-avatar"
              @click="
                () => {
                  $router.push('/profile');
                  playSoundTab();
                  firebaseClickItemHeader('header_profile_button');
                }
              "
            >
              <div class="avatar-section">
                <div class="avatar-frame">
                  <img
                    id="header-avatar-icon"
                    draggable="false"
                    :src="user.avatar_url"
                    class="to-center-abs"
                  />
                  <div id="avatar-hover-text" class="dead-center">
                    {{ $t("lobby.navigation.profile") }}
                  </div>
                </div>
              </div>
            </div>

            <!--          exp bar-->
            <div id="header-exp-container">
              <div class="progress-bar-section">
                <div class="icon-expcrown"></div>
                <animation-expboost></animation-expboost>
                <div
                  class="progress-fill"
                  :style="{ width: currentExpPercent }"
                ></div>
                <div v-if="user.experience" class="text-below-level-progress">
                  Level {{ formatNumber(user.experience.level) }}
                </div>
              </div>
            </div>
          </div>
        </b-nav-item>

        <!--        shop-->
        <b-nav-item href="#" class="nav-item-shop mr-0">
          <div class="shop-item position-relative">
            <div
              id="header-shop-container"
              class="position-relative ok-button-green clickable"
              @click="
                () => {
                  playSoundTab();
                  showShop();
                  firebaseClickItemHeader('header_shop_button');
                }
              "
            >
              <div class="icon-gold-three"></div>
              <span class="d-flex align-items-center justify-content-center">
                {{ $t("header.shop_button") }}
              </span>
            </div>
            <!--special offer-->
            <div v-if="isSpecialOfferActive" class="special-offer-container">
              <SpecialOfferBadge />
            </div>

            <div v-if="showInfo" class="more-info to-center-abs">
              {{ $t("header.more") }}
            </div>
          </div>
        </b-nav-item>
      </b-navbar-nav>

      <!--      right area-->
      <b-navbar-nav id="nav-area-right">
        <!--        settings-->
        <b-nav-item class="nav-item-settings">
          <div id="header-settings-container" class="clickable">
            <SettingsMenu />
          </div>
        </b-nav-item>

        <modal-shop :preselected-tab="preselectedTab"></modal-shop>
        <modal-payment></modal-payment>
      </b-navbar-nav>
    </b-navbar>
    <AnimationSpineRewardFlyHeader />
  </div>
</template>

<script>
import ModalShop from "./shop/modal-shop";
import AnimationExpboost from "@/components/animation/animation-expboost.vue";
import AnimationSpineRewardFlyHeader from "@/components/animation/animation-spine-reward-fly-header";
import ModalPayment from "@/components/shop/modal-payment";
import SettingsMenu from "@/components/lobby/settings-menu";
import SpecialOfferBadge from "@/components/lobby/special-offer-badge";
import dateFunctions from "@/mixins/dateFunctions.js";

export default {
  name: "Header",
  components: {
    SpecialOfferBadge,
    SettingsMenu,
    ModalPayment,
    AnimationSpineRewardFlyHeader,
    ModalShop,
    AnimationExpboost,
  },

  mixins: [dateFunctions],
  data() {
    return {
      loading: false,
      error: false,
      preselectedTab: 0,
    };
  },

  computed: {
    showInfo() {
      const expired = new Date("2022-12-04 23:59:59");
      return this.isDateInPast(new Date(), expired);
    },
    isSpecialOfferActive: function () {
      return this.$store.state.shop.specialOffer;
    },
    currentExpPercent: function () {
      if (this.user.experience) {
        return this.user.experience.progress.percentage + "%";
      } else {
        return 0;
      }
    },
    englishActive() {
      return this.$store.state.locale === "en";
    },
    user: function () {
      return this.$store.getters["user/currentUser"];
    },

    userId: function () {
      return this.$store.getters["user/currentUser"].id;
    },
    userLeaguePosition() {
      return this.$store.getters["user/getUserLeagueInfos"].position;
    },

    userLeagueMovement() {
      let league = this.$store.getters["user/getUserLeagueInfos"].league;
      let position = this.$store.getters["user/getUserLeagueInfos"].position;
      if (league && !league.descend && league.ascend) {
        if (position <= league.ascend) {
          return "green";
        } else {
          return "grey";
        }
      } else if (league && league.descend && league.ascend) {
        if (position <= league.ascend) {
          return "green";
        }
        if (position >= league.limit - league.descend) {
          return "red";
        } else {
          return "grey";
        }
      } else if (league && !league.ascend && league.descend) {
        if (position >= league.limit - league.descend) {
          return "red";
        } else {
          return "grey";
        }
      } else {
        return "grey";
      }
    },

    amountElixir() {
      if (!this.user.elixir) {
        return 0;
      }
      return this.user.elixir.amount;
    },
  },

  mounted: function () {
    this.$store.dispatch("user/fetchUserLeagueInfos");
  },

  methods: {
    showShop: function (tab = 0) {
      this.preselectedTab = tab;
      this.$bvModal.show("modal-shop");
    },
    handleHomeButtonClick() {
      this.playSoundTab();
      this.firebaseClickItemHeader("header_home_button");
      if (this.$route.path === "/") {
        this.$root.$emit("go-back-home");
      }
    },

    showBonusSlotElixirPage() {
      this.$store.commit("bonusslot/setForceShowElixirPage", true);
      this.$bvModal.show("modal-bonus-slot");
    },
  },
};
</script>
<style scoped lang="scss">
.navbar-nav {
  height: 100%;
  align-items: center;
  &#nav-area-left {
    padding-left: 0.313rem;
    justify-content: space-around;
  }
  &#nav-area-middle {
    width: 62rem;
    margin-left: -3rem;
    justify-content: space-around;
  }
  &#nav-area-right {
    padding-right: 0.313rem;
    justify-content: flex-end;
    z-index: 1;
  }
}
.nav-item {
  margin-left: auto;
  margin-right: auto;

  &.nav-item-shop {
    margin-left: 1rem;
  }

  &:hover .header-item-icon {
    filter: $hover-shadow;
    transition: 0.2s ease-in-out;
  }
}

.cursor-default {
  cursor: default;
}

#header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: $header-height;
  z-index: 1000;
  background: linear-gradient(to top, #267084, #050d1c 21%, #1f5a6a);
  &.resize-header {
    #header-content {
      max-width: 100%;
    }
  }

  #header-content {
    max-width: $main-content-size;
    height: $header-height;
    top: 0;
    flex-direction: row;
    justify-content: space-between;
    display: flex;
    font-size: 1rem;
    padding: 0 0 0 0.3rem;

    .nav-link {
      color: white;
    }

    .white-border {
      border: double 0.083rem transparent;
      background-image: linear-gradient(#050d1c, #153c46),
        linear-gradient(#8b5a00, #ffee51 49%, #f7ffff);
      background-origin: border-box;
      background-clip: padding-box, border-box;
      background-size: 100% 100%;
      box-shadow: inset 0 -0.063rem 0.125rem rgba(0, 0, 0, 0.8);
    }

    .header-number-bar {
      display: flex;
      display: -webkit-box;
      align-items: center;
      justify-content: flex-end;
      height: 100%;
      font-size: 1rem;
      padding-right: 0.75rem;

      .header-number-text {
        font-size: 1rem;
        color: white;
        font-family: Ubuntu-Bold;
        text-shadow: 0 0 0.313rem #000000, 0 0 0.313rem #000000,
          0 0 0.188rem #000000;

        &.big {
          font-size: 1.313rem;
        }

        &.small {
          font-size: 1rem;
          margin-top: -0.125rem;
        }
      }

      #header-elixir-icon {
        position: absolute;
        left: 0.45rem;
        top: -1rem;
        width: 2.25rem;
        height: 3rem;
        background: url(~@/assets/img/icons/icon-final-elixir.png) center/100%
          100% no-repeat;
        margin-right: 0.625rem;
      }
    }

    #header-home-container {
      display: inline-flex;
      width: 3.75rem;
      height: 3.813rem;
      background: url(~@/assets/img/header/btn-home.png) center/100% 100%
        no-repeat;
      &:hover {
        filter: $hover-shadow;
        transition: 0.2s ease-in-out;
      }
    }

    #header-logo-container {
      display: inline-flex;
      width: 8.063rem;
      height: 3.438rem;
      background: url(~@/assets/img/header/kleinekrone-logo-lobby-small-2-x.png)
        center/100% 100% no-repeat;
      &:hover {
        filter: $hover-shadow;
        transition: 0.2s ease-in-out;
      }
    }

    #header-league-container {
      width: 8rem;
      height: 2.5rem;
      position: relative;
      background: black;
      border: 0.063rem solid #074f5c;
      border-radius: 1.25rem;
      box-shadow: inset 0 -0.063rem 0.125rem #074f5c;

      #header-league-icon {
        width: 2.563rem;
        height: 2.5rem;
        position: absolute;
        left: -1.25rem;
      }

      #header-league-movement {
        position: absolute;
        left: 1.875rem;
      }

      #header-league-text-field {
        position: absolute;
        right: 1.125rem;
        text-align: right;
        font-family: Ubuntu-Bold;
        font-size: 1.313rem;
        text-shadow: 0.063rem 0.063rem 0.125rem black;
      }
    }

    #header-center-container {
      width: 39rem;
      height: 10rem;
      background: url(~@/assets/img/header/panel-wallet-center.png) center 100%/100%
        no-repeat;
      top: -1.5rem;
      border-bottom-left-radius: 0.688rem;
      border-bottom-right-radius: 0.688rem;

      #header-gold-container {
        position: absolute;
        top: 2.5rem;
        left: 1.75rem;
        width: 15.813rem;
        height: 2.5rem;
      }
      #header-crown-container {
        position: absolute;
        top: 2.5rem;
        left: 18.675rem;
        width: 10.938rem;
        height: 2.5rem;
      }

      #header-elixir-container {
        position: absolute;
        top: 6.063rem;
        left: 2.375rem;
        width: 6.975rem;
        height: 1.475rem;
      }

      #header-avatar-container {
        position: absolute;
        top: 2.5rem;
        right: 2.563rem;
        font-size: 1rem;

        .avatar-section {
          margin-left: 0.5rem;
          color: white;
          &:hover .avatar-frame #avatar-hover-text {
            visibility: visible;
          }
          &:hover .avatar-frame #header-avatar-icon {
            opacity: 0.3;
          }
          .avatar-frame {
            border-radius: 0.5rem;
            width: 5.05rem;
            height: 5.05rem;
            position: relative;

            #header-avatar-icon {
              height: 90%;
              width: 90%;
              top: 5%;
              border-radius: 50%;
              transition: 0.2s ease-in-out;
              object-fit: contain;
            }
            #avatar-hover-text {
              height: 100%;
              width: 100%;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              text-transform: uppercase;
              font-size: 0.688rem;
              visibility: hidden;
              transition: 0.2s ease-in;
              text-shadow: 0 0 0.625rem $light-blue, 0 0 0.875rem $light-blue,
                0 0 0.5rem $light-blue, 0 0 0.313rem $light-blue,
                0 0 0.125rem #050d1c;
            }
          }
        }
      }

      #header-exp-container {
        position: absolute;
        top: 6.25rem;
        left: 12.188rem;

        .icon-expcrown {
          position: absolute;
          top: -0.95rem;
          left: -1.25rem;
          z-index: 1;
          width: 3rem;
          height: 2.5rem;
          background: url(~@/assets/img/header/icn-crown-level-2-x.png) center
            100%/100% no-repeat;
        }

        .progress-bar-section {
          position: relative;
          width: 10.6rem;
          height: 1.55rem;

          .progress-fill {
            height: 0.875rem;
            border-radius: 1rem;
            max-width: 100%;
            background-image: linear-gradient(
              to bottom,
              #ffffff,
              #e5e5e5 51%,
              #989898 48%,
              #c1c1c1
            );
          }

          .text-below-level-progress {
            position: absolute;
            right: -5.625rem;
            top: -0.25rem;
            text-align: left;
            font-size: 1rem;
            color: white;
            font-family: Ubuntu-Bold;
            text-shadow: 0 0.125rem 0.125rem rgba(44, 20, 4, 0.93);
          }
        }
      }
    }

    #header-shop-container {
      width: 12.563rem;
      height: 2.5rem;
      color: white;
      border-radius: 1.25rem;
      text-shadow: 0.063rem 0.063rem 0.125rem #0b2b00, 0 0 1.563rem #42ff00,
        0 0 0.313rem #0b2b00;
      min-width: auto;
      min-height: auto;

      .icon-gold-three {
        position: absolute;
        top: -0.188rem;
        left: -2.5rem;
        width: 5.188rem;
        height: 2.875rem;
        background: url(~@/assets/img/header/icon-shop.png) center/100% 100%
          no-repeat;
      }

      span {
        text-transform: uppercase;
        font-size: 1.313rem;
        font-family: Ubuntu-Bold;
        text-align: center;
        line-height: 2.5rem;
        height: 100%;
        padding-top: 0.2rem;
      }
    }

    .special-offer-container {
      position: absolute;
      top: -20px;
      right: 4rem;
      z-index: 1;
    }

    .more-info {
      font-family: Ubuntu-Bold;
      top: -1rem;
      left: -2.5rem;
      font-size: 1.2rem;
      transform: rotate(-10deg);
      text-align: left;
      z-index: 99;
      opacity: 1;
      text-shadow: 0 0 1rem rgb(77 229 255 / 60%),
        0 0 0.5rem rgb(77 229 255 / 60%), 0 0 0.5rem rgb(77 229 255 / 60%),
        0 0 0.25rem rgb(0 94 110 / 80%), 0 0 0.25rem rgb(0 94 110 / 80%);
      pointer-events: none;
    }

    #header-settings-container {
      width: 3.75rem;
      height: 4rem;
      background: url(~@/assets/img/header/btn-settings.png) center 100%/100%
        no-repeat;
    }
  }
}
</style>
