<template>
  <b-modal
    id="modal-olympia-event"
    ref="modal-olympia-event"
    :hide-footer="true"
    modal-class="custom-modal1"
    @shown="setUpContent"
    @hidden="clearContent"
  >
    <template v-if="!loading" slot="modal-header" slot-scope="{ close }">
      <div v-if="!animReady" class="gfg-icon"></div>
      <AnimationEventGoforgold @done="animReady = true" />

      <div
        id="help-button"
        class="clickable"
        @click="$bvModal.show('modal-olympia-tutorial')"
      ></div>

      <div
        id="matchday-box"
        class="clickable text-right"
        :class="[isToday ? 'disabled' : '']"
        @click="selectCurrentMatchday"
      >
        <div id="today-button" :class="[isToday ? 'disabled' : '']">
          <span class="text-uppercase bold">{{ $t("teams.chat.today") }}</span>
        </div>
        <div id="matchday-headline">
          <span class="text-uppercase bold"
            >{{ $t("events.olympics.matchday>one") }}
            {{ matchdayIndex + 1 }}: </span
          ><span class="blue bold">{{ selectedMatchdayDate }}</span>
        </div>
        <div id="matchday-subline" class="d-inline-flex align-items-center">
          <span class="text-uppercase bold" :style="{ color: sublineColor }">{{
            matchdaySubline
          }}</span
          ><template
            v-if="
              isToday &&
              selectedMatchday &&
              !packagePurchased &&
              isSelectedMatchdayActive
            "
            ><span class="mr-1">:</span
            ><CountdownDigital
              class="blue bold"
              :time="matchdayEndTime"
              :with-time-diff="true"
          /></template>
        </div>
      </div>
      <a href="#" class="close-button" @click="close()"> </a>
    </template>

    <template v-if="!loading" slot="default">
      <div class="container-fluid">
        <b-row no-gutters align-v="end" align-h="between"
          ><b-col id="matchdays-col" cols="3"
            ><div
              class="matchday-entry d-inline-flex align-items-center justify-content-center"
            >
              {{ $t("events.olympics.matchday>other") }}
            </div>
            <div id="matchday-scroll-wrapper">
              <ModalOlympiaMatchdayEntry
                v-for="(matchday, index) in matchdays"
                :key="index"
                :index="index"
                :matchday="matchday"
                :selected-matchday-index="matchdayIndex"
                :todays-matchday-index="todaysMatchdayIndex"
                :ingame-events="ingameEvents"
                :user-raffles="userRaffles"
                :active-matchday-index="activeMatchdayIndex"
                @matchdaySelected="handleMatchdaySelect"
              />
            </div>

            <div
              class="matchday-entry scroll-arrow clickable"
              @click="scrollDown(100)"
            ></div></b-col
          ><ModalOlympiaDayCols
            :matchday="selectedMatchday"
            :mega-package="megaPackage"
            :normal-package="normalPackage"
            :matchday-ingame-event="matchdayIngameEvent"
            :is-today="isToday"
            :is-yesterday="isYesterday"
            :day-one-date="selectedMatchdayDate"
            :day-two-date="dayTwoDate"
            :day-three-date="dayThreeDate"
            :is-active="isSelectedMatchdayActive"
            @buy-package="handleBuyPackage"
            @bonus-collected="handleBonusCollect"
          /><ModalOlympiaTutorial />
        </b-row></div
    ></template>
    <Spinner v-if="loading" class="dead-center" />
  </b-modal>
</template>

<script>
import eventPopups from "@/mixins/eventPopups.js";
import ModalOlympiaDayCols from "@/components/events/olympia/modal-olympia-day-cols.vue";
import ModalOlympiaTutorial from "@/components/events/olympia/modal-olympia-tutorial.vue";
import ModalOlympiaMatchdayEntry from "@/components/events/olympia/modal-olympia-matchday-entry.vue";
import { collectGoForGoldRaffle } from "@/API/events.js";
import { fetchPaymentOffersByOfferId } from "@/API/payment.js";
import { getEventForUser } from "@/API/events.js";
import CountdownDigital from "@/components/base/countdown-digital.vue";
import getRewardsFromPackage from "@/mixins/paymentFunctions.js";
import AnimationFullRewardEarned from "@/components/animation/animation-full-reward-earned";
import Spinner from "@/components/animation/animation-spinner.vue";
import AnimationEventGoforgold from "@/components/animation/animation-spine-event-goforgold.vue";
import dateFunctions from "@/mixins/dateFunctions.js";
export default {
  name: "ModalOlympiaEvent",
  components: {
    ModalOlympiaDayCols,
    ModalOlympiaTutorial,
    ModalOlympiaMatchdayEntry,
    CountdownDigital,
    Spinner,
    AnimationEventGoforgold,
  },
  mixins: [
    eventPopups,
    getRewardsFromPackage,
    AnimationFullRewardEarned,
    dateFunctions,
  ],

  data() {
    return {
      animReady: false,
      modalId: "modal-olympia-event",
      matchdayIndex: 0,
      todaysMatchdayIndex: 0,
      today: null,
      offerPackages: [],
      ingameEvents: [],
      loading: true,
    };
  },

  computed: {
    matchdays() {
      return this.$store.getters["events/getPaymentEventDays"];
    },
    selectedMatchday() {
      return this.matchdays[this.matchdayIndex];
    },
    isSelectedMatchdayActive() {
      return this.matchdayIndex === this.activeMatchdayIndex;
    },
    activeMatchdayIndex() {
      return this.matchdays.findIndex((matchday) => {
        let matchdayStart = new Date(matchday.start_at);
        let matchdayEnd = new Date(matchday.end_at);
        return (
          !this.isDateInPast(matchdayEnd, this.today) &&
          !this.isDateInFuture(matchdayStart, this.today)
        );
      });
    },
    todaysMatchday() {
      return this.matchdays[this.todaysMatchdayIndex];
    },
    selectedMatchdayDate() {
      if (!this.selectedMatchday) return;
      return this.createLocalizedDate(this.selectedMatchday.start_at);
    },
    dayTwoDate() {
      let nextDay = this.matchdays[this.matchdayIndex + 1];
      if (nextDay) {
        return this.createLocalizedDate(nextDay.start_at);
      } else {
        let tomorrow = new Date(this.selectedMatchday.start_at);
        tomorrow.setDate(tomorrow.getDate() + 1);
        return this.createLocalizedDate(tomorrow);
      }
    },
    dayThreeDate() {
      let nextDayAfter = this.matchdays[this.matchdayIndex + 2];
      if (nextDayAfter) {
        return this.createLocalizedDate(nextDayAfter.start_at);
      } else {
        let dayAfterTomorrow = new Date(this.selectedMatchday.start_at);
        dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 2);
        return this.createLocalizedDate(dayAfterTomorrow);
      }
    },
    isToday() {
      return this.todaysMatchdayIndex === this.matchdayIndex;
    },
    isYesterday() {
      return this.matchdayIndex === this.todaysMatchdayIndex - 1;
    },
    isInPast() {
      return this.todaysMatchdayIndex > this.matchdayIndex;
    },
    isInFuture() {
      return this.todaysMatchdayIndex < this.matchdayIndex;
    },
    allRaffles() {
      return this.$store.state.events.paymentEventRaffles;
    },
    userRaffles() {
      return this.$store.state.events.paymentEventUserRaffles;
    },
    matchdayRaffle() {
      if (!this.allRaffles) return null;
      return this.allRaffles.find(
        (raffle) => raffle.raffleable_id === this.selectedMatchday.id
      );
    },
    matchdayUserRaffle() {
      if (!this.userRaffles) return null;
      return this.userRaffles.find(
        (userRaffle) =>
          userRaffle.raffle.raffleable_id === this.selectedMatchday.id
      );
    },
    matchdayIngameEvent() {
      if (!this.ingameEvents) return null;
      return this.ingameEvents.find((event) => {
        if (!event) return;
        return event.ingame_event_id === this.selectedMatchday.id;
      });
    },

    packagePurchased() {
      return this.matchdayIngameEvent;
    },
    medalsDecided() {
      if (this.matchdayRaffle) {
        return true;
      } else {
        return false;
      }
    },
    matchdaySubline() {
      if (this.packagePurchased) {
        return this.$t("events.olympics.lucky_bundle.purchased");
      } else if (
        this.isToday &&
        !this.packagePurchased &&
        this.isSelectedMatchdayActive
      ) {
        return this.$t("events.olympics.lucky_bundle.time_remaining");
      } else if (
        (this.isInPast && !this.packagePurchased) ||
        (this.isToday && !this.isSelectedMatchdayActive)
      ) {
        return this.$t("events.olympics.lucky_bundle.not_purchased");
      } else {
        return this.$t("events.olympics.lucky_bundle.not_yet_available");
      }
    },
    sublineColor() {
      if (this.packagePurchased) {
        return "#43ff00";
      }
      if (
        (this.isInPast && !this.packagePurchased) ||
        (this.isToday && !this.isSelectedMatchdayActive)
      ) {
        return "#ff7100";
      } else if (this.isInFuture) {
        return "#ff0000";
      } else {
        return "white";
      }
    },
    matchdayEndTime() {
      return new Date(this.selectedMatchday.end_at).getTime();
    },
    sortedOfferPackages() {
      if (!this.offerPackages) return null;
      let arrayToSort = this.offerPackages.slice();
      return arrayToSort.sort((a, b) => b.package.price - a.package.price);
    },
    megaPackage() {
      return this.sortedOfferPackages[0];
    },
    normalPackage() {
      return this.sortedOfferPackages[1];
    },
  },

  mounted() {
    if (this.$route.path.startsWith("/olympia")) {
      this.$bvModal.show("modal-olympia-event");
    }
  },
  created() {
    this.today = new Date();
    this.$root.$on("bv::modal::hidden", async (bvEvent, modalId) => {
      if (modalId === "modal-buy-complete") {
        // update UserIngameEvent after successful purchase
        this.fetchIngameEventInfo();
      }
    });
  },
  methods: {
    setUpContent() {
      Promise.all([
        this.selectCurrentMatchday(),
        this.$store.dispatch("events/getOlympiaEventRaffle"),
        this.fetchPackages(),
        this.fetchIngameEventInfo(),
      ])
        .then(() => {
          this.loading = false;
          setTimeout(() => {
            this.scrollDown(this.matchdayIndex * 60), 1000;
          });
        })
        .catch((e) => {
          console.log(e);
          this.$bvModal.hide("modal-olympia-event");
        });
    },
    selectCurrentMatchday() {
      return new Promise((resolve) => {
        let currentMatchdayIndex = this.matchdays.findIndex((entry) => {
          let matchdayStart = new Date(entry.start_at);
          let matchdayEnd = new Date(entry.end_at);
          return (
            !this.isDateInPast(matchdayEnd, this.today) &&
            !this.isDateInFuture(matchdayStart, this.today)
          );
        });
        if (currentMatchdayIndex < 0) {
          // select first matchday if event is visible but first event matchday hasn't started yet
          if (
            this.isDateInFuture(
              new Date(this.matchdays[0].start_at),
              new Date(this.today)
            )
          ) {
            currentMatchdayIndex = 0;
          }
          // select last matchday in list if event is already over
          else {
            currentMatchdayIndex = this.matchdays.length - 1;
          }
        }
        this.matchdayIndex = currentMatchdayIndex;
        this.todaysMatchdayIndex = currentMatchdayIndex;
        resolve();
      });
    },
    fetchPackages() {
      return new Promise((resolve, reject) => {
        let packageIdArray =
          this.matchdays[this.matchdays.length - 1].payload.packages;
        packageIdArray.forEach((id) => {
          fetchPaymentOffersByOfferId(id)
            .then((res) => {
              this.offerPackages.push(res.packages[0]);
            })
            .catch((e) => reject(e));
        });
        resolve();
      });
    },

    scrollDown(pixels) {
      let element = document.getElementById("matchday-scroll-wrapper");
      if (!element) return;
      element.scrollBy({ top: pixels, left: 0, behavior: "smooth" });
    },
    handleMatchdaySelect(index) {
      this.matchdayIndex = index;
    },
    handleBuyPackage(packageObj) {
      packageObj.rewards = this.getRewardsFromPackage(packageObj);
      this.$store.commit("shop/setShopChosenPackage", packageObj);
      this.$bvModal.show("modal-payment");
    },
    handleBonusCollect(raffleId) {
      collectGoForGoldRaffle(
        raffleId,
        this.$store.getters["user/currentUser"].id
      )
        .then((res) => {
          this.$store.commit("events/clearPaymentEventUserRaffles");
          this.$bvModal.hide("modal-olympia-event");
          let result = [];
          if (res.data.data.log.gold > 0) {
            result.push({
              reward: "gold",
              amount: this.formatNumber(res.data.data.log.gold),
            });
          }
          if (res.data.data.log.crowns > 0) {
            result.push({
              reward: "crown",
              amount: this.formatNumber(res.data.data.log.crowns),
            });
          }

          this.AnimationFullRewardEarnedAnimate(result, false);
        })
        .catch((e) => console.log(e));
    },
    fetchIngameEventInfo() {
      return new Promise((resolve, reject) => {
        this.matchdays.forEach((matchday) => {
          getEventForUser(
            matchday.id,
            this.$store.getters["user/currentUser"].id
          )
            .then((res) => this.ingameEvents.push(res.data.data.event))
            .catch((e) => reject(e));
        });

        resolve();
      });
    },
    createLocalizedDate(dateString) {
      return new Date(dateString).toLocaleDateString(this.$store.state.locale, {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      });
    },
    clearContent() {
      this.$store.commit("events/clearPaymentEventUserRaffles");
      this.offerPackages = [];
      this.ingameEvents = [];
      this.loading = true;
    },
  },
};
</script>
<style lang="scss">
$modal-width: 1200px;
$modal-height: 837px;

#modal-olympia-event {
  .modal-dialog {
    min-width: $modal-width;
    max-width: $modal-width;
    width: 100%;
    height: $modal-height;
    padding-top: 50px;

    @media (max-height: 850px), (max-width: 1250px) {
      transform: scale(0.8);
      top: -40px;
    }
    @media (max-height: 770px), (max-width: 1100px) {
      transform: scale(0.7);
      top: -100px;
    }
    @media (max-width: 1100px) {
      left: -5%;
    }
  }
  .modal-header {
    margin-top: 0;
    align-items: center;
    justify-content: flex-end;

    width: 100%;
    .close-button {
      right: 10px;
      top: 0;
    }

    #matchday-box {
      background: url(~@/assets/img/events/gfg-playdays-top-display.svg)
        center/100% 100% no-repeat;
      height: 146px;
      width: 589px;
      padding: 30px 35px;
      position: relative;
      &.disabled {
        pointer-events: none;
      }
      #today-button {
        width: 212px;
        height: 100px;
        position: absolute;
        top: -18px;
        left: -38px;
        background: url(~@/assets/img/events/gfg-btn-jump-today.svg) center/100%
          100% no-repeat;
        span {
          position: absolute;
          font-size: 28px;
          text-shadow: 0 0 19px rgba(77, 229, 255, 0.75),
            0 0 9px rgba(77, 229, 255, 0.8);
          left: 40px;
          top: 28px;
          color: white;
        }
        &.disabled {
          background: url(~@/assets/img/events/gfg-btn-jump-today-inactive.svg)
            center/100% 100% no-repeat;
          span {
            color: #52bcce;
          }
        }
      }
      #matchday-headline {
        font-size: 32px;
      }

      #matchday-subline {
        font-size: 24px;
      }
    }
    #help-button {
      background: url(~@/assets/img/events/gfg-btn-help-orange.png) center/100%
        100% no-repeat;
      height: 144px;
      width: 144px;
      z-index: 10;
      position: absolute;
      right: 52%;
      top: -3%;
    }
    .gfg-icon {
      background: url(~@/assets/img/events/gfg-logo.png) center/100% 100%
        no-repeat;
      width: 521px;
      height: 300px;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 5;
    }
  }

  .modal-body {
    margin-top: 0 !important;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .modal-content {
    width: 100%;
    height: 100%;
    background: url(~@/assets/img/events/gfg-bg.png) center/100% 100% no-repeat;
  }
  #matchdays-col {
    height: 480px;
    #matchday-scroll-wrapper {
      height: 350px;
      overflow-y: scroll;
    }
  }

  .reward-icon {
    width: 56px;
    height: 56px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
</style>
