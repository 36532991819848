var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modal-winter-bonus-jagd",attrs:{"id":_vm.modalId,"hide-footer":true,"modal-class":"modal-winter-bonus-jagd"},on:{"shown":_vm.onOpen,"hidden":_vm.onCloseCustom},scopedSlots:_vm._u([{key:"modal-header",fn:function(ref){
var close = ref.close;
return [_c('a',{staticClass:"close-button",attrs:{"href":"#"},on:{"click":function($event){return close()}}})]}}])},[_c('template',{slot:"default"},[(_vm.loading)?_c('Spinner',{attrs:{"size":"medium","context":"Loading Event...","classes":"dead-center"}}):_vm._e(),_c('div',{staticClass:"preload"}),_c('div',{staticClass:"bg main"}),_c('div',{staticClass:"bg snow-left"}),_c('div',{staticClass:"bg snow-right"}),_c('div',{staticClass:"bg snow-front"}),_c('div',{staticClass:"bg border1 top"}),_c('div',{staticClass:"bg border1 bottom"}),_c('div',{staticClass:"bg border2 left"}),_c('div',{staticClass:"bg border2 right"}),_c('div',{staticClass:"logo"},[_c('div',{staticClass:"main"}),_c('div',{staticClass:"bonus",class:_vm.$store.state.locale})]),(!_vm.loading)?[_c('div',{staticClass:"signs"},[_c('div',{staticClass:"text1"},[_vm._v(" "+_vm._s(_vm.$t("events.winter.kingspass"))+" "),_c('div',{class:[_vm.isKingspassActive ? 'check-icon' : 'lock-icon']})]),_c('div',{staticClass:"text2"},[_vm._v(" "+_vm._s(_vm.$t("events.winter.freepass"))+" ")])]),(_vm.kingsPassData)?_c('div',{staticClass:"task-panel-container"},[(
            _vm.getPriceOfLevel(_vm.currentLevel + 1) > 0 &&
            _vm.userProgress >= 100 &&
            !_vm.isLevelBlockerExpired(_vm.kingspassLevels[_vm.currentLevel + 1])
          )?[_c('div',{staticClass:"task-text",on:{"click":function($event){return _vm.openLevelBuyModal(_vm.currentLevel + 1)}}},[_vm._v(" "+_vm._s(_vm.$t("events.winter.task_text.unlock_levels", { 0: _vm.currentLevel + 2, 1: _vm.getPriceOfLevel(_vm.currentLevel + 1), }))+" ")])]:(
            _vm.currentLevel < _vm.kingspassLevels.length - 1 ||
            (_vm.currentLevel === _vm.kingspassLevels.length - 1 &&
              _vm.userProgress < 100)
          )?[_c('div',{staticClass:"task-text",on:{"click":_vm.startRandomSlot}},[_c('div',{staticClass:"text1 text-center"},[_vm._v(" "+_vm._s(_vm.$t("events.winter.task", { 0: _vm.currentLevel + 1 }))+" ")]),(_vm.kingspassLevels)?_c('div',{staticClass:"text2 text-center"},[_vm._v(" "+_vm._s(_vm.$t("events.winter.task_type." + _vm.currentTaskType, { 0: _vm.currentTaskWinAmount, }))+" ")]):_vm._e()])]:[_c('div',{staticClass:"task-text"},[_vm._v(" "+_vm._s(_vm.$t("events.winter.event_completed"))+" ")])],_c('div',{staticClass:"ticket-icon position-absolute"}),_c('div',{staticClass:"ticket-counter position-absolute text-center"},[_vm._v(" "+_vm._s(_vm.ticketCount)+" ")]),_c('div',{staticClass:"timer d-inline-flex align-items-center"},[_c('div',{staticClass:"icon-hourglass bg-img"}),_c('countdown-digital',{attrs:{"fixed-width":true,"time":_vm.endDate,"days-to-hours":true,"with-time-diff":true}})],1),(_vm.isKingspassActive)?[_c('div',{staticClass:"king-pass-button to-center-abs d-inline-flex align-items-center justify-content-around"},[_c('div',{staticClass:"text1"},[_vm._v(" "+_vm._s(_vm.$t("events.winter.kingspass_activated"))+" ")]),_c('div',{staticClass:"check-icon"})])]:[_c('div',{staticClass:"king-pass-button to-center-abs d-inline-flex align-items-center active pointer",on:{"click":_vm.openKingspassBuyModal}},[_c('div',{staticClass:"text1"},[_vm._v(" "+_vm._s(_vm.$t("events.winter.kingspass_activate"))+" ")]),_c('div',{staticClass:"text2"},[_vm._v(" "+_vm._s(_vm.priceKingsPass)+" "),_c('div',{staticClass:"crown-icon"})])])],_c('div',{staticClass:"help-button",on:{"click":_vm.openHelpModal}})],2):_vm._e(),(_vm.levelRewards)?_c('div',{ref:"rewards-container",staticClass:"rewards-container to-center-abs"},[_c('div',{staticClass:"rewards-content"},[_vm._l((_vm.eventData.levels),function(level,index){return [(
                !_vm.isLevelBlockerExpired(level) &&
                level.crowns > 0 &&
                index - 1 === _vm.currentLevel &&
                _vm.userProgress >= 100
              )?_c('div',{key:'panel' + index,staticClass:"lock-panel"},[_c('div',{staticClass:"bg"},[_c('div',{staticClass:"text1 to-center-abs"},[_vm._v("Level")]),_c('div',{staticClass:"text2 to-center-abs"},[_vm._v(" "+_vm._s(index + 1)+" - "+_vm._s(index + 10 >= _vm.levelRewards.length - 1 ? _vm.levelRewards.length : index + 10)+" ")])]),_c('div',{staticClass:"unlock-button to-center-abs",on:{"click":function($event){return _vm.openLevelBuyModal(index)}}},[_c('div',{staticClass:"button-text1 to-center-abs"},[_vm._v(" "+_vm._s(_vm.$t("events.winter.level_unlock"))+" ")]),_c('div',{staticClass:"button-text2 to-center-abs"},[_c('span',{staticClass:"position-relative"},[_vm._v(" "+_vm._s(_vm.getPriceOfLevel(index))+" "),_c('div',{staticClass:"crown-icon"})])])])]):_vm._e(),_c('div',{key:'reward' + index,staticClass:"reward",attrs:{"id":'reward-panel' + index}},[_c('div',{staticClass:"bg",class:_vm.getRewardType(index + 1)}),(_vm.currentLevel + 1 <= index)?_c('div',{staticClass:"frozen to-center-abs"}):_vm._e(),_c('div',{staticClass:"number to-center-abs"},[_vm._v(_vm._s(index + 1))]),(
                  level.level < _vm.currentLevel ||
                  (level.level === _vm.currentLevel && _vm.userProgress === 100)
                )?[_c('div',{staticClass:"collect-button to-center-abs"},[_vm._v(" "+_vm._s(_vm.$t("events.winter.collect_button"))+" ")])]:[_c('div',{staticClass:"progress-container to-center-abs"},[_c('div',{staticClass:"progress-frame"}),(level.level === _vm.currentLevel)?[_c('div',{staticClass:"progress-bar"},[_c('div',{staticClass:"progress-fill",style:({ width: _vm.userProgress + '%' })})]),_c('div',{staticClass:"progress-percent-badge"},[_vm._v(" "+_vm._s(Math.floor(_vm.userProgress))+"% ")])]:_vm._e(),(level.level > _vm.currentLevel)?[_c('div',{staticClass:"progress-bar"},[_c('div',{staticClass:"progress-fill",style:({ width: 0 })})]),_c('div',{staticClass:"progress-percent-badge"},[_vm._v("0%")])]:_vm._e()],2)],_c('div',{staticClass:"reward-kingspass to-center-abs d-inline-flex align-items-center justify-content-around pl-3"},[_vm._l((Object.values(
                    _vm.levelRewards[index]
                  )[1]),function(kingspassReward){return _c('BaseReward',{key:("kingspass-reward-" + (kingspassReward.id)),attrs:{"reward-data":kingspassReward,"shorten-gold-amount":true,"is-kingspass":true}})}),_c('BaseReward',{attrs:{"custom-reward":true,"reward-data":{
                    type: 'ticket',
                    amount: index + 1,
                    src: require('@/assets/img/events/winter-bonus-jagd/icn-reward-ticket.svg'),
                  },"is-kingspass":true}}),(_vm.isKingspassActive)?_c('div',{staticClass:"complete-img",class:[
                    _vm.userProgress >= 100 || index < _vm.currentLevel
                      ? 'check-icon'
                      : '' ]}):_c('div',{staticClass:"lock-icon"})],2),_c('div',{staticClass:"reward-normal to-center-abs d-inline-flex align-items-center justify-content-around pl-3"},[_vm._l((Object.values(
                    _vm.levelRewards[index]
                  )[0]),function(normalReward){return _c('BaseReward',{key:("normal-reward-" + (normalReward.id)),attrs:{"reward-data":normalReward}})}),_c('BaseReward',{attrs:{"custom-reward":true,"reward-data":{
                    type: 'ticket',
                    amount: index + 1,
                    src: require('@/assets/img/events/winter-bonus-jagd/icn-reward-ticket.svg'),
                  }}}),_c('div',{staticClass:"complete-img",class:[
                    _vm.userProgress >= 100 || index < _vm.currentLevel
                      ? 'check-icon'
                      : '' ]})],2)],2)]})],2)]):_vm._e(),_c('div',{staticClass:"scroll-button-container to-center-abs"},[_c('div',{staticClass:"button-left pointer",on:{"click":function($event){return _vm.scroll(-_vm.scrollSpeed)}}}),_c('div',{staticClass:"button-right pointer",on:{"click":function($event){return _vm.scroll(_vm.scrollSpeed)}}})]),_c('modal-winter-help'),_c('modal-winter-buy-pass',{attrs:{"amount":_vm.priceKingsPass},on:{"buy-kingspass":_vm.buyKingspass}}),_c('modal-winter-buy-level',{attrs:{"amount":_vm.currentLevelPrice},on:{"buy-level":_vm.unlockNextLevels}}),(_vm.showKingspassAnimation)?_c('animation-winterbonusjagd-kingspass',{attrs:{"loop":false},on:{"kingspass-ani-completed":function($event){_vm.showKingspassAnimation = false}}}):_vm._e(),(_vm.showLevelUnlockAnimation)?_c('animation-winterbonusjagd-unlock-level',{attrs:{"loop":false,"level":_vm.currentLevel + 1},on:{"kingspass-ani-completed":function($event){_vm.showLevelUnlockAnimation = false}}}):_vm._e()]:_vm._e()],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }