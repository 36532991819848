<template>
  <div id="cards-hero-box" class="bg-img to-center-abs d-inline-flex">
    <div class="preload"></div>
    <div
      class="text-white text-uppercase bold to-center-abs headline text-center"
    >
      {{ $t("trading_cards.title") }}
      <InfoBadge
        v-if="$route.path === '/cards'"
        id="info-card-album"
        :info-text="$t('info_badge.cards.album')"
        faq-link="cards"
        faq-article="360009873097"
        size="lg"
      />
    </div>

    <div class="button-container to-center-abs">
      <div
        v-if="
          $store.getters['chat/cardWishActive'] &&
          $store.getters['teams/teamActive']
        "
        class="button-wish bg-img text-white text-center text-uppercase m-auto clickable"
        @click="activateWishMode"
      >
        {{ $t("trading_cards.desire.ready_new") }}
      </div>
      <div
        v-else-if="!$store.getters['teams/isTeamMember']"
        class="button-wish disabled bg-img text-white text-center text-uppercase m-auto clickable"
        @click="handleClickAttempt"
      >
        {{ $t("trading_cards.desire.ready_new") }}
      </div>

      <div
        v-else-if="
          !$store.getters['chat/cardWishActive'] &&
          $store.getters['teams/teamActive']
        "
        class="button-wish active bg-img text-white text-center text-uppercase m-auto"
      >
        <div class="text1 to-center-abs row m-auto">
          <div class="subtext col-9 m-auto">
            {{ $t("trading_cards.desire.next_time_new") }}
          </div>
          <div class="countdown col-3 m-auto">
            <CountdownDigital
              :time="$store.state.chat.cardWishTime"
              @over.once="getWishTime"
            />
          </div>
        </div>
        <div class="text2 to-center-abs row m-auto">
          <div class="subtext col-12 m-auto">
            {{ $t("trading_cards.desire.ready_new") }}
          </div>
        </div>
      </div>

      <!--      TODO: Needs function in backend-->
      <!--      <div-->
      <!--        v-else-if="-->
      <!--          !$store.getters['chat/cardWishActive'] &&-->
      <!--          $store.getters['teams/teamActive']-->
      <!--        "-->
      <!--        class="button-wish-active bg-img text-white text-center text-uppercase m-auto clickable"-->
      <!--        @click="wishWithCrowns"-->
      <!--      >-->
      <!--        <div class="text1 to-center-abs row m-auto">-->
      <!--          <div class="subtext col-9 m-auto">-->
      <!--            {{ $t("trading_cards.desire.next_time_new") }}-->
      <!--          </div>-->
      <!--          <div class="countdown col-3 m-auto">-->
      <!--            <CountdownDigital-->
      <!--              :time="$store.state.chat.cardWishTime"-->
      <!--              @over.once="getWishTime"-->
      <!--            />-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        <div class="text2 to-center-abs row m-auto">-->
      <!--          <div class="subtext col-9 m-auto">-->
      <!--            {{ $t("trading_cards.desire.with_crowns") }}-->
      <!--          </div>-->
      <!--          <div class="col-3 m-auto">-->
      <!--            <div class="crowns">200 <span class="crown-icon"></span></div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
  </div>
</template>
<script>
import * as CardsAPI from "@/API/cards";
import CountdownDigital from "@/components/base/countdown-digital";
import InfoBadge from "@/components/base/info-badge";

export default {
  name: "CardsHeroBox",
  components: { InfoBadge, CountdownDigital },
  data() {
    return {};
  },
  computed: {
    user() {
      return this.$store.getters["user/currentUser"];
    },
  },

  mounted() {
    this.getWishTime();
  },
  methods: {
    async getWishTime() {
      let seconds = await CardsAPI.getNextWishTime();
      let now = Date.now();
      // Timer needs milliseconds until deadline
      let wishTime = seconds * 1000 + now;
      this.$store.commit("chat/setCardWishTime", wishTime);
    },

    activateWishMode() {
      if (this.$route.path !== "/cards") {
        this.$router.push("/cards").catch(() => {});
      }

      this.$store.commit("cards/setCardsForcedWishMode", true);
    },
    handleClickAttempt() {
      if (!this.$store.getters["teams/isTeamMember"]) {
        this.$store.commit("popups/setAlertBannerContent", {
          alertText: this.$t("locked.not_in_team"),
          type: "fellowship",
          alertHeading: this.$t("locked.not_in_team.title"),
        });
      } else if (!this.$store.getters["teams/teamActive"]) {
        this.$store.commit("popups/setAlertBannerContent", {
          alertText: this.$t("locked.team_not_full"),
          type: "fellowship",
          alertHeading: this.$t("locked.not_in_team.title"),
        });
      }
    },

    wishWithCrowns() {
      alert("WIP");
    },
  },
};
</script>
<style lang="scss">
#cards-hero-box {
  width: 37.188rem;
  height: 6.75rem;
  background-image: url("~@/assets/img/cards/trading-cards-text-badge.png");
  top: 8.75rem;

  .preload {
    height: 0;
    width: 0;
    background-image: url(~@/assets/img/cards/btn-wish-card-hover.png),
      url(~@/assets/img/cards/btn-wish-card-hover.png);
  }

  .headline {
    cursor: default;
    font-size: 3rem;
    font-family: Cinzel-regular;
    top: 0.75rem;
    text-shadow: 0 0 1rem rgba(77, 229, 255, 0.6),
      0 0 1rem rgba(77, 229, 255, 0.6), 0 0 1rem rgba(77, 229, 255, 0.6),
      0 0 1rem rgba(77, 229, 255, 0.6), 0 0 0.5rem rgba(77, 229, 255, 0.6),
      0 0 0.5rem rgba(77, 229, 255, 0.6), 0 0 0.25rem rgba(77, 229, 255, 0.6),
      0 0 0.25rem rgba(77, 229, 255, 0.6);
  }
  #info-card-album {
    right: -50px;
  }

  .button-container {
    top: 8.125rem;

    .button-wish {
      width: 24.5rem;
      height: 3.5rem;
      background: url(~@/assets/img/cards/btn-wish-card.png) center/100% 100%
        no-repeat;
      font-size: 1.25rem;
      font-family: Ubuntu-Bold;
      padding: 0.75rem 0.625rem 0 0.625rem;

      &:hover {
        background: url(~@/assets/img/cards/btn-wish-card-hover.png) center/100%
          100% no-repeat;
      }
      &.disabled {
        filter: grayscale(100);
        cursor: default;

        &:hover {
          filter: grayscale(100) drop-shadow(0 0 transparent);
          background: url(~@/assets/img/cards/btn-wish-card.png) center/100%
            100% no-repeat;
        }
      }
      &.active {
        background: url(~@/assets/img/cards/btn-wish-card-w-timer.png)
          center/100% 100% no-repeat;
        width: 24.5rem;
        height: 5.375rem;
        cursor: default;

        .text1 {
          top: 0.188rem;
          color: #ff0000;
          width: 18.75rem;

          .subtext {
            line-height: 1;
            font-size: 0.813rem;
            font-family: Ubuntu-Medium;
          }

          .countdown {
            font-size: 1.125rem;
            font-family: Ubuntu-Bold;
          }
        }

        .text2 {
          top: 2.625rem;
          width: 23.75rem;

          .subtext {
            font-size: 1.25rem;
            font-family: Ubuntu-Bold;
          }

          .crowns {
            font-size: 1.125rem;
            font-family: Ubuntu-Bold;
            padding-right: 3.125rem;
            position: relative;

            .crown-icon {
              position: absolute;
              top: 0.063rem;
              right: 0;
              width: 1.75rem;
              height: 1.313rem;
              background: url(~@/assets/img/common/crown6.png) center/100% 100%
                no-repeat;
              filter: drop-shadow(0 0.125rem 0.188rem black);
            }
          }
        }

        &:hover {
          background: url(~@/assets/img/cards/btn-wish-card-w-timer.png)
            center/100% 100% no-repeat;
        }
      }
    }
  }
}
</style>
