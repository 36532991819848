<template>
  <b-modal
    id="modal-shop"
    class="custom-modal2"
    :hide-footer="true"
    modal-class="custom-modal2"
    content-class="rectangular-dark-blue-modal-no-shadow"
    @shown="onOpen"
    @hidden="onClose"
  >
    <template slot="modal-header" slot-scope="{ close }">
      <a href="#" class="close-button" @click="close()"> </a>
      <div class="head-icon to-center-abs"></div>
    </template>

    <template slot="default">
      <Spinner
        v-if="loading"
        size="medium"
        context="Loading Shop..."
        classes="dead-center"
      ></Spinner>
      <div id="shop-container">
        <b-row>
          <!--          navigation-->
          <b-col id="nav-container" cols="12">
            <b-nav class="tabbed-nav align-items-center justify-content-center">
              <NavTabCustomAction
                v-for="(tab, index) in contentTabs"
                :key="tab.title"
                :click-event="true"
                :label="$t(tab.title)"
                :active="currentTab === index"
                @click-event="changeCurrentTab(index)"
                @click.native="playSoundTab"
              />
            </b-nav>
          </b-col>
          <!--          table-->
          <b-col cols="12" class="table-col">
            <b-table
              v-if="items.length > 0"
              id="shop-table"
              :items="currentItems"
              :fields="fields"
              thead-class="head-row-text"
              @row-clicked="chooseItem"
            >
              <template v-slot:cell(name)="{ item }">
                <div class="shop-item-name col-8 d-flex align-items-center">
                  {{ $t(`shop.items.${item.package.slug}`) }}
                </div>
              </template>

              <!--              icon-->
              <template v-slot:cell(icon)="{ item }">
                <div class="shop-item-icon">
                  <img
                    draggable="false"
                    :src="getImageSrc(item.package.slug)"
                    class="h-75"
                  />
                  <InfoBadge
                    v-if="item.rewards.expbooster"
                    id="info-expbooster"
                    :tooltip-only="true"
                    :info-text="
                      $t('info_badge.shop.expbooster_info', { 0: 50 })
                    "
                  />

                  <div
                    v-if="getMoreContentLabel(item.package)"
                    class="shop-more-percent"
                  >
                    {{ getMoreContentLabel(item.package).value }}
                  </div>
                </div>
              </template>

              <!--              rewards content-->
              <template v-slot:cell(content)="{ item }">
                <div v-if="item.rewards.gold > 0" class="row h-100 text-right">
                  <div class="col-10">
                    <div class="shop-item-amount glow">
                      {{ formatNumber(item.rewards.gold) }}
                    </div>
                    <div class="old-price glow">
                      {{ getOldPrice(item.package).value }}
                    </div>
                  </div>
                  <div class="col-2">
                    <div class="main-currency-icon gold">
                      <img src="@/assets/img/common/coin1.png" class="h-100" />
                    </div>
                  </div>
                </div>

                <div v-if="item.rewards.crown > 0" class="row h-100 text-right">
                  <div class="col-10">
                    <div class="shop-item-amount glow">
                      {{ formatNumber(item.rewards.crown) }}x
                    </div>
                    <div class="old-price glow">
                      {{ getOldPrice(item.package).value }}
                    </div>
                  </div>
                  <div class="col-2">
                    <div class="main-currency-icon crown">
                      <img src="@/assets/img/common/crown4.png" class="h-100" />
                    </div>
                  </div>
                </div>

                <div
                  v-if="item.rewards.expbooster > 0"
                  class="row h-100 text-right"
                >
                  <div class="col-12">
                    <div class="shop-item-amount-expbooster glow">
                      Turbo +50%
                    </div>
                    <div class="old-price-expbooster glow">
                      {{
                        $t("shop.expboost.valid", {
                          0: Math.round(item.rewards.expbooster / 60),
                        })
                      }}
                    </div>
                  </div>
                </div>
              </template>

              <!--              bonus-->
              <template v-slot:cell(bonus)="{ item }">
                <div class="row item-row">
                  <div class="shop-item-bonus text-right col-3">
                    <div v-if="item.rewards.card" class="reward-text glow">
                      {{ item.rewards.card }} x
                    </div>
                  </div>
                  <div class="col-1">
                    <div
                      v-if="item.rewards.card"
                      class="reward-icon reward-icon-card"
                    >
                      <img
                        src="@/assets/img/common/cardstack-3.png"
                        class="h-100"
                      /><InfoBadge
                        :id="`info-trading-cards${item.id}`"
                        :info-text="$t('info_badge.shop.trading_cards')"
                        :tooltip-only="true"
                      />
                    </div>
                  </div>

                  <div class="shop-item-bonus text-right col-3">
                    <div v-if="item.rewards.loyalty" class="reward-text glow">
                      {{ item.rewards.loyalty }} x
                    </div>
                  </div>
                  <div class="col-1">
                    <div
                      v-if="item.rewards.loyalty"
                      class="reward-icon reward-icon-loyalty"
                    >
                      <img
                        src="@/assets/img/nav-menu/nav-icon-loyalty.png"
                        class="h-100"
                      /><InfoBadge
                        :id="`info-royalty${item.id}`"
                        :info-text="$t('info_badge.shop.loyalty')"
                        :tooltip-only="true"
                      />
                    </div>
                  </div>

                  <div class="shop-item-bonus text-right col-3">
                    <div v-if="item.rewards.elixir" class="reward-text glow">
                      {{ item.rewards.elixir }} x
                    </div>
                  </div>
                  <div class="col-1">
                    <div
                      v-if="item.rewards.elixir"
                      class="reward-icon reward-icon-elixir"
                    >
                      <img
                        src="@/assets/img/common/elixir.png"
                        class="h-100"
                      /><InfoBadge
                        :id="`info-elixir${item.id}`"
                        :info-text="$t('info_badge.shop.elixir')"
                        :tooltip-only="true"
                      />
                    </div>
                  </div>
                </div>
              </template>

              <!--              price button-->
              <template v-slot:cell(price)="{ item }">
                <div
                  class="btn ok-button-green round-new"
                  @click="chooseItem(item)"
                >
                  € {{ formatPrice(item.package.price) }}
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </div>
    </template>
  </b-modal>
</template>

<script>
import NavTabCustomAction from "@/components/base/nav-tab-custom-action.vue";
import InfoBadge from "@/components/base/info-badge.vue";
import * as paymentAPI from "@/API/payment.js";
import Spinner from "@/components/animation/animation-spinner";
import getRewardsFromPackage from "@/mixins/paymentFunctions.js";
import getMoreContentLabel from "@/mixins/paymentFunctions.js";
import getOldPrice from "@/mixins/paymentFunctions.js";

export default {
  name: "ModalShop",
  components: {
    NavTabCustomAction,
    Spinner,
    InfoBadge,
  },
  mixins: [getRewardsFromPackage, getMoreContentLabel, getOldPrice],
  props: {
    preselectedTab: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      fallback:
        "https://images.unsplash.com/photo-1560391243-e6431f0fa114?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1567&q=80",
      loading: true,
      data: null,
      currentTab: 0,
      contentTabs: [
        {
          title: "shop.tab_title.gold",
          path: "/league",
          active: true,
        },
        {
          title: "shop.tab_title.crowns",
          path: "/kingspath",
          active: false,
        },
      ],
      fields: [
        {
          key: "name",
          label: "",
          sortable: false,
          tdClass: "col-name",
        },
        {
          key: "icon",
          label: this.$t("shop.header.package"),
          sortable: false,
          tdClass: "col-icon",
        },
        {
          key: "content",
          label: this.$t("shop.header.content"),
          sortable: false,
          tdClass: "col-content",
        },
        {
          key: "bonus",
          label: this.$t("shop.header.bonus"),
          sortable: false,
          tdClass: "col-bonus",
        },
        {
          key: "price",
          label: this.$t("shop.header.price"),
          sortable: false,
          tdClass: "col-price",
        },
      ],
    };
  },
  computed: {
    //all shop items. mapped all containing rewards
    items: function () {
      if (!this.data) {
        return [];
      }

      let result = [];
      for (let i = 0; i < this.data.length; i++) {
        let item = this.data[i];
        let packageObj = item.offer.packages[0];
        item.offer.packages[0].rewards = this.getRewardsFromPackage(packageObj);
        result.push(packageObj);
      }

      return result;
    },

    //gold icons only
    coinItems: function () {
      if (this.items.length <= 0) {
        return;
      }
      let result = [];

      //search gold items
      this.items.forEach((item) => {
        if (item.rewards.hasOwnProperty("gold")) {
          result.push(item);
          item.category = "Shop";
          item.variant = "Gold";
        }
      });

      //sort DESC
      result.sort(function (a, b) {
        return b.rewards.gold - a.rewards.gold;
      });

      return result;
    },

    //crown items only (exp booster included)
    crownItems: function () {
      if (this.items.length <= 0) {
        return;
      }
      let result = [];

      //search gold items
      this.items.forEach((item) => {
        if (item.rewards.hasOwnProperty("crown")) {
          result.push(item);
          item.category = "Shop";
          item.variant = "Crown";
        }

        if (item.rewards.hasOwnProperty("expbooster")) {
          result.push(item);
          item.category = "Shop";
          item.variant = "EXP";
        }
      });

      //sort DESC
      result.sort(function (a, b) {
        return b.rewards.crown - a.rewards.crown;
      });
      return result;
    },
    currentItems: function () {
      switch (this.currentTab) {
        case 0:
          return this.coinItems;
        case 1:
          return this.crownItems;
        default:
          return this.coinItems;
      }
    },
  },

  mounted: function () {
    // this.$bvModal.show("modal-shop");
  },

  methods: {
    onOpen() {
      this.fetchOfferList();
      this.currentTab = this.preselectedTab;
    },
    onClose() {
      this.firebaseClickItemTreasury("shop_close_button");
    },
    getImageSrc: function (slug) {
      try {
        return require("@/assets/img/shop/" + slug + ".png");
      } catch (e) {
        console.log(e);
        return "";
      }
    },

    //fetch items from shop
    fetchOfferList: async function () {
      await paymentAPI
        .fetchPaymentOffersByOfferType("SHOP_ITEM")
        .then((result) => {
          this.data = result;
          this.loading = false;
        })
        .catch((e) => console.log(e));
    },

    changeCurrentTab: function (newTab) {
      this.currentTab = newTab;
    },

    chooseItem: function (offer) {
      this.firebaseClickItemTreasury("shop_buy_button_#" + offer.id + "#");
      offer.callback = () => {
        this.$bvModal.show("modal-shop");
      };
      this.$store.commit("shop/setShopChosenPackage", offer);
      this.$bvModal.show("modal-payment");
      this.$bvModal.hide("modal-shop");
    },
  },
};
</script>
<style lang="scss">
$modal-width: 1200px;
$modal-height: 632px;

#modal-shop {
  .modal-dialog {
    top: 80px;
    max-width: $modal-width;
    min-height: $modal-height;
    height: auto;
    width: 100%;
    padding: 0 !important;

    @media (max-height: 785px) {
      transform: scale(0.8);
      top: 0;
      margin-bottom: -20%;
    }

    @media (max-height: 650px), (max-width: 1300px) {
      transform: scale(0.7);
      top: -40px;
      margin-bottom: -30%;
    }

    @media (max-width: 1100px) {
      left: -80px;
    }
  }

  .modal-content {
    width: $modal-width;
    box-shadow: 0 0 26px rgba(77, 229, 255, 0.6),
      0 0 26px rgba(77, 229, 255, 0.6), 0 0 26px rgba(77, 229, 255, 0.6),
      0 0 26px rgba(77, 229, 255, 0.6), 0 0 13px rgba(77, 229, 255, 0.6),
      0 0 13px rgba(77, 229, 255, 0.6), 0 0 6px rgba(0, 94, 110, 0.8),
      0 0 6px rgba(0, 94, 110, 0.8);
    border: 2px solid white;
    min-height: $modal-height;
    border-radius: 16px;
  }

  .nav-tabs {
    border: none;
  }

  .content-tab {
    .middle {
      margin-left: -1px;
    }
  }

  .close-button {
    top: -80px;
    right: -50px;
  }

  .spinner-container {
    top: -125px;
  }

  td {
    height: 70px;
    line-height: 28px;

    &.col-icon {
      width: 200px;
    }
    &.col-content {
      width: 450px;
    }

    .row {
      max-height: 70px;
    }
  }

  .shop-item-price {
    .deco-gem {
      width: 22px;
      top: -8px;
    }
  }

  .col-bonus {
    width: 37%;
  }
}
</style>

<style scoped lang="scss">
#modal-shop {
  .modal-header {
    .head-icon {
      top: -95px;
      background: url(~@/assets/img/shop/shop-visual-default.png) center/100%
        100% no-repeat;
      width: 480px;
      height: 182px;
    }
  }

  #shop-container {
    #nav-container {
      position: absolute;
      top: 60px;
      z-index: 10;
    }

    .glow {
      text-shadow: 0 0 16px rgba(77, 229, 255, 0.6),
        0 0 8px rgba(77, 229, 255, 0.6), 0 0 8px rgba(77, 229, 255, 0.6),
        0 0 4px rgba(0, 94, 110, 0.8), 0 0 4px rgba(0, 94, 110, 0.8);
    }

    .table-col {
      padding-top: 140px;
    }

    .item-row {
      padding-left: 10px;
      padding-right: 10px;
    }

    .shop-item-name {
      font-size: 21px;
      font-family: Ubuntu-Medium;
      padding-left: 25px;
      text-align: left;
    }

    .shop-item-icon {
      height: 70px;
      position: relative;

      .shop-more-percent {
        top: 0;
        right: 0;
      }
    }

    .shop-item-amount {
      font-size: 28px;
      font-family: Ubuntu-Bold;
      text-align: right;
    }

    .shop-item-amount-expbooster {
      text-transform: uppercase;
      font-size: 28px;
      font-family: Ubuntu-Bold;
      text-align: right;
    }

    .main-currency-icon {
      position: absolute;

      &.gold {
        right: 10px;
        top: -2px;
        height: 34px;
      }

      &.crown {
        right: 0;
        top: -2px;
        height: 34px;
      }
    }
    .old-price {
      font-size: 21px;
      font-family: Ubuntu;
      text-decoration: line-through;
      color: #ffffff;
      text-align: right;
    }
    .old-price-expbooster {
      font-size: 20px;
      font-family: Ubuntu;
      color: #ffffff;
    }

    .reward-icon {
      position: absolute;

      &.reward-icon-card {
        left: -25px;
        top: -7px;
        height: 46px;
      }
      &.reward-icon-loyalty {
        left: -30px;
        top: -9px;
        height: 50px;
      }
      &.reward-icon-elixir {
        left: -30px;
        top: -11px;
        height: 52px;
      }
    }
    .reward-text {
      font-size: 21px;
      font-family: Ubuntu-Bold;
      padding-right: 20px;
      text-transform: none;
    }

    .ok-button-green {
      color: white;
      font-family: Ubuntu-Bold;
      font-size: 21px;
      width: 128px;
      height: 46px;
      border-radius: 20px;
      padding: 4px 0 0 0;
    }
  }
}
</style>
