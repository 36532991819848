<script>
import elixir_morph from "./json/animation-bonusslot-symbolMorphFX-spritesheet.json";
import elixir_flare from "./json/bonusslot-potion-upcraftFlare-Spritesheet.json";
import AnimationBaseFunctions from "./animation-base-functions";
import AnimationFirework from "../animation/animation-firework";
import AnimationEffects from "../animation/animation-effects";

export default {
  name: "AnimationBonusSlot",
  mixins: [AnimationBaseFunctions, AnimationFirework, AnimationEffects],
  methods: {
    preloadingBonusSlot: function () {
      let imageSrc = [];
      imageSrc.push(
        require("@/assets/img/animation/elixir/animation-bonusslot-symbolMorphFX-spritesheet.png"),
        require("@/assets/img/animation/elixir/bonusslot-potion-upcraft-displayglowback.png"),
        require("@/assets/img/animation/elixir/bonusslot-potion-upcraftFlare-Spritesheet.png"),
        require("@/assets/img/animation/elixir/meinekrone-frame-deko-full-sheet-32.png"),
        require("@/assets/img/animation/firework/meinekrone-coinfirework-masked-bottomup-spritesheet.png"),
        require("@/assets/img/animation/effects/meinekrone-confetti-bottomup-spritesheet.png")
      );
      this.preloadImages(imageSrc);

      let soundSrc = [];
      soundSrc.push(
        require("@/assets/sound/bonus-slot/kleinekrone-animations-atmosphere-LasVegasAtmo5Sek.mp3"),
        require("@/assets/sound/bonus-slot/kleinekrone-animations-mainsound-metal-hit-big.mp3"),
        require("@/assets/sound/bonus-slot/kleinekrone-animations-mainsound-Slot-Button-Start.mp3"),
        require("@/assets/sound/bonus-slot/kleinekrone-animations-mainsound-Slot-ReelSpinMelody.mp3"),
        require("@/assets/sound/bonus-slot/kleinekrone-animations-musicident-bonusslot.mp3"),
        require("@/assets/sound/bonus-slot/kleinekrone-animations-mainsound-Slot-SpinFXLoop.mp3")
      );
      this.preloadSounds(soundSrc);
    },

    preloadImages: function (src) {
      let preloader = document.getElementById("preloader");
      for (let i = 0; i < src.length; i++) {
        let img = new Image();
        img.src = src[i];
        preloader.appendChild(img);
      }
    },
    preloadSounds: function (src) {
      let preloader = document.getElementById("preloader");
      for (let i = 0; i < src.length; i++) {
        let audio = new Audio(src[i]);
        audio.id = src[i];
        preloader.appendChild(audio);
      }
    },

    AnimationElixirAnimateMorph: function (x, y, preload, parent) {
      let json = this.createMergeJsonArr([elixir_morph]);
      return this.AnimationElixirDoAnimation(
        x,
        y,
        json,
        parent,
        "elixir-morph",
        preload
      );
    },
    AnimationElixirAnimateFlare: function (x, y, preload, parent) {
      let json = this.createMergeJsonArr([elixir_flare]);
      return this.AnimationElixirDoAnimation(
        x,
        y,
        json,
        parent,
        "elixir-flare",
        preload,
        true
      );
    },

    AnimationElixirDoAnimation: function (
      x,
      y,
      json_arr,
      parent,
      classname,
      preload,
      scaleIn
    ) {
      let steps = json_arr.length - 1;
      let body = document.body;

      let element = document.createElement("gsap-elixir");
      element.classList.add(classname);

      let parentElement = body;
      if (parent && !preload) {
        parentElement = parent;
      }

      parentElement.appendChild(element);

      element.frame = 0;

      this.gsap.set(element, {
        x: x,
        y: y,
      });
      if (preload) {
        this.gsap.set(element, {
          opacity: 0,
        });
      }

      let duration = steps / 24;
      if (preload) {
        duration = 0.5;
      }
      this.gsap.to(element, duration, {
        frame: steps,
        ease: `steps(${steps})`,
        onComplete: function () {
          parentElement.removeChild(element);
        },
        modifiers: {
          frame: (i, target) => {
            let sprite = json_arr[Math.round(i)].frame;

            //set background images
            this.gsap.set(target, {
              xPercent: -50,
              yPercent: -50,
              background:
                'url("' +
                require("@/assets/img/animation/elixir/" +
                  json_arr[Math.round(i)].image) +
                '")',
            });

            //set posiion and with of sprite
            target.style.backgroundPosition =
              -sprite.x + "px " + -sprite.y + "px";
            target.style.width = sprite.w + "px";
            target.style.height = sprite.h + "px";

            return i;
          },
        },
      });

      const timeline = this.gsap.timeline();
      if (scaleIn) {
        timeline
          .fromTo(
            element,
            0.5,
            {
              scale: 0,
            },
            {
              scale: 1,
            },
            0
          )
          .to(
            element,
            0.5,
            {
              scale: 0,
            },
            0.5
          );
      }

      return element;
    },
  },
};
</script>

<style lang="scss">
gsap-elixir {
  display: block;
  position: absolute;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
</style>
