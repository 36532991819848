<template>
  <div id="modal-bundesliga-no-bet" class="container-fluid">
    <div class="headline to-center-abs">
      <div class="sub1 bold">
        {{ $t("events.bundesliga.no_bet_screen.headline") }}
      </div>
      <div class="sub2 bold">
        {{ $t("events.bundesliga.no_bet_screen.subline") }}
      </div>
    </div>
    <div class="right-side">
      <div class="tipp-reward to-center-abs">
        <div class="deal-item">
          <span class="pr-3 small"> {{ goldReward }}</span>
          <div class="deal-icon gold small"></div>
        </div>
      </div>

      <div class="bonus-box to-center-abs">
        <div class="bonus-text to-center-abs">
          <img
            :src="
              require(`@/assets/img/events/bundesliga/win-headline-bonus-${$store.state.locale}.png`)
            "
            class="w-100"
          />
        </div>
        <div class="bonus-rewards to-center-abs">
          <div class="bonus1 to-center-abs">
            <div v-if="packageGold" class="deal-item w-100 reward-height">
              <span class="pr-3 big"> {{ packageGold }}</span>
              <div class="deal-icon gold big"></div>
            </div>
          </div>

          <div class="bonus2 to-center-abs">
            <div
              v-if="packageCrowns"
              class="deal-item w-50 justify-content-end"
            >
              <span class="small2"> {{ packageCrowns }}</span>
              <div class="deal-icon crown small"></div>
            </div>
            <div
              v-if="packageLoyalty"
              class="deal-item w-50 justify-content-start pl-3"
            >
              <span class="small2"> {{ packageLoyalty }}</span>
              <div class="deal-icon loyalty small"></div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="$store.getters['events/getNextFootballEvent']"
        class="timer-container to-center-abs"
      >
        <div
          class="timer-countdown w-50"
          :class="{
            small: isDateMoreThanOneDay(nextTipDate),
          }"
        >
          <CountdownDigital :time="nextTipDate" :with-time-diff="true"/>
        </div>
        <div class="timer-text">
          {{ $t("events.bundesliga.win_screen.countdown") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CountdownDigital from "@/components/base/countdown-digital";
import dateFunctions from "@/mixins/dateFunctions.js";

export default {
  name: "ModalBundesligaNoBet",
  components: {
    CountdownDigital,
  },
  mixins: [dateFunctions],
  props: {
    packageGold: {
      type: String,
      default: "",
    },
    packageCrowns: {
      type: String,
      default: "",
    },
    packageLoyalty: {
      type: Number,
      default: 0,
    },
    goldReward: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      modalId: "modal-bundesliga-no-bet",
      loading: true,
      matchdayIndex: 0,
    };
  },

  computed: {
    nextTipDate() {
      let result = new Date(
        this.$store.getters["events/getNextFootballEvent"].start_at
      );
      return result.getTime();
    },
  },
};
</script>
<style lang="scss">
$modal-width: 1121px;
$modal-height: 630px;

#modal-bundesliga-no-bet {
  .left-side {
    width: 45%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 125px;
  }
  .headline {
    top: 50px;
    text-transform: uppercase;
    text-shadow: 0 0 18px rgba(77, 229, 255, 0.71),
      0 0 18px rgba(77, 229, 255, 0.71);
    line-height: 1.4;

    .sub1 {
      font-size: 54px;
    }
    .sub2 {
      font-size: 27px;
    }
  }
  .right-side {
    width: 57%;
    height: 100%;
    position: absolute;
    right: 0;

    .tipp-reward {
      width: 504px;
      height: 94px;
      top: 220px;
      border-radius: 10px;
      background-image: linear-gradient(to bottom, #4de5ff 10%, #050d1c 80%);
      border: 1px solid white;
      box-shadow: -3px -3px 15px 15px rgba(77, 229, 255, 0.5);
    }

    .bonus-box {
      width: 559px;
      height: 256px;
      top: 300px;
      border-radius: 10px;
      background: url(~@/assets/img/events/bundesliga/win-megabonus-panel-bg.png)
        bottom center/100% 100% no-repeat;

      .bonus-text {
        width: 400px;
        top: 50px;
      }

      .bonus-rewards {
        top: 90px;
      }

      .bonus1 {
        top: 25px;
      }
      .bonus2 {
        top: 45px;
      }
    }

    .reward-height {
      height: 0;
    }

    .deal-item,
    .deal-item2 {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;

      span {
        font-family: Ubuntu-bold;
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
        background-image: linear-gradient(to bottom, #f7ffff, #ffee51);
        text-shadow: none;
        filter: drop-shadow(0 0 8px #0a2700);

        &.big {
          font-size: 67px;
        }
        &.small {
          font-size: 60px;
        }
        &.small2 {
          font-size: 58px;
        }
      }

      .deal-icon,
      .deal-icon2 {
        background-position: center;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin-left: 5px;

        &.gold {
          background-image: url(~@/assets/img/common/coin1.png);
          &.small {
            width: 52px;
            height: 52px;
          }
          &.big {
            width: 60px;
            height: 60px;
          }
        }
        &.crown {
          background-image: url(~@/assets/img/common/crown6.png);

          &.small {
            width: 76px;
            height: 57px;
          }
        }
        &.loyalty {
          background-image: url(~@/assets/img/common/loyalty-bonus.png);

          &.small {
            width: 57px;
            height: 58px;
          }
        }
      }
    }

    .deal-item2 {
      display: block;
    }
    .timer-container {
      bottom: 20px;
      left: 20px;
      width: 370px;
      height: 76px;
      background: url(~@/assets/img/events/bundesliga/timer-next-tipp.png)
        bottom center/100% 100% no-repeat;

      .timer-countdown {
        position: absolute;
        left: 10px;
        top: 10px;
        font-family: Ubuntu-Bold;
        font-size: 37px;
        color: #ff4040;
        &.small {
          font-size: 26px;
          top: 18px;
        }
      }
      .timer-text {
        position: absolute;
        right: 25px;
        top: 18px;
        text-align: left;
        line-height: 1.3;
        font-family: Ubuntu-Bold;
        font-size: 17px;
        color: white;
        text-transform: uppercase;
        width: 40%;
      }
    }
  }
}
</style>
