<template>
  <div
    id="canvas-container-animation-spine-event-bundesliga-win"
    ref="canvascontainer"
    class="canvas-container-animation-spine-event-bundesliga-win"
  ></div>
</template>

<script>
import AnimationSpineBaseFunctions from "@/components/animation/animation-spine-base-functions";
import { Spine } from "pixi-spine";

export default {
  name: "AnimationEventBundesligaWin",
  mixins: [AnimationSpineBaseFunctions],
  props: {
    loop: {
      type: Boolean,
      default: true,
    },
    run: {
      type: Boolean,
      default: true,
    },
    winAnimation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      unsubscribeEvents: [],
      destroyApp: false,
    };
  },

  watch: {
    run: function () {
      this.play();
    },
  },
  mounted() {
    this.play();
  },
  beforeDestroy() {
    //destroy event listeners
    for (let i = 0; i < this.unsubscribeEvents.length; i++) {
      this.unsubscribeEvents[i]();
    }

    this.destroyApp = true;
  },

  methods: {
    play: function () {
      const canvasEl = document.getElementById(
        "canvas-animation-spine-event-bundesliga-win"
      );

      const assets = [
        [
          "win-coinflight",
          "/assets/spines/events/bundesliga/tipking-win-coins/kleinekrone-ani-specialevent-tipking-win.json",
        ],
        [
          "win-coinflight2",
          "/assets/spines/events/bundesliga/tipking-win-coins/kleinekrone-ani-specialevent-tipking-win2.json",
        ],
        [
          "win-coinflight3",
          "/assets/spines/events/bundesliga/tipking-win-coins/kleinekrone-ani-specialevent-tipking-win3.json",
        ],
        [
          "win-coinflight4",
          "/assets/spines/events/bundesliga/tipking-win-coins/kleinekrone-ani-specialevent-tipking-win4.json",
        ],
      ];
      const options = {
        width: 500,
        height: 300,
        antialias: true,
        backgroundAlpha: 0,
        autoResize: true,
      };

      const app = this.createCanvas(
        options,
        assets,
        this.$refs.canvascontainer.id
      );
      app.view.id = "canvas-animation-spine-event-bundesliga-win";

      app.ticker.add(() => {
        if (this.destroyApp) {
          this.destroyApp = false;
          this.destroyAll(app);
        }
      });

      const setup = (loader, res) => {
        let spineData = this.getSpineData(res);

        spineData.forEach((data) => {
          const spine = new Spine(data);

          //skeleton and animation configs
          let animationIndex = 0;

          // set the position
          spine.x = app.screen.width / 1.5;
          spine.y = app.screen.height - 50;

          // set scale
          spine.scale.set(0.7);

          //add spine to stage
          app.stage.addChild(spine);

          let animation = data.animations[animationIndex];
          //run animation
          spine.state.setAnimation(0, animation.name);

          //show first frame when not running
          if (!this.run) {
            spine.state.getCurrent(0).animationEnd = 0;
            return;
          }

          //set animation end
          spine.state.getCurrent(0).animationEnd = animation.duration;

          // Detach event listeners
          this.unsubscribeEvents.push(() => {
            spine.state.getCurrent(0).onEvent = null;
          });

          //add loop
          if (this.loop) {
            spine.state.getCurrent(0).loop = true;
          }
        });
      };

      app.loader.load(setup);

      //remove old chest when new loaded
      app.loader.onComplete.add(() => {
        if (canvasEl) {
          this.removePrevCanvas(this.$refs.canvascontainer.id);
        }
      });
    },
  },
};
</script>

<style lang="scss">
.canvas-container-animation-spine-event-bundesliga-win {
  pointer-events: auto;
  position: absolute;
  bottom: 0;
  right: 70px;
}
</style>
