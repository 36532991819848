<template>
  <!-- <template v-if="!loading" slot="modal-header" slot-scope="{ close }">
      <a href="#" class="close-button" @click="close()"> </a>
    </template> -->
  <div id="modal-bundesliga-win" class="container-fluid">
    <div class="left-side">
      <div class="result-panel to-center-abs">
        <div class="panel-headline to-center-abs">
          {{ $t("events.bundesliga.win_screen.results") }} {{ lastTipDate }}
        </div>
        <div class="panel-text to-center-abs">
          <div class="text1">
            {{ resultText }}
          </div>
          <div v-if="winningTeam !== 'draw'" class="text2">
            {{ winningTeam }}
          </div>
        </div>
        <div class="check-icon"></div>
      </div>

      <div
        v-if="$store.getters['events/getNextFootballEvent']"
        class="timer-container to-center-abs"
      >
        <div
          class="timer-countdown w-50"
          :class="{
            small: isDateMoreThanOneDay(nextTipDate),
          }"
        >
          <CountdownDigital :time="nextTipDate" :with-time-diff="true" />
        </div>
        <div class="timer-text">
          {{ $t("events.bundesliga.win_screen.countdown") }}
        </div>
      </div>
    </div>

    <div class="right-side">
      <div class="headline to-center-abs">
        <div class="sub1">
          {{ $t("events.bundesliga.win_screen.headline_1") }}
        </div>
        <div class="sub2">
          {{ $t("events.bundesliga.win_screen.headline_2") }}
        </div>
      </div>

      <div class="tipp-reward to-center-abs">
        <div class="deal-item">
          <span class="pr-3 small"> {{ formatNumber(tippReward) }}</span>
          <div class="deal-icon gold small"></div>
        </div>
      </div>

      <div
        class="bonus-box to-center-abs"
        :class="[!packagePurchased ? 'disabled' : '']"
      >
        <div class="bonus-text to-center-abs">
          <img
            :src="
              require(`@/assets/img/events/bundesliga/win-headline-bonus-${$store.state.locale}.png`)
            "
            class="w-100"
          />
        </div>
        <div class="bonus-rewards to-center-abs">
          <div class="bonus1 to-center-abs">
            <div
              v-if="rewards && rewards.gold"
              class="deal-item w-100 reward-height"
            >
              <span class="pr-3 big"> {{ formatNumber(rewards.gold) }}</span>
              <div class="deal-icon gold big"></div>
            </div>
          </div>

          <div class="bonus2 to-center-abs">
            <div
              v-if="rewards && rewards.crown"
              class="deal-item w-50 justify-content-end"
            >
              <span class="small2"> {{ formatNumber(rewards.crown) }}</span>
              <div class="deal-icon crown small"></div>
            </div>
            <div
              v-if="rewards && rewards.loyalty"
              class="deal-item w-50 justify-content-start pl-3"
            >
              <span class="small2"> {{ formatNumber(rewards.loyalty) }}</span>
              <div class="deal-icon loyalty small"></div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!packagePurchased" class="icon-denied to-center-abs"></div>

      <div
        class="collect-button to-center-abs"
        :class="[bonusCollected ? 'disabled' : '']"
        @click="handleBonusCollect"
      >
        <div v-if="bonusCollected" class="check-icon"></div>
        <b-spinner v-if="collectSpinner"></b-spinner>

        {{ collectButtonText }}
      </div>
      <AnimationEventBundesligaWin v-if="!bonusCollected" />
    </div>
  </div>
</template>

<script>
import getRewardsFromPackage from "@/mixins/paymentFunctions.js";
import AnimationFullRewardEarned from "@/components/animation/animation-full-reward-earned";
import dateFunctions from "@/mixins/dateFunctions.js";
import CountdownDigital from "@/components/base/countdown-digital";
import AnimationEventBundesligaWin from "@/components/animation/animation-spine-event-bundesliga-win";
import { collectTipKingRaffle } from "@/API/events.js";
import { fetchPaymentOffersByOfferId } from "@/API/payment.js";

export default {
  name: "ModalBundesligaWin",
  components: {
    AnimationEventBundesligaWin,
    CountdownDigital,
  },
  mixins: [getRewardsFromPackage, AnimationFullRewardEarned, dateFunctions],
  props: {
    eventData: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      loading: true,
      matchdayIndex: 0,
      fetchedPackage: null,
      collectSpinner: false,
    };
  },

  computed: {
    offerPackage() {
      if (this.$store.state.events.activeEventOffer) {
        return this.$store.state.events.activeEventOffer.packages[0];
      } else if (this.fetchedPackage) {
        return this.fetchedPackage;
      } else {
        return null;
      }
    },

    tippReward() {
      return this.$store.state.generalConfig.settings[
        "raffle.sports.reward.gold"
      ];
    },

    lastTipDate() {
      let result = new Date(this.eventData.end_at);
      return result.toLocaleDateString(this.$store.state.locale, {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      });
    },

    nextTipDate() {
      let result = new Date(
        this.$store.getters["events/getNextFootballEvent"].start_at
      );
      return result.getTime();
    },

    rewards() {
      if (!this.offerPackage) {
        return;
      }

      return this.getRewardsFromPackage(this.offerPackage);
    },
    packagePurchased() {
      return (
        this.eventData.userEvent &&
        this.eventData.userEvent.payload.packages.length > 0
      );
    },
    winningTeam() {
      let relevantRaffle =
        this.$store.state.events.paymentEventUserRaffle.raffle;
      if (relevantRaffle.result_away === relevantRaffle.result_home) {
        return "draw";
      } else if (relevantRaffle.result_away > relevantRaffle.result_home) {
        return this.shortenString(relevantRaffle.team_away, 25);
      } else {
        return this.shortenString(relevantRaffle.team_home, 25);
      }
    },
    resultText() {
      if (this.winningTeam === "draw") {
        return this.$t("events.bundesliga.win_screen.draw");
      } else {
        return this.$t("events.bundesliga.win_screen.winning_team");
      }
    },
    bonusCollected() {
      if (
        this.$store.getters["events/getRaffleCollected"] !== this.eventData.id
      ) {
        return (
          this.$store.state.events.paymentEventUserRaffle.status === "COLLECTED"
        );
      } else {
        return true;
      }
    },
    collectButtonText() {
      if (this.bonusCollected) {
        return this.$t("events.bundesliga.win_screen.collect_button.collected");
      } else {
        return this.$t("events.bundesliga.win_screen.collect_button");
      }
    },
  },
  mounted() {
    if (!this.offerPackage) {
      this.fetchPackages();
    }
  },

  methods: {
    fetchPackages() {
      return new Promise((resolve, reject) => {
        let packageIdArray = this.eventData.payload.packages;
        packageIdArray.forEach((id) => {
          fetchPaymentOffersByOfferId(id)
            .then((res) => {
              this.$store.commit("events/setActiveEventOffer", res);
              this.fetchedPackage = res.packages[0];
            })
            .catch((e) => reject(e));
        });
        resolve();
      });
    },
    handleBonusCollect() {
      this.collectSpinner = true;
      let raffleId = this.$store.state.events.paymentEventUserRaffle.raffle_id;
      collectTipKingRaffle(raffleId, this.$store.getters["user/currentUser"].id)
        .then((res) => {
          this.$store.commit("events/clearPaymentEventUserRaffles");
          this.$store.commit("events/markRaffleAsCollected", this.eventData.id);
          this.$emit("hide-bundesliga-modal");
          let result = [];
          if (res.data.data.log.gold > 0) {
            result.push({
              reward: "gold",
              amount: this.formatNumber(res.data.data.log.gold),
            });
          }
          if (res.data.data.log.crowns > 0) {
            result.push({
              reward: "crown",
              amount: this.formatNumber(res.data.data.log.crowns),
            });
          }
          if (res.data.data.log.loyalty > 0) {
            result.push({
              reward: "loyalty",
              amount: this.formatNumber(res.data.data.log.loyalty),
            });
          }

          this.AnimationFullRewardEarnedAnimate(result, false);
        })
        .catch((e) => {
          console.log(e);
          this.$emit("hide-bundesliga-modal");
        });
    },
  },
};
</script>
<style lang="scss">
#modal-bundesliga-win {
  .left-side {
    width: 45%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;

    .result-panel {
      width: 448px;
      height: 168px;
      background: url(~@/assets/img/events/bundesliga/win-result-panel.png)
        bottom center/100% 100% no-repeat;
      top: 390px;
      left: 20px;

      .panel-headline {
        top: 25px;
        font-family: Ubuntu-Bold;
        font-size: 23px;
        color: white;
        text-transform: uppercase;
      }

      .panel-text {
        top: 50px;
        text-transform: uppercase;
        line-height: 1.4;
        padding: 32px;

        .text1 {
          font-family: Ubuntu-Bold;
          font-size: 17px;
          color: #4de5ff;
        }
        .text2 {
          font-family: Ubuntu-Bold;
          font-size: 23px;
          color: white;
        }
      }

      .check-icon {
        width: 40px;
        height: 40px;
        background: url(~@/assets/img/events/bundesliga/win-result-checkmark-green.png)
          bottom center/100% 100% no-repeat;
        position: absolute;
        top: 64px;
        right: 10px;
      }
    }

    .timer-container {
      top: 550px;
      left: 20px;
      width: 370px;
      height: 76px;
      background: url(~@/assets/img/events/bundesliga/timer-next-tipp.png)
        bottom center/100% 100% no-repeat;

      .timer-countdown {
        position: absolute;
        left: 10px;
        top: 10px;
        font-family: Ubuntu-Bold;
        font-size: 37px;
        color: #ff4040;

        &.small {
          font-size: 26px;
          top: 18px;
        }
      }
      .timer-text {
        position: absolute;
        right: 25px;
        top: 18px;
        text-align: left;
        line-height: 1.3;
        font-family: Ubuntu-Bold;
        font-size: 17px;
        color: white;
        text-transform: uppercase;
        width: 40%;
      }
    }
  }

  .right-side {
    width: 57%;
    height: 100%;
    position: absolute;
    right: 0;

    .headline {
      top: 0;
      text-transform: uppercase;
      text-shadow: 0 0 18px rgba(77, 229, 255, 0.71),
        0 0 18px rgba(77, 229, 255, 0.71);
      line-height: 1.4;

      .sub1 {
        font-family: Ubuntu-Bold;
        font-size: 70px;
        margin-left: -140px;
      }
      .sub2 {
        font-family: Ubuntu-Bold;
        font-size: 40px;
      }
    }

    .tipp-reward {
      width: 504px;
      height: 94px;
      top: 170px;
      border-radius: 10px;
      background-image: linear-gradient(to bottom, #4de5ff 10%, #050d1c 80%);
      border: 1px solid white;
      box-shadow: -3px -3px 15px 15px rgba(77, 229, 255, 0.5);
    }

    .bonus-box {
      width: 559px;
      height: 256px;
      top: 250px;
      border-radius: 10px;
      background: url(~@/assets/img/events/bundesliga/win-megabonus-panel-bg.png)
        bottom center/100% 100% no-repeat;

      &.disabled {
        filter: grayscale(100) brightness(0.5);
      }

      .bonus-text {
        width: 400px;
        top: 50px;
      }

      .bonus-rewards {
        top: 90px;
      }

      .bonus1 {
        top: 25px;
      }
      .bonus2 {
        top: 45px;
      }
    }
    .icon-denied {
      width: 150px;
      height: 150px;
      bottom: 207px;
      background: url(~@/assets/img/events/icn-denied-red.svg) center/100% 100%
        no-repeat;
    }
    .collect-button {
      width: 626px;
      height: 117px;
      bottom: 30px;
      border-radius: 10px;
      background: url(~@/assets/img/events/bundesliga/btn-collect-all-bg.png)
        bottom center/100% 100% no-repeat;
      cursor: pointer;
      left: -30px;
      font-family: Ubuntu-Bold;
      color: white;
      font-size: 33px;
      text-transform: uppercase;
      line-height: 3.4;
      z-index: 10;

      .check-icon {
        width: 60px;
        height: 60px;
        background: url(~@/assets/img/events/bundesliga/win-result-checkmark-green.png)
          bottom center/100% 100% no-repeat;
        position: absolute;
        top: 25px;
        right: 10px;
      }

      &:hover {
        background: url(~@/assets/img/events/bundesliga/btn-collect-all-bg-hover.png)
          bottom center/100% 100% no-repeat;
      }
      &.disabled {
        pointer-events: none;
        width: 450px;
      }
    }

    .reward-height {
      height: 0;
    }

    .deal-item,
    .deal-item2 {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;

      span {
        font-family: Ubuntu-bold;
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
        background-image: linear-gradient(to bottom, #f7ffff, #ffee51);
        text-shadow: none;
        filter: drop-shadow(0 0 8px #0a2700);

        &.big {
          font-size: 67px;
        }
        &.small {
          font-size: 60px;
        }
        &.small2 {
          font-size: 58px;
        }
      }

      .deal-icon,
      .deal-icon2 {
        background-position: center;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin-left: 5px;

        &.gold {
          background-image: url(~@/assets/img/common/coin1.png);
          &.small {
            width: 52px;
            height: 52px;
          }
          &.big {
            width: 60px;
            height: 60px;
          }
        }
        &.crown {
          background-image: url(~@/assets/img/common/crown6.png);

          &.small {
            width: 76px;
            height: 57px;
          }
        }
        &.loyalty {
          background-image: url(~@/assets/img/common/loyalty-bonus.png);

          &.small {
            width: 57px;
            height: 58px;
          }
        }
      }
    }

    .deal-item2 {
      display: block;
    }
  }
}
</style>
