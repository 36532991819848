<template>
  <div id="profile-hero-box" class="bg-img to-center-abs d-inline-flex">
    <info-badge
      id="profile-box-info"
      size="lg"
      :info-text="$t('info_badge.profile.hero_box')"
    ></info-badge>
    <div
      class="text-white text-uppercase bold to-center-abs red-shadow headline text-center"
    >
      {{ $t("profile.hero_box.headline") }}
    </div>
    <div
      class="avatar-field position-relative clickable red-shadow"
      @click="openAvatarModal"
    >
      <div class="avatar-edit-pen bg-img position-absolute"></div>
      <img draggable="false" class="avatar-img" :src="user.avatar_url" />
    </div>
    <div class="h-100 w-75 ml-5 position-relative">
      <p class="text-uppercase text-white bold red-shadow mb-1 font-21">
        {{ shortenString(user.name, 26) }}
      </p>
      <p class="medium light-red mb-1">User-Id: {{ user.id }}</p>
      <p class="text-white bold text-uppercase mb-1 font-21">
        Level {{ user.level }}
      </p>
      <div
        v-if="user.experience && user.experience.progress"
        class="position-absolute progress-bar"
      >
        <div
          class="progress-bar-fill position-absolute"
          :style="{ width: userXpProgress }"
        ></div>
      </div>
    </div>
    <ModalSelectAvatar :avatars="avatars" :close-needed="true" />
  </div>
</template>
<script>
import ModalSelectAvatar from "@/components/profile/modal-select-avatar.vue";
import { fetchAvatars } from "@/API/profile.js";
import InfoBadge from "@/components/base/info-badge.vue";

export default {
  name: "ProfileHeroBox",
  components: { ModalSelectAvatar, InfoBadge },
  data() {
    return {
      avatars: [],
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/currentUser"];
    },
    userXpProgress() {
      return this.user.experience.progress.percentage + "%";
    },
  },
  methods: {
    async openAvatarModal() {
      let listOfAvatars = await fetchAvatars();
      this.avatars = listOfAvatars;
      this.$bvModal.show("modal-select-avatar");
    },
  },
};
</script>
<style lang="scss">
#profile-hero-box {
  width: 37.25rem;
  height: 15.375rem;
  background-image: url("~@/assets/img/profile/panel-profile-bg.png");
  top: 5rem;
  padding: 7.813rem 2.813rem 1.563rem 2.813rem;
  #profile-box-info {
    top: 3rem;
    right: -3rem;
    height: 3.75rem;
    width: 3.75rem;
  }
  .red-shadow {
    text-shadow: 0 0 3rem rgba(182, 0, 0, 0.9),
      0 0 1.875rem rgba(182, 0, 0, 0.9), 0 0 2.5rem rgba(182, 0, 0, 0.9);
  }
  .light-red {
    color: #c35c5b;
    font-size: 1rem;
  }

  .font-21 {
    font-size: 1.313rem;
  }

  .headline {
    font-size: 1.625rem;
    top: 3.563rem;
    width: 14.063rem;
  }
  .avatar-field {
    height: 5.875rem;
    width: 5.875rem;
    border-radius: 10%;
    background-color: black;
    box-shadow: 0 0.188rem 0 #5a1f1f;
    .avatar-edit-pen {
      width: 3rem;
      height: 3rem;
      background-image: url("~@/assets/img/profile/icn-edit-profile-pic.svg");
      left: -1.5rem;
      top: -1.5rem;
    }
    .avatar-img {
      min-width:40px;
      min-height:40px;
      max-width: 100%;
      max-height: 100%;
    }
  }
  .progress-bar {
    width: 15rem;
    height: 2rem;
    border-radius: 1rem;
    background-color: #0f0000;
    box-shadow: 0 0.188rem 0 #5a1f1f;
    right: 0;
    bottom: 0;
    .progress-bar-fill {
      height: 1.375rem;
      background-image: linear-gradient(
        to bottom,
        #c1c1c1,
        #989898 46%,
        #e5e5e5 51%,
        #ffffff
      );
      border-radius: 0.688rem;
      left: 0.25rem;
      top: 0.375rem;
    }
  }
}
</style>
